import Cookies from "js-cookie";
import { React, useState, useEffect } from "react";

// external
import Navbar from "../../Components/Navbar";
import { fetchProfile } from "../../utils/fetchProfile";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import { modalStyle } from "../../Components/Modal/Index";
import DataGridAuditPlan from "./DataGridAuditPlan";
import EditDataModal from "./EditDataModal";
import AddDataModal from "./AddDataModal";
import ImportDataModal from "./ImportDataModal";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { fetchUserFormatted } from "../../utils/fetchUser";
// import { fetchProjectFormatted } from "../../utils/fetchProject";
import { fetchProjectFormatted } from "./DataUtil";
import { fetchAuditPlanMasterFormatted } from "../../utils/fetchAuditPlanMaster";
import {
  fetchDataAuditPlan,
  fetchDistrictFormatted,
  fetchExportDataAuditPlan,
  fetchProvincesFormatted,
  fetchRegenciesFormatted,
  fetchSubDistrictFormatted,
  fetchPeriodeFormatted,
} from "./DataUtil";
import { Modal, Button, MenuItem} from "@mui/material";
import { fetchSurveyType } from "./FilterService";
import SearchBarAudit from "../../Components/SearchBarAudit";
import DeleteDataModal from "./DeleteDataModal";
import StatusDataModal from "./StatusDataModal";
import TransferDataRak2Modal from "./TransferDataRak2Modal";
import "../../style/auditplan.css";
import UpdateDataMobileToWeb from "./UpdateDataMobileToWeb";
import { fetchLicense } from "../../utils/fetchLicense";

// Functional component for AuditPlan
function AuditPlan() {
  // initial
  const accessToken = Cookies.get("access_token") || "";
  const [isLoading, setIsLoading] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);

  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);

  // create
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [dataUserSurveyorOptions, setDataUserSurveyorOptions] = useState([]);
  const [dataUserQcOptions, setDataUserQcOptions] = useState([]);

  const [newAuditPlanData, setNewAuditPlanData] = useState({
    store_id: "",
    audit_plan: "",
    user_name_surveyor: "",
    user_name_qc: "",
    audit_start_at: "",
    audit_end_at: "",
  });
  const [selectedAuditPlanMasterId, setSelectedAuditPlanMasterId] =
    useState("");

  // rakb modal
  const [isRakbModalOpen, setIsRakbModalOpen] = useState(false);
  const [requestBodyRakb, setRequestBodyRakb] = useState({});

  // handling modal
  const [modalAdd, setModalAdd] = useState(false);

  // edit or update
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedAuditPlan, setEditedAuditPlan] = useState({});
  const [editAuditPlanId, setEditAuditPlanId] = useState(null);

  // upload
  const [file, setFile] = useState(null);

  // token
  const token = Cookies.get("access_token") || "";

  // for filter
  const dataAuditStatus = [
    {
      label: "Closed",
      id: "Closed",
    },
    {
      label: "On Progress",
      id: "On Progress",
    },
    {
      label: "Valid",
      id: "Valid",
    },
    {
      label: "Not Started",
      id: "Not Started",
    },
  ];
  const [dataProject, setDataProject] = useState([]);
  const [dataUserSurveyor, setDataUserSurveyor] = useState([]);
  const [dataAuditPlanMaster, setDataAuditPlanMaster] = useState([]);
  const [dataSurveyType, setDataSurveyType] = useState([]);
  const [dataProvince, setDataProvince] = useState([]);
  const [dataRegency, setDataRegency] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubDistrict, setDataSubDistrict] = useState([]);
  const [dataPeriode, setDataPeriode] = useState([]);

  const transformedData = dataSurveyType.map((item) => ({
    id: item.desc,
    label: item.desc,
  }));

  // selected
  const [selectedSurveyId, setSelectedSurveyId] = useState(
    localStorage.getItem("selectedSurveyId") || "all"
  );
  const [selectedProjectType, setSelectedProjectType] = useState(
    localStorage.getItem("selectedProjectType") || ""
  );
  const [selectedPeriodeId, setSelectedPeriodeId] = useState(
    localStorage.getItem("selectedPeriodeId") || "all"
  );
  const [selectedStoreTypeCode, setSelectedStoreTypeCode] = useState(
    localStorage.getItem("selectedStoreTypeCode") || "all"
  );
  const [selectedSurveyTypeCode, setSelectedSurveyTypeCode] = useState(
    localStorage.getItem("selectedSurveyTypeCode") || "all"
  );
  const [selectedProvinceId, setSelectedProvinceId] = useState(
    localStorage.getItem("selectedProvinceId") || "all"
  );
  const [selectedRegencyId, setSelectedRegencyId] = useState(
    localStorage.getItem("selectedRegencyId") || "all"
  );
  const [selectedDistrictId, setSelectedDistrictId] = useState(
    localStorage.getItem("selectedDistrictId") || "all"
  );
  const [selectedSubDistrictId, setSelectedSubDistrictId] = useState(
    localStorage.getItem("selectedSubDistrictId") || "all"
  );
  const [selectedAuditStatus, setSelectedAuditStatus] = useState(() => {
    const savedStatus = localStorage.getItem("selectedAuditStatus");
    try {
      return savedStatus ? JSON.parse(savedStatus) : [];
    } catch {
      return savedStatus ? savedStatus.split(",") : [];
    }
  });
  const [selectedUserNameSurveyor, setSelectedUserNameSurveyor] = useState(
    localStorage.getItem("selectedUserNameSurveyor") || "all"
  );

  // read
  useEffect(() => {
    const handler = setTimeout(() => {
    fetchAuditPlanMasterFormatted(accessToken, setDataAuditPlanMaster);
    fetchUserFormatted(accessToken, setDataUserSurveyorOptions, "13", "1");
    fetchUserFormatted(accessToken, setDataUserSurveyor, "13");
    fetchUserFormatted(accessToken, setDataUserQcOptions, "14", "1");
    fetchProvincesFormatted(accessToken, setDataProvince);
    fetchPeriodeFormatted(accessToken, setDataPeriode);
    fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, [
      "13",
      "14",
    ]);
  }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchSurveyType(accessToken, setDataSurveyType);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProjectFormatted(accessToken, selectedPeriodeId, setDataProject);
      fetchRegenciesFormatted(accessToken, selectedProvinceId, setDataRegency);
      fetchDistrictFormatted(accessToken, selectedRegencyId, setDataDistrict);
      fetchSubDistrictFormatted(
        accessToken,
        selectedDistrictId,
        setDataSubDistrict
      );

      if (selectedSurveyId !== "all") {
        fetchDataAuditPlan(
          accessToken,
          setIsLoading,
          setFilteredRows,
          selectedSurveyId,
          selectedStoreTypeCode,
          selectedSurveyTypeCode,
          selectedProvinceId,
          selectedRegencyId,
          selectedDistrictId,
          selectedSubDistrictId,
          selectedAuditStatus,
          selectedUserNameSurveyor,
          selectedPeriodeId
        );
      }
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [
    accessToken,
    selectedSurveyId,
    selectedAuditStatus,
    selectedStoreTypeCode,
    selectedSurveyTypeCode,
    selectedProvinceId,
    selectedRegencyId,
    selectedDistrictId,
    selectedSubDistrictId,
    selectedUserNameSurveyor,
    selectedPeriodeId,
  ]);

  const setDefaultSelectedPeriode = () => {
    if (dataPeriode.length > 0) {
      // Check if the currently selected periode is still available in the data
      const selectedOption = dataPeriode.find(
        (option) => option.id === selectedPeriodeId
      );
      if (selectedOption) {
        setSelectedPeriodeId(selectedOption.id);
      } else {
        // If the selected option is not available, set the first option as default
        setSelectedPeriodeId(dataPeriode[0].id);
      }
    }
  };

  const setDefaultSelectedProject = () => {
    if (dataProject.length > 0) {
      // Check if the currently selected project is still available in the data
      const selectedOption = dataProject.find(
        (option) => option.id === selectedSurveyId
      );
      if (selectedOption) {
        setSelectedSurveyId(selectedOption.id);
        setSelectedProjectType(selectedOption.project_type_id);
      } else {
        // If the selected option is not available, set the first option as default
        setSelectedSurveyId(dataProject[0].id);
        setSelectedProjectType(dataProject[0].project_type_id);
      }
    }
  };

  useEffect(() => {
    setDefaultSelectedPeriode();
    setDefaultSelectedProject();
  }, [dataPeriode, dataProject]);

  const handleAddModal = async () => {
    try{
      const data = await fetchLicense();

      if(data?.is_license_expired){
        alert(data?.license_message)
      }else{
        setIsModalOpen(true);
      }
    }catch (error) {
      console.error(error)
      alert("Something went wrong get license")
    }
  };
  
  const handleImport = async () => {
    try{
      const data = await fetchLicense();

      if(data?.is_license_expired){
        alert(data?.license_message)
      }else{
        window.location.replace("/audit-plan/import");
      }
    }catch (error) {
      console.error(error)
      alert("Something went wrong get license")
    }
  };

  const handlePeriodeIdChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedPeriodeId(id);
    localStorage.setItem("selectedPeriodeId", id);

    setSelectedSurveyId("all");
    localStorage.setItem("selectedSurveyId", "all");
  };

  const handleProjectChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedSurveyId(id);
    localStorage.setItem("selectedSurveyId", id);
  };

  const handleAuditStatusChange = (event) => {
    const { target: { value } } = event;
  
    const newValue = typeof value === "string" ? value.split(",") : value;
    setSelectedAuditStatus(newValue);
  
    localStorage.setItem("selectedAuditStatus", JSON.stringify(newValue));
  };

  const handleUserNameSurveyorChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedUserNameSurveyor(id);
    localStorage.setItem("selectedUserNameSurveyor", id);
  };

  const handleStoreTypeCodeChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedStoreTypeCode(id);
    localStorage.setItem("selectedStoreTypeCode", id);
  };

  const handleSurveyTypeCodeChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedSurveyTypeCode(id);
    localStorage.setItem("selectedSurveyTypeCode", id);
  };

  const handleProvinceIdChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedProvinceId(id);
    localStorage.setItem("selectedProvinceId", id);

    setSelectedRegencyId("all");
    localStorage.setItem("selectedRegencyId", "all");
    setSelectedDistrictId("all");
    localStorage.setItem("selectedDistrictId", "all");
    setSelectedSubDistrictId("all");
    localStorage.setItem("selectedSubDistrictId", "all");
  };

  const handleRegencyIdChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedRegencyId(id);
    localStorage.setItem("selectedRegencyId", id);

    setSelectedDistrictId("all");
    localStorage.setItem("selectedDistrictId", "all");
    setSelectedSubDistrictId("all");
    localStorage.setItem("selectedSubDistrictId", "all");
  };

  const handleDistrictIdChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedDistrictId(id);
    localStorage.setItem("selectedDistrictId", id);

    setSelectedSubDistrictId("all");
    localStorage.setItem("selectedSubDistrictId", "all");
  };

  const handleSubDistrictIdChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedSubDistrictId(id);
    localStorage.setItem("selectedSubDistrictId", id);
  };

  // Template Download
  const handleExportData = async () => {
    fetchExportDataAuditPlan(
      accessToken,
      setIsLoading,
      selectedSurveyId,
      selectedStoreTypeCode,
      selectedProvinceId,
      selectedRegencyId,
      selectedDistrictId,
      selectedSubDistrictId,
      selectedAuditStatus,
      selectedUserNameSurveyor,
      selectedSurveyTypeCode
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewAuditPlanData({
      ...newAuditPlanData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const requestBodyCopy = { ...newAuditPlanData };

      if (requestBodyCopy.user_name_qc === "-") {
        requestBodyCopy.user_name_qc = "";
      }

      const { user_name_qc, ...requestCheckBody } = requestBodyCopy;

      if (Object.values(requestCheckBody).some((value) => value === "")) {
        alert("Isi semua data.");
        setIsLoading(false);
        return;
      }

      const token = Cookies.get("access_token");

      const auditPlanMasterResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/audit-plan-master/${selectedAuditPlanMasterId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const auditPlanMasterData = await auditPlanMasterResponse.json();

      if (auditPlanMasterData.data.project_type_id === "rakb") {
        const checkPreviousResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/audit-plan-check-previous/${requestBodyCopy.store_id}/${auditPlanMasterData.data.survey_id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (checkPreviousResponse.status === 404) {
          const checkPreviousData = await checkPreviousResponse.json();
          if (
            checkPreviousData.detail ===
            "new audit plan. no store previous found."
          ) {
            setRequestBodyRakb(requestBodyCopy);
            setIsRakbModalOpen(true);
          } else {
            alert(checkPreviousData.detail);
          }
          setIsLoading(false);
          return;
        } else if (checkPreviousResponse.status === 200) {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/audit-plan`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBodyCopy),
            }
          );

          if (response.status === 201) {
            alert("Audit plan berhasil ditambahkan!");
            window.location.reload();
          } else if (response.status === 400 || response.status === 404) {
            const data = await response.json();
            alert(data.detail);
          } else {
            alert("Audit plan gagal ditambahkan!");
          }
          setIsLoading(false);
          return;
        }
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/audit-plan`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBodyCopy),
        }
      );

      if (response.status === 201) {
        alert("Audit plan berhasil ditambahkan!");
        window.location.reload();
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Audit plan gagal ditambahkan!");
      }
    } catch (error) {
      console.error("Error creating audit plan:", error);
    }

    setIsLoading(false);
  };

  const handleModalRakbYes = async () => {
    setIsLoading(true);
    const token = Cookies.get("access_token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/audit-plan`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBodyRakb),
        }
      );

      if (response.status === 201) {
        alert("Audit plan berhasil ditambahkan!");
        window.location.reload();
      } else {
        const data = await response.json();
        alert(data.detail);
      }
    } catch (error) {
      console.error("Error creating audit plan:", error);
    }

    setIsRakbModalOpen(false);
    setIsLoading(false);
  };

  const handleModalRakbNo = () => {
    setIsRakbModalOpen(false);
  };

  // status
  const [statusModalOpen, seStatusModalOpen] = useState(false);
  const [statusModalId, seStatusModalId] = useState(null);
  const [titleStatusModal, seTitleStatusModal] = useState("");

  const handleOpenStatusModal = async (id, condition) => {
    seTitleStatusModal(condition);
    seStatusModalId(id);
    seStatusModalOpen(true);
  };

  const handleCloseStatusModal = () => {
    seStatusModalId(null);
    seStatusModalOpen(false);
    seTitleStatusModal("");
  };

  const handleStatus = async () => {
    setIsLoading(true);
    if (statusModalId) {
      try {
        let apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/audit-plan/${statusModalId}/status?set_to=Valid`;
        if (titleStatusModal === "Closed") {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/audit-plan/${statusModalId}/status?set_to=Closed`;
        }
        const token = Cookies.get("access_token");
        const response = await fetch(apiUrl, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          alert("Audit plan status berhasil diubah!");
          window.location.reload();
        } else {
          alert("Gagal mengubah status audit plan!");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState(null);

  const handleOpenDeleteModal = async (id) => {
    setDeleteModalId(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalId(null);
    setDeleteModalOpen(false);
  };

  // transferData
  const [transferDataRak2ModalOpen, setTransferDataRak2ModalOpen] =
    useState(false);
  const [transferDataRak2ModalRow, setTransferDataRak2ModalRow] =
    useState(null);

  const handleOpenTransferDataRak2Modal = async (row) => {
    setTransferDataRak2ModalRow(row);
    setTransferDataRak2ModalOpen(true);
  };

  const handleCloseTransferDataRak2Modal = () => {
    setTransferDataRak2ModalRow(null);
    setTransferDataRak2ModalOpen(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    if (deleteModalId) {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/audit-plan/${deleteModalId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          alert("Audit plan berhasil dihapus!");
          handleCloseDeleteModal();
          window.location.reload();
        } else if (response.status === 400 || response.status === 404) {
          const data = await response.json();
          alert(data.detail);
        } else {
          alert("Gagal menghapus audit plan!");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  const handleTransferRak2 = async () => {
    setIsLoading(true);
    if (transferDataRak2ModalRow) {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/audit-plan/${transferDataRak2ModalRow.id}/participant/${transferDataRak2ModalRow.store_code}/${selectedSurveyId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const { data } = await response.json();
          const responseTransfer = await fetch(
            `${process.env.REACT_APP_API_MOBILE_URL}/survey/manual_upload_rak_1?client_code=${data.client_code}&survey_id=${data.survey_id}&participant_id=${data.participant_id}&role_code=${data.role_code}`,
            {
              method: "POST",
            }
          );

          try {
            const result = await responseTransfer.json();
            if (result.status === "success") {
              alert("data berhasil ditransfer ke rak2");
              handleCloseTransferDataRak2Modal();
            } else {
              alert("ada masalah saat transfer ke rak2");
            }
          } catch (error) {
            console.error("Error:", error);
            alert("ada masalah saat transfer ke rak2");
          }
        } else if (response.status === 400 || response.status === 404) {
          const data = await response.json();
          alert(data.detail);
        } else {
          alert("ada masalah saat pengecekan transfer ke rak2");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  // update data
  const [updateDataMobileToWebModalOpen, setUpdateDataMobileToWebModalOpen] =
    useState(false);
  const [updateDataMobileToWebModalRow, setUpdateDataMobileToWebModalRow] =
    useState(null);

  const handleUpdateDataMobileToWebModal = async (row) => {
    setUpdateDataMobileToWebModalRow(row);
    setUpdateDataMobileToWebModalOpen(true);
  };

  const handleCloseUpdateDataMobileToWebModal = () => {
    setUpdateDataMobileToWebModalRow(null);
    setUpdateDataMobileToWebModalOpen(false);
  };

  const handleDataUpdateMobileToWeb = async () => {
    setIsLoading(true);
    if (updateDataMobileToWebModalRow) {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/audit-plan/${updateDataMobileToWebModalRow.id}/participant/${updateDataMobileToWebModalRow.store_code}/${selectedSurveyId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const { data } = await response.json();
          const responseTransfer = await fetch(
            `${process.env.REACT_APP_API_MOBILE_URL}/survey/update_postgress_data?participant_id=${data.participant_id}&survey_id=${data.survey_id}&client_code=${data.client_code}&role_code=${data.role_code}`,
            {
              method: "POST",
            }
          );

          try {
            const result = await responseTransfer.json();
            if (result.status === "success") {
              alert("data berhasil di update");
              handleCloseTransferDataRak2Modal();
            } else {
              alert("ada masalah saat update data");
            }
          } catch (error) {
            console.error("Error:", error);
            alert("ada masalah saat update data");
          }
        } else if (response.status === 400 || response.status === 404) {
          const data = await response.json();
          alert(data.detail);
        } else {
          alert("ada masalah saat pengecekan update data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  const handleEditClick = async (id) => {
    // Find the row data for the clicked row by id
    const rowData = filteredRows.find((row) => row.id === id);
    if (rowData) {
      // Populate the formDataUpdate state with the current values
      setEditedAuditPlan(rowData);
      setEditAuditPlanId(id);
      setEditModalOpen(true);
    }
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };

  const handleUpdateAuditPlan = async () => {
    setIsLoading(true);
    if (editAuditPlanId) {
      const { audit_status, user_name_qc, ...requestBody } = editedAuditPlan;

      if (user_name_qc === "-") {
        requestBody.user_name_qc = "";
      } else {
        requestBody.user_name_qc = user_name_qc;
      }

      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/audit-plan-manage/${editAuditPlanId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (response.status === 200) {
          alert("Audit plan berhasil diubah!");  
          window.location.reload();
        } else if (
          response.status === 422 ||
          response.status === 400 ||
          response.status === 404
        ) {
          const data = await response.json();
          alert(data.detail);
        } else {
          alert("Audit plan gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Audit Plan"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allow to access this page</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar
        active="Audit Plan"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div className="content" style={{ padding: "1rem" }}>
        <Grid container spacing={0.5}>
          <Grid item xs={12} sm={2.4}>
            <SearchBarAudit
              id={"periode-autocomplete"}
              data={dataPeriode}
              value={
                dataPeriode.find((option) => option.id === selectedPeriodeId) ||
                null
              }
              handleChange={handlePeriodeIdChange}
              label={"Periode"}
            />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <SearchBarAudit
              id={"project-autocomplete"}
              data={dataProject}
              value={
                dataProject.find((option) => option.id === selectedSurveyId) ??
                null
              }
              handleChange={handleProjectChange}
              label={"Project"}
            />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <FormControl sx={{ height: 40, mt: 1, mr: 1, width: "100%"}}>
              <InputLabel sx={{ mt: "-5px" }} id="demo-multiple-name-label">
                Status
              </InputLabel>
              <Select
                multiple
                sx={{ height: 40, mr: 1}}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={selectedAuditStatus}
                onChange={handleAuditStatusChange}
                input={<OutlinedInput label="Status" />}
              >
                {dataAuditStatus.map((data) => (
                  <MenuItem key={data.id} value={data.id}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <SearchBarAudit
              earchBarAudit
              id={"full-name-surveyor"}
              data={dataUserSurveyor}
              value={
                dataUserSurveyor.find(
                  (option) => option.id === selectedUserNameSurveyor
                ) ?? null
              }
              handleChange={handleUserNameSurveyorChange}
              label={"Full Name Surveyor"}
            />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <SearchBarAudit
              id="store-type-autocomplete"
              data={transformedData}
              value={
                transformedData.find(
                  (option) => option.id === selectedSurveyTypeCode
                ) ?? null
              }
              handleChange={handleSurveyTypeCodeChange}
              label="Survey Type"
            />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <SearchBarAudit
              id={"province-autocomplete"}
              data={dataProvince}
              value={
                dataProvince.find(
                  (option) => option.id === selectedProvinceId
                ) ?? null
              }
              handleChange={handleProvinceIdChange}
              label={"Provinsi"}
            />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <SearchBarAudit
              id={"regency-autocomplete"}
              data={dataRegency}
              value={
                dataRegency.find((option) => option.id === selectedRegencyId) ??
                null
              }
              handleChange={handleRegencyIdChange}
              label={"Kota/Kab"}
            />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <SearchBarAudit
              id={"district-autocomplete"}
              data={dataDistrict}
              value={
                dataDistrict.find(
                  (option) => option.id === selectedDistrictId
                ) ?? null
              }
              handleChange={handleDistrictIdChange}
              label={"Kec"}
            />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <SearchBarAudit
              id={"sub-district-autocomplete"}
              data={dataSubDistrict}
              value={
                dataSubDistrict.find(
                  (option) => option.id === selectedSubDistrictId
                ) ?? null
              }
              handleChange={handleSubDistrictIdChange}
              label={"Kel"}
            />
          </Grid>
        </Grid>
      </div>

      {/* button */}
      <div
        className="button-audit"
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "5px",
          marginTop: "10px",
          marginRight: "20px",
        }}
      >
        <div className="spacing-one"></div>
        <BootstrapButton
          onClick={handleAddModal}
          variant="contained"
          disableRipple
          disabled={isLoading}
          className="button-audit-plan"
        >
          <p
            style={{
              fontSize: "15px",
              height: "10px",
              textAlign: "center",
              marginTop: "1px",
            }}
          >
            {isLoading ? "Loading..." : "Add Audit Plan"}
          </p>
        </BootstrapButton>
        <BootstrapButton
          onClick={handleImport}
          variant="contained"
          disableRipple
          disabled={isLoading}
          className="button-audit-plan"
        >
          <p
            style={{
              fontSize: "15px",
              height: "10px",
              textAlign: "center",
              marginTop: "1px",
            }}
          >
            {isLoading ? "Loading..." : "Upload Audit Plans"}
          </p>
        </BootstrapButton>
        <BootstrapButton
          onClick={handleExportData}
          variant="contained"
          disableRipple
          disabled={isLoading}
          className="button-audit-plan"
        >
          <p
            style={{
              fontSize: "15px",
              height: "10px",
              textAlign: "center",
              marginTop: "1px",
            }}
          >
            {isLoading ? "Loading..." : "Download"}
          </p>
        </BootstrapButton>

        {/* modal import */}
        <ImportDataModal
          isLoading={isLoading}
          modalAdd={modalAdd}
          setModalAdd={setModalAdd}
          modalStyle={modalStyle}
        />

        {/* modal add*/}
        <AddDataModal
          accessToken={accessToken}
          isLoading={isLoading}
          newAuditPlanData={newAuditPlanData}
          dataAuditPlanMaster={dataAuditPlanMaster}
          dataUserSurveyorOptions={dataUserSurveyorOptions}
          dataUserQcOptions={dataUserQcOptions}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          modalStyle={modalStyle}
          setSelectedAuditPlanMasterId={setSelectedAuditPlanMasterId}
          setRequestBodyRakb={setRequestBodyRakb}
          setNewAuditPlanData={setNewAuditPlanData}
        />
        {/* modal edit */}
        <EditDataModal
          isLoading={isLoading}
          editedAuditPlan={editedAuditPlan}
          dataUserSurveyorOptions={dataUserSurveyorOptions}
          dataUserQcOptions={dataUserQcOptions}
          setEditedAuditPlan={setEditedAuditPlan}
          handleUpdateAuditPlan={handleUpdateAuditPlan}
          handleCloseEditModal={handleCloseEditModal}
          editModalOpen={editModalOpen}
          modalStyle={modalStyle}
        />
        {/* modal delete */}
        <DeleteDataModal
          isLoading={isLoading}
          modalStyle={modalStyle}
          deleteModalOpen={deleteModalOpen}
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleDelete={handleDelete}
        />
        {/* modal transfer data rak2 */}
        <TransferDataRak2Modal
          isLoading={isLoading}
          modalStyle={modalStyle}
          transferDataRak2ModalOpen={transferDataRak2ModalOpen}
          closeIconTarget={handleCloseTransferDataRak2Modal}
          handleCloseTransferDataRak2Modal={handleCloseTransferDataRak2Modal}
          handleTransferRak2={handleTransferRak2}
        />
        <UpdateDataMobileToWeb
          isLoading={isLoading}
          modalStyle={modalStyle}
          modalOpen={updateDataMobileToWebModalOpen}
          closeIconTarget={handleCloseUpdateDataMobileToWebModal}
          handleClose={handleCloseUpdateDataMobileToWebModal}
          handleSubmit={handleDataUpdateMobileToWeb}
        />
        {/* modal status */}
        <StatusDataModal
          isLoading={isLoading}
          modalStyle={modalStyle}
          statusModalOpen={statusModalOpen}
          handleCloseStatusModal={handleCloseStatusModal}
          handleStatus={handleStatus}
          title={titleStatusModal}
        />
        <Modal open={isRakbModalOpen} onClose={handleModalRakbNo}>
          <div
            style={{
              padding: "20px",
              backgroundColor: "white",
              margin: "20% auto",
              maxWidth: "500px",
              textAlign: "center",
            }}
          >
            <h4>Apakah plan ini dibuat untuk toko yang baru di audit?</h4>
            <Button
              onClick={handleModalRakbNo}
              variant="outlined"
              disableRipple
              sx={{
                width: 182,
                mt: 2,
                mr: 2,
                height: 50,
                color: "#b34469",
                borderColor: "#b34469",
              }}
            >
              Tidak
            </Button>
            <BootstrapButton
              onClick={handleModalRakbYes}
              variant="contained"
              disableRipple
              sx={{ width: 182, mt: 2, height: 50 }}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Ya"}
            </BootstrapButton>
          </div>
        </Modal>
      </div>
      <div>
        <DataGridAuditPlan
          loading={isLoading}
          rows={filteredRows}
          handleOpenStatusModal={handleOpenStatusModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleOpenTransferDataRak2Modal={handleOpenTransferDataRak2Modal}
          handleUpdateDataMobileToWebModal={handleUpdateDataMobileToWebModal}
          handleEditClick={handleEditClick}
          selectedProjectType={selectedProjectType}
          selected
        />
      </div>
    </div>
  );
}

export default AuditPlan;
