import Cookies from "js-cookie";
import { React, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Slide, toast } from "react-toastify";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  fetchProductMasterColumn,
} from "../../services/productMasterService";

// external
import Navbar from "../../Components/Navbar";
import { fetchProfile } from "../../utils/fetchProfile";
import { fetchPackSize, fetchPackType, fetchFlavour } from "./FilterService";

import "../../style/productmaster.css";

// button style
const CancelButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  padding: "3px 10px",
  border: "1px solid #DA6868",
  color: "#DA6868",
  lineHeight: 1.5,
  backgroundColor: "#FFF",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#F0F0F0",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#F0F0F0",
    borderColor: "#DA6868",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(243, 91, 91, 0.5)",
  },
});

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  padding: "3px 10px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#069DAE",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#0696A6",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

function AddProductPage(data) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [dataBrandMaster, setDataBrandMaster] = useState([]);
  const [dataCategoryMaster, setDataCategoryMaster] = useState([]);
  const [dataPrincipalMaster, setDataPrincipalMaster] = useState();
  const [unitVolume, setUnitVolume] = useState("");

  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);

  const [selectedFilteredCategory, setSelectedFilteredCategory] = useState("");
  const [filteredPackSize, setFilteredPackSize] = useState([]);
  const [filteredPackType, setFilteredPackType] = useState([]);
  const [filteredFlavour, setFilteredFlavour] = useState([]);

  async function fetchBrandMaster() {
    const token = Cookies.get("access_token") || "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/brand-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataBrandMaster(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  async function fetchCategoryMaster() {
    const token = Cookies.get("access_token") || "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/category-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataCategoryMaster(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  async function fetchPrincipalMaster() {
    const token = Cookies.get("access_token") || "";
    
    const queryParams = new URLSearchParams();

    // if (newProductData.brand_master_id) {
    //   queryParams.append("brand_master_id", newProductData.brand_master_id);
    // }

    // if (newProductData.category_master_id) {
    //   queryParams.append("category_master_id", newProductData.category_master_id);
    // }

    const queryString = queryParams.toString();
    const apiUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/principal-master` +
      (queryString ? `?${queryString}` : "");

    try {
      const response = await fetch(apiUrl,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataPrincipalMaster(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  // token
  const token = Cookies.get("access_token") || "";

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchCategoryMaster();
      fetchBrandMaster();
      fetchProductMasterColumn(dispatch, token);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  const [newProductData, setNewProductData] = useState({
    product_id: "",
    product_name: "",
    barcode: "",
    category_master_id: "",
    principal_master_id: "",
    brand_master_id: "",
    packsize: "",
    packtype: "Any",
    flavour: "Flavour",
    convertedvol: 0,
    ctrl_price: 0,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchPrincipalMaster();
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [
    // newProductData.brand_master_id, newProductData.category_master_id
  ]);

  const handleProductInputChange = (event) => {
    const { name, value } = event.target;
    setNewProductData({
      ...newProductData,
      [name]: value,
    });

    if (name === "category_master_id") {
      setSelectedFilteredCategory(value);
    }
  };

  const handleCreateProduct = async () => {
    const token = Cookies.get("access_token");
    setIsLoading(true);
    try {
      const createProductData = {
        ...newProductData,
        product_id: newProductData.product_id.trim(),
        product_name: newProductData.product_name.trim(),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-master`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(createProductData),
        }
      );

      if (response.status === 201) {
        toast.success("Product berhasil dibuat!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        setTimeout(function () {
          handleRedirect();
        }, 500);
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Product gagal dibuat!");
      }
    } catch (error) {
      console.error("Error creating product:", error);
    }
    setIsLoading(false);
  };

  const handleRedirect = () => {
    const selectedProductGroupId = localStorage.getItem('selectedProductGroupId');
    const selectedCategory = localStorage.getItem('selectedCategory');
    const selectedPrincipal = localStorage.getItem('selectedPrincipal');
    const selectedBrand = localStorage.getItem('selectedBrand');
    const filterColumnName = localStorage.getItem('filterColumnName');
    const filterColumnValue = localStorage.getItem('filterColumnValue');

    const queryParams = new URLSearchParams();

    if (selectedProductGroupId) {
      queryParams.append('product_group_master_id', selectedProductGroupId);
    }
    if (selectedCategory) {
      queryParams.append('category_master_id', selectedCategory);
    }
    if (selectedPrincipal) {
      queryParams.append('principal_master_id', selectedPrincipal);
    }
    if (selectedBrand) {
      queryParams.append('brand_master_id', selectedBrand);
    }
    if (filterColumnName) {
      queryParams.append('column_name', filterColumnName);
    }
    if (filterColumnValue) {
      queryParams.append('column_value', filterColumnValue);
    }

    const queryString = queryParams.toString();
    const redirectUrl = `/product-master` + (queryString ? `?${queryString}` : '');

    window.location.replace(redirectUrl);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(token, setDataProfile, setNotAllowAccessPage, [
        "TL",
        "13",
        "14",
      ]);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchPackSize(token, setFilteredPackSize, selectedFilteredCategory);
      fetchPackType(token, setFilteredPackType, selectedFilteredCategory);
      fetchFlavour(token, setFilteredFlavour, selectedFilteredCategory);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [selectedFilteredCategory]);

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Product Master"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allow to access this page</div>
      </div>
    );
  }
  return (
    <div>
      <Navbar
        active="Product Master"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div style={{ width: "70%", margin: "0 auto" }}>
        <h2 style={{ textAlign: "center", mt: 2, mb: 2 }}>
          Add Product Master
        </h2>
        <div>
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12} sm={6}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} mb={2}>
                  <TextField
                    label="Kode Produk"
                    name="product_id"
                    value={newProductData.product_id}
                    onChange={handleProductInputChange}
                    sx={{ width: "100%" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <TextField
                    label="Product Name"
                    name="product_name"
                    value={newProductData.product_name}
                    onChange={handleProductInputChange}
                    sx={{ width: "100%" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <TextField
                    label="Barcode"
                    name="barcode"
                    value={newProductData.barcode}
                    onChange={handleProductInputChange}
                    sx={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Autocomplete
                    options={dataCategoryMaster || []}
                    getOptionLabel={(option) => option.name || ""}
                    value={
                      dataCategoryMaster?.find(
                        (option) =>
                          option.id === newProductData.category_master_id
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      handleProductInputChange({
                        target: {
                          name: "category_master_id",
                          value: newValue ? newValue.id : "",
                        },
                      });
                      setUnitVolume(newValue ? newValue.unitvolume : "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category Master"
                        sx={{ width: "100%" }}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Autocomplete
                    options={dataBrandMaster || []}
                    getOptionLabel={(option) => option.name || ""}
                    value={
                      dataBrandMaster?.find(
                        (option) => option.id === newProductData.brand_master_id
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      handleProductInputChange({
                        target: {
                          name: "brand_master_id",
                          value: newValue ? newValue.id : "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Brand Master"
                        required
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Autocomplete
                    options={dataPrincipalMaster || []}
                    getOptionLabel={(option) => option.name || ""}
                    value={
                      dataPrincipalMaster?.find(
                        (option) =>
                          option.id === newProductData.principal_master_id
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      handleProductInputChange({
                        target: {
                          name: "principal_master_id",
                          value: newValue ? newValue.id : "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Principal Master"
                        sx={{ width: "100%" }}
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} mb={2}>
                  <Autocomplete
                    options={filteredPackSize || []}
                    getOptionLabel={(option) => option.atribut_value || ""}
                    value={
                      filteredPackSize?.find(
                        (option) =>
                          option.atribut_value === newProductData.packsize
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      handleProductInputChange({
                        target: {
                          name: "packsize",
                          value: newValue ? newValue.atribut_value : "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Pack Size"
                        sx={{ width: "100%" }}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Autocomplete
                    options={filteredPackType || []}
                    getOptionLabel={(option) => option.atribut_value || ""}
                    value={
                      filteredPackType?.find(
                        (option) =>
                          option.atribut_value === newProductData.packtype
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      handleProductInputChange({
                        target: {
                          name: "packtype",
                          value: newValue ? newValue.atribut_value : "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Pack Type"
                        sx={{ width: "100%" }}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Autocomplete
                    options={filteredFlavour || []}
                    getOptionLabel={(option) => option.atribut_value || ""}
                    value={
                      filteredFlavour?.find(
                        (option) =>
                          option.atribut_value === newProductData.flavour
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      handleProductInputChange({
                        target: {
                          name: "flavour",
                          value: newValue ? newValue.atribut_value : "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Flavour"
                        sx={{ width: "100%" }}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <TextField
                    label={`Converted Volume${
                      unitVolume ? ` (${unitVolume})` : ""
                    }`}
                    name="convertedvol"
                    value={newProductData.convertedvol}
                    onChange={handleProductInputChange}
                    sx={{ width: "100%" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <TextField
                    label="Control Price"
                    name="ctrl_price"
                    value={newProductData.ctrl_price}
                    onChange={handleProductInputChange}
                    sx={{ width: "100%" }}
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  mb={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 2
                  }}
                >
                  <CancelButton
                    variant="contained"
                    disableRipple
                    sx={{ height: 55, width: "50%", mb: 3 }}
                    onClick={handleRedirect}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Cancel"}
                  </CancelButton>
                  <BootstrapButton
                    variant="contained"
                    disableRipple
                    sx={{ height: 55, width: "50%", mb: 3 }}
                    onClick={handleCreateProduct}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Submit"}
                  </BootstrapButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default AddProductPage;
