import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";

// External imports
import Navbar from "../../Components/Navbar";
import { fetchProfile } from "../../utils/fetchProfile";
import { fetchLicense } from "../../utils/fetchLicense";
import logo from "../../assets/images/bg/logo.png";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography } from '@mui/material';
import { roleQcId, roleSurveyorId, roleTeamLeaderId } from "../../utils/constant";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "90%",
  margin: "auto",
  marginTop: "10vh",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    width: "60%",
    justifyContent: "space-between",
  },
}));

const InfoSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "50%",
  },
}));

const WrapperInfo = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "20px",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 0,
  },
}));

const LogoImage = styled("img")(({ theme }) => ({
  width: "60%",
  margin: "20px auto",
  display: "none",
  [theme.breakpoints.up("md")]: {
    width: "100%",
    margin: 0,
    display: "flex",
  },
}));

const LogoImageMobile = styled("img")(({ theme }) => ({
  width: "24%",
  margin: "20px auto",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const InputFile = styled("input")({
  display: "none",
});

const UploadButton = styled("label")(({ theme }) => ({
  backgroundColor: "#06a0b1",
  color: "#fff",
  padding: "8px 14px",
  borderRadius: "4px",
  textAlign: "center",
  cursor: "pointer",
  fontSize: "14px",
  marginTop: "10px",
  width: "fit-content",
  ":hover": {
    backgroundColor: "#048593",
  },
}));

const ButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  marginTop: "10px",
  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-end",
  },
}));

function License() {
  const token = Cookies.get("access_token") || "";
  const [isLoading, setIsLoading] = useState(true);
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);
  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);
  const [dataLicense, setDataLicense] = useState([]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    const formData = new FormData();
    formData.append("file", file);
    
    setIsFileUploadLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_LICENSE_URL}/api/v1/license/renewal`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        toast.success("The license has been renewed!");
        setIsLoading(true);
        const data = await fetchLicense();
        setDataLicense(data.data);
        setIsLoading(false);
      } else if (response.status === 400 || response.status === 404 || response.status === 413) {
        const errorDetail = await response.json();
        toast.error(errorDetail.detail || "An error occurred");
      } else {
        toast.error("Unexpected error occurred");
      }
    } catch (error) {
      toast.error("Failed to upload file. Please try again later.");
    }
    event.target.value = null;
    setIsFileUploadLoading(false);
  };

  useEffect(() => {
    const handler = setTimeout(async () => {
      setIsLoading(true);
      const roleToDisallow = [
        roleTeamLeaderId,
        roleSurveyorId,
        roleQcId,
      ]
      await fetchProfile(token, setDataProfile, setNotAllowAccessPage, roleToDisallow);
      const data = await fetchLicense();
      setDataLicense(data.data);
      setIsLoading(false);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, []);

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar active="License" initial={dataProfile.initial} role_code={dataProfile.role_code} />
        <div>Not allowed to access this page</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar active="License" initial={dataProfile.initial} role_code={dataProfile.role_code} />
      <Wrapper>
        <InfoSection>
          <WrapperInfo>
            <h1>Sivina License Information</h1>
            <LogoImageMobile src={logo} alt="logo" />
          </WrapperInfo>
          {
            isLoading ? "Loading..." :
              <TableContainer component={Paper}>
                <Table aria-label="client information table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle1">Client ID</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle1">:</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">{dataLicense?.code}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle1">Client Name</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle1">:</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">{dataLicense?.name}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle1">Expired Date</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle1">:</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">{dataLicense?.expiry_date ? new Date(dataLicense.expiry_date).toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "long",
                          year: "numeric"
                        }) : ""}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
          }
          <ButtonWrapper>
            <InputFile
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              accept="txt"
              disabled={isFileUploadLoading}
            />
            <UploadButton htmlFor="file-upload" disabled={isFileUploadLoading}>
              {isFileUploadLoading ? "Loading..." : "Upload License Key"}
            </UploadButton>
          </ButtonWrapper>
        </InfoSection>
        <div style={{ width: "30%" }}>
          <LogoImage src={logo} alt="logo" />
        </div>
      </Wrapper>
    </div>
  );
}

export default License;
