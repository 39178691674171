import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from "js-cookie";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const accessToken = Cookies.get("access_token") || "";

    useEffect(() => {
        const verifyUser = async () => {
            if (accessToken) {
                setIsLoading(true); // Start loading
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/auth/profile`, {
                        headers: { Authorization: `Bearer ${accessToken}` }
                    });
                    if (response.status === 200) {
                        setUser({
                          full_name: response.data.data.full_name,
                          initial: response.data.data.initial,
                          role_code: response.data.data.role_code,
                          user_name: response.data.data.user_name,
                        });
                    } else {
                        logout();
                    }
                } catch (err) {
                  console.log(err)
                  logout();
                }
                setIsLoading(false); // loading done
            } else {
                setIsLoading(false); // loading done
            }
        };
        
        const handler = setTimeout(() => {
            verifyUser();
        }, 200);
        return () => {
            clearTimeout(handler);
        };
    }, [accessToken]);

    const logout = () => {
        Cookies.remove("access_token", { path: '/login' });
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, logout, isLoading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
