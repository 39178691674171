import React from 'react'
import {
  TextField,
  Autocomplete,
} from "@mui/material";

function SearchBarProject({id, value, data, handleChange, label}) {
  return (
    <Autocomplete
      disablePortal
      id={id}
      size="small"
      color="secondary"
      sx={{
        width: "100%",
        height: 40,
      }}
      value={value}
      options={data}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} label={label} />
      )}
    />
  )
}

export default SearchBarProject
