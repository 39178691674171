import React, { useState } from "react";
import { useNavigate, Link, Link as RouterLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { teal } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Cookies from "js-cookie";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ListItemButton from "@mui/material/ListItemButton";

// external
import LogoSivina from "../assets/images/bg/logo.png";
import "../style/navbar.css";
import { useAuth } from "../context/AuthContext";
import { roleSuperAdminId, roleAdminId, roleCoAdminId, roleQcId, roleSurveyorId, roleTeamLeaderId } from "../utils/constant";

function Navbar(data) {
  const theme = createTheme({
    typography: {
      gokil: {
        fontFamily: "Red Hat Display",
        fontWeight: 700,
        fontSize: 16,
      },
      button: {
        fontFamily: "Montserrat",
        fontWeight: 500,
      },
    },
  });

  const { user } = useAuth();
  const navigate = useNavigate();
  // set up state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // function handle click
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickActive = (event) => {
    event.preventDefault();
    const hrefValue = event.currentTarget.getAttribute("href");
    // Redirect using the useNavigate hook
    navigate(hrefValue);
  };

  const handleLogout = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/auth/logout`,
        {
          method: "POST",
        }
      );

      if (response.status === 200) {
        Cookies.remove("access_token");
        localStorage.clear();
        window.location.replace("/login");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Inside your Navbar component
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const navbarMenuList = [
    {
      id: 1,
      label: "◎ Dashboard",
      href: "/dashboard",
      roles: [roleSuperAdminId, roleAdminId, roleCoAdminId, roleTeamLeaderId]
    },
    {
      id: 2,
      label: "◎ Project Management",
      href: "/project-management",
      roles: [roleSuperAdminId, roleAdminId]
    },
    {
      id: 3,
      label: "◎ Store Master",
      href: "/store-master",
      roles: [roleSuperAdminId, roleAdminId, roleCoAdminId, roleTeamLeaderId]
    },
    {
      id: 4,
      label: "◎ Product Master",
      href: "/product-master",
      roles: [roleSuperAdminId, roleAdminId, roleCoAdminId]
    },
    {
      id: 5,
      label: "◎ Audit Plan",
      href: "/audit-plan",
      roles: [roleSuperAdminId, roleAdminId, roleCoAdminId, roleTeamLeaderId]
    },
    {
      id: 6,
      label: "◎ User Management",
      href: "/user-management",
      roles: [roleSuperAdminId, roleAdminId]
    },
    {
      id: 7,
      label: "◎ Data Survey",
      href: "/data-survey",
      roles: [roleSuperAdminId, roleAdminId, roleCoAdminId, roleTeamLeaderId, roleSurveyorId, roleQcId]
    },
    {
      id: 8,
      label: "◎ Data Processing",
      href: "/data-processing",
      roles: [roleSuperAdminId, roleAdminId]
    },
    {
      id: 9,
      label: "◎ Non Projected Reporting",
      href: "/non-projected-reporting",
      roles: [roleSuperAdminId, roleAdminId, roleCoAdminId]
    },
    {
      id: 10,
      label: "◎ Projected Reporting",
      href: "/projected-reporting",
      roles: [roleSuperAdminId, roleAdminId, roleCoAdminId]
    },
    {
      id: 11,
      label: "◎ License",
      href: "/license",
      roles: [roleSuperAdminId, roleAdminId]
    },
    {
      id: 12,
      label: "◎ Stat Module",
      href: "/stat-module/setup-category",
      roles: [roleSuperAdminId]
    },
    //{ 
    //  id: 13,
    //  label: "◎ Map", 
    //   href: "/map-project",
    //   roles: [roleSuperAdminId, roleAdminId, roleCoAdminId]
    // },
  ];
  const filteredNavbarList = navbarMenuList.filter(item => item.roles.includes(user.role_code));
  
  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: "white",
        color: "black",
        boxShadow: 0,
        borderBottom: 1,
        borderColor: "grey.200",
      }}
    >
      <Toolbar>
        {/* icon */}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <img src={LogoSivina} alt="Logo Sivina" width={"40rem"} />
        </IconButton>
        {/* title */}
        <ThemeProvider theme={theme}>
          <Typography variant="gokil" component="div" sx={{ flexGrow: 1 }}>
            DATA COLLECTION SYSTEM FOR RDC AND RA SURVEY
          </Typography>
          {/* button active */}
          <Link
            component={RouterLink}
            to={open ? "basic-menu" : undefined}
            style={{ textDecoration: 'none' }}
          >
            <ListItemButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                textTransform: "none",
                fontWeight: 700,
                m: 1,
                color: 'black',
              }}
            >
              {data.active}
            </ListItemButton>
          </Link>
          {/* button dashboard */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {
              filteredNavbarList?.map((item) => (
                <MenuItem key={item.id} component={RouterLink} to={item.href} onClick={handleClickActive}>
                  {item.label}
                </MenuItem>
              ))
            }
          </Menu>
        </ThemeProvider>
        <Stack direction="row" spacing={2} sx={{ ml: 1 }}>
          <Avatar sx={{ bgcolor: teal[300], m: 1 }} onClick={handleOpenModal}>
            {user.initial}
          </Avatar>
        </Stack>
      </Toolbar>
      
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
}

export default Navbar;
