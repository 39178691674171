import Cookies from "js-cookie";
import { React, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { IconButton, Modal, Box, Divider, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import { fetchProductMaster, fetchProductMasterColumn } from '../../services/productMasterService';
import { TableProductMaster } from "../../Components/Fragments/Tables/TableProductMaster";
import {
  setCurrentPage,
  setSelectedProductGroupId,
  setSelectedBrand,
  setSelectedCategory,
  setSelectedPrincipal,
  setFilterColumnName,
  setFilterColumnValue,
} from "../../redux/slices/productMasterSlice";

// external
import Navbar from "../../Components/Navbar";
import { fetchProfile } from "../../utils/fetchProfile";
import ViewDataModal from "./ViewDataModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  fetchPackSize,
  fetchPackType,
  fetchFlavour,
} from "./FilterService";

import "../../style/productmaster.css";

// custom css for modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

// button style
const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  padding: "3px 10px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#069DAE",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#0696A6",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

function ProductMaster(data) {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.productMaster.currentPage);
  const itemsPerPage = useSelector((state) => state.productMaster.itemsPerPage);
  const [isLoading, setIsLoading] = useState(false);
  const [dataBrandMaster, setDataBrandMaster] = useState([]);
  const [dataPackType, setDataPackType] = useState([]);
  const [dataPackSize, setDataPackSize] = useState([]);
  const [dataUnitVol, setDataUnitVol] = useState([]);
  const [dataStockUnit, setDataStockUnit] = useState([]);
  const [dataCategoryMaster, setDataCategoryMaster] = useState([]);
  const [dataPrincipalMaster, setDataPrincipalMaster] = useState();
  const [dataPrincipalFilter, setDataPrincipalFilter] = useState([]);
  const itemsPerPageList = [5, 10, 50, 100];
  const [searchParams, setSearchParams] = useSearchParams();

  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);
  const [storeNameOptions2, setStoreNameOptions2] = useState([]);

  const [selectedFilteredCategory, setSelectedFilteredCategory] = useState("");
  const [filteredPackSize, setFilteredPackSize] = useState([]);
  const [filteredPackType, setFilteredPackType] = useState([]);
  const [filteredFlavour, setFilteredFlavour] = useState([]);

  async function fetchBrandMaster() {
    const token = Cookies.get("access_token") || "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/brand-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataBrandMaster(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/stock-unit`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataStockUnit(data.data);
        setDataPackType(data.data_pack_type);
        setDataPackSize(data.data_pack_size);
        setDataUnitVol(data.data_unit_vol);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  async function fetchCategoryMaster() {
    const token = Cookies.get("access_token") || "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/category-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataCategoryMaster(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  async function fetchPrincipalMaster() {
    const token = Cookies.get("access_token") || "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/principal-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataPrincipalMaster(data.data);
        setDataPrincipalFilter(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  const selectedProductGroupId = useSelector(
    (state) => state.productMaster.selectedProductGroupId
  );
  const selectedCategory = useSelector(
    (state) => state.productMaster.selectedCategory
  );
  const selectedPrincipal = useSelector(
    (state) => state.productMaster.selectedPrincipal
  );
  const selectedBrand = useSelector(
    (state) => state.productMaster.selectedBrand
  );
  const productMasterfilterColumnName = useSelector(
    (state) => state.productMaster.productMasterfilterColumnName
  );
  const filterColumnName = useSelector(
    (state) => state.productMaster.filterColumnName
  );
  const filterColumnOperator = useSelector(
    (state) => state.productMaster.filterColumnOperator
  );
  const filterColumnValue = useSelector(
    (state) => state.productMaster.filterColumnValue
  );

  const handleSearch = (e) => {
    e.preventDefault();

    dispatch(setCurrentPage(1));
    const customParams = {
      size: itemsPerPage,
      page: currentPage,
      product_group_master_id: selectedProductGroupId,
      category_master_id: selectedCategory,
      principal_master_id: selectedPrincipal,
      brand_master_id: selectedBrand,
      column_name: filterColumnName,
      column_operator: filterColumnOperator,
      column_value: filterColumnValue,
    };

    localStorage.setItem('filterColumnValue', filterColumnValue);

    const params = new URLSearchParams(searchParams);
    params.set('column_value', filterColumnValue);
    setSearchParams(params);
    
    fetchProductMaster(dispatch, token, customParams);
  };

  const handleResetFilter = () => {
    dispatch(setSelectedProductGroupId(""));
    dispatch(setSelectedCategory(""));
    dispatch(setSelectedPrincipal(""));
    dispatch(setSelectedBrand(""));
    dispatch(setFilterColumnName(""));
    dispatch(setFilterColumnValue(""));

    const params = new URLSearchParams(searchParams);
    params.delete('product_group_master_id');
    params.delete('category_master_id');
    params.delete('principal_master_id');
    params.delete('brand_master_id');
    params.delete('column_name');
    params.delete('column_value');
    setSearchParams(params);

    localStorage.removeItem("selectedProductGroupId");
    localStorage.removeItem("selectedCategory");
    localStorage.removeItem("selectedPrincipal");
    localStorage.removeItem("selectedBrand");
    localStorage.removeItem("filterColumnName");
    localStorage.removeItem("filterColumnValue");

    const customParams = {
      size: itemsPerPage,
      page: currentPage,
      product_group_master_id: '',
      category_master_id: '',
      principal_master_id: '',
      brand_master_id: '',
      column_name: '',
      column_operator: '',
      column_value: '',
    };

    fetchProductMaster(dispatch, token, customParams);
  };

  // export
  const handleExport = async (e) => {
    setIsLoading(true);
    try {
      const token = Cookies.get("access_token") || "";

      const queryParams = new URLSearchParams();

      if (selectedProductGroupId && selectedProductGroupId !== "") {
        queryParams.append("product_group_master_id", selectedProductGroupId);
      }

      if (selectedCategory && selectedCategory !== "") {
        queryParams.append("category_master_id", selectedCategory);
      }

      if (selectedPrincipal && selectedPrincipal !== "") {
        queryParams.append("principal_master_id", selectedPrincipal);
      }

      if (selectedBrand && selectedBrand !== "") {
        queryParams.append("brand_master_id", selectedBrand);
      }

      // Construct the full URL with query parameters
      const queryString = queryParams.toString();
      const apiUrl =
        `${process.env.REACT_APP_API_URL}/api/v1/product-master/export` +
        (queryString ? `?${queryString}` : "");

      // const selectedLabel = filterDownload.find(
      //   (option) => option.value === selectedSurveyId
      // )?.label;

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const blob = await response.blob();
        const filename = `Product Master.xlsx`;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Failed to export data. Please try again later.");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while exporting data.");
    }
    setIsLoading(false);
  };

  // filter handlers
  const handleProductGroupChange = (event, newValue) => {
    const selectedId = newValue ? newValue.id : "";
    dispatch(setSelectedProductGroupId(selectedId));
    
    const params = new URLSearchParams(searchParams);
    params.set('product_group_master_id', selectedId);
    setSearchParams(params);
    
    localStorage.setItem("selectedProductGroupId", selectedId);
  };

  const handleCategoryChange = (event, newValue) => {
    const selectedId = newValue ? newValue.id : "";
    dispatch(setSelectedCategory(selectedId));
    
    const params = new URLSearchParams(searchParams);
    params.set('category_master_id', selectedId);
    setSearchParams(params);
    
    localStorage.setItem("selectedCategory", selectedId);
  };

  const handlePrincipalChange = (event, newValue) => {
    const selectedId = newValue ? newValue.id : "";
    dispatch(setSelectedPrincipal(selectedId));
    
    const params = new URLSearchParams(searchParams);
    params.set('principal_master_id', selectedId);
    setSearchParams(params);
    
    localStorage.setItem("selectedPrincipal", selectedId);
  };

  const handleBrandChange = (event, newValue) => {
    const selectedId = newValue ? newValue.id : "";
    dispatch(setSelectedBrand(selectedId));
    
    const params = new URLSearchParams(searchParams);
    params.set('brand_master_id', selectedId);
    setSearchParams(params);
    
    localStorage.setItem("selectedBrand", selectedId);
  };

  // remove filter handlers
  const handleProductGroupRemove = () => {
    dispatch(setSelectedProductGroupId(""));
  
    const params = new URLSearchParams(searchParams);
    params.delete('product_group_master_id');
    setSearchParams(params);
    
    localStorage.removeItem("selectedProductGroupId");
  }
  
  const handleCategoryRemove = () => {
    dispatch(setSelectedCategory(""));
  
    const params = new URLSearchParams(searchParams);
    params.delete('category_master_id');
    setSearchParams(params);
    
    localStorage.removeItem("selectedCategory");
  }
  
  const handlePrincipalRemove = () => {
    dispatch(setSelectedPrincipal(""));
  
    const params = new URLSearchParams(searchParams);
    params.delete('principal_master_id');
    setSearchParams(params);
    
    localStorage.removeItem("selectedPrincipal");
  }
  
  const handleBrandRemove = () => {
    dispatch(setSelectedBrand(""));
  
    const params = new URLSearchParams(searchParams);
    params.delete('brand_master_id');
    setSearchParams(params);
    
    localStorage.removeItem("selectedBrand");
  }

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState(null);

  const handleCloseDeleteModal = () => {
    setDeleteModalId(null);
    setDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    if (deleteModalId) {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/product-master/${deleteModalId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          alert("Data berhasil dihapus!");
          handleCloseDeleteModal();
          window.location.reload();
        } else {
          alert("Gagal menghapus data!");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // upload

  const [modalUploadProduct, setModalUploadProduct] = useState(false);

  const handleUploadNewProduct = () => {
    setModalUploadProduct(true);
  };

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    setIsLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const token = Cookies.get("access_token");
      // product master
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/product-master/import/template`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Product Master Error.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          alert("Error, ada masalah di excel");
        } else if (response.status === 201) {
          alert("Data produk berhasil ditambahkan");
          window.location.reload();
        } else if (response.status === 400) {
          const data = await response.json();
          alert(data.detail);
        } else {
          alert("Ada masalah");
        }
      } catch (error) {
        console.error(error);
        alert("Error");
      }
    }
    setIsLoading(false);
  };

  // token
  const token = Cookies.get("access_token") || "";

  // Worksheet Download
  const downloadWorksheetFile = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-master/export/template/base`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Worksheet Product Master.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "Gagal Mendownload worksheet. Respon Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Mendownload worksheet, error:", error);
    }
    setIsLoading(false);
  };

  // Template Download
  const downloadTemplateFile = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-master/export/template`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Template Product Master.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "Gagal Mendownload Template. Respon Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Mendownload Template, error:", error);
    }
    setIsLoading(false);
  };

  // edit or update

  const stockUnitDescriptionToIdMap = {};
  dataStockUnit.forEach((item) => {
    stockUnitDescriptionToIdMap[item.description] = item.id;
  });

  const ProductGroupLabelToValueMap = {};
  storeNameOptions2.forEach((item) => {
    ProductGroupLabelToValueMap[item.label] = item.value;
  });

  const [productGroups, setProductGroups] = useState([]);

  async function fetchProductGroup() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-group-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const { data } = await response.json();
        setProductGroups(data);
        const transformedData = data.map((group) => ({
          value: group.id,
          label: group.name,
        }));
        setStoreNameOptions2(transformedData);
      } else {
        throw new Error("Failed to fetch product groups");
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchCategoryMaster();
      fetchPrincipalMaster();
      fetchBrandMaster();
      fetchProductGroup();
      fetchProductMasterColumn(dispatch, token);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  const [dataSurveyDetail, setDataSurveyDetail] = useState(null);
  const [dataSurveyDetailPhoto, setDataSurveyDetailPhoto] = useState([]);

  const [modalView, setModalView] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (item) => {
    setSelectedImage(item);
  };

  async function fetchProductViewerPhoto(productCode) {
    const token = Cookies.get("access_token") || "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_MOBILE_URL}/survey/get_images?client_code=${process.env.REACT_APP_CLIENT_CODE}&product_code=${productCode}&title=R0`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDataSurveyDetailPhoto([]);

      if (response.ok) {
        const { data } = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          const imageUrls = data.map((item, index) => {
            const { store_code, store_name, survey_name, url } = item;
            return {
              id: index,
              file: url,
              detail: {
                store_code: store_code,
                store_name: store_name,
                survey_name: survey_name,
              },
            };
          });

          setDataSurveyDetailPhoto(imageUrls);
        } else {
          setDataSurveyDetailPhoto([]);
        }
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  }

  //function mengarah setupMaster
  const navigate = useNavigate();
  const handleSetupMasterProduct = () => {
    navigate("/setup-product-master");
  };

  const handleProductViewer = async (params) => {
    setIsLoading(true);
    await fetchProductViewerPhoto(params.product_id);
    setDataSurveyDetail({
      product_id: params.product_id,
      product_name: params.product_name,
    });
    setIsLoading(false);
  };

  function handleCloseProductViewer() {
    setDataSurveyDetailPhoto([]);
    setModalView(false);
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(token, setDataProfile, setNotAllowAccessPage, [
        "TL",
        "13",
        "14",
      ]);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchPackSize(token, setFilteredPackSize, selectedFilteredCategory);
      fetchPackType(token, setFilteredPackType, selectedFilteredCategory);
      fetchFlavour(token, setFilteredFlavour, selectedFilteredCategory);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [selectedFilteredCategory]);

  useEffect(() => {
    const params = new URLSearchParams(searchParams);

    const productGroupMasterId = params.get('product_group_master_id');
    const categoryMasterId = params.get('category_master_id');
    const principalMasterId = params.get('principal_master_id');
    const brandMasterId = params.get('brand_master_id');
    const columnName = params.get('column_name');
    const columnValue = params.get('column_value');

    if (!productGroupMasterId && !categoryMasterId && !principalMasterId && !brandMasterId && !columnName && !columnValue) {
      localStorage.removeItem("selectedProductGroupId");
      localStorage.removeItem("selectedCategory");
      localStorage.removeItem("selectedPrincipal");
      localStorage.removeItem("selectedBrand");
      localStorage.removeItem("filterColumnName");
      localStorage.removeItem("filterColumnValue");

      dispatch(setSelectedProductGroupId(''));
      dispatch(setSelectedCategory(''));
      dispatch(setSelectedPrincipal(''));
      dispatch(setSelectedBrand(''));
      dispatch(setFilterColumnName(''));
      dispatch(setFilterColumnValue(''));
    }
    
    if (productGroupMasterId) {
      dispatch(setSelectedProductGroupId(productGroupMasterId));
    }
    
    if (categoryMasterId) {
      dispatch(setSelectedCategory(categoryMasterId));
    }
    
    if (principalMasterId) {
      dispatch(setSelectedPrincipal(principalMasterId));
    }
    
    if (brandMasterId) {
      dispatch(setSelectedBrand(brandMasterId));
    }
    
    if (columnName && columnValue) {
      const handler = setTimeout(() => {
        dispatch(setFilterColumnName(columnName));
        dispatch(setFilterColumnValue(columnValue));
        const customParams = {
          size: itemsPerPage,
          page: currentPage,
          product_group_master_id: productGroupMasterId || '',
          category_master_id: categoryMasterId || '',
          principal_master_id: principalMasterId || '',
          brand_master_id: brandMasterId || '',
          column_name: columnName,
          column_operator: filterColumnOperator,
          column_value: columnValue,
        };
        fetchProductMaster(dispatch, token, customParams);
      }, 300);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [])

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Product Master"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allow to access this page</div>
      </div>
    );
  }
  return (
    <div>
      <Navbar
        active="Product Master"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div className="content" style={{ padding: "1rem" }}>
        <Grid container spacing={0.5}>
          <Grid item xs={12} sm={1.5}>
            <Autocomplete
              id="autocomplete-select-product-group"
              options={productGroups}
              getOptionLabel={(option) => option.name}
              value={
                productGroups.find(
                  (option) => option.id === selectedProductGroupId
                ) || null
              }
              onChange={handleProductGroupChange}
              disableClearable={false}
              sx={{
                mr: 1,
                mt: 1,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="All Product Groups"
                  size="small"
                  sx={{
                    height: 40,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: selectedProductGroupId && (
                      <IconButton
                        sx={{ mr: "-55px", padding: 0,}}
                        edge="end"
                        onClick={handleProductGroupRemove}
                      >
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <Autocomplete
              id="autocomplete-select-category-master"
              options={dataCategoryMaster}
              getOptionLabel={(option) => option.name}
              value={
                dataCategoryMaster.find(
                  (option) => option.id === selectedCategory
                ) || null
              }
              onChange={handleCategoryChange}
              disableClearable={false}
              sx={{
                mr: 1,
                mt: 1,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="All Categories"
                  size="small"
                  sx={{
                    height: 40,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: selectedCategory && (
                      <IconButton
                        sx={{ mr: "-55px", padding: 0,}}
                        edge="end"
                        onClick={handleCategoryRemove}
                      >
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <Autocomplete
              id="autocomplete-select-principal"
              options={dataPrincipalFilter}
              getOptionLabel={(option) => option.name}
              value={
                dataPrincipalFilter.find(
                  (option) => option.id === selectedPrincipal
                ) || null
              }
              onChange={handlePrincipalChange}
              disableClearable={false}
              sx={{
                mr: 1,
                mt: 1,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="All Principals"
                  size="small"
                  sx={{
                    height: 40,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: selectedPrincipal && (
                      <IconButton
                        sx={{ mr: "-55px", padding: 0,}}
                        edge="end"
                        onClick={handlePrincipalRemove}
                      >
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <Autocomplete
              id="autocomplete-select-brand-master"
              options={dataBrandMaster}
              getOptionLabel={(option) => option.name}
              value={
                dataBrandMaster.find((option) => option.id === selectedBrand) ||
                null
              }
              onChange={handleBrandChange}
              disableClearable={false}
              sx={{
                mr: 1,
                mt: 1,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="All Brands"
                  size="small"
                  sx={{
                    height: 40,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: selectedBrand && (
                      <IconButton
                        sx={{ mr: "-55px", padding: 0,}}
                        edge="end"
                        onClick={handleBrandRemove}
                      >
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <form
              onSubmit={handleSearch}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Select
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                }}
                required
                labelId="select-label-column_name"
                name="column_name"
                id="select_column_name"
                value={
                  productMasterfilterColumnName.some((item) => item.id === filterColumnName)
                    ? filterColumnName
                    : ''
                }
                onChange={(e) => {
                  const selectedValue = e.target.value;

                  dispatch(setFilterColumnName(selectedValue));

                  const params = new URLSearchParams(searchParams);

                  if (selectedValue === "") {
                    params.delete('column_name');
                    dispatch(setFilterColumnValue(""));
                    params.delete('column_value');
                  } else {
                    params.set('column_name', selectedValue);
                  }
              
                  setSearchParams(params);

                  localStorage.setItem("filterColumnName", selectedValue);
                }}
              >
                <MenuItem value="" key="">
                  None
                </MenuItem>
                {productMasterfilterColumnName.map((data) => (
                  <MenuItem value={data.id} key={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                }}
                name="column_value"
                label="Cari"
                size="small"
                value={filterColumnValue}
                onChange={(e) => {
                  dispatch(setFilterColumnValue(e.target.value));

                  if (e.target.value === "") {
                    const params = new URLSearchParams(searchParams);
                    params.delete('column_name');
                    setSearchParams(params);
                  }
                }}
              />
              <BootstrapButton
                variant="contained"
                disableRipple
                sx={{ width: 120, height: 40, mt: 1 }}
                type="submit"
                disabled={isLoading || filterColumnName === ""}
              >
                {isLoading ? "Loading..." : "Cari"}
              </BootstrapButton>
            </form>
          </Grid>
          <Grid item xs={12} sm={1}>
            <BootstrapButton
              variant="contained"
              disableRipple
              sx={{ width: 150, height: 40, mt: 1 }}
              onClick={handleResetFilter}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Reset Filter"}
            </BootstrapButton>
          </Grid>
        </Grid>
      </div>

      {/* button add new project */}
      <div className="content-button">
        <BootstrapButton
          variant="contained"
          disableRipple
          onClick={handleSetupMasterProduct}
          disabled={isLoading}
          className="productbutton"
        >
          <p> {isLoading ? "Loading..." : "Setup"}</p>
        </BootstrapButton>
        <div className="spacing-one"></div>
        <BootstrapButton
          variant="contained"
          disableRipple
          disabled={isLoading}
          className="productbutton"
        >
          <a 
            href="/product-master/create"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'white'}}
          >
            <p>{isLoading ? "Loading..." : "Add Product"}</p>
          </a>
        </BootstrapButton>
        <div className="spacing-one"></div>
        <BootstrapButton
          variant="contained"
          disableRipple
          onClick={handleUploadNewProduct}
          disabled={isLoading}
          className="productbutton"
        >
          <p>{isLoading ? "Loading..." : "Upload Products"}</p>
        </BootstrapButton>
        <div className="spacing-one"></div>
        <BootstrapButton
          variant="contained"
          disableRipple
          onClick={() => window.location.href = "/product-group/import"}
          disabled={isLoading}
          className="productbutton"
        >
          <p> {isLoading ? "Loading..." : "Upload Product Group"}</p>
        </BootstrapButton>
        <div className="spacing-one"></div>
        <BootstrapButton
          variant="contained"
          disableRipple
          onClick={handleExport}
          disabled={isLoading}
          className="productbutton"
        >
          <p>{isLoading ? "Loading..." : "Download"}</p>
        </BootstrapButton>
      </div>
      {/* upload product */}
      <Modal
        open={modalUploadProduct}
        onClose={() => {
          setModalUploadProduct(false);
          setFile(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: 0 }}
      >
        <Box
          sx={{
            ...style,
            width: 500,
            margin: "0 auto",
            maxHeight: "70vh",
            borderRadius: "5px",
            overflow: "auto",
            overflowX: "hidden",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Tooltip title="Close Modal">
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                setModalUploadProduct(false);
                setFile(null);
              }}
              sx={{ position: "absolute", top: 20, right: 30 }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div>
          <h2>Upload New Product</h2>
            <Divider />
            <div style={{ marginBottom: "25px"}}>
              <input type="file" onChange={handleFileChange} sx={{mt: 2}} />{" "}
              {/* File input */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant="outlined"
                  disableRipple
                  sx={{
                    width: 190,
                    mt: 4,
                    mr: 2,
                    textTransform: "none",
                    color: "#069DAE",
                    borderColor: "#069DAE",
                  }}
                  onClick={downloadWorksheetFile}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Worksheet"}
                </Button>
                <Button
                  variant="outlined"
                  disableRipple
                  sx={{
                    width: 190,
                    mt: 4,
                    mr: 2,
                    textTransform: "none",
                    color: "#069DAE",
                    borderColor: "#069DAE",
                  }}
                  onClick={downloadTemplateFile}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Template"}
                </Button>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <BootstrapButton
                  variant="contained"
                  disableRipple
                  sx={{ width: 190, mt: 4 }}
                  onClick={handleUpload}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Upload"}
                </BootstrapButton>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      {/* delete modal */}
      <Modal
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
        sx={{ border: 0 }}
      >
        <Box sx={style}>
          <h2>Konfirmasi Hapus</h2>
          <p>Apakah anda yakin untuk menghapus data ini?</p>
          <div>
            <Button
              variant="outlined"
              onClick={handleCloseDeleteModal}
              sx={{ marginRight: 2, width: "46%" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              disabled={isLoading}
              sx={{ color: "#FFF", width: "48%", height: 36 }}
            >
              {isLoading ? "Loading..." : "DELETE"}
            </Button>
          </div>
        </Box>
      </Modal>
      {/* view modal */}
      <ViewDataModal
        isLoading={isLoading}
        dataSurveyDetail={dataSurveyDetail}
        modalStyle={style}
        handleCloseViewer={handleCloseProductViewer}
        modalView={modalView}
        dataSurveyDetailPhoto={dataSurveyDetailPhoto}
        selectedImage={selectedImage}
        handleImageClick={handleImageClick}
      />

      <div style={{ padding: "1rem" }}>
        <TableProductMaster
          onView={handleProductViewer}
          onViewModalOpen={setModalView}
          itemsPerPageList={itemsPerPageList}
        />
      </div>
    </div>
  );
}

export default ProductMaster;
