import React from 'react'
import { Typography, Box } from '@mui/material';

export default function Copyright() {
  return (
    <Box mt={4} style={{ width: '100%', textAlign: 'center' }}>
      <Typography variant="caption" sx={{ color: '#A09696' }}>
        Copyright 2023 - {new Date().getFullYear()}. PT Exorty Indonesia
      </Typography>
    </Box>
  )
}
