import Cookies from "js-cookie";
import { React, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { IconButton, Modal, Box, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import { DataGrid, GridRowEditStopReasons } from "@mui/x-data-grid";
import { fetchProductMaster, fetchProductMasterColumn } from '../services/productMasterService';
import { TableProductMaster } from "../Components/Fragments/Tables/TableProductMaster";
import {
  setCurrentPage,
  setSelectedProductGroupId,
  setSelectedBrand,
  setSelectedCategory,
  setSelectedPrincipal,
  setFilterColumnName,
  setFilterColumnValue,
} from "../redux/slices/productMasterSlice";

// external
import Navbar from "../Components/Navbar";
import { fetchProfile } from "../utils/fetchProfile";
import ViewDataModal from "./ProductMaster/ViewDataModal";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  fetchPackSize,
  fetchPackType,
  fetchFlavour,
} from "./ProductMasterTwo/FilterService";

import "../style/productmaster.css";

// custom css for modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

// button style
const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  padding: "3px 10px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#069DAE",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#0696A6",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

function ProductMaster(data) {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.productMaster.currentPage);
  const itemsPerPage = useSelector((state) => state.productMaster.itemsPerPage);
  const [isLoading, setIsLoading] = useState(false);
  const [dataProductMaster, setDataProductMaster] = useState(null);
  const [dataBrandMaster, setDataBrandMaster] = useState([]);
  const [dataPackType, setDataPackType] = useState([]);
  const [dataPackSize, setDataPackSize] = useState([]);
  const [dataUnitVol, setDataUnitVol] = useState([]);
  const [dataStockUnit, setDataStockUnit] = useState([]);
  const [dataCategoryMaster, setDataCategoryMaster] = useState([]);
  const [dataPrincipalMaster, setDataPrincipalMaster] = useState();
  const [dataPrincipalFilter, setDataPrincipalFilter] = useState([]);
  const [unitVolume, setUnitVolume] = useState("");
  const itemsPerPageList = [5, 10, 50, 100];

  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);

  const [rowModesModel, setRowModesModel] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [storeNameOptions, setStoreNameOptions] = useState([]);
  const [storeNameOptions2, setStoreNameOptions2] = useState([]);
  const [storeNameOptions3, setStoreNameOptions3] = useState([]);
  const [storeNameOptions4, setStoreNameOptions4] = useState([]);

  const [selectedFilteredCategory, setSelectedFilteredCategory] = useState("");
  const [filteredPackSize, setFilteredPackSize] = useState([]);
  const [filteredPackType, setFilteredPackType] = useState([]);
  const [filteredFlavour, setFilteredFlavour] = useState([]);

  async function fetchBrandMaster() {
    const token = Cookies.get("access_token") || "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/brand-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataBrandMaster(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/stock-unit`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataStockUnit(data.data);
        setDataPackType(data.data_pack_type);
        setDataPackSize(data.data_pack_size);
        setDataUnitVol(data.data_unit_vol);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  async function fetchCategoryMaster() {
    const token = Cookies.get("access_token") || "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/category-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataCategoryMaster(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  async function fetchPrincipalMaster() {
    const token = Cookies.get("access_token") || "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/principal-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataPrincipalMaster(data.data);
        setDataPrincipalFilter(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  const selectedProductGroupId = useSelector(
    (state) => state.productMaster.selectedProductGroupId
  );
  const selectedCategory = useSelector(
    (state) => state.productMaster.selectedCategory
  );
  const selectedPrincipal = useSelector(
    (state) => state.productMaster.selectedPrincipal
  );
  const selectedBrand = useSelector(
    (state) => state.productMaster.selectedBrand
  );
  const productMasterfilterColumnName = useSelector(
    (state) => state.productMaster.productMasterfilterColumnName
  );
  const filterColumnName = useSelector(
    (state) => state.productMaster.filterColumnName
  );
  const filterColumnOperator = useSelector(
    (state) => state.productMaster.filterColumnOperator
  );
  const filterColumnValue = useSelector(
    (state) => state.productMaster.filterColumnValue
  );

  const handleGroupChangeFilter = (event) => {
    const selectedValue = event.target.value;
    dispatch(setSelectedProductGroupId(selectedValue));
  };

  const handleBrandChangeFilter = (event) => {
    const selectedValue = event.target.value;
    dispatch(setSelectedBrand(selectedValue));
  };

  const handleCategoryChangeFilter = (event) => {
    const selectedValue = event.target.value;
    dispatch(setSelectedCategory(selectedValue));
  };

  const handlePrincipalChangeFilter = (event) => {
    const selectedValue = event.target.value;
    dispatch(setSelectedPrincipal(selectedValue));
  };

  const categorySelector = (event) => {
    if (event && event.target) {
      const selectedCategory = event.target.value;
      setEditedProduct({
        ...editedProduct,
        category_master_id: selectedCategory,
      });
      setSelectedFilteredCategory(selectedCategory);
    } else {
      console.error("Something went wrong");
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();

    dispatch(setCurrentPage(1));
    const customParams = {
      size: itemsPerPage,
      page: currentPage,
      product_group_master_id: selectedProductGroupId,
      category_master_id: selectedCategory,
      principal_master_id: selectedPrincipal,
      brand_master_id: selectedBrand,
      column_name: filterColumnName,
      column_operator: filterColumnOperator,
      column_value: filterColumnValue,
    };
    fetchProductMaster(dispatch, token, customParams);
  };

  const [principalMaster, setPrincipalMaster] = useState("");

  // export
  const handleExport = async () => {
    setIsLoading(true);
    try {
      const token = Cookies.get("access_token") || "";

      const queryParams = new URLSearchParams();

      if (selectedProductGroupId && selectedProductGroupId !== "") {
        queryParams.append("product_group_master_id", selectedProductGroupId);
      }

      if (selectedCategory && selectedCategory !== "") {
        queryParams.append("category_master_id", selectedCategory);
      }

      if (selectedPrincipal && selectedPrincipal !== "") {
        queryParams.append("principal_master_id", selectedPrincipal);
      }

      if (selectedBrand && selectedBrand !== "") {
        queryParams.append("brand_master_id", selectedBrand);
      }

      // Construct the full URL with query parameters
      const queryString = queryParams.toString();
      const apiUrl =
        `${process.env.REACT_APP_API_URL}/api/v1/product-master/export` +
        (queryString ? `?${queryString}` : "");

      // const selectedLabel = filterDownload.find(
      //   (option) => option.value === selectedSurveyId
      // )?.label;

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const blob = await response.blob();
        const filename = `Product Master.xlsx`;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Failed to export data. Please try again later.");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while exporting data.");
    }
    setIsLoading(false);
  };

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState(null);

  const handleOpenDeleteModal = (id) => {
    setDeleteModalId(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalId(null);
    setDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    if (deleteModalId) {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/product-master/${deleteModalId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          alert("Data berhasil dihapus!");
          handleCloseDeleteModal();
          window.location.reload();
        } else {
          alert("Gagal menghapus data!");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // upload

  const [modalUploadProduct, setModalUploadProduct] = useState(false);

  const handleUploadNewProduct = () => {
    setModalUploadProduct(true);
  };

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    setIsLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const token = Cookies.get("access_token");
      // product master
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/product-master/import/template`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Product Master Error.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          alert("Error, ada masalah di excel");
        } else if (response.status === 201) {
          alert("Data produk berhasil ditambahkan");
          window.location.reload();
        } else if (response.status === 400) {
          const data = await response.json();
          alert(data.detail);
        } else {
          alert("Ada masalah");
        }
      } catch (error) {
        console.error(error);
        alert("Error");
      }
    }
    setIsLoading(false);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  // token
  const token = Cookies.get("access_token") || "";

  const processRowUpdate = async (newRow) => {
    const updatedData = {
      product_id: newRow.product_id,
      product_name: newRow.product_name,
      address: newRow.address,
      latitude: newRow.latitude,
      longitude: newRow.longitude,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-master/${newRow.id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (response.status === 200) {
        const updatedRow = { ...newRow, isNew: false };
        setFilteredRows(
          filteredRows.map((row) => (row.id === newRow.id ? updatedRow : row))
        );
        return updatedRow;
      } else {
        alert("Ada masalah");
        window.location.reload();
      }
    } catch (error) {
      // Handle error
    }
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // Worksheet Download
  const downloadWorksheetFile = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-master/export/template/base`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Worksheet Product Master.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "Gagal Mendownload worksheet. Respon Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Mendownload worksheet, error:", error);
    }
    setIsLoading(false);
  };

  // Template Download
  const downloadTemplateFile = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-master/export/template`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Template Product Master.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "Gagal Mendownload Template. Respon Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Mendownload Template, error:", error);
    }
    setIsLoading(false);
  };

  // edit or update
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedProduct, setEditedProduct] = useState({});
  const [editProductId, setEditProductId] = useState(null);

  const handleEditClick = async (id) => {
    setEditedProduct(await fetchProductMasterSpecific(id));
    setEditProductId(id);
    setEditModalOpen(true);
  };

  const stockUnitDescriptionToIdMap = {};
  dataStockUnit.forEach((item) => {
    stockUnitDescriptionToIdMap[item.description] = item.id;
  });

  const ProductGroupLabelToValueMap = {};
  storeNameOptions2.forEach((item) => {
    ProductGroupLabelToValueMap[item.label] = item.value;
  });

  function findNewAndOldItems(newArray, oldArray) {
    const sortedNewArray = newArray.slice().sort();
    const sortedOldArray = oldArray.slice().sort();
    return { sortedNewArray, sortedOldArray };
  }

  function findAddedAndRemovedItemGroups(newArray, oldArray) {
    const addedItemGroups = newArray.filter((item) => !oldArray.includes(item));
    const removedItemGroups = oldArray.filter(
      (item) => !newArray.includes(item)
    );
    return { addedItemGroups, removedItemGroups };
  }

  const handleUpdateProduct = async () => {
    if (editProductId) {
      const token = Cookies.get("access_token");
      const { sortedNewArray, sortedOldArray } = findNewAndOldItems(
        editedStock,
        selectedOldStock
      );
      if (sortedOldArray.length > 0) {
        try {
          // unassign
          // Create a new FormData object
          const formData = new FormData();

          const stockUnitIds = sortedOldArray.map(
            (description) => stockUnitDescriptionToIdMap[description]
          );
          formData.append("stock_unit_ids", stockUnitIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/stock-unit/unassign`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );

          if (!response.ok) {
            alert("Stock gagal diubah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }

      if (sortedNewArray.length > 0) {
        try {
          // assign
          // Create a new FormData object
          const formData = new FormData();

          // Add the stock_unit_ids to the form data
          const stockUnitIds = sortedNewArray.map(
            (description) => stockUnitDescriptionToIdMap[description]
          );
          formData.append("stock_unit_ids", stockUnitIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/stock-unit/assign`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );

          if (response.ok) {
            alert("Stock berhasil diubah!");
          } else {
            alert("Stock gagal diubah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }

      const { addedItemGroups, removedItemGroups } =
        findAddedAndRemovedItemGroups(editedGroup, selectedOldGroup);
      if (addedItemGroups.length > 0) {
        try {
          // assign
          // Create a new FormData object
          const formData = new FormData();

          // Add the groups_ids to the form data
          const groupIds = addedItemGroups.map(
            (label) => ProductGroupLabelToValueMap[label]
          );
          formData.append("product_group_ids", groupIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/group/assign`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );

          if (response.ok) {
            alert("Group berhasil ditambah!");
          } else {
            alert("Group gagal ditambah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
      if (removedItemGroups.length > 0) {
        try {
          // unassign
          // Create a new FormData object
          const formData = new FormData();

          const groupIds = removedItemGroups.map(
            (label) => ProductGroupLabelToValueMap[label]
          );
          formData.append("product_group_ids", groupIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/group/unassign`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );

          if (response.ok) {
            alert("Group berhasil diubah!");
          } else {
            alert("Group gagal diubah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedProduct),
          }
        );

        const message = await response.json();

        if (response.ok) {
          alert("Produk berhasil diubah!");
          setEditedProduct(await fetchProductMasterSpecific(editProductId));
          setEditModalOpen(false);
        } else if (response.status === 404) {
          alert(message.detail);
        } else if (response.status === 400) {
          alert(message.detail);
        } else {
          alert("Produk gagal diubah!");
        }
      } catch (error) {
        console.error("Error:", error);
      }
      window.location.reload();
    }
  };

  async function fetchProductMasterSpecific(id) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-master/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const { data } = await response.json();
        setEditedStock(data.product_stock_unit);
        setSelectedOldStock(data.product_stock_unit);
        setEditedGroup(data.product_group);
        setSelectedOldGroup(data.product_group);
        return data;
      } else {
        throw new Error("Failed to fetch product master");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [editedStock, setEditedStock] = useState([]);
  const [selectedOldStock, setSelectedOldStock] = useState([]);

  const handleStockUnitChange = (event) => {
    const {
      target: { value },
    } = event;
    setEditedStock(typeof value === "string" ? value.split(",") : value);
  };

  const [editedGroup, setEditedGroup] = useState([]);
  const [selectedOldGroup, setSelectedOldGroup] = useState([]);

  const handleGroupChange = (event) => {
    const {
      target: { value },
    } = event;
    setEditedGroup(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [productGroups, setProductGroups] = useState([]);

  async function fetchProductGroup() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-group-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const { data } = await response.json();
        setProductGroups(data);
        const transformedData = data.map((group) => ({
          value: group.id,
          label: group.name,
        }));
        setStoreNameOptions2(transformedData);
      } else {
        throw new Error("Failed to fetch product groups");
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchCategoryMaster();
      fetchPrincipalMaster();
      fetchBrandMaster();
      fetchProductGroup();
      fetchProductMasterColumn(dispatch, token);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  const [dataSurveyDetail, setDataSurveyDetail] = useState(null);
  const [dataSurveyDetailPhoto, setDataSurveyDetailPhoto] = useState([]);

  const [modalView, setModalView] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (item) => {
    setSelectedImage(item);
  };

  async function fetchProductViewerPhoto(productCode) {
    const token = Cookies.get("access_token") || "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_MOBILE_URL}/survey/get_images?client_code=${process.env.REACT_APP_CLIENT_CODE}&product_code=${productCode}&title=R0`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDataSurveyDetailPhoto([]);

      if (response.ok) {
        const { data } = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          const imageUrls = data.map((item, index) => {
            const { store_code, store_name, survey_name, url } = item;
            return {
              id: index,
              file: url,
              detail: {
                store_code: store_code,
                store_name: store_name,
                survey_name: survey_name,
              },
            };
          });

          setDataSurveyDetailPhoto(imageUrls);
        } else {
          setDataSurveyDetailPhoto([]);
        }
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  }

  //function mengarah setupMaster
  const navigate = useNavigate();
  const handleSetupMasterProduct = () => {
    navigate("/setup-product-master");
  };

  const handleProductViewer = async (params) => {
    setIsLoading(true);
    await fetchProductViewerPhoto(params.product_id);
    setDataSurveyDetail({
      product_id: params.product_id,
      product_name: params.product_name,
    });
    setIsLoading(false);
  };

  function handleCloseProductViewer() {
    setDataSurveyDetailPhoto([]);
    setModalView(false);
  }

  // create

  const [modalProductGroup, setModalProductGroup] = useState(false);

  const handleProductGroupModal = () => {
    setModalProductGroup(true);
  };

  // create

  const [modalAddProduct, setModalAddProduct] = useState(false);

  const handleAddNewProduct = () => {
    setModalAddProduct(true);
  };

  const [newProductData, setNewProductData] = useState({
    product_id: "",
    product_name: "",
    barcode: "",
    category_master_id: "",
    principal_master_id: "",
    brand_master_id: "",
    packsize: "",
    packtype: "Any",
    flavour: "Flavour",
    convertedvol: 0,
    ctrl_price: 0,
  });

  const handleProductInputChange = (event) => {
    const { name, value } = event.target;
    setNewProductData({
      ...newProductData,
      [name]: value,
    });

    if (name === "category_master_id") {
      setSelectedFilteredCategory(value);
    }
  };

  const handleCreateProduct = async () => {
    const token = Cookies.get("access_token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-master`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newProductData),
        }
      );

      if (response.status === 201) {
        alert("Product berhasil dibuat!");
        window.location.reload();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Product gagal dibuat!");
      }
    } catch (error) {
      console.error("Error creating product:", error);
    }
  };

  // product group
  const handleTemplateProductGroup = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-group-master/product-group/export/template`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Product Group Worksheet.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "Gagal Mendownload worksheet. Respon Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Mendownload worksheet, error:", error);
    }
    setIsLoading(false);
  };

  const handleAddProductGroup = async () => {
    setIsLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/product-group-master/product-group/assign/import/template`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Product Group Result.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          alert("Mengimport product group");
          // } else if (response.status === 201) {
          //   alert("Data produk berhasil ditambahkan");
          //   window.location.reload();
        } else if (response.status === 400) {
          const data = await response.json();
          alert(data.detail);
        } else if (response.status === 422) {
          const data = await response.json();
          alert("Validasi bermasalah");
        } else {
          alert("Ada masalah");
        }
      } catch (error) {
        console.error(error);
        alert("Error");
      }
    }
    setIsLoading(false);
  };

  const handleDeleteProductGroup = async () => {
    setIsLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/product-group-master/product-group/unassign/import/template`,
          {
            method: "DELETE",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Product Group Result.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          alert("Menghapus product group");
          // } else if (response.status === 201) {
          //   alert("Data produk berhasil ditambahkan");
          //   window.location.reload();
        } else if (response.status === 400) {
          const data = await response.json();
          alert(data.detail);
        } else if (response.status === 422) {
          const data = await response.json();
          alert("Validasi bermasalah");
        } else {
          alert("Ada masalah");
        }
      } catch (error) {
        console.error(error);
        alert("Error");
      }
    }
    setIsLoading(false);
  };

  const [modalProductGroupAssign, setModalProductGroupAssign] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  // Add other necessary states

  const handleDeleteButtonClick = () => {
    // Show the delete confirmation modal
    setShowDeleteConfirmation(true);
  };

  // edit stock unit

  const columns = [
    {
      field: "product_id",
      headerClassName: "super-app-theme--header",
      headerName: "KODE PRODUK",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "product_name",
      headerClassName: "super-app-theme--header",
      headerName: "NAMA PRODUK",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "category_master",
      headerClassName: "super-app-theme--header",
      headerName: "KATEGORI PRODUK",
      width: 190,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "principal_master",
      headerClassName: "super-app-theme--header",
      headerName: "NAMA PRINSIPAL",
      width: 180,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "brand_master",
      headerClassName: "super-app-theme--header",
      headerName: "NAMA BRAND",
      width: 180,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "product_stock_unit",
      headerClassName: "super-app-theme--header",
      headerName: "STOK UNIT PRODUK",
      width: 270,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "created_at",
      headerClassName: "super-app-theme--header",
      headerName: "TANGGAL DIBUAT",
      width: 190,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "updated_at",
      headerClassName: "super-app-theme--header",
      headerName: "TANGGAL DIPERBARUI",
      width: 190,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 180,
      renderCell: (params) => (
        <div>
          <Tooltip
            title="View Product Master"
            onClick={() => {
              handleProductViewer(params.row);
              setModalView(true);
            }}
          >
            <IconButton>
              <ImageIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Edit Product Master"
            onClick={() => handleEditClick(params.row.id)}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(token, setDataProfile, setNotAllowAccessPage, [
        "TL",
        "13",
        "14",
      ]);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  useEffect(() => {
    if (editedProduct && dataCategoryMaster) {
      const selectedCategory = dataCategoryMaster.find(
        (option) => option.id === editedProduct.category_master_id
      );
      if (selectedCategory) {
        setUnitVolume(selectedCategory.unitvolume || "");
      } else {
        setUnitVolume("");
      }
    }
  }, [editedProduct, dataCategoryMaster]);

  useEffect(() => {
    setSelectedFilteredCategory(editedProduct.category_master_id);
  }, [editedProduct.category_master_id]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchPackSize(token, setFilteredPackSize, selectedFilteredCategory);
      fetchPackType(token, setFilteredPackType, selectedFilteredCategory);
      fetchFlavour(token, setFilteredFlavour, selectedFilteredCategory);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [selectedFilteredCategory]);

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Product Master"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allow to access this page</div>
      </div>
    );
  }
  return (
    <div>
      <Navbar
        active="Product Master"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div className="content" style={{ padding: "1rem" }}>
        <div className="legend">
          {/* search bar */}
          <div className="searchbar">
            <TextField
              id="outlined-select-currency"
              label="All Product Groups"
              select
              sx={{
                mr: 1,
                mt: 1,
                height: 40,
              }}
              size="small"
              value={selectedProductGroupId}
              onChange={handleGroupChangeFilter}
              InputProps={{
                endAdornment: selectedProductGroupId && (
                  <IconButton
                    sx={{ mr: 1.5 }}
                    edge="end"
                    onClick={() => dispatch(setSelectedProductGroupId(""))}
                  >
                    <CloseIcon />
                  </IconButton>
                ),
              }}
            >
              {productGroups.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="outlined-select-currency"
              label="All Categories"
              select
              sx={{
                mr: 1,
                mt: 1,
                height: 40,
              }}
              size="small"
              value={selectedCategory}
              onChange={handleCategoryChangeFilter}
              InputProps={{
                endAdornment: selectedCategory && (
                  <IconButton
                    sx={{ mr: 1.5 }}
                    edge="end"
                    onClick={() => dispatch(setSelectedCategory(""))}
                  >
                    <CloseIcon />
                  </IconButton>
                ),
              }}
            >
              {dataCategoryMaster.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="outlined-select-currency"
              label="All Principals"
              select
              sx={{
                mr: 1,
                mt: 1,
                height: 40,
              }}
              size="small"
              value={selectedPrincipal}
              onChange={handlePrincipalChangeFilter}
              InputProps={{
                endAdornment: selectedPrincipal && (
                  <IconButton
                    sx={{ mr: 1.5 }}
                    edge="end"
                    onClick={() => dispatch(setSelectedPrincipal(""))}
                  >
                    <CloseIcon />
                  </IconButton>
                ),
              }}
            >
              {dataPrincipalFilter.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="outlined-select-currency"
              label="All Brands"
              select
              sx={{
                mr: 1,
                mt: 1,
                height: 40,
              }}
              size="small"
              value={selectedBrand}
              onChange={handleBrandChangeFilter}
              InputProps={{
                endAdornment: selectedBrand && (
                  <IconButton
                    sx={{ mr: 1.5 }}
                    edge="end"
                    onClick={() => dispatch(setSelectedBrand(""))}
                  >
                    <CloseIcon />
                  </IconButton>
                ),
              }}
            >
              {dataBrandMaster.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <form
            onSubmit={handleSearch}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Select
              sx={{
                mr: 1,
                mt: 1,
                height: 40,
              }}
              required
              labelId="select-label-column_name"
              name="column_name"
              id="select_column_name"
              value={filterColumnName}
              onChange={(e) => {
                dispatch(setFilterColumnName(e.target.value));
              }}
            >
              {productMasterfilterColumnName.map((data) => (
                <MenuItem value={data.id} key={data.id}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              sx={{
                mr: 1,
                mt: 1,
                height: 40,
              }}
              name="column_value"
              label="Cari"
              size="small"
              value={filterColumnValue}
              onChange={(e) => {
                dispatch(setFilterColumnValue(e.target.value));
              }}
            />
            <BootstrapButton
              variant="contained"
              disableRipple
              sx={{ width: 120, height: 40, mt: 1 }}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Cari"}
            </BootstrapButton>
          </form>
          </div>
        </div>
      </div>

      {/* button add new project */}
      <div className="content-button">
        <BootstrapButton
          variant="contained"
          disableRipple
          onClick={handleSetupMasterProduct}
          disabled={isLoading}
          className="productbutton"
        >
          <p> {isLoading ? "Loading..." : "Setup"}</p>
        </BootstrapButton>
        <div className="spacing-one"></div>
        <BootstrapButton
          variant="contained"
          disableRipple
          onClick={handleAddNewProduct}
          disabled={isLoading}
          className="productbutton"
        >
          <p>{isLoading ? "Loading..." : "Add Product"}</p>
        </BootstrapButton>
        <div className="spacing-one"></div>
        <BootstrapButton
          variant="contained"
          disableRipple
          onClick={handleUploadNewProduct}
          disabled={isLoading}
          className="productbutton"
        >
          <p>{isLoading ? "Loading..." : "Upload Products"}</p>
        </BootstrapButton>
        <div className="spacing-one"></div>
        <BootstrapButton
          variant="contained"
          disableRipple
          onClick={handleProductGroupModal}
          disabled={isLoading}
          className="productbutton"
        >
          <p> {isLoading ? "Loading..." : "Upload Product Group"}</p>
        </BootstrapButton>
        <div className="spacing-one"></div>
        <BootstrapButton
          variant="contained"
          disableRipple
          onClick={handleExport}
          disabled={isLoading}
          className="productbutton"
        >
          <p>{isLoading ? "Loading..." : "Download"}</p>
        </BootstrapButton>
      </div>
      {/* create product */}
      <Modal
        open={modalAddProduct}
        onClose={() => {
          setModalAddProduct(false);
          setSelectedFilteredCategory("");
          setNewProductData({});
          setUnitVolume("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: 0 }}
      >
        <Box
          sx={{
            ...style,
            width: 500,
            margin: "0 auto",
            maxHeight: "70vh",
            borderRadius: "5px",
            overflow: "auto",
            overflowX: "hidden",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Tooltip title="Close Modal">
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                setModalAddProduct(false);
                setSelectedFilteredCategory("");
                setNewProductData({});
                setUnitVolume("");
              }}
              sx={{ position: "absolute", top: 20, right: 30 }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div>
            <h2>Add New Product</h2>
            <div>
              <TextField
                label="Kode Produk"
                name="product_id"
                value={newProductData.product_id}
                onChange={handleProductInputChange}
                sx={{ width: "100%", mb: 2 }}
              />
            </div>
            <div>
              <TextField
                label="Product Name"
                name="product_name"
                value={newProductData.product_name}
                onChange={handleProductInputChange}
                sx={{ width: "100%", mb: 2 }}
              />
            </div>
            <div>
              <TextField
                label="Barcode"
                name="barcode"
                value={newProductData.barcode}
                onChange={handleProductInputChange}
                sx={{ width: "100%", mb: 2 }}
              />
            </div>
            <div>
              <Autocomplete
                options={dataCategoryMaster || []}
                getOptionLabel={(option) => option.name || ""}
                value={
                  dataCategoryMaster?.find(
                    (option) => option.id === newProductData.category_master_id
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleProductInputChange({
                    target: {
                      name: "category_master_id",
                      value: newValue ? newValue.id : "",
                    },
                  });
                  setUnitVolume(newValue ? newValue.unitvolume : "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category Master"
                    sx={{ width: "100%", mb: 2 }}
                  />
                )}
              />
            </div>
            <div>
              <Autocomplete
                options={dataBrandMaster || []}
                getOptionLabel={(option) => option.name || ""}
                value={
                  dataBrandMaster?.find(
                    (option) => option.id === newProductData.brand_master_id
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleProductInputChange({
                    target: {
                      name: "brand_master_id",
                      value: newValue ? newValue.id : "",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Brand Master"
                    sx={{ width: "100%", mb: 2 }}
                  />
                )}
              />
            </div>
            <div>
              <Autocomplete
                options={dataPrincipalMaster || []}
                getOptionLabel={(option) => option.name || ""}
                value={
                  dataPrincipalMaster?.find(
                    (option) => option.id === newProductData.principal_master_id
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleProductInputChange({
                    target: {
                      name: "principal_master_id",
                      value: newValue ? newValue.id : "",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Principal Master"
                    sx={{ width: "100%", mb: 2 }}
                  />
                )}
              />
            </div>
            <div>
              <Autocomplete
                options={filteredPackSize || []}
                getOptionLabel={(option) => option.atribut_value || ""}
                value={
                  filteredPackSize?.find(
                    (option) => option.atribut_value === newProductData.packsize
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleProductInputChange({
                    target: {
                      name: "packsize",
                      value: newValue ? newValue.atribut_value : "",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pack Size"
                    sx={{ width: "100%", mb: 2 }}
                  />
                )}
              />
            </div>
            <div>
              <Autocomplete
                options={filteredPackType || []}
                getOptionLabel={(option) => option.atribut_value || ""}
                value={
                  filteredPackType?.find(
                    (option) => option.atribut_value === newProductData.packtype
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleProductInputChange({
                    target: {
                      name: "packtype",
                      value: newValue ? newValue.atribut_value : "",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pack Type"
                    sx={{ width: "100%", mb: 2 }}
                  />
                )}
              />
            </div>
            <div>
              <Autocomplete
                options={filteredFlavour || []}
                getOptionLabel={(option) => option.atribut_value || ""}
                value={
                  filteredFlavour?.find(
                    (option) => option.atribut_value === newProductData.flavour
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleProductInputChange({
                    target: {
                      name: "flavour",
                      value: newValue ? newValue.atribut_value : "",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Flavour"
                    sx={{ width: "100%", mb: 2 }}
                  />
                )}
              />
            </div>
            <div>
              <TextField
                label={`Converted Volume${
                  unitVolume ? ` (${unitVolume})` : ""
                }`}
                name="convertedvol"
                value={newProductData.convertedvol}
                onChange={handleProductInputChange}
                sx={{ width: "100%", mb: 2 }}
              />
            </div>
            <div>
              <TextField
                label="Control Price"
                name="ctrl_price"
                value={newProductData.ctrl_price}
                onChange={handleProductInputChange}
                sx={{ width: "100%", mb: 2 }}
              />
            </div>
            <div>
              <BootstrapButton
                variant="contained"
                disableRipple
                sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                onClick={handleCreateProduct}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Create Product"}
              </BootstrapButton>
            </div>
          </div>
        </Box>
      </Modal>
      {/* uplaod product */}
      <Modal
        open={modalUploadProduct}
        onClose={() => {
          setModalUploadProduct(false);
          setFile(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: 0 }}
      >
        <Box
          sx={{
            ...style,
            width: 500,
            margin: "0 auto",
            maxHeight: "70vh",
            borderRadius: "5px",
            overflow: "auto",
            overflowX: "hidden",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Tooltip title="Close Modal">
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                setModalUploadProduct(false);
                setFile(null);
              }}
              sx={{ position: "absolute", top: 20, right: 30 }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div>
          <h2>Upload New Product</h2>
            <Divider />
            <div style={{ marginBottom: "25px"}}>
              <input type="file" onChange={handleFileChange} sx={{mt: 2}} />{" "}
              {/* File input */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant="outlined"
                  disableRipple
                  sx={{
                    width: 190,
                    mt: 4,
                    mr: 2,
                    textTransform: "none",
                    color: "#069DAE",
                    borderColor: "#069DAE",
                  }}
                  onClick={downloadWorksheetFile}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Worksheet"}
                </Button>
                <Button
                  variant="outlined"
                  disableRipple
                  sx={{
                    width: 190,
                    mt: 4,
                    mr: 2,
                    textTransform: "none",
                    color: "#069DAE",
                    borderColor: "#069DAE",
                  }}
                  onClick={downloadTemplateFile}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Template"}
                </Button>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <BootstrapButton
                  variant="contained"
                  disableRipple
                  sx={{ width: 190, mt: 4 }}
                  onClick={handleUpload}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Upload"}
                </BootstrapButton>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      {/* delete confirmation product group */}
      <Modal
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        aria-labelledby="delete-confirmation-modal-title"
        aria-describedby="delete-confirmation-modal-description"
        sx={{ border: 0 }}
      >
        <Box
          sx={{
            ...style,
            width: 400, // Adjust the width as needed
            margin: "0 auto",
            maxHeight: "200px", // Adjust the height as needed
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <h5 component="div" mb={2}>
            Are you sure you want to delete?
          </h5>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              onClick={() => setShowDeleteConfirmation(false)}
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={handleDeleteProductGroup}
            >
              Delete
            </Button>
          </div>
        </Box>
      </Modal>
      {/* modal product group */}
      <Modal
        open={modalProductGroup}
        onClose={() => setModalProductGroup(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: 0 }}
      >
        <Box
          sx={{
            ...style,
            width: 650,
            margin: "0 auto",
            maxHeight: "700px",
            overflow: "auto",
            overflowX: "hidden",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Tooltip title="Close Modal">
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setModalProductGroup(false)}
              sx={{ position: "absolute", top: 20, right: 30 }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div style={{ width: "90%" }}>
            <h2>Product Group</h2>
            <Divider />
            <div style={{ marginBottom: "25px", marginTop: "10px" }}>
              {/* <h2>Upload New Product</h2> */}
              <input type="file" onChange={handleFileChange} />{" "}
              {/* File input */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant="outlined"
                  disableRipple
                  sx={{
                    width: "32%",
                    mt: 4,
                    textTransform: "none",
                    color: "#069DAE",
                    borderColor: "#069DAE",
                  }}
                  onClick={handleTemplateProductGroup}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Template"}
                </Button>
                <Button
                  variant="outlined"
                  disableRipple
                  sx={{
                    width: "32%",
                    mt: 4,
                    textTransform: "none",
                    color: "#069DAE",
                    borderColor: "#069DAE",
                  }}
                  onClick={handleAddProductGroup}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Add"}
                </Button>
                <Button
                  variant="outlined"
                  disableRipple
                  sx={{
                    width: "32%",
                    mt: 4,
                    textTransform: "none",
                    color: "#069DAE",
                    borderColor: "#069DAE",
                  }}
                  onClick={handleDeleteButtonClick}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Delete"}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      {/* modal edit */}
      <Modal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setSelectedFilteredCategory("");
          setEditedProduct({});
          setUnitVolume("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: 0 }}
      >
        <Box sx={{ ...style, width: "700px" }}>
          {/* Content in the edit modal */}
          <div
            style={{
              maxHeight: "600px",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            <h2>Edit Product</h2>
            <TextField
              label="Product Name"
              sx={{ width: "100%" }}
              value={editedProduct.product_name}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  product_name: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Barcode"
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.barcode}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  barcode: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <Autocomplete
              options={dataCategoryMaster || []}
              getOptionLabel={(option) => option.name || ""}
              value={
                dataCategoryMaster?.find(
                  (option) => option.id === editedProduct.category_master_id
                ) || null
              }
              onChange={(event, newValue) => {
                categorySelector({
                  target: {
                    value: newValue ? newValue.id : "",
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category Master"
                  sx={{ width: "100%", mb: 2 }}
                />
              )}
            />

            <Autocomplete
              options={dataBrandMaster || []}
              getOptionLabel={(option) => option.name || ""}
              value={
                dataBrandMaster?.find(
                  (option) => option.id === editedProduct.brand_master_id
                ) || null
              }
              onChange={(event, newValue) => {
                setEditedProduct({
                  ...editedProduct,
                  brand_master_id: newValue ? newValue.id : "",
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Brand Master"
                  sx={{ width: "100%", mb: 2 }}
                />
              )}
            />

            <Autocomplete
              options={dataPrincipalMaster || []}
              getOptionLabel={(option) => option.name || ""}
              value={
                dataPrincipalMaster?.find(
                  (option) => option.id === editedProduct.principal_master_id
                ) || null
              }
              onChange={(event, newValue) => {
                setEditedProduct({
                  ...editedProduct,
                  principal_master_id: newValue ? newValue.id : "",
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Principal Master"
                  sx={{ width: "100%", mb: 2 }}
                />
              )}
            />

            <Autocomplete
              options={filteredPackSize || []}
              getOptionLabel={(option) => option.atribut_value || ""}
              value={
                filteredPackSize?.find(
                  (option) => option.atribut_value === editedProduct.packsize
                ) || null
              }
              onChange={(event, newValue) => {
                setEditedProduct({
                  ...editedProduct,
                  packsize: newValue ? newValue.atribut_value : "",
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pack Size"
                  sx={{ width: "100%", mb: 2 }}
                />
              )}
            />

            <Autocomplete
              options={filteredPackType || []}
              getOptionLabel={(option) => option.atribut_value || ""}
              value={
                filteredPackType?.find(
                  (option) => option.atribut_value === editedProduct.packtype
                ) || null
              }
              onChange={(event, newValue) => {
                setEditedProduct({
                  ...editedProduct,
                  packtype: newValue ? newValue.atribut_value : "",
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pack Type"
                  sx={{ width: "100%", mb: 2 }}
                />
              )}
            />

            <Autocomplete
              options={filteredFlavour || []}
              getOptionLabel={(option) => option.atribut_value || ""}
              value={
                filteredFlavour?.find(
                  (option) => option.atribut_value === editedProduct.flavour
                ) || null
              }
              onChange={(event, newValue) => {
                setEditedProduct({
                  ...editedProduct,
                  flavour: newValue ? newValue.atribut_value : "",
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Flavour"
                  sx={{ width: "100%", mb: 2 }}
                />
              )}
            />
            <TextField
              label={`Converted Volume${unitVolume ? ` (${unitVolume})` : ""}`}
              type="number"
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.convertedvol}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  convertedvol: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Control Price"
              type="number"
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.ctrl_price}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  ctrl_price: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <FormControl sx={{ width: "100%", mb: 2 }}>
              <InputLabel id="demo-multiple-name-label">Stok Unit</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={editedStock}
                onChange={handleStockUnitChange}
                input={<OutlinedInput label="Stok Unit" />}
              >
                {dataStockUnit.map((item, index) => (
                  <MenuItem key={index} value={item.description}>
                    {item.code} - {item.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "100%", mb: 2 }}>
              <InputLabel id="demo-multiple-name-label-group">Group</InputLabel>
              <Select
                labelId="demo-multiple-name-label-group"
                id="demo-multiple-name-group"
                multiple
                value={editedGroup}
                onChange={handleGroupChange}
                input={<OutlinedInput label="Group" />}
              >
                {storeNameOptions2.map((item) => (
                  <MenuItem key={item.label} value={item.label}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="outlined"
                disableRipple
                sx={{
                  width: 180,
                  height: 50,
                  mt: 2,
                  color: "#b34469",
                  borderColor: "#b34469",
                }}
                onClick={() => {
                  setEditModalOpen(false);
                  setSelectedFilteredCategory("");
                  setEditedProduct({});
                  setUnitVolume("");
                }}
              >
                Cancel
              </Button>
              <BootstrapButton
                variant="contained"
                disableRipple
                sx={{ width: 190, height: 50, mt: 2, ml: 0.3 }}
                onClick={handleUpdateProduct}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "UPDATE"}
              </BootstrapButton>
            </div>
          </div>
        </Box>
      </Modal>
      {/* delete modal */}
      <Modal
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
        sx={{ border: 0 }}
      >
        <Box sx={style}>
          <h2>Konfirmasi Hapus</h2>
          <p>Apakah anda yakin untuk menghapus data ini?</p>
          <div>
            <Button
              variant="outlined"
              onClick={handleCloseDeleteModal}
              sx={{ marginRight: 2, width: "46%" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              disabled={isLoading}
              sx={{ color: "#FFF", width: "48%", height: 36 }}
            >
              {isLoading ? "Loading..." : "DELETE"}
            </Button>
          </div>
        </Box>
      </Modal>
      {/* view modal */}
      <ViewDataModal
        isLoading={isLoading}
        dataSurveyDetail={dataSurveyDetail}
        modalStyle={style}
        handleCloseViewer={handleCloseProductViewer}
        modalView={modalView}
        dataSurveyDetailPhoto={dataSurveyDetailPhoto}
        selectedImage={selectedImage}
        handleImageClick={handleImageClick}
      />

      <div style={{ padding: "1rem" }}>
          <TableProductMaster
            onView={handleProductViewer}
            onEdit={handleEditClick}
            onViewModalOpen={setModalView}
            itemsPerPageList={itemsPerPageList}
          />
      </div>
    </div>
  );
}

export default ProductMaster;
