import React from "react";
import {
  Modal,
  Box,
  TextField,
  MenuItem,
  Button,
  Autocomplete,
} from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";

function EditDataModal({
  isLoading,
  editedAuditPlan,
  editModalOpen,
  setEditedAuditPlan,
  modalStyle,
  dataUserSurveyorOptions,
  dataUserQcOptions,
  handleUpdateAuditPlan,
  handleCloseEditModal,
}) {
  const emptyValue = { id: "-", label: "-" };

  return (
    <Modal
      open={editModalOpen}
      onClose={handleCloseEditModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box
        sx={modalStyle}
        style={{
          maxHeight: "600px",
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        <h2>Edit Audit Plans</h2>
        <p>
          Outlet {editedAuditPlan.store_code} - {editedAuditPlan.store_name}
        </p>
        <div>
          {editedAuditPlan.audit_status === "Not Started" ||
          editedAuditPlan.audit_status === "On Progress" ||
          editedAuditPlan.audit_status === "Valid" ? (
            <div>
              <Autocomplete
                options={dataUserSurveyorOptions || []}
                getOptionLabel={(option) => option.label || ""}
                onChange={(event, newValue) =>
                  setEditedAuditPlan({
                    ...editedAuditPlan,
                    user_name_surveyor: newValue?.id || "",
                  })
                }
                value={
                  dataUserSurveyorOptions?.find(
                    (option) => option.id === editedAuditPlan.user_name_surveyor
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User Full Name Surveyor"
                    sx={{ width: "100%", mb: 2 }}
                  />
                )}
              />
              <Autocomplete
                options={[emptyValue, ...(dataUserQcOptions || [])]}
                getOptionLabel={(option) => option.label || ""}
                onChange={(event, newValue) =>
                  setEditedAuditPlan({
                    ...editedAuditPlan,
                    user_name_qc: newValue?.id || "",
                  })
                }
                value={
                  dataUserQcOptions?.find(
                    (option) => option.id === editedAuditPlan.user_name_qc
                  ) || emptyValue
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User Full Name QC"
                    sx={{ width: "100%", mb: 2 }}
                  />
                )}
              />
            </div>
          ) : null}
          <TextField
            name="audit_start_at"
            label="Audit Start Date"
            type="date"
            value={editedAuditPlan.audit_start_at}
            sx={{ width: "100%", mb: 2 }}
            onChange={(e) =>
              setEditedAuditPlan({
                ...editedAuditPlan,
                audit_start_at: e.target.value,
              })
            }
          />
          <TextField
            name="audit_end_at"
            label="Audit End Date"
            type="date"
            value={editedAuditPlan.audit_end_at}
            sx={{ width: "100%", mb: 2 }}
            onChange={(e) =>
              setEditedAuditPlan({
                ...editedAuditPlan,
                audit_end_at: e.target.value,
              })
            }
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            disableRipple
            sx={{
              width: 190,
              height: 50,
              mt: 2,
              color: "#b34469",
              borderColor: "#b34469",
            }}
            onClick={handleCloseEditModal}
          >
            Cancel
          </Button>
          <BootstrapButton
            variant="contained"
            disableRipple
            sx={{ width: 190, height: 50, mt: 2, ml: 0.3 }}
            onClick={handleUpdateAuditPlan}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "UPDATE"}
          </BootstrapButton>
        </div>
      </Box>
    </Modal>
  );
}

export default EditDataModal;
