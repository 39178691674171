import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../Components/Navbar";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import { Slide, toast } from "react-toastify";
import { fetchProfile } from "../../services/profileService";
import {
  fetchStoreMaster,
  fetchStoreMasterColumn,
} from "../../services/storeMasterService";
import {
  fetchJenisOutlet,
  fetchStoreType,
  fetchTipeJalan,
  fetchTipeLokasi,
  fetchTipeSurvey,
} from "../../services/storeAdditionaService";
import {
  fetchDistrict,
  fetchProvince,
  fetchRegency,
  fetchSubDistrict,
} from "../../services/areaNewService";
import {
  Grid,
  TextField,
  Autocomplete,
  Button,
} from "@mui/material";

export default function AddStorePage() {
  // initial
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    store_id: "",
    store_name: "",
    store_type_code: "",
    store_address: "",
    store_latitude: "",
    store_longitude: "",
    province_code: "",
    regency_code: "",
    district_code: "",
    sub_district_code: "",
    jenis_outlet: "",
    tipe_survey: "",
    tipe_jalan: "",
    tipe_lokasi: "Unspecify",
  });

  // handle change
  const handleChangeStoreMaster = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // handle change for area
  const handleChangeStoreMasterForAutocomplete = (key, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleChangeProvince = async (e) => {
    // set province selected but regency not selected
    setFormData({
      ...formData,
      province_code: e,
      regency_code: "",
      district_code: "",
      sub_district_code: "",
    });
    // get data regency based on province selected
    setDataRegency(
      await fetchRegency(accessToken, {
        province_code: e,
      })
    );
    setDataDistrict([]);
    setDataSubDistrict([]);
  };

  const handleChangeRegency = async (e) => {
    // set regency selected but district not selected
    setFormData({
      ...formData,
      regency_code: e,
      district_code: "",
      sub_district_code: "",
    });
    // get data district based on regency selected
    setDataDistrict(
      await fetchDistrict(accessToken, {
        regency_code: e,
      })
    );
    setDataSubDistrict([]);
  };

  const handleChangeDistrict = async (e) => {
    // set district selected but sub district not selected
    setFormData({
      ...formData,
      district_code: e,
      sub_district_code: "",
    });
    // get data sub district based on district selected
    setDataSubDistrict(
      await fetchSubDistrict(accessToken, {
        district_code: e,
      })
    );
  };

  const dataProfile = {
    initial: useSelector((state) => state.profile.initial),
    role_code: useSelector((state) => state.profile.role_code),
  };

  const accessToken = useSelector((state) => state.general.accessToken);
  // data initial
  const dataStoreType = useSelector((state) => state.storeType.data);
  const dataProvince = useSelector((state) => state.province.data);
  const dataJenisOutlet = useSelector((state) => state.jenisOutlet.data);
  const dataTipeSurvey = useSelector((state) => state.tipeSurvey.data);
  const dataTipeJalan = useSelector((state) => state.tipeJalan.data);
  const dataTipeLokasi = useSelector((state) => state.tipeLokasi.data);
  // data dynamic
  const [dataRegency, setDataRegency] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubDistrict, setDataSubDistrict] = useState([]);
  // initial page
  const currentPage = useSelector((state) => state.storeMaster.currentPage);
  const itemsPerPage = useSelector((state) => state.storeMaster.itemsPerPage);
  const selectedStatusId = useSelector(
    (state) => state.storeMaster.selectedStatusId
  );
  // selected filter
  const selectedTipeSurvey = useSelector(
    (state) => state.storeMaster.selectedTipeSurvey
  );
  const selectedProvinceId = useSelector(
    (state) => state.storeMaster.selectedProvinceId
  );
  const selectedRegencyId = useSelector(
    (state) => state.storeMaster.selectedRegencyId
  );
  const selectedDistrictId = useSelector(
    (state) => state.storeMaster.selectedDistrictId
  );
  const selectedSubDistrictId = useSelector(
    (state) => state.storeMaster.selectedSubDistrictId
  );
  const filterColumnName = useSelector(
    (state) => state.storeMaster.filterColumnName
  );
  const filterColumnOperator = useSelector(
    (state) => state.storeMaster.filterColumnOperator
  );
  const filterColumnValue = useSelector(
    (state) => state.storeMaster.filterColumnValue
  );

  const handleBackToStore = () => {
    window.location.replace("/store-master");
  };

  const customParams = {
    size: itemsPerPage,
    page: currentPage,
    store_status: selectedStatusId,
    tipe_survey: selectedTipeSurvey,
    province_id: selectedProvinceId,
    regency_id: selectedRegencyId,
    district_id: selectedDistrictId,
    sub_district_id: selectedSubDistrictId,
    column_name: filterColumnName,
    column_operator: filterColumnOperator,
    column_value: filterColumnValue,
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      // Fetch the data after a delay (debouncing)
      // fetchProjects(dispatch, accessToken);
      fetchStoreType(dispatch, accessToken);
      fetchProfile(dispatch, accessToken);
      fetchProvince(dispatch, accessToken);
      fetchJenisOutlet(dispatch, accessToken);
      fetchTipeSurvey(dispatch, accessToken);
      fetchTipeJalan(dispatch, accessToken);
      fetchTipeLokasi(dispatch, accessToken);
      fetchStoreMasterColumn(dispatch, accessToken);
    }, 200); // 200ms delay to debounce the effect

    // Cleanup function to clear the timeout if any dependency changes before the delay ends
    return () => {
      clearTimeout(handler);
    };
  }, [dispatch]);

  // create store master
  async function createStoreMaster() {
    setIsLoading(true);
    try {
      const createFormData = {
        ...formData,
        store_id: formData.store_id.trim(),
        store_name: formData.store_name.trim(),
        store_address: formData.store_address.trim(),
      };
      
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/store-master`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(createFormData),
        }
      );

      if (response.status === 201) {
        toast.success("Store berhasil dibuat!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        fetchStoreMaster(dispatch, accessToken, customParams);

        // clear data
        setFormData({
          id: "",
          store_id: "",
          store_name: "",
          store_type_code: "",
          store_address: "",
          store_latitude: "",
          store_longitude: "",
          province_code: "",
          regency_code: "",
          district_code: "",
          sub_district_code: "",
          jenis_outlet: "",
          tipe_survey: "",
          tipe_jalan: "",
          tipe_lokasi: "",
        });
        // reset
        setDataRegency([]);
        setDataDistrict([]);
        setDataSubDistrict([]);
        // close
        setTimeout(function () {
          window.location.replace("/store-master");
        }, 500);
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        toast.error(data.detail || "Failed to create store master", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error("Gagal membuat store!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error("Failed to create store:", response.statusText);
      }
    } catch (error) {
      console.error("Error creating store:", error);
    }
    setIsLoading(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await createStoreMaster();
    setIsLoading(false);
  };

  return (
    <div>
      <Navbar
        active="Store Master"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div style={{ width: "70%", margin: "0 auto" }}>
        <h2 style={{ textAlign: "center", mt: 2, mb: 2 }}>Add Store Master</h2>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12} sm={6}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} mb={2}>
                  <TextField
                    name="store_id"
                    label="Kode Outlet"
                    value={formData.store_id}
                    sx={{ width: "100%" }}
                    onChange={handleChangeStoreMaster}
                    required
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <TextField
                    name="store_name"
                    label="Nama Outlet"
                    value={formData.store_name}
                    sx={{ width: "100%" }}
                    onChange={handleChangeStoreMaster}
                    required
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <TextField
                    name="store_address"
                    label="Alamat"
                    value={formData.store_address}
                    sx={{ width: "100%" }}
                    onChange={handleChangeStoreMaster}
                    required
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Autocomplete
                    id="select_province_code"
                    value={
                      dataProvince.find(
                        (province) => province.code === formData.province_code
                      ) || null
                    }
                    onChange={async (e, newValue) => {
                      handleChangeStoreMasterForAutocomplete(
                        "province_code",
                        newValue ? newValue.code : null
                      );
                      handleChangeProvince(newValue ? newValue.code : null);
                    }}
                    options={dataProvince || []}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.code === value.code
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Provinsi"
                        required
                      />
                    )}
                    disableClearable
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Autocomplete
                    id="select_regency_code"
                    value={
                      dataRegency.find(
                        (regency) => regency.code === formData.regency_code
                      ) || null
                    }
                    onChange={async (e, newValue) => {
                      handleChangeStoreMasterForAutocomplete(
                        "regency_code",
                        newValue ? newValue.code : null
                      );
                      handleChangeRegency(newValue ? newValue.code : null);
                    }}
                    options={dataRegency || []}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.code === value.code
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Kota/Kabupaten"
                        required
                      />
                    )}
                    disableClearable
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Autocomplete
                    id="select_district_code"
                    value={
                      dataDistrict.find(
                        (district) => district.code === formData.district_code
                      ) || null
                    }
                    onChange={async (e, newValue) => {
                      handleChangeStoreMasterForAutocomplete(
                        "district_code",
                        newValue ? newValue.code : null
                      );
                      handleChangeDistrict(newValue ? newValue.code : null);
                    }}
                    options={dataDistrict || []}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.code === value.code
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Kecamatan"
                        required
                      />
                    )}
                    disableClearable
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Autocomplete
                    id="select_sub_district_code"
                    value={
                      dataSubDistrict.find(
                        (sub_district) =>
                          sub_district.code === formData.sub_district_code
                      ) || null
                    }
                    onChange={async (e, newValue) => {
                      handleChangeStoreMasterForAutocomplete(
                        "sub_district_code",
                        newValue ? newValue.code : null
                      );
                    }}
                    options={dataSubDistrict || []}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.code === value.code
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Kelurahan"
                        required
                      />
                    )}
                    disableClearable
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} mb={2}>
                  <Autocomplete
                    id="select_store_type_code"
                    value={
                      dataStoreType.find(
                        (option) => option.id === formData.store_type_code
                      ) || null
                    }
                    onChange={async (e, newValue) => {
                      handleChangeStoreMasterForAutocomplete(
                        "store_type_code",
                        newValue ? newValue.id : null
                      );
                    }}
                    options={dataStoreType || []}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipe Toko"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Autocomplete
                    id="select_jenis_outlet"
                    value={
                      dataJenisOutlet.find(
                        (option) => option.desc === formData.jenis_outlet
                      ) || null
                    }
                    onChange={async (e, newValue) => {
                      handleChangeStoreMasterForAutocomplete(
                        "jenis_outlet",
                        newValue ? newValue.desc : null
                      );
                    }}
                    options={dataJenisOutlet || []}
                    getOptionLabel={(option) => option.desc}
                    isOptionEqualToValue={(option, value) =>
                      option.desc === value.desc
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Jenis Outlet"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Autocomplete
                    id="select_tipe_survey"
                    value={
                      dataTipeSurvey.find(
                        (option) => option.desc === formData.tipe_survey
                      ) || null
                    }
                    onChange={async (e, newValue) => {
                      handleChangeStoreMasterForAutocomplete(
                        "tipe_survey",
                        newValue ? newValue.desc : null
                      );
                    }}
                    options={dataTipeSurvey || []}
                    getOptionLabel={(option) => option.desc}
                    isOptionEqualToValue={(option, value) =>
                      option.desc === value.desc
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipe Survey"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Autocomplete
                    id="select_tipe_jalan"
                    value={
                      dataTipeJalan.find(
                        (option) => option.desc === formData.tipe_jalan
                      ) || null
                    }
                    onChange={async (e, newValue) => {
                      handleChangeStoreMasterForAutocomplete(
                        "tipe_jalan",
                        newValue ? newValue.desc : null
                      );
                    }}
                    options={dataTipeJalan || []}
                    getOptionLabel={(option) => option.desc}
                    isOptionEqualToValue={(option, value) =>
                      option.desc === value.desc
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipe Jalan"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Autocomplete
                    id="select_tipe_lokasi"
                    value={
                      dataTipeLokasi.find(
                        (option) => option.desc === formData.tipe_lokasi
                      ) || null
                    }
                    onChange={async (e, newValue) => {
                      handleChangeStoreMasterForAutocomplete(
                        "tipe_lokasi",
                        newValue ? newValue.desc : null
                      );
                    }}
                    options={dataTipeLokasi || []}
                    getOptionLabel={(option) => option.desc}
                    isOptionEqualToValue={(option, value) =>
                      option.desc === value.desc
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipe Lokasi"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <TextField
                    name="store_latitude"
                    label="Latitude"
                    value={formData.store_latitude}
                    sx={{ width: "100%" }}
                    onChange={handleChangeStoreMaster}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <TextField
                    name="store_longitude"
                    label="Longitude"
                    value={formData.store_longitude}
                    sx={{ width: "100%" }}
                    onChange={handleChangeStoreMaster}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center" gap={2} mb={2}>
            <Button
              xs={12}
              sm={6}
              variant="outlined"
              disableRipple
              sx={{
                width: 190,
                height: 50,
                mt: 2,
                color: "#b34469",
                borderColor: "#b34469",
              }}
              onClick={handleBackToStore}
            >
              Cancel
            </Button>
            <BootstrapButton
              xs={12}
              sm={6}
              variant="contained"
              disableRipple
              sx={{ width: 190, height: 50, mt: 2 }}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Submit"}
            </BootstrapButton>
          </Grid>
        </form>
      </div>
    </div>
  );
}
