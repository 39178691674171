import Cookies from "js-cookie";
import { React, useState, useEffect, useRef } from "react";

// external
import Navbar from "../../../Components/Navbar";
import { BootstrapButton } from "../../../Components/Button/BootstrapButton";
import { fetchProfile } from "../../../utils/fetchProfile";
import TableHistory from "./Table/TableHistory";
import ViewModal from "./Modal/ViewModal";
import { fetchLicense } from "../../../utils/fetchLicense";

function ImportAudit() {
  const accessToken = Cookies.get("access_token") || "";
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [dataProfile, setDataProfile] = useState([]);
  const [dataImportHistory, setDataImportHistory] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const isPersonal = dataProfile.role_code === "A" ? "&is_personal=false" : "&is_personal=true";
  
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/process_queue?category=import_audit_plan&sort_by=updated_at&sort_order=desc${isPersonal}&page=${page + 1}&size=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const result = await response.json();
  
      if (result.status === 200) {
        const { data, pagination_info } = result;
        setDataImportHistory(
          data.map((item) => ({
            id: item.id,
            title: item.title,
            status: item.status,
            description: item.description,
            created_at: item.created_at,
            updated_at: item.updated_at,
            process_at: item.process_at,
          }))
        );
        setRowCount(pagination_info.total || 0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false)
  };  

  const handleRefresh = () => {
    if (!isDisabled) {
      setIsDisabled(true);
      setCountdown(10);
      fetchData(page, pageSize);
  
      const intervalId = setInterval(() => {
        setCountdown(prevCount => {
          if (prevCount <= 1) {
            clearInterval(intervalId);
            setIsDisabled(false);
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  const handleDownloadTemplateFile = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/audit-plan-master/export/template/base`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Template Audit Plan.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "Failed to download template file. Response status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error downloading template file:", error);
    }
    setIsLoading(false);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    try{
      const data = await fetchLicense();

      if(data?.is_license_expired){
        alert(data?.license_message)
        return;
      }
    }catch (error) {
      console.error(error)
      alert("Something went wrong get license");
      return;
    }

    if (!file) {
      alert("Please select a file before uploading.");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/audit-plan-master/store/assign/import/template/v3`, // ubah v3 jadi v2 kalau mau dikembalikan ke yang lama
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 201) {
        const data = await response.json();
        alert(data.detail);
        fetchData(page, pageSize);
      } else if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Audit Plan Error.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        alert("There is an issue in the Excel file. Please check its content!");
      } else {
        const data = await response.json();
        alert(data.detail);
      }
    } catch (error) {
      alert("Error occurred during upload.");
    } finally {
      setFile(null);
      setIsLoading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedHistoryId, setSelectedHistoryId] = useState("");

  const handleOpenViewModal = (id) => {
    setSelectedHistoryId(id);
    setViewModalOpen(true);
  };

  const handleCloseModal = () => {
    setViewModalOpen(false);
    setSelectedHistoryId("");
  };
  
  useEffect(() => {
    const handler = setTimeout(() => {
      if (dataProfile) {
        fetchData();
      }
    }, 200);
    return () => {
      clearTimeout(handler);
    };
}, [dataProfile, page, pageSize]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, [
        "13",
        "14",
      ]);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Upload Audit Plans"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allow to access this page</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar
        active="Upload Audit Plans"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div
        className="button-audit"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
          marginRight: "20px",
          marginLeft: "20px",
        }}
      >
        <div className="">
          <div style={{ marginBottom: 10 }}>
            <input
              type="file"
              onChange={handleFileChange}
              ref={fileInputRef}
              accept=".xlsx"
            />
          </div>
          <div>
            <BootstrapButton
              onClick={handleDownloadTemplateFile}
              variant="contained"
              disableRipple
              disabled={isLoading}
              className="button-audit-plan"
            >
              <p
                style={{
                  fontSize: "15px",
                  height: "10px",
                  textAlign: "center",
                  marginTop: "1px",
                }}
              >
                {isLoading ? "Loading..." : "Template"}
              </p>
            </BootstrapButton>
            <BootstrapButton
              variant="contained"
              disableRipple
              sx={{ ml: 1 }}
              onClick={handleUpload}
              disabled={isLoading}
            >
              <p
                style={{
                  fontSize: "15px",
                  height: "10px",
                  textAlign: "center",
                  marginTop: "1px",
                }}
              >
                {isLoading ? "Loading..." : "Upload"}
              </p>
            </BootstrapButton>
          </div>
        </div>
        <div style={{ textAlign: "right" }}>
          <div style={{ marginBottom: 10 }}>
            <BootstrapButton
              onClick={() => window.location.replace("/audit-plan")}
              variant="contained"
              disableRipple
              disabled={isLoading}
              className="button-audit-plan"
            >
              <p
                style={{
                  fontSize: "15px",
                  height: "10px",
                  textAlign: "center",
                  marginTop: "1px",
                }}
              >
                {isLoading ? "Loading..." : "Back To Audit Plan"}
              </p>
            </BootstrapButton>
          </div>
          <div>
            <BootstrapButton
              onClick={handleRefresh}
              variant="contained"
              disableRipple
              disabled={isLoading || isDisabled}
              className="button-audit-plan"
            >
              <p
                style={{
                  fontSize: "15px",
                  height: "10px",
                  textAlign: "center",
                  marginTop: "1px",
                }}
              >
                {isLoading
                  ? "Loading..."
                  : isDisabled
                  ? `Wait ${countdown}s`
                  : "Refresh Status"}
              </p>
            </BootstrapButton>
          </div>
        </div>
      </div>
      <div>
        <TableHistory
          dataImportHistory={dataImportHistory}
          rowCount={rowCount}
          page={page}
          pageSize={pageSize}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          accessToken={accessToken}
          handleOpenViewModal={handleOpenViewModal}
          dataProfile={dataProfile}
        />
        <ViewModal 
          open={viewModalOpen} 
          onClose={handleCloseModal} 
          selectedHistoryId={selectedHistoryId} />
      </div>
    </div>
  );
}

export default ImportAudit;
