export async function fetchSyncRawdata(token, setDataSyncRawdata, project_type, period) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/process_queue/sync_rawdata/${project_type}/${period}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        const data = await response.json();
        setDataSyncRawdata(data.data);
      } else {
        setDataSyncRawdata([]);
      }
    } catch (error) {
      console.error(error);
    }
  }
  