import { React, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  MenuItem,
  Button,
  InputLabel,
  Grid,
  Autocomplete
} from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";


function AddDataModal({
  setNewProjectData,
  isLoading, newProjectData,
  isModalOpen, setIsModalOpen,
  modalStyle,
  dataProjectTypeOption, dataProductGroupMasterList,
  handleInputChange, handleSubmit
}) {
  let dataProductGroupMasterListFormat = dataProductGroupMasterList;

  if (newProjectData.project_type === 'rak' || newProjectData.project_type === 'rak2_b') {
    dataProductGroupMasterListFormat = dataProductGroupMasterList.filter(data => data.name.includes('msra'));
  }
  if (newProjectData.project_type === 'rdc') {
    dataProductGroupMasterListFormat = dataProductGroupMasterList.filter(data => data.name.includes('rdc_product'));
  }
  if (newProjectData.project_type === 'rdc_mt') {
    dataProductGroupMasterListFormat = dataProductGroupMasterList.filter(data => data.name.includes('rdc_mt'));
  }
  if (newProjectData.project_type === 'rdc_pharmacy') {
    dataProductGroupMasterListFormat = dataProductGroupMasterList.filter(data => data.name.includes('rdc_apotek'));
  }
  if (newProjectData.project_type === 'adhoc') {
    dataProductGroupMasterListFormat = dataProductGroupMasterList.filter(data => data.name.includes('rdc_product'));
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box
        sx={{
          ...modalStyle,
          width: 500,
          margin: "0 auto",
          maxHeight: "700px",
          overflow: "auto",
          overflowX: "hidden",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div>
          <h2>Create Project</h2>
          <TextField
            variant="filled"
            name="project_name"
            label="Project Name"
            value={newProjectData.project_name}
            onChange={handleInputChange}
            sx={{ width: "100%", mb: 2 }}
          />
          <TextField
            variant="filled"
            name="project_type"
            label="Project Type"
            select
            sx={{ width: "100%", mb: 2 }}
            value={newProjectData.project_type}
            onChange={(event) => {
              const { value } = event.target;
              setNewProjectData({
                ...newProjectData,
                project_type: value,
                product_group_name: '',
              });
            }}
          >
            {dataProjectTypeOption.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {
            newProjectData.project_type === 'rak' || newProjectData.project_type === 'rak2_b' ? <>
              {
                newProjectData.project_type === 'rak' ?
                  <Grid container spacing={2}>
                    <Grid item sx={{ width: "50%" }}>
                      <InputLabel htmlFor="filled-adornment-rak1_survey_start">
                        RAK1 Survey Start
                      </InputLabel>
                      <TextField
                        variant="filled"
                        name="rak1_survey_start"
                        type="date"
                        value={newProjectData.rak1_survey_start}
                        onChange={handleInputChange}
                        sx={{ width: "100%", mb: 2 }}
                      />
                    </Grid>
                    <Grid item sx={{ width: "50%" }}>
                      <InputLabel htmlFor="filled-adornment-rak1_survey_end">
                        RAK1 Survey End
                      </InputLabel>
                      <TextField
                        variant="filled"
                        name="rak1_survey_end"
                        type="date"
                        value={newProjectData.rak1_survey_end}
                        onChange={handleInputChange}
                        sx={{ width: "100%", mb: 2 }}
                      />
                    </Grid>
                  </Grid>
                  : null
              }
              <Grid container spacing={2}>
                <Grid item sx={{ width: "50%" }}>
                  <InputLabel htmlFor="filled-adornment-rak2_survey_start">
                    RAK2 Survey Start
                  </InputLabel>
                  <TextField
                    variant="filled"
                    name="rak2_survey_start"
                    type="date"
                    value={newProjectData.rak2_survey_start}
                    onChange={handleInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </Grid>
                <Grid item sx={{ width: "50%" }}>
                  <InputLabel htmlFor="filled-adornment-rak2_survey_end">
                    RAK2 Survey End
                  </InputLabel>
                  <TextField
                    variant="filled"
                    name="rak2_survey_end"
                    type="date"
                    value={newProjectData.rak2_survey_end}
                    onChange={handleInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </Grid>
              </Grid>
              <TextField
                variant="filled"
                name="prev_period"
                label="Prev Period"
                type="number"
                value={newProjectData.prev_period}
                onChange={handleInputChange}
                sx={{ width: "100%", mb: 2 }}
              />
            </> : null
          }
          {
            newProjectData.project_type === 'rdc' | newProjectData.project_type === 'rdc_mt' | newProjectData.project_type === 'rdc_pharmacy' | newProjectData.project_type === 'adhoc' ?
              <Grid container spacing={2}>
                <Grid item sx={{ width: "50%" }}>
                  <InputLabel htmlFor="filled-adornment-rdc_survey_start">
                    RDC Survey Start
                  </InputLabel>
                  <TextField
                    variant="filled"
                    name="rdc_survey_start"
                    type="date"
                    value={newProjectData.rdc_survey_start}
                    onChange={handleInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </Grid>
                <Grid item sx={{ width: "50%" }}>
                  <InputLabel htmlFor="filled-adornment-rdc_survey_end">
                    RDC Survey End
                  </InputLabel>
                  <TextField
                    variant="filled"
                    name="rdc_survey_end"
                    label=""
                    type="date"
                    value={newProjectData.rdc_survey_end}
                    onChange={handleInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </Grid>
              </Grid>
              : null
          }
          <TextField
            variant="filled"
            name="period"
            label="Period"
            type="number"
            value={newProjectData.period}
            onChange={handleInputChange}
            sx={{ width: "100%", mb: 2 }}
          />
          {
            newProjectData.project_type ?
              <Autocomplete
                freeSolo
                id="free-solo-product_group_name"
                name="product_group_name"
                value={newProjectData.product_group_name}
                onInputChange={(event, newValue) => {
                  setNewProjectData({
                    ...newProjectData,
                    product_group_name: newValue,
                  });
                }}
                onChange={(event, newValue) => {
                  setNewProjectData({
                    ...newProjectData,
                    product_group_name: newValue,
                  });
                }}
                disableClearable
                options={dataProductGroupMasterListFormat.map((option) => option.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Product Group Name"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                  />
                )}
              />
              : null
          }
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              disableRipple
              sx={{
                width: 182,
                mt: 2,
                mr: 2,
                height: 50,
                color: "#b34469",
                borderColor: "#b34469",
              }}
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
            <BootstrapButton
              variant="contained"
              disableRipple
              sx={{ width: 182, mt: 2, height: 50 }}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "SUBMIT"}
            </BootstrapButton>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default AddDataModal
