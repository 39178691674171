export async function fetchDataProjectManagement(token, selectedProjectStatus, selectedPeriode, setIsLoading, setFilteredRows) {
  setFilteredRows([])
  setIsLoading(true);

  // Create an empty URLSearchParams object
  const queryParams = new URLSearchParams();

  // Add selectedProjectStatus to the query if it's provided
  if (selectedProjectStatus && selectedProjectStatus !== 'all') {
    queryParams.append('project_status', selectedProjectStatus);
  }

  if (selectedPeriode && selectedPeriode !== 'all') {
    queryParams.append('period', selectedPeriode);
  }

  // Construct the full URL with query parameters
  const queryString = queryParams.toString();
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/project` + (queryString ? `?${queryString}` : '');

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      setFilteredRows(data.data); // Initialize filteredRows with the retrieved data
    } else {
      setFilteredRows([])
      // Handle error
    }
  } catch (error) {
    // Handle error
  }
  
  setIsLoading(false);
}


export async function fetchProductGroupMaster(token, setIsLoading, setDataProductGroupMasterList) {
  setIsLoading(true);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/product-group-master`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const { data } = await response.json();
      setDataProductGroupMasterList(data)
    } else {
      setDataProductGroupMasterList([])
    }
  } catch (error) {
    console.error(error);
  }
  setIsLoading(false);
}


export async function fetchDetectProjectName(accessToken, name){
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/project/detect/${name}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  if (response.status === 200) {
    return '';
  } else if (response.status === 400) {
    const data = await response.json();
    return data.detail;
  } else {
    return 'error';
  }
}

export async function fetchDetectProductGroupMaster(accessToken, requestBody){
  const requestBodyProductGroupMaster = {
    'name': requestBody.product_group_name,
    'project_type': requestBody.project_type,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/product-group-master/detect`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBodyProductGroupMaster),
    }
  );

  if (response.status === 200) {
    const {data} = await response.json();
    requestBody.product_group_id = data.id
  } else if (response.status === 400) {
    const data = await response.json();
    alert(data.detail);
  } else {
    alert("Product group master gagal dibuat!");
  }
}

export async function fetchDetectAuditPlanMaster(accessToken, requestBody, audit_plan_name){
  const requestBodyAuditPlanMaster = {
    'name': audit_plan_name,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/audit-plan-master/detect`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBodyAuditPlanMaster),
    }
  );

  if (response.status === 200) {
    const {data} = await response.json();
    return data.id
  } else if (response.status === 400) {
    const data = await response.json();
    alert(data.detail);
    return ''
  } else {
    alert("audit plan master gagal dibuat!");
    return ''
  }
}

export async function fetchCreateSurveyProjectRDC(accessToken, requestBody){
  const clientCode = requestBody.client_code
  let projectTypeTemp = requestBody.project_type
  const projectType = requestBody.project_type
  if(projectType == 'adhoc'){
    projectTypeTemp = 'rdc'
  }
  
  const projectName = requestBody.project_name
  const RDCSurveyStart = requestBody.rdc_survey_start
  const RDCSurveyEnd = requestBody.rdc_survey_end
  const response = await fetch(
    `${process.env.REACT_APP_API_MOBILE_URL}/survey/create_project?client_code=${clientCode}&project_type=${projectTypeTemp.toUpperCase()}&project_name=${projectName}&rdc_survey_start=${RDCSurveyStart} 00:00:00&rdc_survey_end=${RDCSurveyEnd} 00:00:00`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  let data = await response.json();
  if (data.status === "success") {
    requestBody.survey_id = data.data.rdc_project_id
    const audit_plan_name = data.data.rdc_project_name
    requestBody.rdc_audit_plan_id = await fetchDetectAuditPlanMaster(accessToken, requestBody, audit_plan_name)
    await fetchDetectProductGroupMaster(accessToken, requestBody)
    if(requestBody.survey_id && requestBody.product_group_id && requestBody.rdc_audit_plan_id) {
      let project_type = 'rdc';
      if (projectType === 'rdc_mt') {
        project_type = 'rdc_mt';
      }else if(projectType === 'rdc_pharmacy'){
        project_type = 'rdc_pharmacy'
      }else if(projectType === 'adhoc'){
        project_type = 'adhoc'
      }

      await fetchCreateProject(accessToken, requestBody, requestBody.rdc_survey_start, requestBody.rdc_survey_end, requestBody.rdc_audit_plan_id, project_type, audit_plan_name, null, false)
    }
    window.location.reload();
  } else {
    alert("Survey rdc gagal dibuat!");
  }
}

export async function fetchCreateSurveyProjectRAK(accessToken, requestBody){
  const clientCode = requestBody.client_code
  const projectType = requestBody.project_type
  const projectName = requestBody.project_name
  const RAK1SurveyStart = requestBody.rak1_survey_start
  const RAK1SurveyEnd = requestBody.rak1_survey_end
  const RAK2SurveyStart = requestBody.rak2_survey_start
  const RAK2SurveyEnd = requestBody.rak2_survey_end
  const response = await fetch(
    `${process.env.REACT_APP_API_MOBILE_URL}/survey/create_project?client_code=${clientCode}&project_type=${projectType.toUpperCase()}&project_name=${projectName}&rak1_survey_start=${RAK1SurveyStart} 00:00:00&rak1_survey_end=${RAK1SurveyEnd} 00:00:00&rak2_survey_start=${RAK2SurveyStart} 00:00:00&rak2_survey_end=${RAK2SurveyEnd} 00:00:00`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  let data = await response.json();
  if (data.status === "success") {
    // rak 1
    requestBody.survey_id = data.data.rak1_project_id
    let audit_plan_name = data.data.rak1_project_name
    requestBody.rak1_audit_plan_id = await fetchDetectAuditPlanMaster(accessToken, requestBody, audit_plan_name)
    await fetchDetectProductGroupMaster(accessToken, requestBody)
    if(requestBody.survey_id && requestBody.product_group_id && requestBody.rak1_audit_plan_id) {
      await fetchCreateProject(accessToken, requestBody, requestBody.rak1_survey_start, requestBody.rak1_survey_end, requestBody.rak1_audit_plan_id, 'rak1', audit_plan_name, null, false)
    }
    // rak 2
    requestBody.survey_id = data.data.rak2_project_id
    audit_plan_name = data.data.rak2_project_name
    requestBody.rak2_audit_plan_id = await fetchDetectAuditPlanMaster(accessToken, requestBody, audit_plan_name)
    await fetchDetectProductGroupMaster(accessToken, requestBody)
    if(requestBody.survey_id && requestBody.product_group_id && requestBody.rak2_audit_plan_id) {
      await fetchCreateProject(accessToken, requestBody, requestBody.rak2_survey_start, requestBody.rak2_survey_end, requestBody.rak2_audit_plan_id, 'rak2', audit_plan_name, requestBody.prev_period, false)
    }
    window.location.reload();
  } else {
    alert("Survey rak gagal dibuat!");
  }
}

export async function fetchCreateSurveyProjectRAK2B(accessToken, requestBody){
  const clientCode = requestBody.client_code
  const projectType = requestBody.project_type
  const projectName = requestBody.project_name
  const RAK2SurveyStart = requestBody.rak2_survey_start
  const RAK2SurveyEnd = requestBody.rak2_survey_end
  const response = await fetch(
    `${process.env.REACT_APP_API_MOBILE_URL}/survey/create_project?client_code=${clientCode}&project_type=${projectType.toUpperCase()}&project_name=${projectName}&rak2_survey_start=${RAK2SurveyStart} 00:00:00&rak2_survey_end=${RAK2SurveyEnd} 00:00:00`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  let data = await response.json();
  if (data.status === "success") {
    // rak 2
    requestBody.survey_id = data.data.rak2_project_id
    const audit_plan_name = data.data.rak2_project_name
    requestBody.rak2_audit_plan_id = await fetchDetectAuditPlanMaster(accessToken, requestBody, audit_plan_name)
    await fetchDetectProductGroupMaster(accessToken, requestBody)
    if(requestBody.survey_id && requestBody.product_group_id && requestBody.rak2_audit_plan_id) {
      await fetchCreateProject(accessToken, requestBody, requestBody.rak2_survey_start, requestBody.rak2_survey_end, requestBody.rak2_audit_plan_id, 'rak2', audit_plan_name, requestBody.prev_period, true)
    }
    window.location.reload();
  } else {
    alert("Survey rak 2 b gagal dibuat!");
  }
}

export async function fetchCreateProject(accessToken, requestBody, project_start_at, project_end_at, audit_plan_id, project_type, project_name, prev_period, rakb){
  const requestBodyProject = {
    'name': requestBody.audit_plan_name,
    "audit_plan_id": audit_plan_id,
    "product_group_id": requestBody.product_group_id,
    "project_type_id": project_type,
    "project_name": project_name,
    "project_start_at": project_start_at,
    "project_end_at": project_end_at,
    "period": parseInt(requestBody.period),
    "prev_period": prev_period,
    "survey_id": requestBody.survey_id,
    "rakb": rakb
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/project`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBodyProject),
    }
  );

  if (response.status === 200 || response.status === 201) {
    alert('project berhasil dibuat');
  } else if (response.status === 400) {
    const data = await response.json();
    alert(data.detail);
  } else {
    alert("Project master gagal dibuat!");
  }
}

export async function fetchPeriodeFormatted(token, setDataPeriode) {
  setDataPeriode([]);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/project/period`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      const newArray = data.data.map(item => ({
        label: item.period.toString(), // Mengonversi period menjadi string
        id: item.period.toString(),
      }));
      setDataPeriode(newArray);
    } else {
      setDataPeriode([]);
    }
  } catch (error) {
    console.error(error);
  }
}

export async function fetchProjectType(token, setDataProjectTypes) {
  setDataProjectTypes([]);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/project-type`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      const newArray = data.data.map(item => ({
        label: item.name,
        id: item.id,
      }));
      setDataProjectTypes(newArray);
    } else {
      setDataProjectTypes([]);
    }
  } catch (error) {
    console.error(error);
    setDataProjectTypes([]);
  }
}
