import React, { useState, useEffect } from "react";
import Navbar from "../../../../Components/Navbar";
import { fetchProfile } from "../../../../utils/fetchProfile";
import Cookies from "js-cookie";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { IconButton, Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SearchBarSetupArea from "./SearchBarSetupArea";
import { Autocomplete, TextField, Button } from '@mui/material';
import { DataGrid, GridRowEditStopReasons } from "@mui/x-data-grid";
import DataTable from "./DataGridSetup";
import EditIcon from "@mui/icons-material/Edit";
import { modalStyle } from "../../../../Components/Modal/Index";
import { fetchDataStoreMaster, fetchExportDataStoreMaster } from "./DataUtil";
import { ImportModalSetup } from "../../../../Components/Fragments/Modals/ModalSetupStoreImport";
import { exportStoreMaster } from "../../../../services/storeMasterService";
import { Slide, toast } from "react-toastify";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import "../../../../style/setup.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "5px 10px",
  border: "1px solid",
  marginLeft: "10px",
  width: "100px",
  lineHeight: 1.5,
  backgroundColor: "#069DAE",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#0696A6",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

function SetUpArea() {
  const accessToken = Cookies.get("access_token") || "";
  const [dataProfile, setDataProfile] = useState([]);
  const [dataArea, setDataArea] = useState([]);
  const [dataRegion, setDataRegion] = useState([]);
  const [dataJenisWilayah, setDataJenisWilayah] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchBarValue, setSearchBarValue] = useState("area");
  const [selectedOption, setSelectedOption] = useState("area");
  const [selectedUpdate, setSelectedUpdate] = useState("area");
  const [modalAddSetup, setModalAddSetup] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedStore, setEditedStore] = useState({});

  const [editStoreId, setEditStoreId] = useState(null);
  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedTable, setSelectedTable] = useState("area");
  const [initialText, setInitialText] = useState("");

  const [openDialogImport, setOpenDialogImport] = useState(false);
  const [excelFile, setExcelFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  const [totalCountArea, setTotalCountArea] = useState(0);
  const [totalCountRegion, setTotalCountRegion] = useState(0);
  const [totalCountJenisWilayah, setTotalCountJenisWilayah] = useState(0);
  const [pageArea, setPageArea] = useState(0);
  const [pageRegion, setPageRegion] = useState(0);
  const [pageJenisWilayah, setPageJenisWilayah] = useState(0);
  const [rowsPerPageArea, setRowsPerPageArea] = useState(0);
  const [rowsPerPageRegion, setRowsPerPageRegion] = useState(0);
  const [rowsPerPageJenisWilayah, setRowsPerPageJenisWilayah] = useState(0);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, [
        "13",
        "14",
      ]);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  useEffect(() => {
    if (rowsPerPageArea > 0) {
      const handler = setTimeout(() => {
        fetchArea(pageArea, rowsPerPageArea);
      }, 200);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [accessToken, pageArea, rowsPerPageArea]);  

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchRegion(pageRegion, rowsPerPageRegion);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken, pageRegion, rowsPerPageRegion]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchJenisWilayah(pageJenisWilayah, rowsPerPageJenisWilayah);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken, pageJenisWilayah, rowsPerPageJenisWilayah]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchDataStoreMaster(accessToken, setIsLoading, setFilteredRows);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  useEffect(() => {
    if (selectedTable == "area") {
      setPageArea(0)
      setRowsPerPageArea(5)
    } else if (selectedTable == "region") {
      setPageRegion(0)
      setRowsPerPageRegion(5)
    } else if (selectedTable == "jenis_wilayah") {
      setPageJenisWilayah(0)
      setRowsPerPageJenisWilayah(5)
    }
  }, [selectedTable])

  async function fetchArea() {
    try {
      let url = `${process.env.REACT_APP_API_URL}/api/v1/area-breakdown`;
    
      const sortBy = "area";
      const sortOrder = "asc";
  
      if (rowsPerPageArea > 0) {
        url += `?page=${pageArea + 1}&size=${rowsPerPageArea}&sort_by=${sortBy}&sort_order=${sortOrder}`;
      } else {
        url += `?sort_by=${sortBy}&sort_order=${sortOrder}`;
      }
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setDataArea(data.data);
        setTotalCountArea(data.pagination_info.total);
      }
    } catch (e) {
      console.error(e);
    }
  }
  
  async function fetchRegion() {
  try {
    let url = `${process.env.REACT_APP_API_URL}/api/v1/region`;
    
    const sortBy = "name";
    const sortOrder = "asc";

    if (rowsPerPageRegion > 0) {
      url += `?page=${pageRegion + 1}&size=${rowsPerPageRegion}&sort_by=${sortBy}&sort_order=${sortOrder}`;
    } else {
      url += `?sort_by=${sortBy}&sort_order=${sortOrder}`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    });

    if (response.ok) {
      const data = await response.json();
      setDataRegion(data.data);
      setTotalCountRegion(data.pagination_info.total);
    } else {
      console.error("Error fetching Jenis Outlet:", response.statusText);
    }
  } catch (e) {
    console.error("Error fetching Jenis Outlet:", e);
  }
}


  async function fetchJenisWilayah() {
    try {
      let url = `${process.env.REACT_APP_API_URL}/api/v1/wilayah`;
    
      const sortBy = "name";
      const sortOrder = "asc";
  
      if (rowsPerPageJenisWilayah > 0) {
        url += `?page=${pageJenisWilayah + 1}&size=${rowsPerPageJenisWilayah}&sort_by=${sortBy}&sort_order=${sortOrder}`;
      } else {
        url += `?sort_by=${sortBy}&sort_order=${sortOrder}`;
      }
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });

      // const response = await fetch(
      //   `${process.env.REACT_APP_API_URL}/api/v1/wilayah?page=${
      //     pageJenisWilayah + 1
      //   }&size=${rowsPerPageJenisWilayah}`,
      //   {
      //     method: "GET",
      //     headers: {
      //       Authorization: "Bearer " + accessToken,
      //     },
      //   }
      // );

      if (response.ok) {
        const data = await response.json();
        setDataJenisWilayah(data.data);
        setTotalCountJenisWilayah(data.pagination_info.total);
      } else {
        console.error("Error fetching Wilayah:", response.statusText);
      }
    } catch (e) {
      console.error("Error fetching Wilayah:", e);
    }
  }

  const handlePageChangeArea = (event, newPage) => {
    setPageArea(newPage);
  };

  const handlePageChangeRegion = (event, newPage) => {
    setPageRegion(newPage);
  };

  const handlePageChangeJenisWilayah = (event, newPage) => {
    setPageJenisWilayah(newPage);
  };

  const handleRowsPerPageChangeArea = (event) => {
    setRowsPerPageArea(parseInt(event.target.value, 10));
    setPageArea(0);
  };

  const handleRowsPerPageChangeRegion = (event) => {
    setRowsPerPageRegion(parseInt(event.target.value, 10));
    setPageRegion(0);
  };

  const handleRowsPerPageChangeJenisWilayah = (event) => {
    setRowsPerPageJenisWilayah(parseInt(event.target.value, 10));
    setPageJenisWilayah(0);
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleTableSelection = (table) => {
    setPageArea(0);
    setPageRegion(0);
    setPageJenisWilayah(0);
    setRowsPerPageArea(0);
    setRowsPerPageRegion(0);
    setRowsPerPageJenisWilayah(0);
    setTotalCountArea(0);
    setTotalCountRegion(0);
    setTotalCountJenisWilayah(0);
    setSelectedTable(table);
    setSearchBarValue(table);
    setSelectedOption(table);
    setSelectedUpdate(table);
  };

  const [newArea, setNewArea] = useState({
    area: "",
    region: "",
    wilayah: "",
  });
  const [newRegion, setRegion] = useState({
    code: "",
    name: "",
  });
  const [newJenisWilayah, setNewJenisWilayah] = useState({
    code: "",
    name: "",
  });

  const handleSetupInputChange = (event) => {
    const { name, value } = event.target;
    setNewArea({
      ...newArea,
      [name]: value,
    });
    setRegion({
      ...newRegion,
      [name]: value,
    });
    setNewJenisWilayah({
      ...newJenisWilayah,
      [name]: value,
    });
  };

  const handleCreateArea = async () => {
    const token = Cookies.get("access_token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area-breakdown`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newArea),
        }
      );

      if (response.status === 201) {
        alert("Area berhasil dibuat!");
        setModalAddSetup(false);
        setPageArea(0);
        setNewArea({
          area: "",
          region: "",
          wilayah: "",
        })
        await fetchArea(pageArea, rowsPerPageArea);
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Area gagal dibuat!");
      }
    } catch (error) {
      console.error("Error creating Area:", error);
    }
  };

  const handleCreateRegion = async () => {
    const token = Cookies.get("access_token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/region`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newRegion),
        }
      );

      if (response.status === 201) {
        alert("Region berhasil dibuat!");
        setModalAddSetup(false);
        setPageRegion(0);
        setRegion({
          code: "",
          name: "",
        })
        await fetchRegion(pageRegion, rowsPerPageRegion);
      } else if (response.status === 400) {
        const data = await response.json();
        console.log("Data gagal dibuat. Pesan kesalahan:", data.detail);
        alert(data.detail);
      } else {
        alert("Region gagal dibuat!");
      }
    } catch (error) {
      console.error("Error creating Region:", error);
    }
  };

  const handleCreateJenisWilayah = async () => {
    const token = Cookies.get("access_token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/wilayah`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newJenisWilayah),
        }
      );

      if (response.status === 201) {
        alert("Wilayah berhasil dibuat!");
        setModalAddSetup(false);
        setPageJenisWilayah(0);
        setNewJenisWilayah({
          code: "",
          name: "",
        })
        await fetchJenisWilayah(pageJenisWilayah, rowsPerPageJenisWilayah);
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Wilayah gagal dibuat!");
      }
    } catch (error) {
      console.error("Error creating Wilayah:", error);
    }
  };

  const handleAddNewSetup = () => {
    setModalAddSetup(true);
  };

  const handleEditClick = async (params) => {
    setEditedStore(params);
    if (selectedTable == 'area') {
      setEditStoreId(params.area);
    } else {
      setEditStoreId(params.id);
    }
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };

  const handleUpdateArea = async () => {
    setIsLoading(true);
    if (editStoreId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/area-breakdown/${editStoreId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedStore),
          }
        );

        if (response.status === 200) {
          alert("Area berhasil diubah!");
          setEditModalOpen(false);
          setPageArea(0);
          await fetchArea(pageArea, rowsPerPageArea);
        } else {
          alert("Area gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleUpdateRegion = async () => {
    setIsLoading(true);
    if (editStoreId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/region/${editStoreId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedStore),
          }
        );

        if (response.status === 200) {
          alert("Region berhasil diubah!");
          setEditModalOpen(false);
          setPageRegion(0);
          await fetchRegion(pageRegion, rowsPerPageRegion);
        } else {
          alert("Region gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleUpdateJenisWilayah = async () => {
    setIsLoading(true);
    if (editStoreId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/wilayah/${editStoreId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedStore),
          }
        );

        if (response.status === 200) {
          alert("Wilayah berhasil diubah!");
          setEditModalOpen(false);
          setPageJenisWilayah(0);
          await fetchJenisWilayah(pageJenisWilayah, rowsPerPageJenisWilayah);
        } else {
          alert("Wilayah gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleCloseDialog = () => {
    setOpenDialogImport(false);
    setExcelFile("");
    setFileUrl("");
  };

  async function importStoreMasterTemplate() {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", excelFile);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/store-master/import/setup/template`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }
      );

      if (response.status === 201) {
        toast.success("Import Setup Store berhasil dibuat!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        setOpenDialogImport(false);
        if (selectedTable === "area") {
          fetchArea(pageArea, rowsPerPageArea);
        } else if (selectedTable === "region") {
          fetchRegion(pageRegion, rowsPerPageRegion);
        } else if (selectedTable === "jenis_wilayah") {
          fetchJenisWilayah(pageJenisWilayah, rowsPerPageJenisWilayah);
        }
      } else if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Setup Store Master Error.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        toast.error("Ada masalah di excel. Periksa kembali isi excel!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 400) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error("Gagal mengimport setup store!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error(
          "Failed to import setup store template:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error importing setup store template:", error);
    }
    setIsLoading(false);
  }

  async function exportStoreMasterTemplate() {
    setIsLoading(true);
    const blob = await exportStoreMaster(
      accessToken,
      "template",
      "api/v1/store-master/export/setup/template/base",
      {}
    );
    if (blob) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Template Setup Store Master.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      toast.error("Gagal mendownload template setup store!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setIsLoading(false);
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await importStoreMasterTemplate();
    setIsLoading(false);
  };

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Setup Store Master"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allowed to access this page</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar
        active="Setup Area"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div style={{ padding: "1rem" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "30px",
            marginLeft: "20px",
          }}
        >
          <div>
            <SearchBarSetupArea
              label={"Pilih"}
              data={[
                { label: "Area", value: "area" },
                { label: "Wilayah", value: "jenis_wilayah" },
                { label: "Region", value: "region" },
              ]}
              onSelectTable={(table) => handleTableSelection(table)}
              value={searchBarValue}
            />
          </div>
          {/* button */}
          <div className="button-setup" style={{ display: "flex", gap: "5px" }}>
            <BootstrapButton
              variant="contained"
              disableRipple
              onClick={handleAddNewSetup}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Add"}
            </BootstrapButton>
            {selectedTable === "area" && (
              <BootstrapButton
              sx={{width: "fit-content"}}
              variant="contained"
              disableRipple
              onClick={() =>
                (window.location.href =
                  "/setup-area/assign")
              }
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Assign Area"}
            </BootstrapButton>
            )}
          </div>

          {/* create store */}
          <Modal
            open={modalAddSetup}
            onClose={() => {
              setNewArea({
                area: "",
                region: "",
                wilayah: "",
              })
              setRegion({
                code: "",
                name: "",
              })
              setNewJenisWilayah({
                code: "",
                name: "",
              })
              setModalAddSetup(false)
            }}            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ border: 0 }}
          >
            <Box
              sx={{
                ...style,
                width: 900,
                margin: "0 auto",
                maxHeight: "700px",
                overflow: "auto",
                overflowX: "hidden",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Tooltip title="Close Modal">
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    setNewArea({
                      area: "",
                      region: "",
                      wilayah: "",
                    })
                    setRegion({
                      code: "",
                      name: "",
                    })
                    setNewJenisWilayah({
                      code: "",
                      name: "",
                    })
                    setModalAddSetup(false)
                  }}
                  sx={{ position: "absolute", top: 20, right: 30 }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              {/* Add your modal content here */}

              {selectedOption === "area" && (
                <div className="area">
                  <h2 style={{ marginBottom: "15px" }}>Create Area</h2>
                  <div>
                    <TextField
                      label="AREA"
                      name="area"
                      value={newArea.area}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <Autocomplete
                        value={dataJenisWilayah.find(option => option.name === newArea.wilayah) || null}
                        options={dataJenisWilayah}
                        onChange={(event, newValue) => {
                          setNewArea((prevState) => ({
                            ...prevState,
                            wilayah: newValue ? newValue.name : "",
                          }));
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="WILAYAH"
                            name="wilayah"
                            variant="outlined"
                            sx={{ width: "100%", mb: 2 }}
                          />
                        )}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                      />
                  </div>
                  <div>
                    <Autocomplete
                      value={dataRegion.find(option => option.name === newArea.region) || null}
                      options={dataRegion}
                      onChange={(event, newValue) => {
                        setNewArea((prevState) => ({
                          ...prevState,
                          region: newValue ? newValue.name : "",
                        }));
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="REGION"
                          name="region"
                          variant="outlined"
                          sx={{ width: "100%", mb: 2 }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) => option.id === value?.id}
                    />
                  </div>
                  <div>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                      onClick={handleCreateArea}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Submit"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedOption === "region" && (
                <div className="region">
                  <h2 style={{ marginBottom: "15px" }}>Create Region</h2>
                  <div>
                    <TextField
                      label="CODE"
                      name="code"
                      value={newRegion.code}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <TextField
                      label="NAME"
                      name="name"
                      value={newRegion.name}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                      onClick={handleCreateRegion}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Submit"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedOption === "jenis_wilayah" && (
                <div className="jenis_wilayah">
                  <h2 style={{ marginBottom: "15px" }}>Create Wilayah</h2>
                  <div>
                    <TextField
                      label="CODE"
                      name="code"
                      value={newJenisWilayah.code}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <TextField
                      label="NAME"
                      name="name"
                      value={newJenisWilayah.name}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                      onClick={handleCreateJenisWilayah}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Submit"}
                    </BootstrapButton>
                  </div>
                </div>
              )}
            </Box>
          </Modal>

          <Modal
            open={editModalOpen}
            onClose={handleCloseEditModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ border: 0 }}
          >
            <Box
              sx={modalStyle}
              style={{
                maxHeight: "400px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              {selectedUpdate === "area" && (
                <div className="area">
                  <h2>Edit Area</h2>
                  <div>
                    <TextField
                      name="area"
                      label="Area"
                      sx={{ width: "100%", mb: 2 }}
                      value={editedStore.area ?? ""}
                      onChange={(e) =>
                        setEditedStore({
                          ...editedStore,
                          area: e.target.value,
                        })
                      }
                    />

                    <Autocomplete
                      value={dataJenisWilayah.find(option => option.name === editedStore.wilayah) || null}
                      options={dataJenisWilayah}
                      onChange={(event, newValue) => {
                        setEditedStore({
                          ...editedStore,
                          wilayah: newValue ? newValue.name : "",
                        })
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="WILAYAH"
                          name="wilayah"
                          variant="outlined"
                          sx={{ width: "100%", mb: 2 }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) => option.id === value?.id}
                    />

                    <Autocomplete
                      value={dataRegion.find(option => option.name === editedStore.region) || null}
                      options={dataRegion}
                      onChange={(event, newValue) => {
                        setEditedStore({
                          ...editedStore,
                          region: newValue ? newValue.name : "",
                        })
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="REGION"
                          name="region"
                          variant="outlined"
                          sx={{ width: "100%", mb: 2 }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) => option.id === value?.id}
                    />
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateArea}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedUpdate === "region" && (
                <div className="region">
                  <h2>Edit Region</h2>
                  <div>
                    <TextField
                      name="name"
                      label="Name"
                      sx={{ width: "100%", mb: 2 }}
                      value={editedStore.name ?? ""}
                      onChange={(e) =>
                        setEditedStore({
                          ...editedStore,
                          name: e.target.value,
                        })
                      }
                    ></TextField>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateRegion}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedUpdate === "jenis_wilayah" && (
                <div className="jenis_wilayah">
                  <h2>Edit Wilayah</h2>
                  <div>
                    <TextField
                      name="name"
                      label="Name"
                      sx={{ width: "100%", mb: 2 }}
                      value={editedStore.name ?? ""}
                      onChange={(e) =>
                        setEditedStore({
                          ...editedStore,
                          name: e.target.value,
                        })
                      }
                    ></TextField>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateJenisWilayah}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              )}
            </Box>
          </Modal>
        </div>

        <ImportModalSetup
          isLoading={isLoading}
          open={openDialogImport}
          onClose={handleCloseDialog}
          title={"Import Setup Store Master"}
          buttonTitle={"Upload"}
          fileUrl={fileUrl}
          handleSubmit={handleUpload}
          handleChange={{
            excel: setExcelFile,
            fileUrl: setFileUrl,
          }}
          handleExport={{
            template: exportStoreMasterTemplate,
          }}
        />

        <Paper
          sx={{
            mt: 2,
            ml: 2,
            mr: 2,
            mb: 2,
            border: 2,
            borderColor: "rgb(234,243,248)",
          }}
        >
          {selectedTable === "area" && dataArea && dataArea.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        AREA
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        WILAYAH
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        REGION
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        ACTION
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataArea.length > 0 ? (
                      dataArea.map((params) => (
                        <TableRow key={params.area}>
                          <TableCell align="center">{params.area}</TableCell>
                          <TableCell align="center">{params.wilayah}</TableCell>
                          <TableCell align="center">{params.region}</TableCell>
                          <TableCell align="center">
                            <div>
                              {dataProfile &&
                              (dataProfile.role_code === "A" ||
                                dataProfile.role_code === "COA") ? (
                                <>
                                  <Tooltip
                                    title="Edit Area"
                                    onClick={() => handleEditClick(params)}
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  {/* <Tooltip
                                    title="Assign"
                                    onClick={() =>
                                      (window.location.href =
                                        "/setup-area/assign")
                                    }
                                  >
                                    <IconButton>
                                      <FileUploadIcon />
                                    </IconButton>
                                  </Tooltip> */}
                                </>
                              ) : null}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>No Data Found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 25, 50, 100]}
                component="div"
                count={totalCountArea}
                rowsPerPage={rowsPerPageArea}
                page={pageArea}
                onPageChange={handlePageChangeArea}
                onRowsPerPageChange={handleRowsPerPageChangeArea}
              />
            </>
          ) : selectedTable === "region" &&
            dataRegion &&
            dataRegion.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        KODE
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        NAME
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        ACTION
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataRegion.length > 0 ? (
                      dataRegion.map((params) => (
                        <TableRow key={params.id}>
                          <TableCell align="center">{params.code}</TableCell>
                          <TableCell align="center">{params.name}</TableCell>
                          <TableCell align="center">
                            <div>
                              {dataProfile &&
                              (dataProfile.role_code === "A" ||
                                dataProfile.role_code === "COA") ? (
                                <>
                                  <Tooltip
                                    title="Edit Region"
                                    onClick={() => handleEditClick(params)}
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              ) : null}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>No Data Found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 25, 50, 100]}
                component="div"
                count={totalCountRegion}
                rowsPerPage={rowsPerPageRegion}
                page={pageRegion}
                onPageChange={handlePageChangeRegion}
                onRowsPerPageChange={handleRowsPerPageChangeRegion}
              />
            </>
          ) : selectedTable === "jenis_wilayah" &&
            dataJenisWilayah &&
            dataJenisWilayah.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        KODE
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        NAME
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        ACTION
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataJenisWilayah.length > 0 ? (
                      dataJenisWilayah.map((params) => (
                        <TableRow key={params.id}>
                          <TableCell align="center">{params.code}</TableCell>
                          <TableCell align="center">{params.name}</TableCell>
                          <TableCell align="center">
                            <div>
                              {dataProfile &&
                              (dataProfile.role_code === "A" ||
                                dataProfile.role_code === "COA") ? (
                                <>
                                  <Tooltip
                                    title="Edit Wilayah"
                                    onClick={() => handleEditClick(params)}
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              ) : null}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>No Data Found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 25, 50, 100]}
                component="div"
                count={totalCountJenisWilayah}
                rowsPerPage={rowsPerPageJenisWilayah}
                page={pageJenisWilayah}
                onPageChange={handlePageChangeJenisWilayah}
                onRowsPerPageChange={handleRowsPerPageChangeJenisWilayah}
              />
            </>
          ) : (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  marginTop: "5px",
                  marginLeft: "20px",
                }}
              >
                {initialText}
              </p>
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}

export default SetUpArea;