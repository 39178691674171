import './index.css';
import "./App.css"
import "./style/form.css"
import "./style/store.css"
import "./style/master.css"
import "./style/data.css"
import "./style/area.css"
import "./style/tabel.css"
import React from 'react';
import { createBrowserRouter } from 'react-router-dom'
import ActiveSessionRedirect from "./Components/ActiveSessionRedirect";
import RequireAuth from './Components/RequireAuth';
import Home from './Pages/Home'
import Login from './Pages/Login'
import NotFound from './Pages/NotFound'
import License from './Pages/License'
import ProjectManagement from './Pages/ProjectManagement'
import ProductMaster from './Pages/ProductMaster'
import AddProductPage from './Pages/ProductMaster/AddProductPage'
import EditProductPage from './Pages/ProductMaster/EditProductPage'
import DataSurvey from './Pages/DataSurvey'
import DataSurveyDev from './Pages/DataSurveyDev'
import ViewDataTrx from './Pages/DataSurvey/ViewDataTrx'
import AuditPlan from './Pages/AuditPlan'
import AuditPlanDev from './Pages/AuditPlanDev'
import ImportAudit from './Pages/AuditPlan/ImportAudit'
import ImportProductGroup from './Pages/ProductMaster/ImportProductGroup'
import Dashboard from './Pages/Dashboard'
import UserManagement from './Pages/UserManagement'
import SetUpMasterProduct from './Pages/ProductMaster/SetUp/SetUpMasterProduct'
import SetUpStoreMaster from './Pages/StoreMaster/SetupStoreMaster/Setup'
import SetUpArea from './Pages/Report/ReportNonProjected/SetupArea';
import ProcessReportNonProjected from './Pages/Report/ReportNonProjected/process'
import ExcelRequestImport from './Pages/Report/ReportNonProjected/ExcelRequestImport'
import ReportProjectedPivotData from './Pages/Report/ReportProjected/ReportProjectedPivotData'
import ReportProjectedRawData from './Pages/Report/ReportProjected/ReportProjectedRawData'
import ProjectCustomQuestion from './Pages/ProjectManagement/ProjectCustomQuestion'
import ProjectCustomQuestionStore from './Pages/ProjectManagement/ProjectCustomQuestionStore'

import StoreMaster from './Pages/StoreMaster';
import AddStorePage from './Pages/StoreMaster/AddStorePage';
import EditStorePage from './Pages/StoreMaster/EditStorePage';
import DataProcessing from './Pages/DataProcessing';
import DataProcessingDev from './Pages/DataProcessingDev';
import { CompletenessProduct, CompletenessStoreGT } from './Pages/DataProcessing/DataProcessingCompletenessCheck';
import PublicPhotoStore from './Pages/PublicPhotoStore';
import PublicPhotoProduct from './Pages/PublicPhotoProduct';
// import Map from './Pages/Map';
// import MapStore from './Pages/Map/MapStore';
import ProjectManagementDev from './Pages/ProjectManagementDev';
import CrosscodingBrand from './Pages/DataProcessingDev/Crosscoding/Brand';
import CrosscodingProduct from './Pages/DataProcessingDev/Crosscoding/Product';
import AssignArea from './Pages/Report/ReportNonProjected/SetupArea/Assign';

import SetupStoreType from './Pages/StatModule/SetupStoreType';
import SetupCategory from './Pages/StatModule/SetupCategory';

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <NotFound />
  },
  {
    path: "/login",
    element:
      <ActiveSessionRedirect>
        <Login />
      </ActiveSessionRedirect>
  },
  {
    path: "/license",
    element:
      <RequireAuth>
        <License />
      </RequireAuth>
  },
  {
    path: "/dashboard",
    element: <RequireAuth>
      <Dashboard />
    </RequireAuth>
  },
  {
    path: "/old/project-management",
    element: <RequireAuth>
      <ProjectManagement />
    </RequireAuth> // only show adhoc for custom question
  },
  {
    path: "/project-management",
    element: <RequireAuth>
      <ProjectManagementDev />
    </RequireAuth> // for master edit (able to edit rdc)
  },
  {
    path: "/project-management/custom-question-sku/:projectId",
    element: <RequireAuth>
      <ProjectCustomQuestion />
    </RequireAuth>
  },
  {
    path: "/project-management/custom-question-store/:projectId",
    element: <RequireAuth>
      <ProjectCustomQuestionStore />
    </RequireAuth>
  },
  {
    path: "/store-master",
    element: <RequireAuth>
      <StoreMaster />
    </RequireAuth>
  },
  {
    path: "/store-master/create",
    element: <RequireAuth>
      <AddStorePage />
    </RequireAuth>
  },
  {
    path: "/store-master/edit/:storeId",
    element: <RequireAuth>
      <EditStorePage />
    </RequireAuth>
  },
  {
    path: "/product-master",
    element: <RequireAuth>
      <ProductMaster />
    </RequireAuth>
  },
  {
    path: "/product-master/create",
    element: <RequireAuth>
      <AddProductPage />
    </RequireAuth>
  },
  {
    path: "/product-master/edit/:productId",
    element: <RequireAuth>
      <EditProductPage />
    </RequireAuth>
  },
  {
    path: "/old/data-survey",
    element: <RequireAuth>
      <DataSurvey />
    </RequireAuth>
  },
  {
    path: "/data-survey",
    element: <RequireAuth>
      <DataSurveyDev />
    </RequireAuth>
  },
  {
    path: "/data-survey/:surveyId/:storeId",
    element: <RequireAuth>
      <ViewDataTrx />
    </RequireAuth>
  },
  {
    path: "/old/audit-plan",
    element: <RequireAuth>
      <AuditPlan />
    </RequireAuth>
  },
  {
    path: "/audit-plan",
    element: <RequireAuth>
      <AuditPlanDev />
    </RequireAuth>
  },
  {
    path: "/audit-plan/import",
    element: <RequireAuth>
      <ImportAudit />
    </RequireAuth>
  },
  {
    path: "/product-group/import",
    element: <RequireAuth>
      <ImportProductGroup />
    </RequireAuth>
  },
  {
    path: "/user-management",
    element: <RequireAuth>
      <UserManagement />
    </RequireAuth>
  },
  {
    path: "/data-processing",
    element: <RequireAuth>
      <DataProcessing />
    </RequireAuth>
  },
  {
    path: "/dev/data-processing",
    element: <RequireAuth>
      <DataProcessingDev />
    </RequireAuth> // coming soon : it has upload retailer for rta mt
  },
  {
    path: "/dev/data-processing/crosscode-brand",
    element: <RequireAuth>
      <CrosscodingBrand />
    </RequireAuth>  // coming soon : it has upload retailer for rta mt
  },
  {
    path: "/dev/data-processing/crosscode-product",
    element: <RequireAuth>
      <CrosscodingProduct />
    </RequireAuth>  // coming soon : it has upload retailer for rta mt
  },
  {
    path: "/non-projected-reporting",
    element: <RequireAuth>
      <ProcessReportNonProjected />
    </RequireAuth>
  },
  {
    path: "/data-processing/completeness-check-store/:id",
    element: <RequireAuth>
      <CompletenessStoreGT />
    </RequireAuth>
  },
  {
    path: "/data-processing/completeness-check-product/:id",
    element: <RequireAuth>
      <CompletenessProduct />
    </RequireAuth>
  },
  {
    path: "/non-projected-reporting/preview/:selectedReportGroup/:selectedReport",
    element: <RequireAuth>
      <ExcelRequestImport />
    </RequireAuth>
  },
  {
    path: "/projected-reporting",
    element: <RequireAuth>
      <ReportProjectedPivotData />
    </RequireAuth>
  },
  {
    path: "/projected-reporting-tabular",
    element: <RequireAuth>
      <ReportProjectedRawData />
    </RequireAuth>
  },
  {
    path: "/setup-product-master",
    element: <RequireAuth>
      <SetUpMasterProduct />
    </RequireAuth>
  },
  {
    path: "/setup-store-master",
    element: <RequireAuth>
      <SetUpStoreMaster />
    </RequireAuth>
  },
  {
    path: "/setup-area",
    children: [
      {
        path: "",
        element: (
          <RequireAuth>
            <SetUpArea />
          </RequireAuth>
        ),
      },
      {
        path: "assign",
        element: (
          <RequireAuth>
            <AssignArea />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: "/stat-module",
    children: [
      {
        path: "setup-category",
        element: (
          <RequireAuth>
            <SetupCategory />
          </RequireAuth>
        ),
      },
      {
        path: "setup-store-type",
        element: (
          <RequireAuth>
            <SetupStoreType />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: "/public-photo",
    children: [
      {
        path: "store/:storeId",
        element: (
          <PublicPhotoStore />
        ),
      },
      {
        path: "product/:productId",
        element: (
          <PublicPhotoProduct />
        ),
      },
    ],
  },
  // {
  //   path: "/map-project",
  //   element: <RequireAuth>
  // <Map /></RequireAuth >
  // },
  // {
  //   path: "/map-store-master",
  //   element: <RequireAuth>
  // <MapStore /></RequireAuth >
  // },
]);
