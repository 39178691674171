import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ImageList, ImageListItem, Paper, Card, CardContent, Typography } from "@mui/material";
import { fetchProductPhoto } from "../../services/productMasterService";

function PublicPhotoProduct() {
  const { productId } = useParams();

  const [dataSurveyDetail, setDataSurveyDetail] = useState(null);
  const [dataSurveyDetailPhoto, setDataSurveyDetailPhoto] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (item) => {
    setSelectedImage(item);
  };

  const fetchPhotoProduct = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/public-data/product/${productId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const { data } = await response.json();
        setDataSurveyDetail(data);

        // set photo
        const dataImage = await fetchProductPhoto('', productId);
        setDataSurveyDetailPhoto(dataImage);

      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchPhotoProduct();
  }, [])

  return (
    <div>
      <Card sx={{ minWidth: 275, padding: 5 }} component={Paper}>
        <h4>PRODUCT PHOTOS</h4>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {dataSurveyDetail?.product_id}
          </Typography>
          <Typography variant="h5" component="div">
            {dataSurveyDetail?.product_name}
          </Typography>

          {dataSurveyDetailPhoto?.length > 0 ?
            <ImageList sx={{ width: 750, height: 300 }} cols={2}>
              {dataSurveyDetailPhoto?.map((item) => (
                <ImageListItem key={item.id} onClick={() => handleImageClick(item)}>
                  <img src={`${item.file}`} alt={item.id} loading="lazy" />
                  {selectedImage === item && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(255, 255, 255, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{
                        padding: '20px'
                      }}>
                        {
                          item.detail.survey_name && <p>Project : {item.detail.survey_name}</p>
                        }
                        {
                          item.detail.store_code && <p>Kode Outlet : {item.detail.store_code}</p>
                        }
                        {
                          item.detail.store_name && <p>Nama Outlet : {item.detail.store_name}</p>
                        }
                      </div>
                    </div>
                  )}
                </ImageListItem>
              ))}
            </ImageList>
            : ''}
        </CardContent>
      </Card>
    </div >
  );
}

export default PublicPhotoProduct;
