import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../../../Components/Navbar";
import { fetchProfile } from "../../../../services/profileService";
import {
  fetchRawData,
  fetchPeriod,
  fetchMarketBreakDown,
  fetchLevel,
  fetchFact,
  fetchTypeProjectNew,
} from "../../../../services/reportDBRawDataService";
import {
  setSelectedPeriod,
  setSelectedMarketBreakDown,
  setSelectedLevel,
  setSelectedFact,
  setSelectedTypeProjectData,
  setCurrentPage,
  setRawData,
} from "../../../../redux/slices/reportDBRawDataSlice";
import { TableDBRawData } from "../../../../Components/Fragments/Tables/TableReportDBRawData";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { BootstrapButton } from "../../../../Components/Button/BootstrapButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import "../../../../style/reportdbrawdata.css";
import { Slide, toast } from "react-toastify";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ReportProjectedRawData() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = useSelector((state) => state.general.accessToken);
  const dataProfile = {
    initial: useSelector((state) => state.profile.initial),
    role_code: useSelector((state) => state.profile.role_code),
  };

  //Pagination
  const itemsPerPage = useSelector((state) => state.rawData.itemsPerPage);
  const itemsPerPageList = [5, 10, 50, 100];

  //Type Project
  const [selectedTypeProject, setSelectedTypeProject] = useState("");
  const type_projects = useSelector((state) => state.rawData.type_projects);

  //Period
  const [selectedPeriodCheckBox, setSelectedPeriodCheckBox] = useState([]);
  const periods = useSelector((state) => state.rawData.periods);

  //Market Break Down
  const [selectedMarketBreakDownCheckBox, setSelectedMarketBreakDownCheckBox] =
    useState([]);
  const market_breakdowns = useSelector(
    (state) => state.rawData.market_breakdowns
  );

  //Level
  const [selectedLevelCheckBox, setSelectedLevelCheckBox] = useState([]);
  const levels = useSelector((state) => state.rawData.levels);

  //Fact
  const [selectedFactCheckBox, setSelectedFactCheckBox] = useState("");
  const facts = useSelector((state) => state.rawData.facts);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(dispatch, accessToken);
      fetchTypeProjectNew(dispatch, accessToken, setSelectedTypeProject);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [dispatch, accessToken]);

  useEffect(() => {
    // Fetch initial data when type project changes
    if (selectedTypeProject) {
      fetchFact(
        dispatch,
        accessToken,
        selectedTypeProject,
        setSelectedFactCheckBox
      );
      fetchPeriod(
        dispatch,
        accessToken,
        selectedTypeProject,
        setSelectedPeriodCheckBox
      );
      fetchLevel(
        dispatch,
        accessToken,
        selectedTypeProject,
        setSelectedLevelCheckBox
      );
      fetchMarketBreakDown(
        dispatch,
        accessToken,
        selectedTypeProject,
        setSelectedMarketBreakDownCheckBox
      );
    }
  }, [dispatch, accessToken, selectedTypeProject]);

  const handleTypeProject = (event) => {
    const value = event.target.value;
    setSelectedTypeProject(value);

    dispatch(setSelectedTypeProjectData(value));

    fetchFact(dispatch, accessToken, value);
    fetchPeriod(dispatch, accessToken, value);
    fetchLevel(dispatch, accessToken, value);
    fetchMarketBreakDown(dispatch, accessToken, value);

    setSelectedFactCheckBox([]);
    setSelectedPeriodCheckBox([]);
    setSelectedLevelCheckBox([]);
    setSelectedMarketBreakDownCheckBox([]);
    // reset data of report db raw data
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDataReadyShow(
        selectedFactCheckBox,
        selectedPeriodCheckBox,
        selectedLevelCheckBox,
        selectedMarketBreakDownCheckBox
      );
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [
    selectedFactCheckBox,
    selectedPeriodCheckBox,
    selectedLevelCheckBox,
    selectedMarketBreakDownCheckBox,
  ]);

  const setDataReadyShow = (
    selectedFactCheckBox,
    selectedPeriodCheckBox,
    selectedLevelCheckBox,
    selectedMarketBreakDownCheckBox
  ) => {
    // validate when all column is fill (fact, period, level and market breakdown)
    if (
      selectedFactCheckBox.length > 0 &&
      selectedPeriodCheckBox.length > 0 &&
      selectedLevelCheckBox.length > 0 &&
      selectedMarketBreakDownCheckBox.length > 0
    ) {
      // call api report db raw data
      fetchData(
        selectedTypeProject,
        selectedFactCheckBox,
        selectedPeriodCheckBox,
        selectedLevelCheckBox,
        selectedMarketBreakDownCheckBox
      );
    } else {
      dispatch(setRawData([]));
    }
  };

  const handleFact = (event) => {
    const value = event.target.value;
    setSelectedFactCheckBox(value);

    const optionListString = value.toString();
    dispatch(setSelectedFact(optionListString));
  };

  const handlePeriod = (event) => {
    const value = event.target.value;
    let updatedSelectedPeriodCheckBox = [];

    if (value.includes("selectAll")) {
      updatedSelectedPeriodCheckBox =
        selectedPeriodCheckBox.length === periods.length
          ? []
          : periods.map((period) => period.id);
    } else {
      updatedSelectedPeriodCheckBox = value.filter(
        (val) => val !== "selectAll"
      );
    }

    setSelectedPeriodCheckBox(updatedSelectedPeriodCheckBox);

    const optionListString = updatedSelectedPeriodCheckBox.toString();
    dispatch(setSelectedPeriod(optionListString));
  };

  const handleLevel = (event) => {
    const value = event.target.value;
    let updatedSelectedLevelCheckBox = [];

    if (value.includes("selectAll")) {
      updatedSelectedLevelCheckBox =
        selectedLevelCheckBox.length === levels.length
          ? []
          : levels.map((level) => level.id);
    } else {
      updatedSelectedLevelCheckBox = value.filter((val) => val !== "selectAll");
    }

    setSelectedLevelCheckBox(updatedSelectedLevelCheckBox);

    const optionListString = value.toString();
    dispatch(setSelectedLevel(optionListString));
  };

  const handleMarketBreakDown = (event) => {
    const value = event.target.value;
    let updatedSelectedMarketBreakDownCheckBox = [];

    if (value.includes("selectAll")) {
      updatedSelectedMarketBreakDownCheckBox =
        selectedMarketBreakDownCheckBox.length === market_breakdowns.length
          ? []
          : market_breakdowns.map((market_breakdown) => market_breakdown.id);
    } else {
      updatedSelectedMarketBreakDownCheckBox = value.filter(
        (val) => val !== "selectAll"
      );
    }

    setSelectedMarketBreakDownCheckBox(updatedSelectedMarketBreakDownCheckBox);

    const optionListString = value.toString();
    dispatch(setSelectedMarketBreakDown(optionListString));
  };

  const fetchData = async (
    selectedTypeProject,
    selectedFactCheckBox,
    selectedPeriodCheckBox,
    selectedLevelCheckBox,
    selectedMarketBreakDownCheckBox
  ) => {
    try {
      dispatch(setCurrentPage(1));
      const customParams = {
        size: itemsPerPage,
        page: 1,
        db_set: selectedTypeProject,
        fact: selectedFactCheckBox,
        period: selectedPeriodCheckBox,
        level: selectedLevelCheckBox,
        market_breakdown: selectedMarketBreakDownCheckBox,
      };
      await fetchRawData(dispatch, accessToken, customParams);
    } catch (error) {
      console.error(`Error fetching data:`, error);
    }
  };

  const reportDBRawData = (...arrays) => {
    return arrays.some((array) => array.length === 0);
  };

  const goToPivot = () => {
    window.location.replace("/projected-reporting");
  };

  async function handleProcessExport() {
    setIsLoading(true);
    try {
      const formData = {
        db_set: selectedTypeProject,
        fact: selectedFactCheckBox,
        period: Array.isArray(selectedPeriodCheckBox)
          ? selectedPeriodCheckBox.join(",")
          : selectedPeriodCheckBox,
        level: Array.isArray(selectedLevelCheckBox)
          ? selectedLevelCheckBox.join(",")
          : selectedLevelCheckBox,
        market_breakdown: Array.isArray(selectedMarketBreakDownCheckBox)
          ? selectedMarketBreakDownCheckBox.join(",")
          : selectedMarketBreakDownCheckBox,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/report_db/process_queue`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (
        response.status === 201 ||
        response.status === 200 ||
        response.status === 202
      ) {
        toast.success("data akan diproses kedalam antrian", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 400) {
        const message = await response.json();
        toast.error(message?.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 404) {
        toast.error("data tidak ditemukan", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error("ada masalah saat menyimpan data", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      }
    } catch (error) {
      console.error("Error creating process queue:", error);
    }
    setIsLoading(false);
  }

  const handleExport = async () => {
    setIsLoading(true);
    try {
      const formData = {
        db_set: selectedTypeProject,
        fact: selectedFactCheckBox,
        level: Array.isArray(selectedLevelCheckBox)
          ? selectedLevelCheckBox.join(",")
          : selectedLevelCheckBox,
        period: Array.isArray(selectedPeriodCheckBox)
          ? selectedPeriodCheckBox.join(",")
          : selectedPeriodCheckBox,
        market_breakdown: Array.isArray(selectedMarketBreakDownCheckBox)
          ? selectedMarketBreakDownCheckBox.join(",")
          : selectedMarketBreakDownCheckBox,
      };

      const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/report_db/export`;

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const filename = `report_rawdata_${selectedLevelCheckBox}_${selectedTypeProject}_${selectedFactCheckBox}.xlsx`;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error("Gagal mengunduh", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error("Failed to export process queue:", response.statusText);
      }
    } catch (error) {
      console.error("Error export process queue:", error);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Navbar
        active="Projected Reporting"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div style={{ padding: "2rem" }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div>
            <ButtonGroup style={{ color: "white" }}>
              <BootstrapButton
                onClick={goToPivot}
                style={{
                  color: "white",
                  borderColor: "white",
                  height: "40px",
                  width: "fit-content",
                }}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Go to Pivot Report View"}
              </BootstrapButton>
              <BootstrapButton
                onClick={handleProcessExport}
                style={{
                  color: "white",
                  borderColor: "white",
                  height: "40px",
                  width: "100px",
                }}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Process"}
              </BootstrapButton>
              <BootstrapButton
                onClick={handleExport}
                style={{
                  color: "white",
                  borderColor: "white",
                  height: "40px",
                  width: "100px",
                }}
              >
                {isLoading ? "Loading..." : "Download"}
              </BootstrapButton>
            </ButtonGroup>
          </div>
        </div>

        <div
          style={{
            display: "block",
          }}
        >
          <div
            className="container-textfield"
            style={{ marginBottom: "30px", marginTop: "-50px" }}
          >
            <div className="content-text" style={{ textAlign: "center" }}>
              <h4
                style={{
                  fontSize: 20,
                  fontFamily: ["Montserrat"].join(","),
                }}
              >
                Projected Report - Tabular
              </h4>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="form-raw-data">
            <FormControl sx={{ mb: 2, mt: 1, mr: 2, width: 250 }}>
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ fontSize: "16px" }}
              >
                DB Set Name
              </InputLabel>
              <Select
                id="type-project-autocomplete"
                label="DB Set Name"
                select
                value={selectedTypeProject}
                onChange={handleTypeProject}
              >
                {type_projects.map((db_set) => (
                  <MenuItem key={db_set.id} value={db_set.id}>
                    {db_set.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 2, mt: 1, mr: 2, width: 250 }}>
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ fontSize: "16px" }}
              >
                Fact
              </InputLabel>
              <Select
                id="fact-autocomplete"
                select
                label="Fact"
                value={selectedFactCheckBox}
                onChange={(event) =>
                  handleFact(event, { id: event.target.value })
                }
              >
                {facts.map((fact) => (
                  <MenuItem key={fact.id} value={fact.id}>
                    {fact.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 2, mt: 1, mr: 2, width: 250 }}>
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ fontSize: "16px" }}
              >
                Period
              </InputLabel>
              <Select
                label="Period"
                id="demo-multiple-checkbox"
                multiple
                value={selectedPeriodCheckBox}
                onChange={handlePeriod}
                input={<OutlinedInput label="Period" />}
                renderValue={(selected) => {
                  if (typeof selected === "string") {
                    return selected;
                  }
                  return selected.join(",");
                }}
                MenuProps={MenuProps}
              >
                <MenuItem key="selectAll" value="selectAll">
                  <Checkbox
                    checked={selectedPeriodCheckBox.length === periods.length}
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {periods.map((period) => (
                  <MenuItem key={period.id} value={period.id}>
                    <Checkbox
                      checked={selectedPeriodCheckBox.indexOf(period.id) > -1}
                    />
                    <ListItemText primary={period.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 2, mt: 1, mr: 2, width: 250 }}>
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ fontSize: "16px" }}
              >
                Level
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                label="Level"
                id="demo-multiple-checkbox"
                multiple
                value={selectedLevelCheckBox}
                onChange={handleLevel}
                // input={<OutlinedInput label="level" />}
                renderValue={(selected) => {
                  if (typeof selected === "string") {
                    return selected;
                  }
                  return selected.join(",");
                }}
                MenuProps={MenuProps}
              >
                <MenuItem key="selectAll" value="selectAll">
                  <Checkbox
                    checked={selectedLevelCheckBox.length === levels.length}
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {levels.map((level) => (
                  <MenuItem key={level.id} value={level.id}>
                    <Checkbox
                      checked={selectedLevelCheckBox.indexOf(level.id) > -1}
                    />
                    <ListItemText primary={level.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 2, mt: 1, mr: 2, width: 250 }}>
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ fontSize: "16px" }}
              >
                Market Breakdown
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                label="Market_Breakdown"
                id="demo-multiple-checkbox"
                multiple
                value={selectedMarketBreakDownCheckBox}
                onChange={handleMarketBreakDown}
                renderValue={(selected) => {
                  if (typeof selected === "string") {
                    return selected;
                  }
                  return selected.join(",");
                }}
                MenuProps={MenuProps}
              >
                <MenuItem key="selectAll" value="selectAll">
                  <Checkbox
                    checked={
                      selectedMarketBreakDownCheckBox.length ===
                      market_breakdowns.length
                    }
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {market_breakdowns.map((market_breakdown) => (
                  <MenuItem
                    key={market_breakdown.id}
                    value={market_breakdown.id}
                  >
                    <Checkbox
                      checked={
                        selectedMarketBreakDownCheckBox.indexOf(
                          market_breakdown.id
                        ) > -1
                      }
                    />
                    <ListItemText primary={market_breakdown.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        {!reportDBRawData(
          selectedFactCheckBox,
          selectedPeriodCheckBox,
          selectedLevelCheckBox,
          selectedMarketBreakDownCheckBox
        ) && (
          <TableDBRawData
            itemsPerPageList={itemsPerPageList}
            selectedTypeProject={selectedTypeProject}
            selectedFact={selectedFactCheckBox}
            selectedPeriod={selectedPeriodCheckBox}
            selectedLevel={selectedLevelCheckBox}
            selectedMarketBreakDown={selectedMarketBreakDownCheckBox}
          />
        )}
      </div>
    </div>
  );
}
