import Cookies from "js-cookie";
import { React, useState, useEffect } from "react";

// external
import Navbar from "../../Components/Navbar";
import { fetchProfile } from "../../utils/fetchProfile";
// import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import { modalStyle } from "../../Components/Modal/Index";
import DataGridProjectManagement from "./DataGridProjectManagement";
import AddDataModal from "./AddDataModal";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import EditDataModal from "./EditDataModal";
import { fetchDataProjectManagement, fetchProductGroupMaster, fetchCreateSurveyProjectRDC, fetchCreateSurveyProjectRAK, fetchPeriodeFormatted, fetchDetectProjectName, fetchCreateSurveyProjectRAK2B } from "./DataUtil";
import StatusDataModal from "./StatusDataModal";
import SearchBar from "../../Components/SearchBar";

//styles for open source
import "../../style/projectmanagement.css"

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  padding: "3px 10px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#069DAE",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#0696A6",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

// Functional component for ProjectManagement
function ProjectManagement() {
  // initial
  const symbolRegex = /[.,;:'"&]/;
  const accessToken = Cookies.get("access_token") || "";
  const [isLoading, setIsLoading] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [dataProductGroupMasterList, setDataProductGroupMasterList] = useState([]);

  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);

  // create
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [newProjectData, setNewProjectData] = useState({
    client_code: "2021",
    project_name: "",
    project_type: "",
    rdc_survey_start: "",
    rdc_survey_end: "",
    rak1_survey_start: "",
    rak1_survey_end: "",
    rak2_survey_start: "",
    rak2_survey_end: "",
    period: '',
    prev_period: '',
    survey_id: '',
    rak1_audit_plan_name: "",
    rak2_audit_plan_name: "",
    rdc_audit_plan_name: "",
    rak1_audit_plan_id: "",
    rak2_audit_plan_id: "",
    rdc_audit_plan_id: "",
    product_group_name: "",
    product_group_id: "",
  });

  const dataProjectTypeOption = [
    {
      'label': 'RAK',
      'id': 'rak'
    },
    {
      'label': 'RAK2 B',
      'id': 'rak2_b'
    },
    {
      'label': 'RDC',
      'id': 'rdc'
    },
    {
      'label': 'RDC_MT',
      'id': 'rdc_mt'
    },
    {
      'label': 'RDC_PHARMACY',
      'id': 'rdc_pharmacy'
    },
    {
      'label': 'ADHOC',
      'id': 'adhoc'
    },
  ];

  // for filter
  const dataProjectStatus = [
    {
      label: "Active",
      id: "1",
    },
    {
      label: "Inactive",
      id: "3",
    },
  ];

  const [dataPeriode, setDataPeriode] = useState([]);

  // selected
  const [selectedProjectStatus, setSelectedProjectStatus] = useState(
    localStorage.getItem("selectedProjectStatus") || "1"
  );
  const [selectedPeriodeId, setSelectedPeriodeId] = useState(
    localStorage.getItem("selectedPeriodeId") || "all"
  );

  // edit or update
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedProject, setEditedProject] = useState({});
  const [editProjectId, setEditProjectId] = useState(null);

  // read
  useEffect(() => {
    const handler = setTimeout(() => {
    fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, ["TL", "COA", "13", '14']);
    fetchProductGroupMaster(accessToken, setIsLoading, setDataProductGroupMasterList);
    fetchPeriodeFormatted(accessToken, setDataPeriode);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  useEffect(() => {
    const handler = setTimeout(() => {
    fetchDataProjectManagement(accessToken, selectedProjectStatus, selectedPeriodeId, setIsLoading, setFilteredRows);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken, selectedProjectStatus, selectedPeriodeId]);

  const handleEditClick = async (rows) => {
    setEditedProject(rows.row);
    setEditProjectId(rows.row.id);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  }

  const handleProjectStatusChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedProjectStatus(id);
    localStorage.setItem("selectedProjectStatus", id);
  };

  const handlePeriodeChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedPeriodeId(id);
    localStorage.setItem("selectedPeriodeId", id);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewProjectData({
      ...newProjectData,
      [name]: value,
    });
  };

  const validProjectNameSymbolCheck = (projectName) => {
    // Validate the project name
    // symbol not allowing , . ; : \' " &
    if (symbolRegex.test(projectName)) {
      alert('Project name should not contain any symbols (, . ; : \' " &)');
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const requestBody = {
        ...newProjectData,
      };
      // Check if any value in newProjectData is an empty string
      const requestBodyCheckMaster = {
        'project_type': requestBody.project_type,
        'product_group_name': requestBody.product_group_name,
        'period': requestBody.period,
      };

      if (Object.values(requestBodyCheckMaster).some((value) => value === "")) {
        alert("Project type, period dan product group tidak boleh kosong.");
        setIsLoading(false);
        return; // Exit the function if any value is an empty string
      }

      if (requestBodyCheckMaster.project_type === 'rdc' || requestBodyCheckMaster.project_type === 'rdc_mt' || requestBodyCheckMaster.project_type === 'rdc_pharmacy' || requestBodyCheckMaster.project_type === 'adhoc') {
        const requestBodyCheckSurveyRDC = {
          'project_name': requestBody.project_name,
          'rdc_survey_start': requestBody.rdc_survey_start,
          'rdc_survey_end': requestBody.rdc_survey_end,
        };
        if (Object.values(requestBodyCheckSurveyRDC).some((value) => value === "")) {
          alert("Project name, RDC Survey Start dan RDC Survey End tidak boleh kosong.");
          setIsLoading(false);
          return; // Exit the function if any value is an empty string
        } else if (requestBody.product_group_name.includes('msra')) {
          alert("Project dengan tipe rdc tidak bisa menggunakan product group msra. pilihan yang diijinkan adalah rdc_product");
          setIsLoading(false);
          return;
        } else {
          // Validate the project name
          const validProjectName = validProjectNameSymbolCheck(requestBody.project_name);
          if (validProjectName) {
            // Proceed with the fetch request if the name is valid
            const allowNewProjectCreated = await fetchDetectProjectName(accessToken, requestBody.project_name);
            if (allowNewProjectCreated !== '') {
              alert(allowNewProjectCreated);
            } else {
              await fetchCreateSurveyProjectRDC(accessToken, requestBody)
            }
          }
        }
      } else if (requestBodyCheckMaster.project_type === 'rak') {
        const requestBodyCheckSurveyRAK = {
          'project_name': requestBody.project_name,
          'rak1_survey_start': requestBody.rak1_survey_start,
          'rak1_survey_end': requestBody.rak1_survey_end,
          'rak2_survey_start': requestBody.rak2_survey_start,
          'rak2_survey_end': requestBody.rak2_survey_end,
          'prev_period': requestBody.prev_period,
        };
        if (Object.values(requestBodyCheckSurveyRAK).some((value) => value === "")) {
          alert("Project name, RAK1 Survey Start, RAK1 Survey End, RAK2 Survey Start dan RAK2 Survey End tidak boleh kosong.");
          setIsLoading(false);
          return; // Exit the function if any value is an empty string
        } else if (requestBody.product_group_name.includes('rdc')) {
          alert("Project dengan tipe rak tidak bisa menggunakan product group rdc. pilihan yang diijinkan adalah msra_product");
          setIsLoading(false);
          return;
        } else {
          // Validate the project name
          const validProjectName = validProjectNameSymbolCheck(requestBody.project_name);
          if (validProjectName) {
            // Proceed with the fetch request if the name is valid
            const allowNewProjectCreated = await fetchDetectProjectName(accessToken, requestBody.project_name);
            if (allowNewProjectCreated !== '') {
              alert(allowNewProjectCreated);
            } else {
              await fetchCreateSurveyProjectRAK(accessToken, requestBody)
            }
          }
        }
      } else if (requestBodyCheckMaster.project_type === 'rak2_b') {
        const requestBodyCheckSurveyRAK = {
          'project_name': requestBody.project_name,
          'rak2_survey_start': requestBody.rak2_survey_start,
          'rak2_survey_end': requestBody.rak2_survey_end,
          'prev_period': requestBody.prev_period,
        };
        if (Object.values(requestBodyCheckSurveyRAK).some((value) => value === "")) {
          alert("Project name, RAK2 Survey Start dan RAK2 Survey End tidak boleh kosong.");
          setIsLoading(false);
          return; // Exit the function if any value is an empty string
        } else if (requestBody.product_group_name.includes('rdc')) {
          alert("Project dengan tipe rak tidak bisa menggunakan product group rdc. pilihan yang diijinkan adalah msra_product");
          setIsLoading(false);
          return;
        } else {
          // Validate the project name
          const validProjectName = validProjectNameSymbolCheck(requestBody.project_name);
          if (validProjectName) {
            // Proceed with the fetch request if the name is valid
            const allowNewProjectCreated = await fetchDetectProjectName(accessToken, requestBody.project_name);
            if (allowNewProjectCreated !== '') {
              alert(allowNewProjectCreated);
            } else {
              await fetchCreateSurveyProjectRAK2B(accessToken, requestBody)
            }
          }
        }
      } else {
        alert('ada masalah sewaktu membuat project')
      }
    } catch (error) {
      console.error("Error creating Project:", error);
    }
    setIsLoading(false);
  };

  const handleUpdateProject = async () => {
    setIsLoading(true);
    if (editProjectId) {
      const token = Cookies.get("access_token");
      try {
        let requestBody = {
          ...editedProject
        };

        if (editedProject.project_type_id !== 'rak2') {
          const { prev_period, ...rest } = requestBody;
          // Update requestBody with the rest of the properties
          requestBody = rest;
        }
        // Check if any value in newProjectData is an empty string
        if (Object.values(requestBody).some(value => value === "")) {
          alert("Isi semua data.");
          setIsLoading(false);
          return; // Exit the function if any value is an empty string
        }

        const requestBodyUpdate = {
          'project_name': requestBody.project_name,
          'project_start_at': requestBody.project_start_at,
          'project_end_at': requestBody.project_end_at,
          'period': parseInt(requestBody.period),
          'prev_period': requestBody.prev_period ? parseInt(requestBody.prev_period) : null,
        }
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project/${editProjectId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBodyUpdate),
          }
        );

        if (response.status === 200) {
          alert("Project berhasil diubah!");
          window.location.reload();
        } else if (response.status === 400) {
          const data = await response.json();
          alert(data.detail);
        } else if (response.status === 404) {
          const data = await response.json();
          alert(data.detail);
        } else {
          alert("Project gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  // status
  const [statusModalOpen, seStatusModalOpen] = useState(false);
  const [statusModalData, seStatusModalData] = useState(null);

  const handleOpenStatusModal = async (row) => {
    seStatusModalData(row);
    seStatusModalOpen(true);
  };

  const handleCloseStatusModal = () => {
    seStatusModalData(null);
    seStatusModalOpen(false);
  };

  const handleStatus = async () => {
    setIsLoading(true);
    let statusChange = "1";
    if (statusModalData.project_status === '1') {
      statusChange = "3";
    }
    if (statusModalData) {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project/${statusModalData.id}/status?project_status=${statusChange}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          alert("Project status berhasil diubah!");
          window.location.reload();
        } else {
          alert("Gagal mengubah status Project!");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  const openNewTab = (projectId, surveyId) => {
    const url = `/project-management/custom-question-sku/${projectId}`;
    window.location.href = url;
  };

  const handleOpenProjectCustom = async (row) => {
    const { id } = row;
    openNewTab(id);
  };

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar active="Project Management" initial={dataProfile.initial} role_code={dataProfile.role_code} />
        <div>Not allow to access this page</div>
      </div>
    );
  }
  return (
    <div>
      <Navbar active="Project Management" initial={dataProfile.initial} role_code={dataProfile.role_code} />
      <div className="content" style={{ padding: "1rem" }}>
        <div
          className="legend"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* search bar */}
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: "10px",
                marginBottom: "5px",
              }}
            >
              <SearchBar
                id={"project-status-autocomplete"}
                data={dataProjectStatus}
                value={
                  dataProjectStatus.find(
                    (option) => option.id === selectedProjectStatus
                  ) ?? null
                }
                handleChange={handleProjectStatusChange}
                label={"Project Status"}
              />
              <SearchBar
                id={"periode-autocomplete"}
                data={dataPeriode}
                value={
                  dataPeriode.find(
                    (option) => option.id === selectedPeriodeId
                  ) ?? null
                }
                handleChange={handlePeriodeChange}
                label={"Periode"}
              />
            </div>
          </div>
          {/* button */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="spacing-one"></div>
            <BootstrapButton
              onClick={() => setIsModalOpen(true)}
              variant="contained"
              disableRipple
              disabled={isLoading}
              className="button-add"
            >
              <p style={{ fontSize: "18px", height: "10px", textAlign: "center", marginTop: "1px" }}>{isLoading ? "Loading..." : "Add Project"}</p>
            </BootstrapButton>
          </div>
          {/* modal add*/}
          <AddDataModal
            isLoading={isLoading}
            newProjectData={newProjectData}
            setNewProjectData={setNewProjectData}

            dataProductGroupMasterList={dataProductGroupMasterList}
            dataProjectTypeOption={dataProjectTypeOption}

            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            modalStyle={modalStyle}
          />
          {/* modal edit */}
          <EditDataModal
            isLoading={isLoading}
            editedProject={editedProject}

            setEditedProject={setEditedProject}
            handleUpdateProject={handleUpdateProject}
            handleCloseEditModal={handleCloseEditModal}
            editModalOpen={editModalOpen}
            modalStyle={modalStyle}
          />
          {/* modal status */}
          <StatusDataModal
            isLoading={isLoading}
            modalStyle={modalStyle}
            statusModalOpen={statusModalOpen}
            handleCloseStatusModal={handleCloseStatusModal}
            handleStatus={handleStatus}
          />
        </div>
      </div>
      <div>
        <DataGridProjectManagement
          loading={isLoading}
          rows={filteredRows}
          handleEditClick={handleEditClick}
          handleOpenStatusModal={handleOpenStatusModal}
          handleOpenProjectCustom={handleOpenProjectCustom}
        />
      </div>
    </div>
  );
}

export default ProjectManagement;
