import axios from "axios";

export const fetchSurveyType = async (
  accessToken,
  setDataSurveyType
) => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/tipe-survey`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    let result_data = response?.data?.data;
    if (response.status === 200) {
      setDataSurveyType(result_data);
    } else if (response.status === 404) {
      setDataSurveyType([]);
    } else console.log("Error");
  } catch (e) {
    setDataSurveyType([]);
  }
};

export const fetchStoreType = async (
  accessToken,
  setDataStoreType
) => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/store-type`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    let result_data = response?.data?.data;
    if (response.status === 200) {
      setDataStoreType(result_data);
    } else if (response.status === 404) {
      setDataStoreType([]);
    } else console.log("Error");
  } catch (e) {
    setDataStoreType([]);
  }
};
