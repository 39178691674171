import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#069dae",
    color: theme.palette.common.white,
    border: "solid 1px #fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '6px 16px', 
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '40px',
}));

function createData(brand, product, category, opt, alt) {
  return { brand, product, category, opt, alt };
}

const rows = [
  createData('Adem Sari', 'Adem Sari Air Sejuk 600 ML_1C20P', 'HEALTHY DRINKS', 'Option 1', 'Alt 1'),
  createData('Vape', 'Vape', 'Aerosols', 'Option 2', 'Alt 2'),
];

export default function TableRetailer() {
  return (
    <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ height: "10px" }}>
            <StyledTableCell>RET_Brand</StyledTableCell>
            <StyledTableCell align="left">RET_Product</StyledTableCell>
            <StyledTableCell align="left">RET_Category</StyledTableCell>
            <StyledTableCell align="left">RET_Opt</StyledTableCell>
            <StyledTableCell align="left">RET_Alt</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.brand}>
              <StyledTableCell component="th" scope="row">
                {row.brand}
              </StyledTableCell>
              <StyledTableCell align="left">{row.product}</StyledTableCell>
              <StyledTableCell align="left">{row.category}</StyledTableCell>
              <StyledTableCell align="left">{row.opt}</StyledTableCell>
              <StyledTableCell align="left">{row.alt}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
