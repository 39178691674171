import { setProductMasters, setTotal, setProductMasterColumn } from '../redux/slices/productMasterSlice';

export const fetchProductMaster = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/product-master`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(setProductMasters(data.data));
      dispatch(setTotal(data.pagination_info.total));
    } else {
      dispatch(setProductMasters([]));
      console.error(`Failed to fetch store masters:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching store masters:`, error);
  }
};

export const fetchProductPhoto = async (accessToken, productId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_MOBILE_URL}/survey/get_images?client_code=${process.env.REACT_APP_CLIENT_CODE}&product_code=${productId}&title=R0`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.ok) {
      const { data } = await response.json();

      if (Array.isArray(data) && data.length > 0) {
        const imageUrls = data.map((item, index) => {
          const { store_code, store_name, survey_name, url } = item;
          return {
            id: index,
            file: url,
            detail: {
              store_code: store_code,
              store_name: store_name,
              survey_name: survey_name,
            }
          };
        });
        return imageUrls;
      }
    }
    return [];
  } catch (error) {
    // Handle error
    console.error(error)
    return [];
  }
}

export const fetchProductMasterColumn = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/product-master-columns`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(setProductMasterColumn(data.data));
    } else {
      dispatch(setProductMasterColumn([]));
      console.error(`Failed to fetch product master column:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching product master column:`, error);
  }
};
