// import Cookies from "js-cookie";
// import { React, useState, useEffect } from "react";

// import Navbar from "../../Components/Navbar";
// import { fetchProfile } from "../../utils/fetchProfile";
// import { fetchUserFormatted } from "../../utils/fetchUser";

// import MapLayout from "./MapLayout";
// import L from 'leaflet';
// import ProvinsiData from "./IDN.json";
// import * as turf from '@turf/turf';

// import { TextField, MenuItem } from "@mui/material";
// import Card from "@mui/material/Card";
// import { styled } from "@mui/material/styles";
// import Button from "@mui/material/Button";
// import { ButtonGroup, IconButton } from "@mui/material";

// const BootstrapButton = styled(Button)({
//   boxShadow: "none",
//   textTransform: "none",
//   fontSize: 14,
//   width: "50%", // Set each button to take up 50% of the ButtonGroup width
//   padding: "3px 10px",
//   border: "1px solid",
//   lineHeight: 1.5,
//   backgroundColor: "#069DAE",
//   fontFamily: ["Roboto"].join(","),
//   "&:hover": {
//     backgroundColor: "#0696A6",
//     boxShadow: "none",
//   },
//   "&:active": {
//     boxShadow: "none",
//     backgroundColor: "#0062cc",
//     borderColor: "#005cbf",
//   },
//   "&:focus": {
//     boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
//   },
// });

// const RectangleIcon = ({ color }) => (
//   <div
//     style={{
//       width: '20px',
//       height: '20px',
//       backgroundColor: color || 'gray',
//       marginRight: '3px'
//     }}
//   >
//   </div>
// );

// const LegendItem = ({ color, label }) => (
//   <div
//     style={{
//       display: 'flex',
//       alignItems: 'center',
//       marginBottom: '4px',
//       marginRight: '5px'
//     }}
//   >
//     <RectangleIcon color={color} />
//     {label}
//   </div>
// );

// function Map() {
//   const accessToken = Cookies.get("access_token") || "";
//   const [isLoading, setIsLoading] = useState(false);
//   const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
//   const [initialText, setInitialText] = useState("");
//   const [dataProfile, setDataProfile] = useState([]);

//   const [selectedSurveyId, setSelectedSurveyId] = useState("");
//   const [surveyType, setSurveyType] = useState("");

//   // selected
//   const [provinceCode, setProvinceCode] = useState("");
//   const [tipeSurveyCode, setTipeSurveyCode] = useState("");
//   const [usernameSurveyorCode, setUsernameSurveyorCode] = useState("");
//   const [wilayahName, setWilayahName] = useState("");
//   const [regionName, setRegionName] = useState("");
//   const [regencyCode, setRegencyCode] = useState("");
//   const [districtCode, setDistrictCode] = useState("");
//   const [subDistrictCode, setSubDistrictCode] = useState("");

//   // all data
//   const [dataPeriode, setDataPeriode] = useState([]);
//   const [dataProject, setDataProject] = useState([]);
//   const [dataSurveyType, setDataSurveyType] = useState([]);
//   const [dataRegion, setDataRegion] = useState([]);
//   const [dataWilayah, setDataWilayah] = useState([]);

//   const [dataUserSurveyor, setDataUserSurveyor] = useState([]);
//   const [dataUserSurveyorOptions, setDataUserSurveyorOptions] = useState([]);
//   const [dataUserQcOptions, setDataUserQcOptions] = useState([]);

//   const [provinces, setProvinces] = useState([]);
//   const [provincesId, setProvincesId] = useState([]);
//   const [regencies, setRegencies] = useState([]);
//   const [districts, setDistricts] = useState([]);
//   const [subDistricts, setSubDistricts] = useState([]);

//   const [surveyDataId, setSurveyDataId] = useState([]);
//   const [dataCount, setDataCount] = useState(0);
//   const [auditStatusCounts, setAuditStatusCounts] = useState({
//     valid: 0,
//     notStarted: 0,
//     onProgress: 0,
//     closed: 0,
//   });

//   const [selectedPeriodeId, setSelectedPeriodeId] = useState(
//     localStorage.getItem("selectedPeriodeId") || "all"
//   );

//   const [filteredGeoJSONData, setFilteredGeoJSONData] = useState(ProvinsiData);


//   useEffect(() => {
//     fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, []);
//   }, [accessToken]);

//   useEffect(() => {
//     fetchUserFormatted(accessToken, setDataUserSurveyorOptions, "13");
//     fetchUserFormatted(accessToken, setDataUserSurveyor, "13");
//     fetchUserFormatted(accessToken, setDataUserQcOptions, "14");
//   }, []);

//   async function fetchPeriodeFormatted(token, setDataPeriode) {
//     setDataPeriode([]);
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/api/v1/project/period`,
//         {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         const newArray = data.data.map(item => ({
//           label: item.period.toString(), // Mengonversi period menjadi string
//           id: item.period.toString(),
//         }));
//         setDataPeriode(newArray);
//       } else {
//         setDataPeriode([]);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   }

//   async function fetchProjectData(token, periodeCode, setDataProject) {
//     setDataProject([])
//     if (periodeCode && periodeCode !== 'all') {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/api/v1/project?period=${periodeCode}`,
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );
//         if (response.status === 200) {
//           const { data } = await response.json();
//           const transformedData = data.map((project) => ({
//             value: project.survey_id,
//             label: project.project_name,
//             type: project.project_type_name,
//           }));

//           setDataProject(transformedData);

//           if (transformedData.length > 0) {
//             setSelectedSurveyId(transformedData[0].value);
//             setSurveyType(transformedData[0].type);
//           }
//         } else {
//           throw new Error("Failed to fetch project data");
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     }
//   }


//   useEffect(() => {
//     fetchPeriodeFormatted(accessToken, setDataPeriode);
//   }, []);

//   useEffect(() => {
//     fetchProjectData(accessToken, selectedPeriodeId, setDataProject);
//   }, [selectedPeriodeId]);

//   async function fetchTipeSurvey() {
//     try {
//       const token = Cookies.get("access_token");
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/api/v1/store-type`,
//         {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.status === 200) {
//         const data = await response.json();
//         const newArray = data.data_tipe_survey.map((item) => ({
//           label: item,
//           id: item,
//         }));
//         setDataSurveyType(newArray);
//       } else {
//         setDataSurveyType();
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   }

//   useEffect(() => {
//     fetchTipeSurvey();
//   }, []);

//   async function fetchRegion() {
//     try {
//       const token = Cookies.get("access_token");
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/api/v1/area/regions`,
//         {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.status === 200) {
//         const { data } = await response.json();
//         const newArray = data.map((item) => ({
//           label: item.name,
//           id: item.name,
//         }));

//         setDataRegion(newArray);
//       } else {
//         setDataRegion();
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   }

//   useEffect(() => {
//     fetchRegion();
//   }, []);

//   async function fetchWilayah() {
//     try {
//       const token = Cookies.get("access_token");
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/api/v1/area/wilayah`,
//         {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.status === 200) {
//         const { data } = await response.json();
//         const newArray = data.map((item) => ({
//           label: item.name,
//           id: item.name,
//         }));

//         setDataWilayah(newArray);
//       } else {
//         setDataWilayah([]);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   }

//   useEffect(() => {
//     fetchWilayah();
//   }, []);

//   async function fetchProvinces() {
//     try {
//       const token = Cookies.get("access_token");
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/api/v1/area/provinces`,
//         {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.status === 200) {
//         const { data } = await response.json();
//         setProvinces(data);
//         setProvincesId(data.id);
//       } else {
//         setProvinces();
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   }

//   useEffect(() => {
//     fetchProvinces();
//   }, []);

//   async function fetchRegencies(province_code) {
//     if (province_code !== "all" && province_code) {
//       try {
//         const token = Cookies.get("access_token");
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/api/v1/area/regencies?province_code=${province_code}`,
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (response.status === 200) {
//           const data = await response.json();
//           setRegencies(data.data);
//         } else {
//           throw new Error("Ada masalah saat mengambil kabupaten");
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     } else {
//       setRegencies([]); // Clear regencies if no province is selected
//     }
//   }

//   async function fetchDistricts(province_code, regency_code) {
//     if (regency_code !== "all" && regency_code) {
//       try {
//         const token = Cookies.get("access_token");
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/api/v1/area/districts?province_code=${province_code}&regency_code=${regency_code}`,
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (response.status === 200) {
//           const data = await response.json();
//           setDistricts(data.data);
//         } else {
//           throw new Error("Error when fetch district");
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     }
//   }

//   async function fetchSubDistricts(province_code, regency_code, district_code) {
//     if (districtCode !== "all" && district_code) {
//       try {
//         const token = Cookies.get("access_token");
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/api/v1/area/sub-districts?province_code=${province_code}&regency_code=${regency_code}&district_code=${district_code}`,
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (response.status === 200) {
//           const data = await response.json();
//           setSubDistricts(data.data);
//         } else {
//           throw new Error("Error when fetch sub district");
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     }
//   }

//   const handlePeriodeIdChange = (event, newValue) => {
//     let id = "";
//     if (newValue) {
//       id = newValue.id;
//     }
//     setSelectedPeriodeId(id);
//     localStorage.setItem("selectedPeriodeId", id);

//     setSelectedSurveyId("all");
//     localStorage.setItem("selectedSurveyId", "all");
//     // localStorage.setItem("selectedSurveyId");

//     setProvinceCode("");
//     setRegencyCode("");
//     setDistrictCode("");
//     setSubDistrictCode("");
//     setRegionName("");
//     setWilayahName("");
//     setTipeSurveyCode("");
//     setUsernameSurveyorCode("");

//     setFilteredGeoJSONData(ProvinsiData);
//     setMapPosition([-2.548, 112.544]);
//     setMapZoom(5);
//   };

//   const handleProjectChange = (event) => {
//     const selectedId = event.target.value;
//     setSelectedSurveyId(selectedId);

//     localStorage.setItem("selectedSurveyId", selectedId);
    
//     setProvinceCode("");
//     setRegencyCode("");
//     setDistrictCode("");
//     setSubDistrictCode("");
//     setRegionName("");
//     setWilayahName("");
//     setTipeSurveyCode("");
//     setUsernameSurveyorCode("");

//     setFilteredGeoJSONData(ProvinsiData);
//     setMapPosition([-2.548, 112.544]);
//     setMapZoom(5);
//   };

//   const setDefaultSelectedPeriode = () => {
//     if (dataPeriode.length > 0) {
//       // Check if the currently selected periode is still available in the data
//       const selectedOption = dataPeriode.find(option => option.id === selectedPeriodeId);
//       if (selectedOption) {
//         setSelectedPeriodeId(selectedOption.id);
//       } else {
//         // If the selected option is not available, set the first option as default
//         setSelectedPeriodeId(dataPeriode[0].id);
//       }
//     }
//   };

//   const setDefaultSelectedProject = () => {
//     if (dataProject.length > 0) {
//       // Check if the currently selected project is still available in the data
//       const selectedOption = dataProject.find(option => option.value === selectedSurveyId);
//       if (selectedOption) {
//         setSelectedSurveyId(selectedOption.value);
//         setSurveyType(selectedOption.type);
//       } else {
//         // If the selected option is not available, set the first option as default
//         setSelectedSurveyId(dataProject[0].value);
//         setSurveyType(dataProject[0].type);
//       }
//     }
//   };

//   useEffect(() => {
//     setDefaultSelectedPeriode();
//     setDefaultSelectedProject();
//   }, [dataPeriode, dataProject]);

//   const handleRegionName = (event) => {
//     const selectedRegion = event.target.value;
//     setRegionName(selectedRegion);
//   };

//   const handleWilayahName = (event) => {
//     const selectedWilayah = event.target.value;
//     setWilayahName(selectedWilayah);
//   };

//   const handleTipeSurveyCodeChange = (event) => {
//     const selectedTipeSurveyCode = event.target.value;
//     setTipeSurveyCode(selectedTipeSurveyCode);
//   };

//   const handleUsernameSurveyorCodeChange = (event) => {
//     const selectedUsernameSurveyorCode = event.target.value;
//     setUsernameSurveyorCode(selectedUsernameSurveyorCode);
//   };

//   const [mapPosition, setMapPosition] = useState([-2.548, 112.544]);
//   const [mapZoom, setMapZoom] = useState(5);

//   const handleProvinceCodeChange = (event) => {
//     const selectedProvinceCode = event.target.value;
//     setProvinceCode(selectedProvinceCode);

//     if (!selectedProvinceCode) {
//       setFilteredGeoJSONData(ProvinsiData);
//       setMapPosition([-2.548, 112.544]);
//       setMapZoom(5);
//     } else {
//       const filteredGeoJSONData = {
//         type: "FeatureCollection",
//         features: ProvinsiData.features.filter(feature => feature.properties.prov_id.toString() === selectedProvinceCode)
//       };
//       setFilteredGeoJSONData(filteredGeoJSONData);

//       // Calculate the bounding box and center of the selected province
//       const bbox = turf.bbox(filteredGeoJSONData);
//       const center = [(bbox[1] + bbox[3]) / 2, (bbox[0] + bbox[2]) / 2];

//       // Calculate an appropriate zoom level based on the bbox size
//       const bboxWidth = bbox[2] - bbox[0];
//       const bboxHeight = bbox[3] - bbox[1];
//       const maxDim = Math.max(bboxWidth, bboxHeight);
//       const zoom = Math.min(Math.max(8 - Math.log2(maxDim), 5), 10); // Adjust zoom calculation as needed

//       // Set the map position and zoom
//       setMapPosition(center);
//       setMapZoom(zoom);
//     }
//   };

//   const handleRegencyCodeChange = (event) => {
//     const selectedRegencyCode = event.target.value;
//     setRegencyCode(selectedRegencyCode);
//   };

//   const handleDistrictCodeChange = (event) => {
//     const selectedDistrictCode = event.target.value;
//     setDistrictCode(selectedDistrictCode);
//   };

//   const handleSubDistrictCodeChange = (event) => {
//     const selectedSubDistrictCode = event.target.value;
//     setSubDistrictCode(selectedSubDistrictCode);
//   };

//   const MapIcon = (mapData) => {
//     let iconUrl = '';

//     // Set icon URL based on audit_status
//     switch (mapData.audit_status) {
//       case 'Closed':
//         iconUrl = 'https://img.icons8.com/?size=100&id=7880&format=png&color=FA5252';
//         break;
//       case 'Valid':
//         iconUrl = 'https://img.icons8.com/?size=100&id=7880&format=png&color=5D9C59';
//         break;
//       case 'On Progress':
//         iconUrl = 'https://img.icons8.com/?size=100&id=7880&format=png&color=2CAFFE';
//         break;
//       case 'Not Started':
//         iconUrl = 'https://img.icons8.com/?size=100&id=7880&format=png&color=FFFFFF';
//         break;
//       default:
//         // Set a default icon if audit_status doesn't match any case
//         iconUrl = 'default-icon-url.png';
//         break;
//     }

//     // Create an instance of the Leaflet Icon class
//     const icon = L.icon({
//       iconUrl: iconUrl,
//       iconSize: [10, 10],
//       className: 'custom-icon'
//     });

//     return icon;
//   };

//   async function fetchData(
//     token,
//     selectedSurveyId,
//     selectedProvinceId = "all",
//     selectedRegencyId = "all",
//     selectedDistrictId = "all",
//     selectedSubDistrictId = "all",
//     selectedTipeSurveyId = "",
//     selectedUsernameSurveyorId = "",
//     selectedIsNegative = "",
//     selectedRegion = "",
//     selectedWilayah = "",
//   ) {
//     setIsLoading(true);
//     if (selectedSurveyId) {
//       // Create an empty URLSearchParams object
//       const queryParams = new URLSearchParams();

//       // Add selectedIsNegative to the query if it's provided
//       if (typeof selectedIsNegative === 'boolean') {
//         queryParams.append("is_negative", selectedIsNegative.toString());
//       }

//       // Add selectedSurveyType to the query if it's provided
//       if (selectedTipeSurveyId && selectedTipeSurveyId !== "") {
//         queryParams.append("tipe_survey", selectedTipeSurveyId);
//       }

//       // Add selectedSurveyType to the query if it's provided
//       if (selectedUsernameSurveyorId && selectedUsernameSurveyorId !== "") {
//         queryParams.append("user_name_surveyor", selectedUsernameSurveyorId);
//       }

//       // Add selectedProvinceId to the query if it's provided
//       if (selectedProvinceId && selectedProvinceId !== "all") {
//         queryParams.append("province_code", selectedProvinceId);
//       }

//       // Add selectedRegencyId to the query if it's provided
//       if (selectedRegencyId && selectedRegencyId !== "all") {
//         queryParams.append("regency_code", selectedRegencyId);
//       }

//       // Add selectedDistrictId to the query if it's provided
//       if (selectedDistrictId && selectedDistrictId !== "all") {
//         queryParams.append("district_code", selectedDistrictId);
//       }

//       // Add selectedSubDistrictId to the query if it's provided
//       if (selectedSubDistrictId && selectedSubDistrictId !== "all") {
//         queryParams.append("sub_district_code", selectedSubDistrictId);
//       }

//       // Add selectedRegion to the query if it's provided
//       if (selectedRegion && selectedRegion !== "all") {
//         queryParams.append("region_name", selectedRegion);
//       }

//       // Add selectedWilayah to the query if it's provided
//       if (selectedWilayah && selectedWilayah !== "all") {
//         queryParams.append("wilayah_name", selectedWilayah);
//       }

//       // Construct the full URL with query parameters
//       const queryString = queryParams.toString();
//       const apiUrl =
//         `${process.env.REACT_APP_API_URL}/api/v1/survey/store/${selectedSurveyId}` +
//         (queryString ? `?${queryString}` : "");

//       try {
//         setSurveyDataId([]);
//         setInitialText("Loading...");
//         const response = await fetch(apiUrl, {
//           method: "POST",
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         if (response.status === 200) {
//           const { data, pagination_info } = await response.json();

//           const mapData = data.map((item) => ({
//             id: item.store_id,
//             name: item.store_name,
//             address: item.store_address,
//             tipe_toko: item.store_type,
//             tipe_survey: item.tipe_survey,
//             latitude: item.store_latitude,
//             longitude: item.store_longitude,
//             audit_status: item.audit_status,
//             icon: MapIcon(item),
//           }));

//           // const validLocationsCount = mapData.filter(
//           //   (item) => item.latitude && item.longitude
//           // ).length;
  
//           setInitialText("");

//           setSurveyDataId(mapData);
//           setDataCount(pagination_info.total);
//           // setValidLocationsCount(validLocationsCount);

//         } else if (response.status === 404) {
//           setSurveyDataId([]);
//           setInitialText("No data found");
//           setDataCount(0); // Reset the data count
//         }
//       } catch (error) {
//         // Handle error
//       }
//     }
//     setIsLoading(false);
//   }

//   useEffect(() => {
//     // Check if a survey ID is selected before making the API call
//     if (selectedSurveyId) {
//       fetchData(
//         accessToken,
//         selectedSurveyId,
//         provinceCode,
//         regencyCode,
//         districtCode,
//         subDistrictCode,
//         tipeSurveyCode,
//         usernameSurveyorCode,
//         selectedPeriodeId,
//         regionName,
//         wilayahName,
//       );
//       fetchRegencies(provinceCode);
//       fetchDistricts(provinceCode, regencyCode);
//       fetchSubDistricts(provinceCode, regencyCode, districtCode);
//     }
//   }, [
//     accessToken,
//     selectedSurveyId,
//     provinceCode,
//     regencyCode,
//     districtCode,
//     subDistrictCode,
//     tipeSurveyCode,
//     usernameSurveyorCode,
//     selectedPeriodeId,
//     regionName,
//     wilayahName,
//   ]);
  
//   async function fetchDataStatData(
//     token, 
//     selectedSurveyId,
//     selectedProvinceId = "all",
//     selectedRegencyId = "all",
//     selectedDistrictId = "all",
//     selectedSubDistrictId = "all",
//     selectedTipeSurveyId = "",
//     selectedUsernameSurveyorId = "",
//     selectedIsNegative = "",
//     selectedRegion = "",
//     selectedWilayah = "",
//   ) {
//     setIsLoading(true);
//     if (selectedSurveyId) {
//       const queryParams = new URLSearchParams();

//       // Add selectedSurveyType to the query if it's provided
//       if (selectedTipeSurveyId && selectedTipeSurveyId !== "") {
//         queryParams.append("tipe_survey", selectedTipeSurveyId);
//       }

//       // Add selectedSurveyType to the query if it's provided
//       if (selectedUsernameSurveyorId && selectedUsernameSurveyorId !== "") {
//         queryParams.append("user_name_surveyor", selectedUsernameSurveyorId);
//       }

//       // Add selectedProvinceId to the query if it's provided
//       if (selectedProvinceId && selectedProvinceId !== "all") {
//         queryParams.append("province_id", selectedProvinceId);
//       }

//       // Add selectedRegencyId to the query if it's provided
//       if (selectedRegencyId && selectedRegencyId !== "all") {
//         queryParams.append("regency_id", selectedRegencyId);
//       }

//       // Add selectedDistrictId to the query if it's provided
//       if (selectedDistrictId && selectedDistrictId !== "all") {
//         queryParams.append("district_id", selectedDistrictId);
//       }

//       // Add selectedSubDistrictId to the query if it's provided
//       if (selectedSubDistrictId && selectedSubDistrictId !== "all") {
//         queryParams.append("sub_district_id", selectedSubDistrictId);
//       }

//       // Add selectedRegion to the query if it's provided
//       if (selectedRegion && selectedRegion !== "all") {
//         queryParams.append("region_name", selectedRegion);
//       }

//       // Add selectedWilayah to the query if it's provided
//       if (selectedWilayah && selectedWilayah !== "all") {
//         queryParams.append("wilayah_name", selectedWilayah);
//       }

//       // Construct the full URL with query parameters
//       const queryString = queryParams.toString();
//       const apiUrl =
//         `${process.env.REACT_APP_API_URL}/api/v1/dashboard/stat?survey_id=${selectedSurveyId}` +
//         (queryString ? `&${queryString}` : "");
    
//     try {
//       const response = await fetch(apiUrl, {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );
  
//       if (response.ok) {
//         const { data } = await response.json();
//         const counts = {
//           valid: 0,
//           notStarted: 0,
//           onProgress: 0,
//           closed: 0,
//         };
  
//         data.forEach((item) => {
//           if (item.audit_status === "Valid") {
//             counts.valid = item.count_audit_status;
//           } else if (item.audit_status === "Not Started") {
//             counts.notStarted = item.count_audit_status;
//           } else if (item.audit_status === "On Progress") {
//             counts.onProgress = item.count_audit_status;
//           } else if (item.audit_status === "Closed") {
//             counts.closed = item.count_audit_status;
//           }
//         });
  
//         setAuditStatusCounts(counts);
//       } else {
//         setAuditStatusCounts({
//           valid: 0,
//           notStarted: 0,
//           onProgress: 0,
//           closed: 0,
//         });
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   }
//   }
  
//   useEffect(() => {
//     // Check if a survey ID is selected before making the API call
//     if (selectedSurveyId) {
//       fetchDataStatData(
//         accessToken,
//         selectedSurveyId,
//         provinceCode,
//         regencyCode,
//         districtCode,
//         subDistrictCode,
//         tipeSurveyCode,
//         usernameSurveyorCode,
//         selectedPeriodeId,
//         regionName,
//         wilayahName,
//       );
//       fetchRegencies(provinceCode);
//       fetchDistricts(provinceCode, regencyCode);
//       fetchSubDistricts(provinceCode, regencyCode, districtCode);
//     }
//   }, [
//     accessToken,
//     selectedSurveyId,
//     provinceCode,
//     regencyCode,
//     districtCode,
//     subDistrictCode,
//     tipeSurveyCode,
//     usernameSurveyorCode,
//     selectedPeriodeId,
//     regionName,
//     wilayahName,
//   ]);

//   const detailMasterData = (mapData) => {
//     return <article>
//       <h4>Kode Outlet : {mapData.id}</h4>
//       <h4>Nama Outlet : {mapData.name}</h4>
//       <h4>Tipe Outlet : {mapData.tipe_toko}</h4>
//       <h4>Tipe Survey : {mapData.tipe_survey}</h4>
//       <h4>Alamat : {mapData.address}</h4>
//     </article>
//   };

//   const StatusGrid = () => {
//     return (
//       <div style={{ display: 'flex', paddingLeft: '3rem', textAlign: 'left', marginTop: '25px' }}>
//         <div>
//           <h3 >Closed: {auditStatusCounts.closed}</h3>
//           <h3 >Valid: {auditStatusCounts.valid}</h3>
//         </div>
//         <div style={{ marginLeft: '20px' }}>
//           <h3 >On Progress: {auditStatusCounts.onProgress}</h3>
//           <h3 >Not Started: {auditStatusCounts.notStarted}</h3>
//         </div>
//       </div>
//     );
//   };

//   if (notAllowAccessPage) {
//     return (
//       <div>
//         <Navbar
//           active="Dashboard"
//           initial={dataProfile.initial}
//           role_code={dataProfile.role_code}
//         />
//         <div>Not allow to access this page</div>
//       </div>
//     );
//   }
//   return (
//     <div>
//       <Navbar
//         active="Map"
//         initial={dataProfile.initial}
//         role_code={dataProfile.role_code}
//       />
//       <div className="content" style={{ padding: "1rem" }}>
//         <div className="legend">
//         <ButtonGroup style={{ width: "39.5%", color: "white", marginBottom: "20px" }}>
//           <BootstrapButton
//             style={{ color: "white", borderColor: "white", height: "40px" }}
//           >
//             Peta Project
//           </BootstrapButton>
//           <BootstrapButton
//             href="/map-store-master"
//             style={{ color: "white", borderColor: "white", height: "40px" }}
//           >
//             Peta Semua Toko
//           </BootstrapButton>
//         </ButtonGroup>

//           {/* Search bar */}
//           <div>
//             <div
//               className="searchbar"
//             >
//               <TextField
//                 id="periode-autocomplete"
//                 select
//                 label="Periode"
//                 value={selectedPeriodeId}
//                 onChange={(event) => handlePeriodeIdChange(event, { id: event.target.value })}
//                 sx={{
//                   mr: 1,
//                   mt: 1,
//                   height: 40,
//                 }}
//                 size="small"
//               >
//                 {dataPeriode.map((option) => (
//                   <MenuItem key={option.id} value={option.id}>
//                     {option.label}
//                   </MenuItem>
//                 ))}
//               </TextField>
//               <TextField
//                 id="outlined-select-currency"
//                 label="Project - Survey"
//                 select
//                 sx={{
//                   mr: 1,
//                   mt: 1,
//                   height: 40,
//                 }}
//                 size="small"
//                 value={selectedSurveyId}
//                 onChange={handleProjectChange}
//               >
//                 {dataProject.map((option) => (
//                   <MenuItem key={option.value} value={option.value}>
//                     {option.label}
//                   </MenuItem>
//                 ))}
//               </TextField>
//               <TextField
//                 label="Tipe Survey"
//                 select
//                 sx={{
//                   mr: 1,
//                   mt: 1,
//                   height: 40,
//                 }}
//                 size="small"
//                 value={tipeSurveyCode}
//                 onChange={handleTipeSurveyCodeChange}
//               >
//                 {dataSurveyType.map((option) => (
//                   <MenuItem key={option.id} value={option.id}>
//                     {option.label}
//                   </MenuItem>
//                 ))}
//               </TextField>
//               <TextField
//                 label="Region"
//                 select
//                 sx={{
//                   mr: 1,
//                   mt: 1,
//                   height: 40,
//                 }}
//                 size="small"
//                 value={regionName}
//                 onChange={handleRegionName}
//               >
//                 {dataRegion.map((option) => (
//                   <MenuItem key={option.id} value={option.id}>
//                     {option.label}
//                   </MenuItem>
//                 ))}
//               </TextField>
//               <TextField
//                 label="Wilayah"
//                 select
//                 sx={{
//                   mt: 1,
//                   height: 40,
//                 }}
//                 size="small"
//                 value={wilayahName}
//                 onChange={handleWilayahName}
//               >
//                 {dataWilayah.map((option) => (
//                   <MenuItem key={option.id} value={option.id}>
//                     {option.label}
//                   </MenuItem>
//                 ))}
//               </TextField>
//               <TextField
//                 label="Provinsi"
//                 select
//                 sx={{
//                   mr: 1,
//                   height: 40,
//                   mt: 1,
//                 }}
//                 size="small"
//                 value={provinceCode}
//                 onChange={handleProvinceCodeChange}
//               >
//                 {provinces.map((item) => (
//                   <MenuItem key={item.code} value={item.code}>
//                     {item.name}
//                   </MenuItem>
//                 ))}
//               </TextField>
//               <TextField
//                 id="outlined-select-currency"
//                 label="Kota/Kab"
//                 select
//                 sx={{
//                   mr: 1,
//                   height: 40,
//                   mt: 1,
//                 }}
//                 size="small"
//                 value={regencyCode}
//                 onChange={handleRegencyCodeChange}
//               >
//                 {regencies.map((item) => (
//                   <MenuItem key={item.code} value={item.code}>
//                     {item.name}
//                   </MenuItem>
//                 ))}
//               </TextField>

//               <TextField
//                 id="outlined-select-currency"
//                 label="Kec"
//                 select
//                 sx={{
//                   mr: 1,
//                   mt: 1,
//                   height: 40,
//                 }}
//                 size="small"
//                 value={districtCode}
//                 onChange={handleDistrictCodeChange}
//               >
//                 {districts.map((item) => (
//                   <MenuItem key={item.code} value={item.code}>
//                     {item.name}
//                   </MenuItem>
//                 ))}
//               </TextField>

//               <TextField
//                 id="outlined-select-currency"
//                 label="Kel"
//                 select
//                 sx={{
//                   mr: 1,
//                   mt: 1,
//                   height: 40,
//                 }}
//                 size="small"
//                 value={subDistrictCode}
//                 onChange={handleSubDistrictCodeChange}
//               >
//                 {subDistricts.map((item) => (
//                   <MenuItem key={item.code} value={item.code}>
//                     {item.name}
//                   </MenuItem>
//                 ))}
//               </TextField>

//               <TextField
//                 label="Full Name Surveyor"
//                 select
//                 sx={{
//                   mt: 1,
//                   height: 40,
//                 }}
//                 size="small"
//                 value={usernameSurveyorCode}
//                 onChange={handleUsernameSurveyorCodeChange}
//               >
//                 {dataUserSurveyor.map((option) => (
//                   <MenuItem key={option.id} value={option.id}>
//                     {option.label}
//                   </MenuItem>
//                 ))}
//               </TextField>
//             </div>
//           </div>

//           <Card sx={{ m: 0.3, mt: 3, backgroundColor: "#F6F5F2" }} >
//           <div>
//       <StatusGrid />
//     </div>

//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'flex-end',
//                 paddingRight: '4rem'
//               }}
//               className="Status"
//             >
//               <LegendItem color="#DF2E38" label="closed" />
//               <LegendItem color="#5D9C59" label="valid" />
//               <LegendItem color="#2CAFFE" label="on progress" />
//               <LegendItem color="#FFFFFF" label="not started" />
//             </div>

//             <div className="Peta" style={{ margin: "50px", marginTop: "10px" }}>
//               {
//                 isLoading ? <div>Loading...</div> : <MapLayout
//                   markersData={{
//                     data: surveyDataId,
//                     icon: MapIcon,
//                   }}
//                   position={mapPosition}
//                   zoom={mapZoom}
//                   scrollWheelZoom={true}
//                   styleContainer={{ width: '90.3vw', height: '500px' }}
//                   renderDataDetail={detailMasterData}
//                   geoJSONData={filteredGeoJSONData}  
//                   pathOptions={{ color: 'black' }}
//                 />
//               }
//             </div>
//           </Card>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Map
