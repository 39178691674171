import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from "../../Components/Navbar";
import { BootstrapButton } from '../../Components/Button/BootstrapButton';
import { fetchProjects } from '../../services/projectService';
import { Slide, toast } from 'react-toastify';
import { fetchProfile } from '../../services/profileService';
import { TableStoreMaster } from '../../Components/Fragments/Tables/TableStoreMaster';
import { exportStoreMaster, fetchStoreMaster, fetchStoreMasterById, fetchStoreMasterColumn, fetchStorePhoto } from '../../services/storeMasterService';
import { ModalConfirmation } from '../../Components/Fragments/Modals/ModalGeneral';
import { editStoreMaster, setCurrentPage, setFilterColumnName, setFilterColumnValue, setSelectedDistrictId, setSelectedProvinceId, setSelectedRegencyId, setSelectedStatusId, setSelectedSubDistrictId, setSelectedTipeSurvey } from '../../redux/slices/storeMasterSlice';
import { ModalAddStoreMaster, ModalEditStoreMaster, ModalImportStoreMaster, ModalViewStoreMaster } from '../../Components/Fragments/Modals/ModalStoreMaster';
import { fetchJenisOutlet, fetchStoreType, fetchTipeJalan, fetchTipeLokasi, fetchTipeSurvey } from '../../services/storeAdditionaService';
import { fetchDistrict, fetchProvince, fetchRegency, fetchSubDistrict } from '../../services/areaNewService';
import { FormControl, InputLabel, Select, MenuItem, Grid, TextField, Typography } from '@mui/material';

export default function StoreMasterTwo() {
  // initial
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    store_id: "",
    store_name: "",
    store_type_code: "",
    store_address: "",
    store_latitude: "",
    store_longitude: "",
    province_code: "",
    regency_code: "",
    district_code: "",
    sub_district_code: "",
    jenis_outlet: "",
    tipe_survey: "",
    tipe_jalan: "",
    tipe_lokasi: "",
  });
  const [excelFile, setExcelFile] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [storePhotoDetail, setStorePhotoDetail] = useState([]);
  const [storeDetail, setStoreDetail] = useState({});
  // handle change
  const handleChangeStoreMaster = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeProvince = async (e) => {
    // set province selected but regency not selected
    setFormData({
      ...formData,
      province_code: e.target.value,
      regency_code: '',
    });
    // get data regency based on province selected
    setDataRegency(
      await fetchRegency(accessToken, {
        province_code: e.target.value,
      })
    )
  };
  // for filter change
  const handleChangeProvinceFilter = async (e) => {
    const provinceId = e.target.value
    if (provinceId) {
      // get data regency based on province selected
      setDataRegencyFilter(
        await fetchRegency(accessToken, {
          province_code: provinceId,
        })
      )
    } else {
      setDataRegencyFilter([]);
    }
    dispatch(setSelectedProvinceId(provinceId))
    // reset
    dispatch(setSelectedRegencyId(''))
    dispatch(setSelectedDistrictId(''))
    dispatch(setSelectedSubDistrictId(''))
    setDataDistrictFilter([]);
    setDataSubDistrictFilter([]);
  };

  const handleChangeRegency = async (e) => {
    // set regency selected but district not selected
    setFormData({
      ...formData,
      regency_code: e.target.value,
      district_code: '',
    });
    // get data district based on regency selected
    setDataDistrict(
      await fetchDistrict(accessToken, {
        regency_code: e.target.value,
      })
    )
  };

  const handleChangeRegencyFilter = async (e) => {
    const regencyId = e.target.value
    if (regencyId) {
      // get data district based on regency selected
      setDataDistrictFilter(
        await fetchDistrict(accessToken, {
          regency_code: regencyId,
        })
      )
    } else {
      setDataDistrictFilter([]);
    }
    dispatch(setSelectedRegencyId(regencyId))
    // reset
    dispatch(setSelectedDistrictId(''))
    dispatch(setSelectedSubDistrictId(''))
    setDataSubDistrictFilter([]);
  };

  const handleChangeDistrict = async (e) => {
    // set district selected but sub district not selected
    setFormData({
      ...formData,
      district_code: e.target.value,
      sub_district_code: '',
    });
    // get data sub district based on district selected
    setDataSubDistrict(
      await fetchSubDistrict(accessToken, {
        district_code: e.target.value,
      })
    )
  };

  const handleChangeDistrictFilter = async (e) => {
    const districtId = e.target.value
    if (districtId) {
      // get data sub district based on district selected
      setDataSubDistrictFilter(
        await fetchSubDistrict(accessToken, {
          district_code: districtId,
        })
      );
    } else {
      setDataSubDistrictFilter([]);
    }
    dispatch(setSelectedDistrictId(districtId))
    // reset
    dispatch(setSelectedSubDistrictId(''))
  };

  const dataProfile = {
    initial: useSelector((state) => state.profile.initial),
    role_code: useSelector((state) => state.profile.role_code),
  }

  const accessToken = useSelector((state) => state.general.accessToken);
  // data initial
  const dataStoreType = useSelector((state) => state.storeType.data);
  const dataProvince = useSelector((state) => state.province.data);
  const dataStatus = useSelector((state) => state.storeMaster.storeMasterStatus);
  const dataJenisOutlet = useSelector((state) => state.jenisOutlet.data);
  const dataTipeSurvey = useSelector((state) => state.tipeSurvey.data);
  const dataTipeJalan = useSelector((state) => state.tipeJalan.data);
  const dataTipeLokasi = useSelector((state) => state.tipeLokasi.data);
  const storeMasterfilterColumnName = useSelector((state) => state.storeMaster.storeMasterfilterColumnName);
  const storeMasterfilterColumnOperator = useSelector((state) => state.storeMaster.storeMasterfilterColumnOperator);
  // data dynamic
  const [dataRegency, setDataRegency] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubDistrict, setDataSubDistrict] = useState([]);
  // data dynamic for filter
  const [dataRegencyFilter, setDataRegencyFilter] = useState([]);
  const [dataDistrictFilter, setDataDistrictFilter] = useState([]);
  const [dataSubDistrictFilter, setDataSubDistrictFilter] = useState([]);
  // initial page
  const currentPage = useSelector((state) => state.storeMaster.currentPage);
  const itemsPerPage = useSelector((state) => state.storeMaster.itemsPerPage);
  const selectedStatusId = useSelector((state) => state.storeMaster.selectedStatusId);
  // selected filter
  const selectedTipeSurvey = useSelector((state) => state.storeMaster.selectedTipeSurvey);
  const selectedProvinceId = useSelector((state) => state.storeMaster.selectedProvinceId);
  const selectedRegencyId = useSelector((state) => state.storeMaster.selectedRegencyId);
  const selectedDistrictId = useSelector((state) => state.storeMaster.selectedDistrictId);
  const selectedSubDistrictId = useSelector((state) => state.storeMaster.selectedSubDistrictId);
  const filterColumnName = useSelector((state) => state.storeMaster.filterColumnName);
  const filterColumnOperator = useSelector((state) => state.storeMaster.filterColumnOperator);
  const filterColumnValue = useSelector((state) => state.storeMaster.filterColumnValue);
  const itemsPerPageList = [5, 10, 50, 100];

  // edit status 
  const [editStatusId, setEditStatusId] = useState({
    id: null,
    status: null,
  });

  // dialog initial
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogEditStatus, setOpenDialogEditStatus] = useState(false);
  const [openDialogView, setOpenDialogView] = useState(false);
  const [openDialogImport, setOpenDialogImport] = useState(false);

  // handle search
  const handleSearch = (e) => {
    e.preventDefault();

    dispatch(setCurrentPage(1))
    const customParams = {
      size: itemsPerPage,
      page: 1,
      store_status: selectedStatusId,
      tipe_survey: selectedTipeSurvey,
      province_id: selectedProvinceId,
      regency_id: selectedRegencyId,
      district_id: selectedDistrictId,
      sub_district_id: selectedSubDistrictId,
      column_name: filterColumnName,
      column_operator: filterColumnOperator,
      column_value: filterColumnValue,
    };
    fetchStoreMaster(dispatch, accessToken, customParams)
  }

  const handleAdd = () => {
    setOpenDialogAdd(true);
  };

  const handleCloseDialog = () => {
    // close the dialog
    setOpenDialogAdd(false);
    setOpenDialogEdit(false);
    setOpenDialogEditStatus(false);
    setOpenDialogView(false);
    setOpenDialogImport(false);
    // clear data
    setFormData({
      id: "",
      store_id: "",
      store_name: "",
      store_type_code: "",
      store_address: "",
      store_latitude: "",
      store_longitude: "",
      province_code: "",
      regency_code: "",
      district_code: "",
      sub_district_code: "",
      jenis_outlet: "",
      tipe_survey: "",
      tipe_jalan: "",
      tipe_lokasi: "",
    });
    // reset
    setExcelFile('');
    setFileUrl('');
    setDataRegency([]);
    setDataDistrict([]);
    setDataSubDistrict([]);
    setStorePhotoDetail([]);
    setStoreDetail({});
  };

  const customParams = {
    size: itemsPerPage,
    page: currentPage,
    store_status: selectedStatusId,
    tipe_survey: selectedTipeSurvey,
    province_id: selectedProvinceId,
    regency_id: selectedRegencyId,
    district_id: selectedDistrictId,
    sub_district_id: selectedSubDistrictId,
    column_name: filterColumnName,
    column_operator: filterColumnOperator,
    column_value: filterColumnValue,
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      // Fetch the data after a delay (debouncing)
      // fetchProjects(dispatch, accessToken);
      fetchStoreType(dispatch, accessToken);
      fetchProfile(dispatch, accessToken);
      fetchProvince(dispatch, accessToken);
      fetchJenisOutlet(dispatch, accessToken);
      fetchTipeSurvey(dispatch, accessToken);
      fetchTipeJalan(dispatch, accessToken);
      fetchTipeLokasi(dispatch, accessToken);
      fetchStoreMasterColumn(dispatch, accessToken);
    }, 200); // 200ms delay to debounce the effect

    // Cleanup function to clear the timeout if any dependency changes before the delay ends
    return () => {
      clearTimeout(handler);
    };
  }, [dispatch]);

  // create store master
  async function createStoreMaster() {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/store-master`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 201) {
        toast.success('Store berhasil dibuat!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        fetchStoreMaster(dispatch, accessToken, customParams)

        // clear data
        setFormData({
          id: "",
          store_id: "",
          store_name: "",
          store_type_code: "",
          store_address: "",
          store_latitude: "",
          store_longitude: "",
          province_code: "",
          regency_code: "",
          district_code: "",
          sub_district_code: "",
          jenis_outlet: "",
          tipe_survey: "",
          tipe_jalan: "",
          tipe_lokasi: "",
        });
        // reset
        setDataRegency([]);
        setDataDistrict([]);
        setDataSubDistrict([]);
        // close
        setOpenDialogAdd(false);
      } else if (response.status === 400) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error('Gagal membuat store!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error('Failed to create store:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating store:', error);
    }
    setIsLoading(false);
  }

  // export store master template
  async function exportStoreMasterTemplate() {
    setIsLoading(true);
    const blob = await exportStoreMaster(accessToken, 'template', 'api/v1/store-master/export/template', {});
    if (blob) {
      // throw data as blob then download it
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Template Store Master.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      toast.error('Gagal mendownload template store!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setIsLoading(false);
  }

  // export store master
  async function handleExport() {
    setIsLoading(true);
    const blob = await exportStoreMaster(accessToken, 'result', 'api/v1/store-master/export', customParams);
    if (blob) {
      // throw data as blob then download it
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Result Store Master.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      toast.error('Gagal mendownload store!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setIsLoading(false);
  }

  // export store master worksheet
  async function exportStoreMasterWorksheet() {
    setIsLoading(true);
    const blob = await exportStoreMaster(accessToken, 'worksheet', 'api/v1/store-master/export/template/base', {});
    if (blob) {
      // throw data as blob then download it
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Worksheet Store Master.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      toast.error('Gagal mendownload worksheet store!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setIsLoading(false);
  }

  // import store master
  async function importStoreMasterTemplate() {
    try {
      const formData = new FormData();
      formData.append("file", excelFile);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/store-master/import/template`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (response.status === 201) {
        toast.success('Import Store berhasil dibuat!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        // refresh
        fetchStoreMaster(dispatch, accessToken, customParams)
        // close
        setOpenDialogImport(false);
      } else if (response.status === 200) {
        // throw data as blob then download it when validation on excel got fail
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Store Master Error.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        toast.error('Ada masalah di excel. Periksa kembali isi excel!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 400) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error('Gagal mengimport store!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error('Failed to import store template:', response.statusText);
      }
    } catch (error) {
      console.error('Error importing store template:', error);
    }
  }

  // update store msater
  async function updateStoreMaster() {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/store-master/${formData.id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success('Store berhasil diubah!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        fetchStoreMaster(dispatch, accessToken, customParams)

        // clear data
        setFormData({
          id: "",
          store_id: "",
          store_name: "",
          store_type_code: "",
          store_address: "",
          store_latitude: "",
          store_longitude: "",
          province_code: "",
          regency_code: "",
          district_code: "",
          sub_district_code: "",
          jenis_outlet: "",
          tipe_survey: "",
          tipe_jalan: "",
          tipe_lokasi: "",
        });
        // reset
        setDataRegency([]);
        setDataDistrict([]);
        setDataSubDistrict([]);
        // close dialog
        setOpenDialogEdit(false);
      } else {
        toast.error('Gagal mengubah store!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error('Failed to update store:', response.statusText);
      }
    } catch (error) {
      console.error('Error editing store:', error);
    }
    setIsLoading(false);
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    // call update store master
    updateStoreMaster()
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // call create store master
    createStoreMaster()
    setIsLoading(false);
  };

  const handleEdit = async (id) => {
    const detail = await fetchStoreMasterById(accessToken, id);
    // call data dynamic 
    setDataRegency(await fetchRegency(accessToken, {
      province_code: detail.province_code,
    }))
    setDataDistrict(await fetchDistrict(accessToken, {
      regency_code: detail.regency_code,
    }))
    setDataSubDistrict(await fetchSubDistrict(accessToken, {
      district_code: detail.district_code,
    }))
    // set data 
    setFormData({
      id: detail.id,
      store_id: detail.store_id,
      store_name: detail.store_name,
      store_type_code: detail.store_type_code,
      store_address: detail.store_address ? detail.store_address : "",
      store_latitude: detail.store_latitude ? detail.store_latitude : 0,
      store_longitude: detail.store_longitude ? detail.store_longitude : 0,
      province_code: detail.province_code,
      regency_code: detail.regency_code,
      district_code: detail.district_code,
      sub_district_code: detail.sub_district_code,
      jenis_outlet: detail.jenis_outlet,
      tipe_survey: detail.tipe_survey,
      tipe_jalan: detail.tipe_jalan,
      tipe_lokasi: detail.tipe_lokasi,
    });
    // show dialog
    setOpenDialogEdit(true);
  };

  const handleView = async (id, store_id) => {
    setIsLoading(true);
    // set photo detail
    setStorePhotoDetail(
      await fetchStorePhoto(accessToken, store_id)
    )

    // consume store master specific
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/store-master/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setStoreDetail(data.data);
      } else {
        toast.error("Ada masalah saat view store");
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
    // show dialog
    setOpenDialogView(true);
    setIsLoading(false)
  };

  const handleConfirmationUpdateStatus = async (id, status) => {
    // show dialog
    setOpenDialogEditStatus(true);
    setEditStatusId({
      id: id,
      status: status,
    });
  };

  const handleUpdateStatus = async (id, status) => {
    setIsLoading(true);
    // this will update status refer when active -> inactive or inactive -> active
    const statusChange = status === "0" ? "1" : "0";
    try {
      const updates = {
        store_status: statusChange,
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/store-master/${id}/status?store_status=${statusChange}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        toast.success('Store status berhasil diubah!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        dispatch(editStoreMaster({ id, updates }));
      } else {
        toast.error('Gagal mengubah status store!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error('Failed to update store status:', response.statusText);
      }
      setEditStatusId({
        id: null,
        status: null,
      });
      setOpenDialogEditStatus(false);
    } catch (error) {
      console.error('Error update status:', error);
    }
    setIsLoading(false);
  };

  const handleImport = () => {
    setOpenDialogImport(true);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    importStoreMasterTemplate()
    setIsLoading(false);
  };

  return (
    <div>
      <Navbar active="Store Master" initial={dataProfile.initial} role_code={dataProfile.role_code} />
      {/* filter */}
      <div style={{ marginTop: '20px', paddingRight: '20px', paddingLeft: '20px' }}>
        <Grid container spacing={0.5}>
          <Grid item xs={12} sm={1}>
            <FormControl fullWidth>
              <InputLabel id="select-label-status_filter">Status</InputLabel>
              <Select
                sx={{
                  mt: 1,
                  height: 40,
                }}
                required
                labelId="select-label-status_filter"
                name="status_filter"
                id="select_status_filter"
                value={selectedStatusId}
                onChange={
                  (e) => dispatch(setSelectedStatusId(e.target.value))
                }
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {dataStatus?.length > 0 &&
                  dataStatus?.map((data, i) => {
                    return <MenuItem value={data.id} key={data.id}>
                      {data.label}
                    </MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1.3}>
            <FormControl fullWidth>
              <InputLabel id="select-label-tipe_survey_filter">Tipe Survey</InputLabel>
              <Select
                sx={{
                  mt: 1,
                  height: 40,
                }}
                required
                labelId="select-label-tipe_survey_filter"
                name="tipe_survey_filter"
                id="select_tipe_survey_filter"
                value={selectedTipeSurvey}
                onChange={
                  (e) => dispatch(setSelectedTipeSurvey(e.target.value))
                }
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {dataTipeSurvey?.length > 0 &&
                  dataTipeSurvey?.map((data, i) => {
                    return <MenuItem value={data.desc} key={data.desc}>
                      {data.desc}
                    </MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControl fullWidth>
              <InputLabel id="select-label-province_filter">Provinsi</InputLabel>
              <Select
                sx={{
                  mt: 1,
                  height: 40,
                }}
                required
                labelId="select-label-province_filter"
                name="province_filter"
                id="select_province_filter"
                value={selectedProvinceId}
                onChange={handleChangeProvinceFilter}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {dataProvince?.length > 0 &&
                  dataProvince?.map((data, i) => {
                    return <MenuItem value={data.id} key={data.id}>
                      {data.name}
                    </MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControl fullWidth>
              <InputLabel id="select-label-regency_id_filter">Kota/Kabupaten</InputLabel>
              <Select
                sx={{
                  mt: 1,
                  height: 40,
                }}
                required
                labelId="select-label-regency_id_filter"
                name="regency_id_filter"
                id="select_regency_id_filter"
                value={selectedRegencyId}
                onChange={handleChangeRegencyFilter}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {dataRegencyFilter?.length > 0 &&
                  dataRegencyFilter?.map((data, i) => {
                    return <MenuItem value={data.id} key={data.id}>
                      {data.name}
                    </MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControl fullWidth>
              <InputLabel id="select-label-district_id_filter">Kecamatan</InputLabel>
              <Select
                sx={{
                  mt: 1,
                  height: 40,
                }}
                required
                labelId="select-label-district_id_filter"
                name="district_id_filter"
                id="select_district_id_filter"
                value={selectedDistrictId}
                onChange={handleChangeDistrictFilter}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {dataDistrictFilter?.length > 0 &&
                  dataDistrictFilter?.map((data, i) => {
                    return <MenuItem value={data.id} key={data.id}>
                      {data.name}
                    </MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel id="select-label-sub_district_id_filter">Kelurahan</InputLabel>
              <Select
                sx={{
                  mt: 1,
                  height: 40,
                }}
                required
                labelId="select-label-sub_district_id_filter"
                name="sub_district_id_filter"
                id="select_sub_district_id_filter"
                value={selectedSubDistrictId}
                onChange={
                  (e) => dispatch(setSelectedSubDistrictId(e.target.value))
                }
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {dataSubDistrictFilter?.length > 0 &&
                  dataSubDistrictFilter?.map((data, i) => {
                    return <MenuItem value={data.id} key={data.id}>
                      {data.name}
                    </MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </Grid> */}
        


        <form onSubmit={handleSearch} style={{ display: 'flex', marginTop: '4px', marginLeft: '5px' }}>
          <Grid container spacing={0.5} >
            {/* <Grid container alignItems={'center'}>
          <Grid item>
            <Select
              sx={{
                  mt: 1,
                  height: 40,
                }}
              required
              labelId="select-label-column_operator"
              name="column_operator"
              id="select_column_operator"
              value={filterColumnOperator}
              readOnly
            >
              {
                storeMasterfilterColumnOperator.map((data) => {
                  return <MenuItem value={data.id} key={data.id}>
                    {data.name}
                  </MenuItem>
                })
              }
            </Select>
          </Grid> */}
            <Grid item>
              <Select
                sx={{
                  mt: 1,
                  height: 40,
                }}
                required
                labelId="select-label-column_name"
                name="column_name"
                id="select_column_name"
                value={filterColumnName}
                onChange={(e) => {
                  dispatch(setFilterColumnName(e.target.value))
                }}
              >
                {
                  storeMasterfilterColumnName.map((data) => {
                    return <MenuItem value={data.id} key={data.id}>
                      {data.name}
                    </MenuItem>
                  })
                }
              </Select>
            </Grid>
            <Grid item>
              <TextField
                sx={{
                  mt: 1,
                  "& .MuiInputBase-root": {
                    height: 40,
                  },
                  "& .MuiInputLabel-root": {
                    top: "-7px", 
                  },
                }}
                name="column_value"
                label="Cari"
                value={filterColumnValue}
                onChange={(e) => {
                  dispatch(setFilterColumnValue(e.target.value))
                }}
              />
            </Grid>
            <Grid item>
              <BootstrapButton
                variant="contained"
                disableRipple
                sx={{ width: 120, height: 40, mt: 1 }}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : 'Cari'}
              </BootstrapButton>
            </Grid>
          </Grid>
        </form>
        </Grid>
      </div>
      {/* control management */}
      <Grid container spacing={0.5} sx={{ marginTop: '10px', paddingRight: '20px', paddingLeft: '20px' }} justifyContent="flex-end">
        <Grid>
          {/* button go to store setup*/}
          <BootstrapButton
            className="button"
            style={{
              height: "40px",
              marginLeft: "10px"
            }}
            href='/setup-store-master'
            variant="contained"
            disableRipple
            disabled={isLoading}
          >
            <p> {isLoading ? "Loading..." : "Setup"}</p>
          </BootstrapButton>
        </Grid>
        <Grid>
          {/* button add store*/}
          <BootstrapButton
            className="button"
            style={{
              height: "40px",
              marginLeft: "10px"
            }}
            onClick={() => handleAdd()}
            variant="contained"
            disableRipple
            disabled={isLoading}
          >
            <p> {isLoading ? "Loading..." : "Add Store"}</p>
          </BootstrapButton>
        </Grid>
        <Grid>

          {/* button import store*/}
          <BootstrapButton
            className="button"
            style={{
              height: "40px",
              marginLeft: "10px"
            }}
            onClick={() => handleImport()}
            variant="contained"
            disableRipple
            disabled={isLoading}
          >
            <p> {isLoading ? "Loading..." : "Upload Stores"}</p>
          </BootstrapButton>
        </Grid>
        <Grid>
          {/* button export store*/}
          <BootstrapButton
            className="button"
            style={{
              height: "40px",
              marginLeft: "10px"
            }}
            onClick={() => handleExport()}
            variant="contained"
            disableRipple
            disabled={isLoading}
          >
            <p> {isLoading ? "Loading..." : "Download"}</p>
          </BootstrapButton>
        </Grid>
      </Grid>

      {/* modal view */}
      <ModalViewStoreMaster
        open={openDialogView}
        onClose={handleCloseDialog}
        title={'View Store Photo'}
        dataSurveyDetail={storeDetail}
        dataSurveyDetailPhoto={storePhotoDetail}
      />

      {/* modal add */}
      <ModalAddStoreMaster
        open={openDialogAdd}
        onClose={handleCloseDialog}
        title={'Add Store'}
        buttonTitle={'Submit'}
        formData={formData}
        handleSubmit={handleSubmit}
        handleChange={{
          general: handleChangeStoreMaster,
          province: handleChangeProvince,
          regency: handleChangeRegency,
          district: handleChangeDistrict
        }}
        isLoading={isLoading}
        items={{
          dataStoreType: dataStoreType,
          dataProvince: dataProvince,
          dataRegency: dataRegency,
          dataDistrict: dataDistrict,
          dataSubDistrict: dataSubDistrict,
          dataJenisOutlet: dataJenisOutlet,
          dataTipeSurvey: dataTipeSurvey,
          dataTipeJalan: dataTipeJalan,
          dataTipeLokasi: dataTipeLokasi,
        }}
      />

      {/* modal import */}
      <ModalImportStoreMaster
        open={openDialogImport}
        onClose={handleCloseDialog}
        title={'Import Store'}
        buttonTitle={'Upload'}
        fileUrl={fileUrl}
        handleSubmit={handleUpload}
        handleChange={{
          excel: setExcelFile,
          fileUrl: setFileUrl,
        }}
        handleExport={{
          template: exportStoreMasterTemplate,
          worksheet: exportStoreMasterWorksheet
        }}
        isLoading={isLoading}
      />

      {/* modal edit */}
      <ModalEditStoreMaster
        open={openDialogEdit}
        onClose={handleCloseDialog}
        title={'Edit Store'}
        buttonTitle={'Update'}
        formData={formData}
        handleSubmit={handleUpdate}
        handleChange={{
          general: handleChangeStoreMaster,
          province: handleChangeProvince,
          regency: handleChangeRegency,
          district: handleChangeDistrict
        }}
        isLoading={isLoading}
        items={{
          dataStoreType: dataStoreType,
          dataProvince: dataProvince,
          dataRegency: dataRegency,
          dataDistrict: dataDistrict,
          dataSubDistrict: dataSubDistrict,
          dataJenisOutlet: dataJenisOutlet,
          dataTipeSurvey: dataTipeSurvey,
          dataTipeJalan: dataTipeJalan,
          dataTipeLokasi: dataTipeLokasi,
        }}
      />

      {/* modal edit status */}
      <ModalConfirmation
        open={openDialogEditStatus}
        onClose={handleCloseDialog}
        title={'Update Status'}
        description={'Apakah anda yakin ingin update status toko ini?'}
        confirmationText={'Yes'}
        isLoading={isLoading}
        handleClick={() => handleUpdateStatus(editStatusId.id, editStatusId.status)}
      />

      <div style={{ padding: "1.3rem", marginTop: "0px" }}>
        {/* table */}
        <TableStoreMaster
          itemsPerPageList={itemsPerPageList}
          onEditStatus={handleConfirmationUpdateStatus}
          onEdit={handleEdit}
          onView={handleView}
        />
      </div>
    </div>
  )
}
