import { React, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  MenuItem,
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
} from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import { fetchStoreMasterByStoreId } from "./DataUtil";

function AddDataModal({
  accessToken,
  isLoading,
  newAuditPlanData,
  isModalOpen,
  setIsModalOpen,
  modalStyle,
  dataAuditPlanMaster,
  dataUserSurveyorOptions,
  dataUserQcOptions,
  handleInputChange,
  setSelectedAuditPlanMasterId,
  setRequestBodyRakb,
  setNewAuditPlanData,
  handleSubmit,
}) {
  const [dataStoreMasterSpecific, setDataStoreMasterSpecific] = useState({});
  const [isModalOpenDetailStore, setIsModalOpenDetailStore] = useState(false);
  const emptyValue = { id: "-", label: "-" };
  // management look specific store master
  const handleModalStoreMasterSpecific = async () => {
    const data = await fetchStoreMasterByStoreId(
      accessToken,
      newAuditPlanData.store_id
    );
    if (data) {
      setDataStoreMasterSpecific(data);
      setIsModalOpenDetailStore(true);
    } else {
      alert("Kode Outlet tidak ditemukan atau tidak aktif");
    }
  };
  const handleCloseModalStoreMasterSpecific = () => {
    setDataStoreMasterSpecific({});
    setIsModalOpenDetailStore(false);
  };
  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedAuditPlanMasterId("");
          setRequestBodyRakb({});
          setNewAuditPlanData({});
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: 0 }}
      >
        <Box
          sx={modalStyle}
          style={{
            maxHeight: "600px",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <h2>Add Audit Plan</h2>
          <FormControl sx={{ mb: 2, width: "100%" }} variant="filled">
            <InputLabel htmlFor="filled-adornment-store-id">
              Kode Outlet
            </InputLabel>
            <FilledInput
              name="store_id"
              autoComplete="off"
              id="filled-adornment-store-id"
              type={"text"}
              value={newAuditPlanData.store_id}
              onChange={handleInputChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle store id visibility"
                    onClick={handleModalStoreMasterSpecific}
                    edge="end"
                  >
                    <Visibility />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Autocomplete
            options={dataAuditPlanMaster || []}
            getOptionLabel={(option) => option.label || ""}
            onChange={(event, newValue) => {
              handleInputChange({
                target: {
                  name: "audit_plan",
                  value: newValue?.label || "",
                },
              });
              setSelectedAuditPlanMasterId(newValue?.id || "");             }}
            value={
              dataAuditPlanMaster?.find(
                (option) => option.label === newAuditPlanData.audit_plan
              ) || null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Audit Plan"
                variant="filled"
                sx={{ width: "100%", mb: 2 }}
              />
            )}
          />

          <Autocomplete
            options={dataUserSurveyorOptions || []}
            getOptionLabel={(option) => option.label || ""}
            onChange={(event, newValue) =>
              handleInputChange({
                target: {
                  name: "user_name_surveyor",
                  value: newValue?.id || "",
                },
              })
            }
            value={
              dataUserSurveyorOptions?.find(
                (option) => option.id === newAuditPlanData.user_name_surveyor
              ) || null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="User Full Name Surveyor"
                variant="filled"
                sx={{ width: "100%", mb: 2 }}
              />
            )}
          />
          <Autocomplete
            options={[emptyValue, ...(dataUserQcOptions || [])]}
            getOptionLabel={(option) => option.label || ""}
            onChange={(event, newValue) =>
              handleInputChange({
                target: { name: "user_name_qc", value: newValue?.id || "" },
              })
            }
            value={
              dataUserQcOptions?.find(
                (option) => option.id === newAuditPlanData.user_name_qc
              ) || emptyValue
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="User Full Name QC"
                variant="filled"
                sx={{ width: "100%", mb: 2 }}
              />
            )}
          />
          <TextField
            name="audit_start_at"
            type="date"
            label="Audit Start Date"
            sx={{ width: "100%", mb: 2 }}
            InputLabelProps={{ shrink: true }}
            value={newAuditPlanData.audit_start_at}
            onChange={handleInputChange}
            variant="filled"
          />
          <TextField
            name="audit_end_at"
            type="date"
            label="Audit End Date"
            sx={{ width: "100%" }}
            InputLabelProps={{ shrink: true }}
            value={newAuditPlanData.audit_end_at}
            onChange={handleInputChange}
            variant="filled"
          />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              disableRipple
              sx={{
                width: 182,
                mt: 2,
                mr: 2,
                height: 50,
                color: "#b34469",
                borderColor: "#b34469",
              }}
              onClick={() => {
                setIsModalOpen(false);
                setSelectedAuditPlanMasterId("");
                setRequestBodyRakb({});
                setNewAuditPlanData({});
              }}
            >
              Cancel
            </Button>
            <BootstrapButton
              variant="contained"
              disableRipple
              sx={{ width: 182, mt: 2, height: 50 }}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "SUBMIT"}
            </BootstrapButton>
          </div>
        </Box>
      </Modal>
      <Modal
        open={isModalOpenDetailStore}
        onClose={handleCloseModalStoreMasterSpecific}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: 0 }}
      >
        <Box
          sx={modalStyle}
          style={{
            maxHeight: "400px",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <h2>Store Master Detail</h2>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <div>
                <b>Kode Outlet</b> : {dataStoreMasterSpecific.store_id}
              </div>
              <div>
                <b>Nama Outlet</b> : {dataStoreMasterSpecific.store_name}
              </div>
              <div>
                <b>Tipe Outlet</b> : {dataStoreMasterSpecific.store_type}
              </div>
              <div>
                <b>Provinsi</b> : {dataStoreMasterSpecific.province_name}
              </div>
              <div>
                <b>Kab/Kota</b> : {dataStoreMasterSpecific.regency_name}
              </div>
              <div>
                <b>Kel</b> : {dataStoreMasterSpecific.sub_district_name}
              </div>
              <div>
                <b>Kec</b> : {dataStoreMasterSpecific.district_name}
              </div>
              <Button
                variant="outlined"
                disableRipple
                sx={{
                  width: 182,
                  mt: 2,
                  mr: 2,
                  height: 50,
                  color: "#b34469",
                  borderColor: "#b34469",
                }}
                onClick={handleCloseModalStoreMasterSpecific}
              >
                Close
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AddDataModal;
