import React from "react";

import { styled } from "@mui/material/styles";

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#069dae",
    color: theme.palette.common.white,
    border: "solid 1px #fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function TableLogDB({ dataLogDB, isLoading }) {
  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: "100%", height: 400, overflow: "auto" }}
      >
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Tipe Project
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                DB Set
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Period
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Completed
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataLogDB.length > 0 ? (
              dataLogDB.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{
                    whiteSpace: "nowrap",
                    minWidth: row.minWidth,
                    color: "#FFF",
                    fontWeight: "bold",
                  }}
                >
                  <TableCell align="center">
                    {row.project_type.replace(/_/g, " ").toUpperCase()}
                  </TableCell>
                  <TableCell align="center">{row.description}</TableCell>
                  <TableCell align="center">{row.period}</TableCell>
                  <TableCell align="center">{row.process_at}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  {isLoading ? "Loading..." : "No Data Available"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
