import Cookies from "js-cookie";
import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Button, TextField, Modal, MenuItem, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import TableViewIcon from "@mui/icons-material/TableView";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Slide, toast } from 'react-toastify';

import "../../style/datasurvey.css"

// External
import Navbar from "../../Components/Navbar";
import { fetchProfile } from "../../utils/fetchProfile";
import { fetchUserFormatted } from "../../utils/fetchUser";
import { ModalConfirmation } from "../../Components/Fragments/Modals/ModalGeneral";
import { TableDataSurvey } from "../../Components/Fragments/Tables/TableDataSurvey";
import { setSelectedSurveyId, setSelectedAuditStatus, setProvinceCode, setRegencyCode, setDistrictCode, setSubDistrictCode, setTipeSurveyCode, setUsernameSurveyorCode, setCurrentPage, setFilterColumnName, setFilterColumnValue } from "../../redux/slices/dataSurveySlice";
import { fetchDataSurveys, fetchDataSurveyColumn } from "../../services/dataSurveyService";

// Custom CSS
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

function DataSurveyThree() {
  const dispatch = useDispatch();

  //data period
  const [dataPeriode, setDataPeriode] = useState([]);

  // Initial
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("access_token") || "";
  const [initialText, setInitialText] = useState("");
  const [openDialogConfirmationExport, setOpenDialogConfirmationExport] = useState(false);
  const [viewDataExport, setViewDataExport] = useState({
    title: null,
    process_at: null,
  });

  const currentPage = useSelector((state) => state.dataSurvey.currentPage);
  const itemsPerPage = useSelector((state) => state.dataSurvey.itemsPerPage);

  const [selectedPeriodeId, setSelectedPeriodeId] = useState(
    localStorage.getItem("selectedPeriodeId") || "all"
  );

  const selectedSurveyId = useSelector((state) => state.dataSurvey.selectedSurveyId);
  const provinceCode = useSelector((state) => state.dataSurvey.selectedProvinceId);
  const regencyCode = useSelector((state) => state.dataSurvey.selectedRegencyId);
  const districtCode = useSelector((state) => state.dataSurvey.selectedDistrictId);
  const subDistrictCode = useSelector((state) => state.dataSurvey.selectedSubDistrictId);
  const selectedAuditStatus = useSelector((state) => state.dataSurvey.selectedAuditStatus);
  const tipeSurveyCode = useSelector((state) => state.dataSurvey.selectedTipeSurveyId);
  const usernameSurveyorCode = useSelector((state) => state.dataSurvey.selectedUserNameSurveyor);
  const selectedIsNegative = useSelector((state) => state.dataSurvey.selectedIsNegative);

  const dataSurveyfilterColumnName = useSelector((state) => state.dataSurvey.dataSurveyfilterColumnName);
  const filterColumnName = useSelector((state) => state.dataSurvey.filterColumnName);
  const filterColumnOperator = useSelector((state) => state.dataSurvey.filterColumnOperator);
  const filterColumnValue = useSelector((state) => state.dataSurvey.filterColumnValue);

  const [rowModesModel, setRowModesModel] = useState({});
  const [surveyData, setSurveyData] = useState([]);

  const itemsPerPageList = [5, 10, 50, 100];

  const dataAuditStatus = [
    // {
    //   label: "SEMUA",
    //   value: "all",
    // },
    {
      label: "Closed",
      value: "Closed",
    },
    {
      label: "On Progress",
      value: "On Progress",
    },
    {
      label: "Valid",
      value: "Valid",
    },
    {
      label: "Not Started",
      value: "Not Started",
    },
  ];
  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);

  // Handling modal
  const [modalAdd, setModalAdd] = useState(false);

  // Token
  const token = Cookies.get("access_token") || "";

  // Button style
  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 14,
    padding: "3px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#069DAE",
    fontFamily: ["Roboto"].join(","),
    "&:hover": {
      backgroundColor: "#0696A6",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  });

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // Download
  const [selectedDownload, setSelectedDownload] = useState(null);
  const [filterDownload, setFilterDownload] = useState([]);

  async function fetchProjectData(token, periodeCode, setSurveyData) {
    setSurveyData([])
    if (periodeCode && periodeCode !== 'all') {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project?period=${periodeCode}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          const { data } = await response.json();
          const transformedData = data.map((project) => ({
            value: project.survey_id,
            label: project.project_name,
            type: project.project_type_name,
          }));

          setFilterDownload(transformedData);

          if (transformedData.length > 0) {
            setSelectedSurveyId(transformedData[0].value);
            setSurveyType(transformedData[0].type);
          }
        } else {
          throw new Error("Failed to fetch project data");
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, []);
      fetchPeriodeFormatted(accessToken, setDataPeriode);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProjectData(accessToken, selectedPeriodeId, setSurveyData);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [selectedPeriodeId]);

  const provinceInputText = "SEMUA";
  const tipeSurveyInputText = "SEMUA";
  const usernameSurveyorInputText = "SEMUA";
  const [regencyInputText, setRegencyInputText] = useState("SEMUA");
  const [districtInputText, setDistrictInputText] = useState("SEMUA");
  const [subDistrictInputText, setSubDistrictInputText] = useState("SEMUA");

  // all data
  const [provinces, setProvinces] = useState([]);
  const [dataSurveyType, setDataSurveyType] = useState([]);

  const [dataUserSurveyor, setDataUserSurveyor] = useState([]);
  const [dataUserSurveyorOptions, setDataUserSurveyorOptions] = useState([]);
  const [dataUserQcOptions, setDataUserQcOptions] = useState([]);

  const [regencies, setRegencies] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [subDistricts, setSubDistricts] = useState([]);



  async function fetchPeriodeFormatted(token, setDataPeriode) {
    setDataPeriode([]);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project/period`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const newArray = data.data.map(item => ({
          label: item.period.toString(), // Mengonversi period menjadi string
          id: item.period.toString(),
        }));
        setDataPeriode(newArray);
      } else {
        setDataPeriode([]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchTipeSurvey() {
    try {
      const token = Cookies.get("access_token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/store-type`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        const newArray = data.data_tipe_survey.map((item) => ({
          label: item,
          id: item,
        }));
        setDataSurveyType(newArray);
      } else {
        setDataSurveyType();
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchTipeSurvey();
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchUserFormatted(accessToken, setDataUserSurveyorOptions, "13");
      fetchUserFormatted(accessToken, setDataUserSurveyor, "13");
      fetchUserFormatted(accessToken, setDataUserQcOptions, "14");
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  async function fetchProvinces() {
    try {
      const token = Cookies.get("access_token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/provinces`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { data } = await response.json();
        setProvinces(data);
      } else {
        setProvinces();
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProvinces();
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  async function fetchRegencies(province_code) {
    if (province_code !== "all") {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/area/regencies?province_code=${province_code}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          setRegencies(data.data);
        } else {
          throw new Error("Ada masalah saat mengambil kabupaten");
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function fetchDistricts(province_code, regency_code) {
    if (regency_code !== "all") {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/area/districts?province_code=${province_code}&regency_code=${regency_code}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          setDistricts(data.data);
        } else {
          throw new Error("Error when fetch district");
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function fetchSubDistricts(province_code, regency_code, district_code) {
    if (districtCode !== "all") {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/area/sub-districts?province_code=${province_code}&regency_code=${regency_code}&district_code=${district_code}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          setSubDistricts(data.data);
        } else {
          throw new Error("Error when fetch sub district");
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  const handlePeriodeIdChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedPeriodeId(id);
    localStorage.setItem("selectedPeriodeId", id);

    dispatch(setSelectedSurveyId("all"));
    localStorage.setItem("selectedSurveyId", "all");
  };

  const handleProjectChange = (event) => {
    const selectedId = event.target.value;
    dispatch(setSelectedSurveyId(selectedId));

    localStorage.setItem("selectedSurveyId", selectedId);

    const selectedProject = filterDownload.find(
      (project) => project.value === selectedId
    );

    if (selectedProject) {
      setSurveyType((prevSurveyType) => selectedProject.type);
    } else {
      console.error("Project not found");
    }

    dispatch(setProvinceCode("all"));
    dispatch(setRegencyCode("all"));
    dispatch(setDistrictCode("all"));
    dispatch(setSubDistrictCode("all"));
  };

  const setDefaultSelectedPeriode = () => {
    if (dataPeriode.length > 0) {
      const selectedOption = dataPeriode.find(option => option.id === selectedPeriodeId);
      if (selectedOption) {
        setSelectedPeriodeId(selectedOption.id);
      } else {
        setSelectedPeriodeId(dataPeriode[0].id);
      }
    }
  };

  const setDefaultSelectedProject = () => {
    if (surveyData.length > 0) {
      const selectedOption = surveyData.find(option => option.id === selectedSurveyId);
      if (selectedOption) {
        dispatch(setSelectedSurveyId(selectedOption.id));
      } else {
        dispatch(setSelectedSurveyId(surveyData[0].id));
      }
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDefaultSelectedPeriode();
      setDefaultSelectedProject();
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [dataPeriode]);

  const handleProvinceCodeChange = (event) => {
    const selectedProvinceCode = event.target.value;
    dispatch(setProvinceCode(selectedProvinceCode));
    dispatch(setRegencyCode("all"));
    dispatch(setDistrictCode("all"));
    dispatch(setSubDistrictCode("all"));
  };

  const handleTipeSurveyCodeChange = (event) => {
    const selectedTipeSurveyCode = event.target.value;
    dispatch(setTipeSurveyCode(selectedTipeSurveyCode));
  };

  const handleUsernameSurveyorCodeChange = (event) => {
    const selectedUsernameSurveyorCode = event.target.value;
    dispatch(setUsernameSurveyorCode(selectedUsernameSurveyorCode));
  };

  const handleRegencyCodeChange = (event) => {
    const selectedRegencyCode = event.target.value;
    dispatch(setRegencyCode(selectedRegencyCode));
    dispatch(setDistrictCode("all"));
  };

  const handleDistrictCodeChange = (event) => {
    const selectedDistrictCode = event.target.value;
    dispatch(setDistrictCode(selectedDistrictCode));
    dispatch(setSubDistrictCode("all"));
  };

  const handleSubDistrictCodeChange = (event) => {
    const selectedSubDistrictCode = event.target.value;
    dispatch(setSubDistrictCode(selectedSubDistrictCode));
  };

  const handleAuditStatusChange = (event) => {
    const { target: { value } } = event;
    dispatch(setSelectedAuditStatus(
      typeof value === "string" ? value.split(",") : value
    ));
    localStorage.setItem("selectedAuditStatus", value);
  };

  async function createDataSurveyProcess() {
    setIsLoading(true);
    try {
      const surveyId = selectedSurveyId;
      const selectedLabel = filterDownload.find(
        (option) => option.value === selectedSurveyId
      )?.label;

      const formData = {
        title: `Rawdata ${selectedLabel}`,
        audit_status: selectedAuditStatus.length > 0 ? selectedAuditStatus[0] : "",
        tipe_survey: tipeSurveyCode,
        province_code: provinceCode == "all" ? "" : provinceCode,
        regency_code: regencyCode == "all" ? "" : regencyCode,
        district_code: districtCode == "all" ? "" : districtCode,
        survey_id: surveyId
      }

      // const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/process_queue/data_surveys`, {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/rawdata/process_queue/data_surveys`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 201 || response.status === 200 || response.status === 202) {
        toast.success('data akan diproses kedalam antrian', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 400) {
        const message = await response.json();
        toast.error(message?.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 404) {
        toast.error('data tidak ditemukan', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error('ada masalah saat menyimpan data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      }
    } catch (error) {
      console.error('Error creating process queue:', error);
    }
    setIsLoading(false);
  }

  const handleConfirmationExport = async () => {
    setIsLoading(true);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/process_queue/data_surveys/${selectedSurveyId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      const { data } = await response.json();
      setOpenDialogConfirmationExport(true);
      setViewDataExport({
        title: data.data.title ?? "rawdata",
        process_at: data.process_at,
      });
    } else if (response.status === 400) {
      toast.error("Data sedang diproses", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    } else {
      toast.error("Tidak ditemukan excel, klik Process terlebih dahulu", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setIsLoading(false);
  };

  const handleCloseDialog = () => {
    setOpenDialogConfirmationExport(false);
    // clear data
    setViewDataExport({
      title: "",
      process_at: "",
    });
  };

  // export
  const handleExport = async () => {
    setIsLoading(true);
    try {
      const queryParams = new URLSearchParams();

      // Construct the full URL with query parameters
      const queryString = queryParams.toString();
      const apiUrl =
        `${process.env.REACT_APP_API_URL}/api/v1/process_queue/data_surveys/export/${selectedSurveyId}` +
        (queryString ? `?${queryString}` : "");

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const selectedLabel = filterDownload.find(
        (option) => option.value === selectedSurveyId
      )?.label;

      if (response.status === 200) {
        const blob = await response.blob();
        const filename = `${selectedLabel}.xlsx`;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error('Gagal mengunduh', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error('Failed to export process queue:', response.statusText);
      }
    } catch (error) {
      console.error('Error export process queue:', error);
    }
    setIsLoading(false);
  };

  // view trx
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenQc, setModalOpenQc] = useState(false);
  const [modalOpenEdit, setModalOpenEdit] = useState(false);
  const [modalOpenLogCorrection, setModalOpenLogCorrection] = useState(false);

  const [selectedModalStoreCode, setSelectedModalStoreCode] = useState("");

  const [selectedProductCode, setSelectedProductCode] = useState("");

  const [modalTableData, setModalTableData] = useState([]);
  const [modalTableDataQc, setModalTableDataQc] = useState([]);

  const [selectedSurveyType, setSelectedSurveyType] = useState("");
  const [selectedStatusAudit, setSelectedStatusAudit] = useState("");

  const [surveyType, setSurveyType] = useState("");

  const openNewTab = (storeId, statusCode, surveyType, isQc) => {
    const url = `/data-survey/${selectedSurveyId}/${storeId}?is_qc=${isQc}`;
    window.open(url, '_blank');

    setSelectedStatusAudit(statusCode);
    setSelectedSurveyType(surveyType);
  };
  // handle search
  const handleSearch = (e) => {
    e.preventDefault();

    dispatch(setCurrentPage(1))
    const customParams = {
      size: itemsPerPage,
      page: currentPage,
      province_code: provinceCode,
      regency_code: regencyCode,
      district_code: districtCode,
      sub_district_code: subDistrictCode,
      tipe_survey: tipeSurveyCode,
      audit_status: selectedAuditStatus.length > 0 ? selectedAuditStatus : null,
      user_name_surveyor: usernameSurveyorCode,
      is_negative: selectedIsNegative,
      column_name: filterColumnName,
      column_operator: filterColumnOperator,
      column_value: filterColumnValue,
    };
    fetchDataSurveys(dispatch, accessToken, customParams, selectedSurveyId);
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchDataSurveyColumn(dispatch, token);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Data Survey"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allow to access this page</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar
        active="Data Survey"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div className="content" style={{ padding: "1rem" }}>
        <div
          className="legend"
        >
          {/* Search bar */}
          <div>
            <div
              className="searchbar"
            >
              <TextField
                id="periode-autocomplete"
                select
                label="Periode"
                value={selectedPeriodeId}
                onChange={(event) => handlePeriodeIdChange(event, { id: event.target.value })}
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                }}
                size="small"
              >
                {dataPeriode.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-select-currency"
                label="Project - Survey"
                select
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                  // border: 3,
                  // borderRadius: 2,
                  // borderColor: "#069dae",
                }}
                size="small"
                value={selectedSurveyId}
                onChange={handleProjectChange}
              >
                {filterDownload.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <FormControl sx={{ height: 40, mt: 1, mr: 1 }}>
                <InputLabel sx={{ mt: "-5px" }} id="demo-multiple-name-label">
                  Status
                </InputLabel>
                <Select
                  multiple
                  sx={{ height: 40 }}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectedAuditStatus}
                  onChange={handleAuditStatusChange}
                  input={<OutlinedInput label="Status" />}
                >
                  {dataAuditStatus.map((data) => (
                    <MenuItem key={data.value} value={data.value}>
                      {data.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                label="Tipe Survey"
                select
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                }}
                size="small"
                value={tipeSurveyCode}
                onChange={handleTipeSurveyCodeChange}
              >
                <MenuItem key={"x"} value={""}>
                  {tipeSurveyInputText}
                </MenuItem>
                {dataSurveyType.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                label="Full Name Surveyor"
                select
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                }}
                size="small"
                value={usernameSurveyorCode}
                onChange={handleUsernameSurveyorCodeChange}
              >
                <MenuItem key={"x"} value={""}>
                  {usernameSurveyorInputText}
                </MenuItem>
                {dataUserSurveyor.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-select-currency"
                label="Provinsi"
                select
                sx={{
                  mr: 1,
                  height: 40,
                  mt: 1,
                  // border: 3,
                  // borderRadius: 2,
                  // borderColor: "#069dae",
                }}
                size="small"
                value={provinceCode}
                onChange={handleProvinceCodeChange}
              >
                <MenuItem key={"x"} value={"all"}>
                  {provinceInputText}
                </MenuItem>
                {provinces.map((item) => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-select-currency"
                label="Kota/Kab"
                select
                sx={{
                  mr: 1,
                  height: 40,
                  mt: 1,
                  // border: 3,
                  // borderRadius: 2,
                  // borderColor: "#069dae",
                }}
                size="small"
                value={regencyCode}
                onChange={handleRegencyCodeChange}
              >
                <MenuItem key={"x"} value={"all"}>
                  {regencyInputText}
                </MenuItem>
                {regencies.map((item) => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-select-currency"
                label="Kec"
                select
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                  // border: 3,
                  // borderRadius: 2,
                  // borderColor: "#069dae",
                }}
                size="small"
                value={districtCode}
                onChange={handleDistrictCodeChange}
              >
                <MenuItem key={"x"} value={"all"}>
                  {districtInputText}
                </MenuItem>
                {districts.map((item) => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-select-currency"
                label="Kel"
                select
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                }}
                size="small"
                value={subDistrictCode}
                onChange={handleSubDistrictCodeChange} // Add this line to call the function
              >
                <MenuItem key={"x"} value={"all"}>
                  {subDistrictInputText}
                </MenuItem>
                {subDistricts.map((item) => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>

          <ModalConfirmation
            open={openDialogConfirmationExport}
            onClose={handleCloseDialog}
            title={'Konfirmasi Download'}
            confirmationText={'Yes'}
            description={`Ditemukan ${viewDataExport.title} terakhir diproses pada ${viewDataExport.process_at}. Apakah anda yakin ingin download rawdata ini?`}
            isLoading={isLoading}
            handleClick={handleExport}
          />

          {/* Button Add New Project */}
          <div className="button-audit"
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}>
            <form onSubmit={handleSearch} style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                }}
                required
                labelId="select-label-column_name"
                name="column_name"
                id="select_column_name"
                value={filterColumnName}
                onChange={(e) => {
                  dispatch(setFilterColumnName(e.target.value));
                }}
              >
                {dataSurveyfilterColumnName.map((data) => (
                  <MenuItem value={data.id} key={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                }}
                InputProps={{
                  style: {
                    height: 40,
                  },
                }}
                name="column_value"
                label="Cari"
                size="small"
                value={filterColumnValue}
                onChange={(e) => {
                  dispatch(setFilterColumnValue(e.target.value));
                }}
              />
              <BootstrapButton
                variant="contained"
                disableRipple
                sx={{ width: 120, height: 40, mt: 1 }}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : 'Cari'}
              </BootstrapButton>
            </form>
            <div className="spacing-one"></div>
            <BootstrapButton
              variant="contained"
              disableRipple
              onClick={createDataSurveyProcess}
              disabled={isLoading}
              className="button-process"
              sx={{ mr: 1 }}
            >
              {isLoading ? "Loading..." : "Process"}
            </BootstrapButton>

            <BootstrapButton
              variant="contained"
              disableRipple
              onClick={handleConfirmationExport}
              disabled={isLoading}
              className="button-process"
              sx={{ mr: 1 }}
            >
              {isLoading ? "Loading..." : "Download"}
            </BootstrapButton>
          </div>

          {/* Modal Add New Project */}
          <Modal
            open={modalAdd}
            onClose={() => setModalAdd(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ border: 0 }}
          >
            <Box sx={style}>
              <TextField
                id="outlined-select-currency"
                label="Download - Project Type"
                select
                sx={{ width: 400 }}
                size="small"
                value={selectedDownload}
                onChange={(event) => setSelectedDownload(event.target.value)}
              >
                {filterDownload.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {/* File Input */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <BootstrapButton
                  variant="contained"
                  disableRipple
                  sx={{ width: 400, mt: 2, height: 50 }}
                // onClick={handleDownload}
                >
                  Download
                </BootstrapButton>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
      <div style={{ padding: "1rem" }}>
        <TableDataSurvey
          isLoading={isLoading}
          itemsPerPageList={itemsPerPageList}
          openNewTab={openNewTab}
          surveyType={surveyType}
        />
      </div>
    </div>
  );
}

export default DataSurveyThree;
