import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setItemsPerPage,
} from "../../../redux/slices/auditPlanSlice";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { fetchAuditPlans } from "../../../services/auditPlanService";

export const TableAuditPlan = ({
  itemsPerPageList,
  isLoading,
  handleOpenStatusModal,
  handleOpenDeleteModal,
  handleEditClick,
  selectedProjectType,
  handleOpenTransferDataRak2Modal,
  handleUpdateDataMobileToWebModal,
}) => {
  const dispatch = useDispatch();

  const items = useSelector((state) => state.auditPlan.data);
  const accessToken = useSelector((state) => state.general.accessToken);
  const currentPage = useSelector((state) => state.auditPlan.currentPage);
  const itemsPerPage = useSelector((state) => state.auditPlan.itemsPerPage);
  const total = useSelector((state) => state.auditPlan.total);

  const selectedSurveyId = useSelector(
    (state) => state.auditPlan.selectedSurveyId
  );
  const selectedStoreTypeCode = useSelector(
    (state) => state.auditPlan.selectedStoreTypeCode
  );
  const selectedSurveyTypeCode = useSelector(
    (state) => state.auditPlan.selectedSurveyTypeCode
  );
  const selectedProvinceId = useSelector(
    (state) => state.auditPlan.selectedProvinceId
  );
  const selectedRegencyId = useSelector(
    (state) => state.auditPlan.selectedRegencyId
  );
  const selectedDistrictId = useSelector(
    (state) => state.auditPlan.selectedDistrictId
  );
  const selectedSubDistrictId = useSelector(
    (state) => state.auditPlan.selectedSubDistrictId
  );
  const selectedAuditStatus = useSelector(
    (state) => state.auditPlan.selectedAuditStatus
  );
  const selectedUserNameSurveyor = useSelector(
    (state) => state.auditPlan.selectedUserNameSurveyor
  );

  const [sortBy, setSortBy] = useState("store_code");
  const [sortOrder, setSortOrder] = useState("desc");

  const fetchData = (currentPage, itemsPerPage, sortBy, sortOrder) => {
    try {
      dispatch(setCurrentPage(currentPage));
      const customParams = {
        size: itemsPerPage,
        page: currentPage,
        survey_id: selectedSurveyId,
        province_code: selectedProvinceId,
        regency_code: selectedRegencyId,
        district_code: selectedDistrictId,
        sub_district_code: selectedSubDistrictId,
        store_type_code: selectedStoreTypeCode,
        tipe_survey: selectedSurveyTypeCode,
        audit_status: selectedAuditStatus,
        user_name_surveyor: selectedUserNameSurveyor,
        sort_by: sortBy,
        sort_order: sortOrder,
      };
      fetchAuditPlans(dispatch, accessToken, customParams);
    } catch (error) {
      console.error(`Error fetching data:`, error);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (selectedSurveyId !== "all") {
          fetchData(currentPage || 1, itemsPerPage, sortBy, sortOrder);
      }
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [
    dispatch,
    currentPage,
    itemsPerPage,
    selectedSurveyId,
    selectedAuditStatus,
    selectedStoreTypeCode,
    selectedSurveyTypeCode,
    selectedProvinceId,
    selectedRegencyId,
    selectedDistrictId,
    selectedSubDistrictId,
    selectedUserNameSurveyor,
    sortBy,
    sortOrder,
  ]);

  const handlePageChange = (event, page) => {
    fetchData(page + 1, itemsPerPage, sortBy, sortOrder);
  };

  const handleChangeItemPerPageSelect = (e) => {
    dispatch(setItemsPerPage(e.target.value));
    fetchData(1, e.target.value, sortBy, sortOrder);
  };

  const handleSort = (column) => {
    const isSorting = sortBy === column && sortOrder === "asc";
    setSortOrder(isSorting ? "desc" : "asc");
    setSortBy(column);
    // fetchData(currentPage, itemsPerPage, column, isSorting ? 'desc' : 'asc');
  };

  const sortTableCell = (label, sortByKey) => (
    <TableCell style={tableCellStyle}>
      <TableSortLabel
        active={sortBy === sortByKey}
        direction={sortOrder}
        onClick={() => handleSort(sortByKey)}
        sx={{
          color: "#FFF !important",
          "&:hover": {
            color: "#FFF",
          },
          "& .MuiTableSortLabel-icon": {
            color: "#FFF !important",
          },
        }}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  const tableCellStyle = {
    whiteSpace: "nowrap",
    backgroundColor: "rgb(6,157,174)",
    color: "#FFF",
    fontWeight: "bold",
    position: "sticky",
    top: 0,
    zIndex: 1000,
    minWidth: 120,
  };

  const getStatusStyle = (status) => {
    const baseStyle = {
      borderRadius: "4px",
      margin: "10px",
      display: "inline-block",
    };

    switch (status) {
      case "Valid":
        return { ...baseStyle, background: "lightblue", color: "white" };
      case "Not Started":
        return { ...baseStyle, background: "red", color: "white" };
      case "Closed":
        return { ...baseStyle, background: "green", color: "white" };
      case "On Progress":
        return { ...baseStyle, background: "orange", color: "white" };
      default:
        return {};
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={tableCellStyle}>Actions</TableCell>
              {sortTableCell("Kode Outlet", "store_code")}
              {sortTableCell("Audit Plan", "audit_plan_master_id")}
              {sortTableCell("Nama Outlet", "store_name")}
              {sortTableCell("Tipe Outlet", "store_type")}
              <TableCell style={tableCellStyle}>Status</TableCell>
              {sortTableCell("Username Surveyor", "user_name_surveyor")}
              {sortTableCell("Full Name Surveyor", "user_full_name_surveyor")}
              {sortTableCell("Username QC", "user_name_qc")}
              {sortTableCell("Full Name QC", "user_full_name_qc")}
              {sortTableCell("Mulai Kunjungan", "audit_start_at")}
              {sortTableCell("Akhir Kunjungan", "audit_end_at")}
              {sortTableCell("Tanggal Dibuat", "created_at")}
              {sortTableCell("Tanggal Diperbarui", "updated_at")}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length > 0 ? (
              items.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="center">
                    <div>
                      {selectedProjectType !== "adhoc" && (
                        <Tooltip
                          title="Update Data Mobile to Website"
                          onClick={() => handleUpdateDataMobileToWebModal(row)}
                        >
                          <IconButton>
                            <DownloadingIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {selectedProjectType === "rak1" && (
                        <Tooltip
                          title="Transfer data to rak2"
                          onClick={() => handleOpenTransferDataRak2Modal(row)}
                        >
                          <IconButton>
                            <MoveDownIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {(row.audit_status === "Not Started" ||
                        row.audit_status === "Valid" ||
                        row.audit_status === "On Progress") && (
                        <Tooltip
                          title="Edit Audit Plan"
                          onClick={() => handleEditClick(row.id)}
                        >
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {(row.audit_status === "Not Started" ||
                        row.audit_status === "Valid" ||
                        row.audit_status === "On Progress") && (
                        <Tooltip
                          title="Delete Audit Plan"
                          onClick={() => handleOpenDeleteModal(row.id)}
                        >
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {(row.audit_status === "Closed" ||
                        row.audit_status === "closed") && (
                        <Tooltip
                          title="Update Audit Plan to Valid"
                          onClick={() => handleOpenStatusModal(row.id, "Valid")}
                        >
                          <IconButton>
                            <AutorenewIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {row.audit_status === "Valid" && (
                        <Tooltip
                          title="Update Audit Plan to Closed"
                          onClick={() =>
                            handleOpenStatusModal(row.id, "Closed")
                          }
                        >
                          <IconButton>
                            <AutorenewIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="center">{row.store_code}</TableCell>
                  <TableCell align="center">{row.audit_plan}</TableCell>
                  <TableCell align="center">{row.store_name}</TableCell>
                  <TableCell align="center">{row.store_type}</TableCell>
                  <TableCell
                    align="center"
                    style={getStatusStyle(row.audit_status)}
                  >
                    {row.audit_status}
                  </TableCell>
                  <TableCell align="center">{row.user_name_surveyor}</TableCell>
                  <TableCell align="center">
                    {row.user_full_name_surveyor}
                  </TableCell>
                  <TableCell align="center">{row.user_name_qc}</TableCell>
                  <TableCell align="center">{row.user_full_name_qc}</TableCell>
                  <TableCell align="center">{row.audit_start_at}</TableCell>
                  <TableCell align="center">{row.audit_end_at}</TableCell>
                  <TableCell align="center">{row.created_at}</TableCell>
                  <TableCell align="center">{row.updated_at}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  {isLoading ? "Loading..." : "No Data Available"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* pagination */}
      {items.length > 0 && (
        <TablePagination
          rowsPerPageOptions={itemsPerPageList}
          component="div"
          count={total}
          rowsPerPage={itemsPerPage}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeItemPerPageSelect}
        />
      )}
    </Paper>
  );
};
