import React, { useState } from 'react'
import logo from "../assets/images/bg/logo.png";
import Cookies from 'js-cookie';
import { fetchLicense } from '../utils/fetchLicense';
import { Container, Grid, Typography, Box, TextField, Alert } from '@mui/material';
import { BootstrapButton } from '../Components/Button/BootstrapButton';
import Copyright from '../Components/Copyright';

function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const fetchLogin = async (bodyForm) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/auth/login`,
      {
        method: "POST",
        body: JSON.stringify(bodyForm),
        headers: { 'Content-Type': 'application/json' }
      }
    );
    if (response.status === 200) {
      const data = await response.json();
      const access_token = data.data.access_token || ''
      Cookies.set('access_token', access_token)
      setMessage('login successful')
      window.location.replace("dashboard")
    } else if (response.status === 400 || response.status === 422 || response.status === 404 || response.status === 403) {
      const data = await response.json();
      setMessage(data.detail)
    } else {
      setMessage('something went wrong, try again later')
    }
  }

  const handleSubmit = async (e) => {
    setIsFormLoading(true);
    e.preventDefault();
    const bodyForm = {
      'user_name': userName,
      'password': password
    }
    try {
      localStorage.clear();
      const data = await fetchLicense();
      if (data.is_license_expired) {
        alert(data.license_message)
      }
      await fetchLogin(bodyForm);
    } catch (error) {
      console.error(error)
      setMessage('something went wrong, try again later')
    }
    setIsFormLoading(false);
  };

  return (
    <Container maxWidth="lg"> {/* Increased the maxWidth for overall container */}
      <Box style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}> {/* Centering the logo within its grid */}
            <img src={logo} alt="logo" style={{ width: 'auto', height: 'auto', maxWidth: '80%' }} />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <Typography variant="h4" component="div" gutterBottom sx={{ mt: '10px', fontWeight: '700', fontSize: '40px' }}>
              Application For <span style={{ color: '#0190A0' }}>Retail Audit and Observation Survey.</span>
            </Typography>

            {message ? (
              <Alert severity="warning">{message}</Alert>
            ) : null}

            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' }, // Column on xs (extra-small) screens, row on sm (small) and larger
                alignItems: 'center',
                gap: '10px',
                mt: 2
              }}
            >
              <TextField
                variant="filled"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                label="Username"
                required={true}
                sx={{ flexGrow: 1, width: 'auto' }}  // Ensure the width is auto to fill the space in column mode
              />
              <TextField
                variant="filled"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                label="Password"
                required={true}
                sx={{ flexGrow: 1, width: 'auto' }}  // Ensure the width is auto to fill the space in column mode
              />
              <BootstrapButton
                variant="contained"
                disableRipple
                type="submit"
                className="button-process"
                disabled={isFormLoading}
                sx={{ px: '40px', py: '15px', width: { xs: '100%', sm: 'auto' } }}  // Full width on xs screens
              >
                {isFormLoading ? 'Loading...' : 'Sign In'}
              </BootstrapButton>
            </Box>


          </Grid>
        </Grid>
        <Copyright />
      </Box>
    </Container>
  )
}

export default Login
