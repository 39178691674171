import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

import { fetchProfile } from "../../../../utils/fetchProfile";
import Navbar from "../../../../Components/Navbar";
import { BootstrapButton } from "../../../../Components/Button/BootstrapButton";
import { TextField, MenuItem, ButtonGroup } from "@mui/material";
import { Slide, toast } from "react-toastify";

import TableRetailer from "./Table/TableRetailer";
import TableMaster from "./Table/TableMaster";

function CrosscodingBrand() {
  const accessToken = Cookies.get("access_token") || "";

  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const goToProduct = () => {
    window.location.replace("/dev/data-processing/crosscode-product");
  };

  useEffect(() => {
    fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, [
      "COA",
      "TL",
      "13",
      "14",
    ]);
  }, []);

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Data Processing"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allowed to access this page</div>
      </div>
    );
  }
  return (
    <div>
      <Navbar
        active="Data Processing"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div className="content" style={{ padding: "2rem" }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div>
            <ButtonGroup style={{ color: "white" }}>
              <BootstrapButton
                onClick={goToProduct}
                style={{
                  color: "white",
                  borderColor: "white",
                  height: "40px",
                  width: "fit-content",
                }}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Go to Crosscoding Product"}
              </BootstrapButton>
            </ButtonGroup>
          </div>
        </div>

        <div
          style={{
            display: "block",
          }}
        >
          <div
            className="container-textfield"
            style={{ marginBottom: "30px", marginTop: "-50px" }}
          >
            <div
              className="content-text"
              style={{
                textAlign: "center",
              }}
            >
              <h4
                style={{
                  fontSize: 20,
                  fontFamily: ["Montserrat"].join(","),
                }}
              >
                Crosscode - Brand
              </h4>
            </div>
          </div>
        </div>

        <div>
          <div
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              borderRadius: "5px",
              padding: "0rem 1rem 1rem 1rem",
            }}
          >
            <h4
              style={{
                fontSize: 14,
                fontFamily: ["Montserrat"].join(","),
                paddingTop: 14,
              }}
            >
              Filter Brand Retailer
            </h4>
            <div style={{ display: "flex", justifyContent: "justify-content" }}>
              <TextField
                id="outlined-select-currency"
                label="Filter Brand Retailer"
                select
                sx={{
                  height: 40,
                }}
                size="small"
                // value={selectedProjectType}
                // onChange={handleProjectTypeChange}
                className="reporting-textfield"
              >
                <MenuItem key="rta_gt" value="rta_gt">
                  RTA GT
                </MenuItem>
                <MenuItem key="rta_mt" value="rta_mt">
                  RTA MT
                </MenuItem>
                <MenuItem key="rdc_gt" value="rdc_gt">
                  RDC GT
                </MenuItem>
                <MenuItem key="rdc_mt" value="rdc_mt">
                  RDC MT
                </MenuItem>
                <MenuItem key="rdc_pharmacy" value="rdc_pharmacy">
                  RDC PHARMACY
                </MenuItem>
              </TextField>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                maxHeight: "50px",
              }}
            >
              <h4
                style={{
                  fontSize: 14,
                  fontFamily: ["Montserrat"].join(","),
                }}
              >
                List Brand Retailer
              </h4>
              <ButtonGroup style={{ color: "white" }}>
                <BootstrapButton
                  style={{
                    color: "white",
                    borderColor: "white",
                    height: "34px",
                    width: "fit-content",
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Exclude Selected Brand"}
                </BootstrapButton>
                <BootstrapButton
                  style={{
                    color: "white",
                    borderColor: "white",
                    height: "34px",
                    width: "fit-content",
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Show Exclude Brand"}
                </BootstrapButton>
              </ButtonGroup>
            </div>
            <TableRetailer />
          </div>

          <div
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              borderRadius: "5px",
              padding: "0rem 1rem 1rem 1rem",
              marginTop: "30px",
            }}
          >
            <h4
              style={{
                fontSize: 14,
                fontFamily: ["Montserrat"].join(","),
                paddingTop: 14,
              }}
            >
              Filter Brand Master
            </h4>
            <div style={{ display: "flex", justifyContent: "justify-content" }}>
              <TextField
                id="outlined-select-currency"
                label="Filter Brand Retailer"
                select
                sx={{
                  height: 40,
                }}
                size="small"
                // value={selectedProjectType}
                // onChange={handleProjectTypeChange}
                className="reporting-textfield"
              >
                <MenuItem key="rta_gt" value="rta_gt">
                  RTA GT
                </MenuItem>
                <MenuItem key="rta_mt" value="rta_mt">
                  RTA MT
                </MenuItem>
                <MenuItem key="rdc_gt" value="rdc_gt">
                  RDC GT
                </MenuItem>
                <MenuItem key="rdc_mt" value="rdc_mt">
                  RDC MT
                </MenuItem>
                <MenuItem key="rdc_pharmacy" value="rdc_pharmacy">
                  RDC PHARMACY
                </MenuItem>
              </TextField>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                maxHeight: "50px",
              }}
            >
              <h4
                style={{
                  fontSize: 14,
                  fontFamily: ["Montserrat"].join(","),
                }}
              >
                List Brand Master
              </h4>
              <ButtonGroup style={{ color: "white" }}>
                <BootstrapButton
                  style={{
                    color: "white",
                    borderColor: "white",
                    height: "34px",
                    width: "fit-content",
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Show Crosscode Brand"}
                </BootstrapButton>
              </ButtonGroup>
            </div>
            <TableMaster />
          </div>
        </div>
        
        <div style={{display: "flex", justifyContent: "flex-end", marginTop: 14}}>
          <ButtonGroup style={{ color: "white" }}>
            <BootstrapButton
              style={{
                color: "white",
                borderColor: "white",
                height: "34px",
                width: "fit-content",
              }}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Crosscode Selected Brand"}
            </BootstrapButton>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
}

export default CrosscodingBrand;
