import { Modal, Box, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapButton } from "../../../Components/Button/BootstrapButton";
import TextField from "@mui/material/TextField";
import Cookies from "js-cookie";
import { React, useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";

import axios from "axios";

function ModalEditCondition({
  isLoading,
  isUpdateModalCondition,
  setIsUpdateModalCondition,
  editedCondition,
  seteditedCondition,
  handleUpdateCondition,
  editcondition,
}) {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: 800,
    overflowY: "auto",
    width: 800,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const accessToken = Cookies.get("access_token") || "";
  const [dataConditionItems, setDataConditionItems] = useState([]);

  const fetchConditionItems = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/condition/${editcondition}/condition-items`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      let result_data = response?.data?.data;
      if (response.status === 200) {
        setDataConditionItems(result_data);
      } else if (response.status === 404) {
        setDataConditionItems([]);
      } else console.log("Error");
    } catch (e) {
      setDataConditionItems([]);
    }
  };

  const columns = [
    {
      field: "operator",
      headerClassName: "super-app-theme--header",
      width: 200,
      headerName: "OPERATOR",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "prefix",
      headerClassName: "super-app-theme--header",
      width: 200,
      headerName: "PREFIX",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "condition_item_order",
      headerClassName: "super-app-theme--header",
      width: 200,
      headerName: "CONDITION ITEM ORDER",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "value",
      headerClassName: "super-app-theme--header",
      width: 200,
      headerName: "VALUE",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    // {
    //   field: "actions",
    //   align: "center",
    //   headerAlign: "center",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "ACTIONS",
    //   width: 214,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <Tooltip
    //           title="Edit Condition"
    //           onClick={() => handleEditCondition(params)}
    //         >
    //           <IconButton>
    //             <EditIcon />
    //           </IconButton>
    //         </Tooltip>
    //         <Tooltip
    //           title="Delete Condition"
    //           onClick={() => handleOpenDeleteModalCondition(params.row.id)}
    //         >
    //           <IconButton>
    //             <DeleteOutlineOutlinedIcon />
    //           </IconButton>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    fetchConditionItems(editcondition);
  }, [editcondition]);

  return (
    <Modal
      open={isUpdateModalCondition}
      onClose={() => setIsUpdateModalCondition(false)}
      aria-labelledby="delete-modal-title"
      aria-describedby="delete-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={modalStyle}>
        <Box sx={{ display: "flex", justifyContent: "space-between", height: "40px"}}>
          <h1 style={{marginTop: 0}}>Condition Items {editedCondition.condition_name}</h1>
          <IconButton onClick={() => setIsUpdateModalCondition(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <TextField
          label="Condition Name"
          name="condition_name"
          value={editedCondition.condition_name}
          sx={{ mb: 2, width: "95%", mt: 2}}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            style: { fontSize: "1.5rem", fontWeight: "bold" },
          }}
          onChange={(e) =>
            seteditedCondition({
              ...editedCondition,
              condition_name: e.target.value,
            })
          }
        />
        <div>
          <Button
            variant="outlined"
            disableRipple
            sx={{
              width: 190,
              height: 50,
              mt: 2,
              color: "#b34469",
              borderColor: "#b34469",
              mr: 1,
            }}
            onClick={() => setIsUpdateModalCondition(false)}
          >
            Cancel
          </Button>
          <BootstrapButton
            variant="outlined"
            disableRipple
            sx={{
              width: 190,
              mt: 2,
              height: 50,
              color: "#fff",
              borderColor: "#fff",
              ml: 1,
            }}
            disabled={isLoading}
            onClick={handleUpdateCondition}
          >
            <p>{isLoading ? "Loading..." : "Update Condition"}</p>
          </BootstrapButton>
        </div>
        <DataGrid
          sx={{
            mt: 4,
            "& .super-app-theme--header": {
              backgroundColor: "rgb(6,157,174)",
              color: "#FFF",
              border: 2,
              fontWeight: "bold",
              borderColor: "rgb(234,243,248)",
            },
            border: 2,
            borderColor: "rgb(234,243,248)",
            fontSize: 15,
          }}
          loading={isLoading}
          getRowId={(row) => row.id}
          rows={dataConditionItems}
          columns={columns}
          editMode="row"
          getRowHeight={() => 50}

          // rowModesModel={rowModesModel}
          // slotProps={{
          //   toolbar: { rows, setRowModesModel },
          // }}
        />
      </Box>
    </Modal>
  );
}

export default ModalEditCondition;
