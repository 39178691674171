import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  TablePagination,
  Paper,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

function TableHistory({
  dataImportHistory,
  rowCount,
  page,
  pageSize,
  handlePageChange,
  handlePageSizeChange,
  accessToken,
}) {
  const handleExportError = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/process_queue/export/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Audit Plan Error.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        const data = await response.json();
        alert(data.detail);
      }
    } catch (error) {
      console.error("Error exporting audit plan error:", error);
    }
  };

  return (
    <Paper
      sx={{
        mt: 2,
        ml: 2,
        mr: 2,
        mb: 2,
        border: 2,
        borderColor: "rgb(234,243,248)",
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "rgb(6,157,174)" }}>
              <TableCell
                align="center"
                style={{ color: "#FFF", fontWeight: "bold" }}
              >
                ID
              </TableCell>
              <TableCell
                align="center"
                style={{ color: "#FFF", fontWeight: "bold" }}
              >
                Audit Plan Title
              </TableCell>
              <TableCell
                align="center"
                style={{ color: "#FFF", fontWeight: "bold" }}
              >
                Status
              </TableCell>
              <TableCell
                align="center"
                style={{ color: "#FFF", fontWeight: "bold" }}
              >
                Description
              </TableCell>
              <TableCell
                align="center"
                style={{ color: "#FFF", fontWeight: "bold" }}
              >
                Created Time
              </TableCell>
              <TableCell
                align="center"
                style={{ color: "#FFF", fontWeight: "bold" }}
              >
                Completed Time
              </TableCell>
              {/* <TableCell
                align="center"
                style={{ color: "#FFF", fontWeight: "bold" }}
              >
                Process At
              </TableCell> */}
              <TableCell
                align="center"
                style={{ color: "#FFF", fontWeight: "bold" }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataImportHistory.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">{row.id}</TableCell>
                <TableCell align="center">{row.title}</TableCell>
                <TableCell align="center">
                  <div
                    style={{
                      backgroundColor:
                        row.status === "done"
                          ? "green"
                          : row.status === "failed"
                          ? "red"
                          : row.status === "progress"
                          ? "orange"
                          : row.status === "open"
                          ? "blue"
                          : row.status === "ready_import"
                          ? "purple"
                          : "inherit",
                      color: "#FFF",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      display: "inline-block",
                    }}
                  >
                    {row.status}
                  </div>
                </TableCell>
                <TableCell align="center">{row.description}</TableCell>
                <TableCell align="center">{row.created_at}</TableCell>
                <TableCell align="center">{row.updated_at}</TableCell>
                {/* <TableCell align="center">{row.process_at}</TableCell> */}
                <TableCell align="center">
                  {row.status === "failed" && (
                    <Tooltip title="Download Excel Error">
                      <IconButton onClick={() => handleExportError(row.id)}>
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rowCount}
        page={page}
        onPageChange={(event, newPage) => handlePageChange(newPage)}
        rowsPerPage={pageSize}
        onRowsPerPageChange={(event) =>
          handlePageSizeChange(parseInt(event.target.value, 10))
        }
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Paper>
  );
}

export default TableHistory;
