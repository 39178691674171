import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import logo from "../assets/images/bg/logo.png";
import { BootstrapButton } from './Button/BootstrapButton';
import Copyright from './Copyright';

function Landing() {
  return (
    <Container maxWidth="lg"> {/* Increased the maxWidth for overall container */}
      <Box style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}> {/* Centering the logo within its grid */}
            <img src={logo} alt="logo" style={{ width: 'auto', height: 'auto', maxWidth: '80%' }} />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <Typography variant="subtitle2" component="div" sx={{ color: '#A09696' }}>
              DATA COLLECTION SYSTEM FOR RDC AND RA SURVEY
            </Typography>
            <Typography variant="h4" component="div" gutterBottom sx={{ mt: '10px', fontWeight: '700', fontSize: '40px' }}>
              Application For <span style={{ color: '#0190A0' }}>Retail Audit and Observation Survey.</span>
            </Typography>
            <BootstrapButton
              variant="contained"
              disableRipple
              href='/login'
              className="button-process"
              sx={{ px: '40px', py: '5px' }}
            >
              Sign In
            </BootstrapButton>

            {/* guide */}
            {/* <BootstrapButton
              variant="contained"
              disableRipple
              href='"https://sites.google.com/starcore.co/sivina/home'
              target="_blank"
              className="button-process"
              sx={{ px: '40px', py: '5px' }}
            >
             User Guide
            </BootstrapButton> */}
          </Grid>
        </Grid>
        <Copyright />
      </Box>
    </Container>
  );
}

export default Landing;

