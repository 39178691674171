import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import { fetchProfile } from "../../utils/fetchProfile";
import { useDispatch, useSelector } from "react-redux";
import { Slide, toast } from "react-toastify";
import Cookies from "js-cookie";
import {
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";

import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import {
  TableCompleteness,
  TableCompletenessStoreGT,
  TableCompletenessStoreMT,
} from "../../Components/Fragments/Tables/TableDataProcessing/TableCompleteness";
import {
  fetchPackSize,
  fetchPackType,
  fetchFlavour,
} from "../ProductMasterTwo/FilterService";
import {
  fetchDistrict,
  fetchProvince,
  fetchRegency,
  fetchSubDistrict,
} from "../../services/areaNewService";
import {
  fetchStoreMaster,
  fetchStoreMasterById,
} from "../../services/storeMasterService";
import { ModalEditStoreMaster } from "../../Components/Fragments/Modals/ModalStoreMaster";

const style = {
  p: 4,
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

export const CompletenessProduct = () => {
  const accessToken = Cookies.get("access_token") || "";
  const [unitVolume, setUnitVolume] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [dataProfile, setDataProfile] = useState({});
  const [projectType, setProjectType] = useState(null);
  const [dataCategoryMaster, setDataCategoryMaster] = useState([]);
  const [dataBrandMaster, setDataBrandMaster] = useState([]);
  const [dataPackType, setDataPackType] = useState([]);
  const [dataPackSize, setDataPackSize] = useState([]);
  const [dataUnitVol, setDataUnitVol] = useState([]);
  const [dataStockUnit, setDataStockUnit] = useState([]);
  const [dataPrincipalFilter, setDataPrincipalFilter] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [dataPrincipalMaster, setDataPrincipalMaster] = useState();

  const selectedProductGroupId = useSelector(
    (state) => state.productMaster.selectedProductGroupId
  );
  const selectedCategory = useSelector(
    (state) => state.productMaster.selectedCategory
  );
  const selectedPrincipal = useSelector(
    (state) => state.productMaster.selectedPrincipal
  );
  const selectedBrand = useSelector(
    (state) => state.productMaster.selectedBrand
  );

  const [selectedFilteredCategory, setSelectedFilteredCategory] = useState("");
  const [filteredPackSize, setFilteredPackSize] = useState([]);
  const [filteredPackType, setFilteredPackType] = useState([]);
  const [filteredFlavour, setFilteredFlavour] = useState([]);
  const [storeNameOptions2, setStoreNameOptions2] = useState([]);

  const [editedStock, setEditedStock] = useState([]);
  const [selectedOldStock, setSelectedOldStock] = useState([]);
  const [editedGroup, setEditedGroup] = useState([]);
  const [selectedOldGroup, setSelectedOldGroup] = useState([]);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedProduct, setEditedProduct] = useState({});
  const [editProductId, setEditProductId] = useState(null);

  const stockUnitDescriptionToIdMap = {};
  dataStockUnit.forEach((item) => {
    stockUnitDescriptionToIdMap[item.description] = item.id;
  });

  const ProductGroupLabelToValueMap = {};
  storeNameOptions2.forEach((item) => {
    ProductGroupLabelToValueMap[item.label] = item.value;
  });

  function findNewAndOldItems(newArray, oldArray) {
    const sortedNewArray = newArray.slice().sort();
    const sortedOldArray = oldArray.slice().sort();
    return { sortedNewArray, sortedOldArray };
  }

  function findAddedAndRemovedItemGroups(newArray, oldArray) {
    const addedItemGroups = newArray.filter((item) => !oldArray.includes(item));
    const removedItemGroups = oldArray.filter(
      (item) => !newArray.includes(item)
    );
    return { addedItemGroups, removedItemGroups };
  }

  const onCompletenessStore = () => {
    const path = `/data-processing/completeness-check-store/${projectType}`;
    navigate(path);
  };

  const handleEditProduct = async (id) => {
    setEditedProduct(await fetchProductMasterSpecific(id));
    setEditProductId(id);
    setEditModalOpen(true);
  };

  const handleStockUnitChange = (event) => {
    const {
      target: { value },
    } = event;
    setEditedStock(typeof value === "string" ? value.split(",") : value);
  };

  const handleGroupChange = (event) => {
    const {
      target: { value },
    } = event;
    setEditedGroup(typeof value === "string" ? value.split(",") : value);
  };

  const categorySelector = (event) => {
    if (event && event.target) {
      const selectedCategory = event.target.value;
      setEditedProduct({
        ...editedProduct,
        category_master_id: selectedCategory,
      });
      setSelectedFilteredCategory(selectedCategory);
    } else {
      console.error("Something went wrong");
    }
  };

  const handleUpdateProduct = async () => {
    if (editProductId) {
      const { sortedNewArray, sortedOldArray } = findNewAndOldItems(
        editedStock,
        selectedOldStock
      );
      if (sortedOldArray.length > 0) {
        try {
          const formData = new FormData();

          const stockUnitIds = sortedOldArray.map(
            (description) => stockUnitDescriptionToIdMap[description]
          );
          formData.append("stock_unit_ids", stockUnitIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/stock-unit/unassign`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              body: formData,
            }
          );

          if (!response.ok) {
            alert("Stock gagal diubah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }

      if (sortedNewArray.length > 0) {
        try {
          const formData = new FormData();

          const stockUnitIds = sortedNewArray.map(
            (description) => stockUnitDescriptionToIdMap[description]
          );
          formData.append("stock_unit_ids", stockUnitIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/stock-unit/assign`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              body: formData,
            }
          );

          if (response.ok) {
            alert("Stock berhasil diubah!");
            setEditModalOpen(false);
          } else {
            alert("Stock gagal diubah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }

      const { addedItemGroups, removedItemGroups } =
        findAddedAndRemovedItemGroups(editedGroup, selectedOldGroup);
      if (addedItemGroups.length > 0) {
        try {
          const formData = new FormData();

          const groupIds = addedItemGroups.map(
            (label) => ProductGroupLabelToValueMap[label]
          );
          formData.append("product_group_ids", groupIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/group/assign`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              body: formData,
            }
          );

          if (response.ok) {
            alert("Group berhasil ditambah!");
          } else {
            alert("Group gagal ditambah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
      if (removedItemGroups.length > 0) {
        try {
          const formData = new FormData();

          const groupIds = removedItemGroups.map(
            (label) => ProductGroupLabelToValueMap[label]
          );
          formData.append("product_group_ids", groupIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/group/unassign`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              body: formData,
            }
          );

          if (response.ok) {
            alert("Group berhasil diubah!");
            setEditModalOpen(false);
          } else {
            alert("Group gagal diubah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedProduct),
          }
        );

        const message = await response.json();

        if (response.ok) {
          alert("Produk berhasil diubah!");
          setEditedProduct(await fetchProductMasterSpecific(editProductId));
          window.location.reload();
        } else if (response.status === 404) {
          alert(message.detail);
        } else if (response.status === 400) {
          alert(message.detail);
        } else {
          alert("Produk gagal diubah!");
        }
      } catch (error) {
        console.error("Error:", error);
      }
      window.location.reload();
    }
  };

  async function fetchProductGroup() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-group-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const { data } = await response.json();
        setProductGroups(data);
        const transformedData = data.map((group) => ({
          value: group.id,
          label: group.name,
        }));
        setStoreNameOptions2(transformedData);
      } else {
        throw new Error("Failed to fetch product groups");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchBrandMaster() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/brand-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataBrandMaster(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/stock-unit`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataStockUnit(data.data);
        setDataPackType(data.data_pack_type);
        setDataPackSize(data.data_pack_size);
        setDataUnitVol(data.data_unit_vol);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  async function fetchCategoryMaster() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/category-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataCategoryMaster(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  async function fetchPrincipalMaster() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/principal-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataPrincipalMaster(data.data);
        setDataPrincipalFilter(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  async function fetchProductMasterSpecific(id) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-master/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const { data } = await response.json();
        setEditedStock(data.product_stock_unit);
        setSelectedOldStock(data.product_stock_unit);
        setEditedGroup(data.product_group);
        setSelectedOldGroup(data.product_group);
        return data;
      } else {
        throw new Error("Failed to fetch product master");
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (id) {
      setProjectType(id);
    }
  }, [id]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSelectedFilteredCategory(editedProduct.category_master_id);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [editedProduct.category_master_id]);

  useEffect(() => {
    if (editedProduct && dataCategoryMaster) {
      const selectedCategory = dataCategoryMaster.find(
        (option) => option.id === editedProduct.category_master_id
      );
      if (selectedCategory) {
        setUnitVolume(selectedCategory.unitvolume || "");
      } else {
        setUnitVolume("");
      }
    }
  }, [editedProduct, dataCategoryMaster]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchCategoryMaster();
      fetchPrincipalMaster();
      fetchBrandMaster();
      fetchProductGroup();
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchPackSize(accessToken, setFilteredPackSize, selectedFilteredCategory);
      fetchPackType(accessToken, setFilteredPackType, selectedFilteredCategory);
      fetchFlavour(accessToken, setFilteredFlavour, selectedFilteredCategory);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [selectedFilteredCategory]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(accessToken, setDataProfile);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  return (
    <div>
      <Navbar
        active="Data Processing"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <Box sx={style}>
        <Grid item sx={{ float: "left", mb: 3 }}>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ flexGrow: 1 }}
          >
            List Completeness Product Master
          </Typography>
        </Grid>
        <Grid item sx={{ float: "right", mb: 3 }}>
          <Button
            className="button-pros"
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            href="/setup-product-master"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Setup Product Master"}
          </Button>
          <Button
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            onClick={onCompletenessStore}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Go To Store Master"}
          </Button>
        </Grid>
        <TableCompleteness onEdit={handleEditProduct} />
      </Box>
      <Modal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setUnitVolume("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: 0 }}
      >
        <Box sx={{ ...styleModal, width: "700px" }}>
          {/* Content in the edit modal */}
          <div
            style={{
              maxHeight: "600px",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            <h2>Edit Product</h2>
            <TextField
              label="Product Name"
              sx={{ width: "100%" }}
              value={editedProduct.product_name}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  product_name: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Barcode"
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.barcode}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  barcode: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Category Master"
              select
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.category_master_id}
              onChange={categorySelector}
            >
              {dataCategoryMaster?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Brand Master"
              select
              sx={{ width: "100%", mb: 1 }}
              value={editedProduct.brand_master_id}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  brand_master_id: e.target.value,
                })
              }
            >
              {dataBrandMaster?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Principal Master"
              select
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.principal_master_id}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  principal_master_id: e.target.value,
                })
              }
            >
              {dataPrincipalMaster?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Pack Size"
              select
              value={editedProduct.packsize}
              sx={{ width: "100%", mb: 2 }}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  packsize: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              variant="outlined"
            >
              {filteredPackSize?.map((option) => (
                <MenuItem
                  key={option.atribut_value}
                  value={option.atribut_value}
                >
                  {option.atribut_value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Pack Type"
              select
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.packtype}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  packtype: e.target.value,
                })
              }
            >
              {filteredPackType?.map((option) => (
                <MenuItem
                  key={option.atribut_value}
                  value={option.atribut_value}
                >
                  {option.atribut_value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Flavour"
              select
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.flavour}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  flavour: e.target.value,
                })
              }
            >
              {filteredFlavour?.map((option) => (
                <MenuItem
                  key={option.atribut_value}
                  value={option.atribut_value}
                >
                  {option.atribut_value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label={`Converted Volume${unitVolume ? ` (${unitVolume})` : ""}`}
              type="number"
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.convertedvol}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  convertedvol: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Control Price"
              type="number"
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.ctrl_price}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  ctrl_price: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <FormControl sx={{ width: "100%", mb: 2 }}>
              <InputLabel id="demo-multiple-name-label">Stok Unit</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={editedStock}
                onChange={handleStockUnitChange}
                input={<OutlinedInput label="Stok Unit" />}
              >
                {dataStockUnit.map((item, index) => (
                  <MenuItem key={index} value={item.description}>
                    {item.code} - {item.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "100%", mb: 2 }}>
              <InputLabel id="demo-multiple-name-label-group">Group</InputLabel>
              <Select
                labelId="demo-multiple-name-label-group"
                id="demo-multiple-name-group"
                multiple
                value={editedGroup}
                onChange={handleGroupChange}
                input={<OutlinedInput label="Group" />}
              >
                {storeNameOptions2.map((item) => (
                  <MenuItem key={item.label} value={item.label}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="outlined"
                disableRipple
                sx={{
                  width: 180,
                  height: 50,
                  mt: 2,
                  color: "#b34469",
                  borderColor: "#b34469",
                }}
                onClick={() => {
                  setEditModalOpen(false);
                  setUnitVolume("");
                }}
              >
                Cancel
              </Button>
              <BootstrapButton
                variant="contained"
                disableRipple
                sx={{ width: 190, height: 50, mt: 2, ml: 0.3 }}
                onClick={handleUpdateProduct}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "UPDATE"}
              </BootstrapButton>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export const CompletenessStoreGT = () => {
  const accessToken = Cookies.get("access_token") || "";
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [projectType, setProjectType] = useState(null);
  const [dataProfile, setDataProfile] = useState({});
  const [formData, setFormData] = useState({
    id: "",
    store_id: "",
    store_name: "",
    store_type_code: "",
    store_address: "",
    store_latitude: "",
    store_longitude: "",
    province_code: "",
    regency_code: "",
    district_code: "",
    sub_district_code: "",
    jenis_outlet: "",
    tipe_survey: "",
    tipe_jalan: "",
    tipe_lokasi: "",
  });
  const [openDialogEdit, setOpenDialogEdit] = useState(false);

  const [dataStoreType, setDataStoreType] = useState([]);
  const [dataJenisOutlet, setDataJenisOutlet] = useState([]);
  const [dataTipeSurvey, setDataTipeSurvey] = useState([]);
  const [dataTipeJalan, setDataTipeJalan] = useState([]);
  const [dataTipeLokasi, setDataTipeLokasi] = useState([]);
  const [dataRegency, setDataRegency] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubDistrict, setDataSubDistrict] = useState([]);

  const currentPage = useSelector((state) => state.storeMaster.currentPage);
  const itemsPerPage = useSelector((state) => state.storeMaster.itemsPerPage);

  const dataProvince = useSelector((state) => state.province.data);
  const selectedStatusId = useSelector(
    (state) => state.storeMaster.selectedStatusId
  );
  const selectedTipeSurvey = useSelector(
    (state) => state.storeMaster.selectedTipeSurvey
  );
  const selectedProvinceId = useSelector(
    (state) => state.storeMaster.selectedProvinceId
  );
  const selectedRegencyId = useSelector(
    (state) => state.storeMaster.selectedRegencyId
  );
  const selectedDistrictId = useSelector(
    (state) => state.storeMaster.selectedDistrictId
  );
  const selectedSubDistrictId = useSelector(
    (state) => state.storeMaster.selectedSubDistrictId
  );
  const filterColumnName = useSelector(
    (state) => state.storeMaster.filterColumnName
  );
  const filterColumnOperator = useSelector(
    (state) => state.storeMaster.filterColumnOperator
  );
  const filterColumnValue = useSelector(
    (state) => state.storeMaster.filterColumnValue
  );

  const onCompletenessProduct = () => {
    navigate(`/data-processing/completeness-check-product/${projectType}`);
  };

  const handleEditStore = async (id) => {
    const detail = await fetchStoreMasterById(accessToken, id);
    setDataRegency(
      await fetchRegency(accessToken, {
        province_code: detail.province_code,
      })
    );
    setDataDistrict(
      await fetchDistrict(accessToken, {
        regency_code: detail.regency_code,
      })
    );
    setDataSubDistrict(
      await fetchSubDistrict(accessToken, {
        district_code: detail.district_code,
      })
    );
    setFormData({
      id: detail.id,
      store_id: detail.store_id,
      store_name: detail.store_name,
      store_type_code: detail.store_type_code,
      store_address: detail.store_address ? detail.store_address : "",
      store_latitude: detail.store_latitude ? detail.store_latitude : 0,
      store_longitude: detail.store_longitude ? detail.store_longitude : 0,
      province_code: detail.province_code,
      regency_code: detail.regency_code,
      district_code: detail.district_code,
      sub_district_code: detail.sub_district_code,
      jenis_outlet: detail.jenis_outlet,
      tipe_survey: detail.tipe_survey,
      tipe_jalan: detail.tipe_jalan,
      tipe_lokasi: detail.tipe_lokasi,
    });
    setOpenDialogEdit(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogEdit(false);
    setFormData({
      id: "",
      store_id: "",
      store_name: "",
      store_type_code: "",
      store_address: "",
      store_latitude: "",
      store_longitude: "",
      province_code: "",
      regency_code: "",
      district_code: "",
      sub_district_code: "",
      jenis_outlet: "",
      tipe_survey: "",
      tipe_jalan: "",
      tipe_lokasi: "",
    });
    setDataRegency([]);
    setDataDistrict([]);
    setDataSubDistrict([]);
  };

  const handleChangeStoreMaster = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  async function updateStoreMaster() {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/store-master/${formData.id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.status === 200) {
        toast.success("Store berhasil diubah!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        // fetchStoreMaster(dispatch, accessToken, customParams);
        // setFormData({
        //   id: "",
        //   store_id: "",
        //   store_name: "",
        //   store_type_code: "",
        //   store_address: "",
        //   store_latitude: "",
        //   store_longitude: "",
        //   province_code: "",
        //   regency_code: "",
        //   district_code: "",
        //   sub_district_code: "",
        //   jenis_outlet: "",
        //   tipe_survey: "",
        //   tipe_jalan: "",
        //   tipe_lokasi: "",
        // });
        // setDataRegency([]);
        // setDataDistrict([]);
        // setDataSubDistrict([]);
        // setOpenDialogEdit(false);
        window.location.reload();
      } else {
        toast.error("Gagal mengubah store!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error("Failed to update store:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing store:", error);
    }
    setIsLoading(false);
  }

  async function fetchStoreType() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/store-type`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setDataStoreType(data.data);
      } else {
        setDataStoreType([]);
      }
    } catch (error) {
      console.error("Error editing store:", error);
      setDataStoreType([]);
    }
  }

  async function fetchJenisOutlet() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/jenis-outlet`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setDataJenisOutlet(data.data);
      } else {
        setDataJenisOutlet([]);
      }
    } catch (error) {
      console.error("Error editing store:", error);
      setDataJenisOutlet([]);
    }
  }

  async function fetchTipeSurvey() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/tipe-survey`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setDataTipeSurvey(data.data);
      } else {
        setDataTipeSurvey([]);
      }
    } catch (error) {
      console.error("Error editing store:", error);
      setDataTipeSurvey([]);
    }
  }

  async function fetchTipeJalan() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/tipe-jalan`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setDataTipeJalan(data.data);
      } else {
        setDataTipeJalan([]);
      }
    } catch (error) {
      console.error("Error editing store:", error);
      setDataTipeJalan([]);
    }
  }

  async function fetchTipeLokasi() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/tipe-lokasi`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setDataTipeLokasi(data.data);
      } else {
        setDataTipeLokasi([]);
      }
    } catch (error) {
      console.error("Error editing store:", error);
      setDataTipeSurvey([]);
    }
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    updateStoreMaster();
  };

  const customParams = {
    size: itemsPerPage,
    page: currentPage,
    store_status: selectedStatusId,
    tipe_survey: selectedTipeSurvey,
    province_id: selectedProvinceId,
    regency_id: selectedRegencyId,
    district_id: selectedDistrictId,
    sub_district_id: selectedSubDistrictId,
    column_name: filterColumnName,
    column_operator: filterColumnOperator,
    column_value: filterColumnValue,
  };

  const handleChangeProvince = async (e) => {
    setFormData({
      ...formData,
      province_code: e.target.value,
      regency_code: "",
    });
    setDataRegency(
      await fetchRegency(accessToken, {
        province_code: e.target.value,
      })
    );
  };

  const handleChangeRegency = async (e) => {
    setFormData({
      ...formData,
      regency_code: e.target.value,
      district_code: "",
    });
    setDataDistrict(
      await fetchDistrict(accessToken, {
        regency_code: e.target.value,
      })
    );
  };

  const handleChangeDistrict = async (e) => {
    setFormData({
      ...formData,
      district_code: e.target.value,
      sub_district_code: "",
    });
    setDataSubDistrict(
      await fetchSubDistrict(accessToken, {
        district_code: e.target.value,
      })
    );
  };

  useEffect(() => {
    if (id) {
      setProjectType(id);
    }
  }, [id]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(accessToken, setDataProfile);
      fetchProvince(dispatch, accessToken);
      fetchJenisOutlet(accessToken);
      fetchTipeSurvey(accessToken);
      fetchTipeJalan(accessToken);
      fetchTipeLokasi(accessToken);
      fetchStoreType(accessToken);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);
  return (
    <div>
      <Navbar
        active="Data Processing"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <Box sx={style}>
        <Grid item sx={{ float: "left", mb: 3 }}>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ flexGrow: 1 }}
          >
            List Completeness Store Master
          </Typography>
        </Grid>
        <Grid item sx={{ float: "right", mb: 3 }}>
          <Button
            className="button-pros"
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            href="/setup-product-master"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Setup Product Master"}
          </Button>
          <Button
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            onClick={onCompletenessProduct}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Go To Product Master"}
          </Button>
        </Grid>
        <TableCompletenessStoreGT onEdit={handleEditStore} />
      </Box>
      <ModalEditStoreMaster
        open={openDialogEdit}
        onClose={handleCloseDialog}
        title={"Edit Store"}
        buttonTitle={"Update"}
        formData={formData}
        handleSubmit={handleUpdate}
        handleChange={{
          general: handleChangeStoreMaster,
          province: handleChangeProvince,
          regency: handleChangeRegency,
          district: handleChangeDistrict,
        }}
        isLoading={isLoading}
        items={{
          dataStoreType: dataStoreType,
          dataProvince: dataProvince,
          dataRegency: dataRegency,
          dataDistrict: dataDistrict,
          dataSubDistrict: dataSubDistrict,
          dataJenisOutlet: dataJenisOutlet,
          dataTipeSurvey: dataTipeSurvey,
          dataTipeJalan: dataTipeJalan,
          dataTipeLokasi: dataTipeLokasi,
        }}
      />
    </div>
  );
};
