import Cookies from "js-cookie";
import { React, useState, useEffect } from "react";
import { fetchProfile } from "../../../utils/fetchProfile";
import { useParams } from "react-router-dom";
import Navbar from "../../../Components/Navbar";
import {
  Box,
  Modal,
  MenuItem,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import "../../../style/customquestion.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Slide, toast } from "react-toastify";
import ModalDeleteAnswerItem from "./ModalDeleteAnswerItem";
import ModalCreateAnswerItem from "./ModalCreateAnswerItem";
import ModalUpdateAnswerItem from "./ModalUpdateAnswerItem";
import ModalViewAnswerItem from "./ModalViewAnswerItem";
import ModalDeleteQuestion from "./ModalDeleteQuestion";
import ModalUpdateVersion from "./ModalUpdateVersion";
import ModalEditCondition from "./ModalEditCondition";
import { modalStyle } from "../../../Components/Modal/Index";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DataGridListQuestion from "./DataGridListQuestion";
import DataGridCondition from "./DataGridCondition";
import ModalCreateAnswer from "./ModalCreateAnswer";
import ModalDeleteCondition from "./ModalDeleteCondition";
import CloseIcon from "@mui/icons-material/Close";
import { dataOperatorAll } from "../../../utils/constant";
import DataGridRetailDistribusi from "./DataGridRetailDistribusi";

// custom css for modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};
const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  height: "31px",
  backgroundColor: "#069DAE",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#069DAD",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#069DAA",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

function ProjectCustomQuestion() {
  const accessToken = Cookies.get("access_token") || "";
  const [isLoading, setIsLoading] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);
  const [project, setProject] = useState([]);
  const [version, setVersion] = useState("");
  const [retailDistribusi, setRetailDistribusi] = useState([]);
  const [projectCustom, setProjectCustom] = useState([]);
  const [projectCustomById, setProjectCustomById] = useState({});
  const [viewAnswerItem, setViewAnswerItem] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [surveyId, setSurveyId] = useState("");
  const [condition, setCondition] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [answerItem, setAnswerItem] = useState([]);
  const [type, setType] = useState([]);
  const { projectId } = useParams();
  //const untuk mendapatkan answerItems
  const [answerId, setAnswerId] = useState(""); //function to get ID answer
  //function modal create answer
  const [modalCreateAnswer, setModalCreateAnswer] = useState(false);
  //modal update answer
  const [modalUpdateAnswer, setModalUpdateAnswer] = useState(false);
  //modal view answer item
  const [modalviewAnswerItem, setModalviewAnswerItem] = useState(false);
  //open create question box
  const [isCreatingQuestion, setIsCreatingQuestion] = useState(false);
  //function open modal update versi
  const [isUpdateVersion, setIsUpdateVersion] = useState(false);
  const [updateVersionLog, setUpdateVersionLog] = useState("");
  //modal create answer item
  const [isCreateAnswerItem, setIsCreateAnswerItem] = useState(false);
  //modal update answer item
  const [isUpdateAnswerItem, setIsUpdateAnswerItem] = useState(false);
  //modal create condition
  const [isCreateCondition, setIsCreateCondition] = useState(false);
  //update answer name
  const [editAnswer, setEditAnswer] = useState(null);
  const [editedAnswerId, setEditedAnswerId] = useState({
    answer_name: "",
  });
  //function to change the answer in the question
  const [editedProjectQuestionAnswer, setEditedProjectQuestionAnswer] =
    useState(null);
  const [editQuestionAnswer, setEditQuestionAnswer] = useState({
    answer_id: "",
  });
  //function to change the Condition in the question
  const [editQuestionCondition, setEditQuestionCondition] = useState({
    condition_id: "",
  });
  //function to change the Condition in the question
  const [editQuestionMandatory, setEditQuestionMandatory] = useState({
    is_mandatory: "",
  });
  //function update question
  const [editTextQuestionId, setEditTextQuestionId] = useState(null);
  const [editedtextquestion, setEditedtextquestion] = useState({
    question: "",
  });
  //function to filter the data displayed when creating a question
  const [isNumeric, setIsNumeric] = useState(false); //displays numeric
  const [isAnswer, setIsAnswer] = useState(false); //displayer answer

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalQuestion, setDeleteModalQuestion] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState(null);

  const [deleteModalAnswer, setDeleteModalAnswer] = useState(null);
  const [modaldeleteModalAnswer, setModaldeleteModalAnswer] = useState(false);

  const [isConditionNeedAnswerItem, setIsConditionNeedAnswerItem] =
    useState(false);
  const [isConditionShowValue, setIsConditionShowValue] = useState(false);

  const [customListByCondition, setcustomListByCondition] = useState([]);
  const [answerItemBYCondition, setAnswerItemBYCondition] = useState([]); //view answer Item To Condition
  const [aidBycondition, setaidBycondition] = useState(""); //untuk mrndapatkan answerID

  const [createconditionItem, setcreateconditionItem] = useState({
    survey_id: surveyId,
    condition_id: "",
    question_id: "",
    condition_item_order: "",
    operator: "",
    value: [],
  });

  // toast.success(projectCustomById.type, {
  //   position: "top-right",
  //   autoClose: 5000,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined,
  //   theme: "light",
  //   transition: Slide,
  // });

  //create question
  const [newQuestion, setNewQuestion] = useState({
    title: "",
    question: "",
    type: "",
    is_mandatory: "",
    text_question_show_type: "",
    survey_id: surveyId,
    aid: "",
    cid: "",
    numeric_min_value: 0,
    numeric_max_value: 1,
    numeric_decimal_places: 0,
    numeric_prefix: "",
    numeric_sufix: "",
  });

  const [dataOperator, setDataOperator] = useState(dataOperatorAll);

  const fetchProject = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project/${projectId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setProject(data.data);
        setSurveyId(data.data.survey_id);
      } else {
        console.error("Failed to fetch Project detail:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchRetailDistribusi = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/questions/${surveyId}/group/sku_retail_distribution`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setRetailDistribusi(data.data);
        // setVersion(data.pagination_info.questionnaire_version);
      } else {
        console.error("Failed to fetch Project detail:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchProjectcustom = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/questions/${surveyId}/group/sku_retail`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setProjectCustom(data.data);
        setVersion(data.pagination_info.questionnaire_version);
      } else {
        console.error("Failed to fetch Project detail:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //untuk menampilkan list question saat create condition
  const fetchProjectcustomByCreateCondition = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/questions/${surveyId}?type=MUL_0%2C%20SIN_0%2C%20NUM_0`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setcustomListByCondition(data.data);
      } else {
        console.error("Failed to fetch Project detail:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAnswerItemByCondition = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/answer_items/${aidBycondition}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setAnswerItemBYCondition(data.data);
      } else {
        console.error("Failed to fetch Project detail:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // create answer
  const handleCreateConditionItem = async (condition_id) => {
    setIsLoading(true);

    let finalValue = createconditionItem.value;
    if (Array.isArray(finalValue)) {
      if (finalValue.length > 0) {
        finalValue = finalValue.join(",");
      }
    }

    const requestBody = {
      survey_id: createconditionItem.survey_id,
      condition_id: condition_id,
      question_id: createconditionItem.question_id,
      condition_item_order: 0,
      operator: createconditionItem.operator,
      value: finalValue,
    };

    try {
      const token = Cookies.get("access_token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/condition_item`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status === 200) {
        toast.success("Condition Item Created successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 400) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error("Failed to Create", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      }
    } catch (error) {
      console.error("Error creating Condition Item", error);
    }
    setIsLoading(false);
  };

  const handleInputConditionItem = (event) => {
    const { name, value } = event.target;
    setcreateconditionItem({
      ...createconditionItem,
      [name]: value,
    });
  };

  const handleInputConditionItemQuestion = (event) => {
    const { name, value } = event.target;
    setcreateconditionItem({
      ...createconditionItem,
      [name]: value,
      value: [],
    });

    const selectedQuestion = customListByCondition.find(
      (item) => item.id == value
    );

    if (selectedQuestion) {
      const question_type = selectedQuestion.type;

      if (question_type == "NUM_0") {
        setIsConditionShowValue(true); // value
        setIsConditionNeedAnswerItem(false); // answer item
      } else if (question_type == "SIN_0" || question_type == "MUL_0") {
        setIsConditionNeedAnswerItem(true); // answer item
        setIsConditionShowValue(false); // value
      }

      const filteredDataOperator = dataOperatorAll.filter((item) => {
        if (Array.isArray(item.question_type)) {
          return item.question_type.some((type) =>
            question_type.includes(type)
          );
        } else {
          return question_type.includes(item.question_type);
        }
      });
      setDataOperator(filteredDataOperator);
    }
  };

  const handleInputConditionItemMultiple = (event) => {
    const {
      target: { name, value },
    } = event;
    const finalValue = typeof value === "string" ? value.split(",") : value;
    setcreateconditionItem({
      ...createconditionItem,
      [name]: finalValue,
    });
  };

  const handleCheckboxToggle = (optionCode) => () => {
    const currentIndex = createconditionItem.value.indexOf(optionCode);
    const newChecked = [...createconditionItem.value];

    if (currentIndex === -1) {
      newChecked.push(optionCode);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setcreateconditionItem({
      ...createconditionItem,
      value: newChecked,
    });
  };

  //function untuk mendapatkan id answer saat create condition
  const fetchitemcondition = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/question/${createconditionItem.question_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setaidBycondition(data.data.aid);
      } else {
        console.error("Failed to fetch Project detail:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleupdatequestionanswer = async () => {
    setIsLoading(true);
    if (editedProjectQuestionAnswer) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/question-answer/${editedProjectQuestionAnswer}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editQuestionAnswer),
          }
        );
        if (response.status === 200) {
          toast.success("Answer update successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
          await fetchProjectcustombyId();
        } else {
          toast.error("Failed to Update Answer", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleupdatequestionCondition = async () => {
    setIsLoading(true);
    if (editedProjectQuestionAnswer) {
      const token = Cookies.get("access_token");

      if (editQuestionCondition.condition_id === "all-respondent") {
        editQuestionCondition.condition_id = "";
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/question-condition/${editedProjectQuestionAnswer}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editQuestionCondition),
          }
        );

        if (response.status === 200) {
          toast.success("Condition update successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
          await fetchProjectcustombyId();
        } else {
          toast.error("Failed to Update Condition", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleUpdateMandatory = async () => {
    setIsLoading(true);
    if (editedProjectQuestionAnswer) {
      const token = Cookies.get("access_token");

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/question/${editedProjectQuestionAnswer}/is_mandatory`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editQuestionMandatory),
          }
        );

        if (response.status === 200) {
          toast.success("Condition update successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
          await fetchProjectcustombyId();
        } else if (response.status === 404) {
          const message = await response.json();
          toast.error(message.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        } else if (response.status === 400) {
          const message = await response.json();
          toast.error(message.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        } else {
          const message = await response.json();
          toast.error(message.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const fetchProjectcustombyId = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/question/${selectedItem}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setEditQuestionMandatory({
        ...editQuestionMandatory,
        is_mandatory: "",
      });

      if (response.status === 200) {
        const data = await response.json();
        setProjectCustomById(data.data);
        setEditAnswer(data.data.aid);
        setAnswerId(data.data.aid);
        setEditQuestionAnswer(data.data);
        setEditQuestionCondition(data.data);
      } else {
        console.error(
          "Failed to fetch Project Custom detail:",
          response.statusText
        );
        setAnswerId("");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const fetchProjectconditions = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/conditions/${surveyId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setCondition(data.data);
      } else {
        console.error("Failed to fetch Condition detail:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const fetchProjectAnswer = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/answers/${surveyId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        const newArray = data.data.map((item) => ({
          answer_name: item.answer_name,
          id: item.id,
        }));
        setAnswer(newArray);
        if (editAnswer) {
          const editedAnswer = newArray.find((item) => item.id === editAnswer);
          if (editedAnswer) {
            setEditedAnswerId(editedAnswer);
          } else {
            setEditedAnswerId({ answer_name: "" });
          }
        } else {
          setEditedAnswerId({ answer_name: "" });
        }
      } else {
        console.error("Failed to fetch Project detail:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const fetchProjectType = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/question_type`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setType(data.data);
      } else {
        console.error("Failed to fetch Project detail:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const fetchAnswerItemsByCreate = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/answer_items/${answerId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setAnswerItem(data.data);
      } else {
        console.error("Failed to fetch Project detail:", response.statusText);
        setAnswerItem();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const fetchAnswerItemsByview = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/answer_items/${projectCustomById.aid}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setViewAnswerItem(data.data);
      } else {
        console.error("Failed to fetch Project detail:", response.statusText);
        setViewAnswerItem();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const handleUpdatetextquestion = async () => {
    setIsLoading(true);
    if (editTextQuestionId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/question/${editTextQuestionId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedtextquestion),
          }
        );
        if (response.status === 200) {
          toast.success("Question update successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
          await fetchProjectcustom();
        } else if (response.status === 400) {
          const data = await response.json();
          toast.error(data.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        } else {
          const data = await response.json();
          toast.error(data.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleUpadateAnswer = async () => {
    setIsLoading(true);
    if (editAnswer) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/answer/${editAnswer}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedAnswerId),
          }
        );
        if (response.status === 200) {
          toast.success("Answer update successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
          await fetchProjectAnswer();
          setModalUpdateAnswer(false);
        } else if (response.status === 400) {
          const data = await response.json();
          toast.error(data.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        } else {
          const data = await response.json();
          toast.error(data.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleCreateCustomquestion = async () => {
    setIsLoading(true);
    try {
      const token = Cookies.get("access_token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/custom_question`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newQuestion),
        }
      );

      if (response.status === 201) {
        toast.success("Create Question Successfully ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        await fetchProjectcustom();
        await fetchProjectcustomByCreateCondition();
        //mengkosongkan input create ketika berhasil create question
        setNewQuestion({
          title: "",
          question: "",
          type: "",
          is_mandatory: "",
          text_question_show_type: "",
          survey_id: surveyId,
          aid: "",
          cid: "",
          numeric_min_value: 0,
          numeric_max_value: 1,
          numeric_decimal_places: 0,
          numeric_prefix: "",
          numeric_sufix: "",
        });
      } else if (response.status === 400) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error("Failed to Create Question ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      }
    } catch (error) {
      console.error("Error creating Create Question", error);
    }
    setIsLoading(false);
  };

  //create answer Item
  const [newAnswerItem, setnewAnswerItem] = useState({
    code: "",
    answer: "",
    answer_id: answerId,
  });

  const handleCreateAnswerItem = async () => {
    setIsLoading(true);
    try {
      const token = Cookies.get("access_token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/answer_item`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newAnswerItem),
        }
      );

      if (response.status === 201) {
        toast.success("Answer Item Created successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        await fetchAnswerItemsByCreate();
        await fetchAnswerItemsByview();
        setnewAnswerItem({
          code: "",
          answer: "",
          answer_id: answerId,
        });
        setIsCreateAnswerItem(false);
      } else if (response.status === 400) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error("Failed to Create", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      }
    } catch (error) {
      console.error("Error creating Answer Item", error);
    }
    setIsLoading(false);
  };

  //create answer
  const [createnewanswer, setcreatenewanswer] = useState({
    answer_name: "",
    answer_id: answerId,
  });
  // create answer
  const handlecreatenewanswer = async () => {
    setIsLoading(true);
    try {
      const token = Cookies.get("access_token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/answer`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(createnewanswer),
        }
      );

      if (response.status === 200) {
        toast.success("Answer Created successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        await fetchProjectAnswer();
        setcreatenewanswer({
          answer_name: "",
          answer_id: answerId,
          survey_id: surveyId,
        });
        // setModalCreateAnswer(false)
      } else if (response.status === 400) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error("Failed to Create", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      }
    } catch (error) {
      console.error("Error creating Answer", error);
    }
    setIsLoading(false);
  };

  //create condition
  const [createnewcondition, setcreatenewcondition] = useState({
    condition_name: "",
    survey_id: surveyId,
  });

  const handleCreateCondition = async () => {
    setIsLoading(true);
    try {
      const token = Cookies.get("access_token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/condition`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(createnewcondition),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        toast.success("Condition Created successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        // setcreateconditionItem({
        //     ...createconditionItem,
        //     condition_id: data.id,
        // });
        await fetchProjectconditions();
        setcreatenewcondition({
          condition_name: "",
          answer_id: answerId,
          survey_id: surveyId,
        });
        // create a new condition item
        handleCreateConditionItem(data.id);
      } else if (response.status === 400) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error("Failed to Create", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      }
    } catch (error) {
      console.error("Error creating Answer", error);
    }
    setIsLoading(false);
  };

  const handleinputnewAnswer = (event) => {
    const { name, value } = event.target;
    setcreatenewanswer({
      ...createnewanswer,
      [name]: value,
    });
  };
  const handleinputcreatecondition = (event) => {
    const { name, value } = event.target;
    setcreatenewcondition({
      ...createnewcondition,
      [name]: value,
    });
  };

  //update answer item
  const [editAnswerItem, setEditAnswerItem] = useState(null);
  const [editedAnswerItem, seteditedAnswerItem] = useState({
    answer: "",
    answer_item_order: 0,
  });

  const handleUpdateAnswerItem = async () => {
    setIsLoading(true);
    if (editAnswerItem) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/answer_item/${editAnswerItem}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedAnswerItem),
          }
        );
        if (response.status === 200) {
          toast.success("Update Successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
          await fetchAnswerItemsByCreate();
          await fetchAnswerItemsByview();
          setIsUpdateAnswerItem(false);
          seteditedAnswerItem({
            answer: "",
            answer_item_order: "",
          });
        } else if (response.status === 400) {
          toast.error("already exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        } else {
          toast.error("Failed to Update", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  //update Condition
  const [isUpdateModalCondition, setIsUpdateModalCondition] = useState(false);
  const [editcondition, seteditcondition] = useState(null);
  const [editedCondition, seteditedCondition] = useState({
    condition_name: "",
  });

  const handleUpdateCondition = async () => {
    setIsLoading(true);
    if (editcondition) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/condition/${editcondition}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedCondition),
          }
        );
        if (response.status === 200) {
          toast.success("Update Successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
          await fetchProjectconditions();
          setIsUpdateModalCondition(false);
          seteditedCondition({
            condition_name: "",
          });
        } else if (response.status === 400) {
          toast.error("already exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        } else {
          toast.error("Failed to Update", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  //delete answer item
  const handleDelete = async () => {
    setIsLoading(true);
    if (deleteModalId) {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/answer_item/${deleteModalId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          toast.success("Delete Successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
          handleCloseDeleteModal();
          await fetchAnswerItemsByCreate();
          await fetchAnswerItemsByview();
        } else {
          toast.error("Failed to delete", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  //delete answer
  const handleDeleteAnswer = async () => {
    setIsLoading(true);
    if (deleteModalAnswer) {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/answer/${deleteModalAnswer}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          toast.success("Delete Successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
          handleCloseDeleteAnswerId();
          await fetchProjectAnswer();
        } else {
          toast.error("Failed to delete", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  const [openmmodalDeleteCondition, setopenmmodalDeleteCondition] =
    useState(false); //modal
  const [deletemodalCondition, setDeletemodalCondition] = useState(null);
  //delete Condition
  const handleDeleteCondition = async () => {
    setIsLoading(true);
    if (deletemodalCondition) {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/condition/${deletemodalCondition}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          toast.success("Delete Successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
          setopenmmodalDeleteCondition(false);
          await fetchProjectconditions();
        } else {
          toast.error("Failed to delete", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  //delete question
  const handleDeleteQuestion = async () => {
    setIsLoading(true);
    if (selectedItem) {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/custom_question/${selectedItem}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          toast.success("Delete Successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
          handleCloseDeleteModalQuestion();
          await fetchProjectcustom();
          setProjectCustomById({});
          setEditedtextquestion({
            question: "",
          });
        } else if (response.status === 404) {
          const message = await response.json();
          toast.error(message.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        } else if (response.status === 400) {
          const message = await response.json();
          toast.error(message.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        } else {
          const message = await response.json();
          toast.error(message.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  //Update Version
  const handleUpdateVersion = async () => {
    setIsLoading(true);

    const requestBody = {
      log: updateVersionLog,
    };

    try {
      const token = Cookies.get("access_token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/question_version/${surveyId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        toast.success("Update Version Successful", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        fetchProjectcustom();
        setIsUpdateVersion(false);
      } else if (response.status === 404) {
        const message = await response.json();
        toast.error(message.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 400) {
        const message = await response.json();
        toast.error(message.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error("Failed to Update", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setIsLoading(false);
  };

  const [updateswitchId, setupdateswitchId] = useState([]);
  const [updateswitchtargetId, setupdateswitchtargetId] = useState([]);
  const [targetUpdate, settargetUpdate] = useState("");

  const [isEditInitiated, setIsEditInitiated] = useState(false);

  const handleEditbyswitchDown = (id1, id2) => {
    setupdateswitchId(id1);
    setupdateswitchtargetId(id2);
    settargetUpdate("down");
    setIsEditInitiated(true);
  };
  const handleEditbyswitchTop = (id1, id2) => {
    setupdateswitchId(id1);
    setupdateswitchtargetId(id2);
    settargetUpdate("top");
    setIsEditInitiated(true);
  };

  useEffect(() => {
    if (
      targetUpdate &&
      isEditInitiated &&
      updateswitchId &&
      updateswitchtargetId
    ) {
      handleupdatequestionByswitch();
    }
  }, [targetUpdate, isEditInitiated, updateswitchId, updateswitchtargetId]);

  const handleupdatequestionByswitch = async () => {
    if (updateswitchId && updateswitchtargetId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/question-order-switch/${updateswitchId}/${updateswitchtargetId}?option=${targetUpdate}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(),
          }
        );
        if (response.status === 200) {
          await fetchProjectcustom();
        } else {
          toast.error("Failed to Update Question", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const [updateswitchAnswerItem, setupdateswitchAnswerItem] = useState([]);
  const [UpdateSwitchtargetAnswerItem, setUpdateSwitchtargetAnswerItem] =
    useState([]);
  const [targetUpdateAnswerItem, settargetUpdateAnswerItem] = useState("");
  const [runEditAnswer, setrunEditAnswer] = useState(false);

  const editTopAnswerItem = (id1, id2) => {
    setupdateswitchAnswerItem(id1);
    setUpdateSwitchtargetAnswerItem(id2);
    settargetUpdateAnswerItem("top");
    setrunEditAnswer(true);
  };

  const editdownAnswerItem = (id1, id2) => {
    setupdateswitchAnswerItem(id1);
    setUpdateSwitchtargetAnswerItem(id2);
    settargetUpdateAnswerItem("down");
    setrunEditAnswer(true);
  };
  useEffect(() => {
    if (
      targetUpdateAnswerItem &&
      runEditAnswer &&
      updateswitchAnswerItem &&
      UpdateSwitchtargetAnswerItem
    ) {
      handleupdatepositionAnswerItem();
    }
  }, [
    targetUpdateAnswerItem,
    runEditAnswer,
    updateswitchAnswerItem,
    UpdateSwitchtargetAnswerItem,
  ]);

  const handleupdatepositionAnswerItem = async () => {
    if (updateswitchAnswerItem && UpdateSwitchtargetAnswerItem) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/answer_item_order_switch/${updateswitchAnswerItem}/${UpdateSwitchtargetAnswerItem}?option=${targetUpdateAnswerItem}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(),
          }
        );
        if (response.status === 200) {
          await fetchAnswerItemsByCreate();
          await fetchAnswerItemsByview();
        } else {
          toast.error("Failed to Update", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  //function create answer item
  const handleinputansweritem = (event) => {
    const { name, value } = event.target;
    setnewAnswerItem({
      ...newAnswerItem,
      [name]: value,
    });
  };
  //function edit answer item
  const handleEditClick = async (rows) => {
    setEditAnswerItem(rows.row.id);
    seteditedAnswerItem(rows.row);
    setIsUpdateAnswerItem(true);
  };
  const handleEditCondition = async (rows) => {
    seteditcondition(rows.row.id);
    seteditedCondition(rows.row);
    setIsUpdateModalCondition(true);
  };

  //modal delete answer item
  const handleOpenDeleteModal = async (id) => {
    setDeleteModalId(id);
    setDeleteModalOpen(true);
  };

  const handleOpenDeleteModalCondition = async (id) => {
    setDeletemodalCondition(id);
    setopenmmodalDeleteCondition(true);
  };

  const handleCloseModalDeleteCondition = () => {
    setopenmmodalDeleteCondition(false);
  };

  //modal delete answer
  const handleDeleteAnswerId = async (id) => {
    setDeleteModalAnswer(id);
    setModaldeleteModalAnswer(true);
  };
  //modal delete answer
  const handleCloseDeleteAnswerId = async (id) => {
    setDeleteModalAnswer();
    setModaldeleteModalAnswer(false);
  };

  //open modal delete question
  const handleopenmodaldeletequestion = async (selectedItem) => {
    setDeleteModalQuestion(true);
  };

  //fungsi menutup modal
  const handleCloseDeleteModal = () => {
    setDeleteModalId(null);
    setDeleteModalOpen(false);
  };
  //fungsi menutup modal setelah delete question
  const handleCloseDeleteModalQuestion = () => {
    setDeleteModalQuestion(false);
  };
  // Fungsi untuk menangani pemilihan
  const handleclickdata = (item) => {
    setSelectedItem(item.id);
    setEditTextQuestionId(item.id);
    setEditedProjectQuestionAnswer(item.id);
    setEditedtextquestion(item);
    setIsCreatingQuestion(false);
    setNewQuestion({
      title: "",
      question: "",
      type: "",
      is_mandatory: "",
      text_question_show_type: "",
      survey_id: surveyId,
      aid: "",
      cid: "",
      numeric_min_value: 0,
      numeric_max_value: 1,
      numeric_decimal_places: 0,
      numeric_prefix: "",
      numeric_sufix: "",
    });
    setIsAnswer(false);
    setIsNumeric(false);
  };

  const [questionType, setQuestionType] = useState("");

  //fungsi input create question
  const handleinputQuestion = (event) => {
    const { name, value } = event.target;

    if (name === "cid" && value === "all-respondent") {
      setNewQuestion((prevQuestion) => ({
        ...prevQuestion,
        cid: "",
      }));
      return;
    }

    setNewQuestion((prevQuestion) => ({
      ...prevQuestion,
      [name]: value,
    }));

    if (name === "type") {
      setQuestionType(value);
    }

    // Memeriksa apakah tipe yang dipilih
    const selectedType = type.find(
      (option) => option.question_type_code === value
    );
    const selectedIdAnswer = answer.find((option) => option.id === value);

    if (selectedType) {
      setIsNumeric(selectedType.is_numeric === true);
      setIsAnswer(selectedType.is_need_answer === true);
    }

    if (selectedIdAnswer) {
      setAnswerId(selectedIdAnswer.id);
      setEditAnswer(selectedIdAnswer.id);
    }

    // Check if the selected type does not need an answer and clear `aid` if necessary
    if (selectedType && !selectedType.is_need_answer) {
      setNewQuestion((prevQuestion) => ({
        ...prevQuestion,
        aid: "",
      }));
    }
    if (selectedType && !selectedType.is_numeric) {
      setNewQuestion((prevQuestion) => ({
        ...prevQuestion,
        numeric_min_value: 0,
        numeric_max_value: 1,
        numeric_decimal_places: 0,
        numeric_prefix: "",
        numeric_sufix: "",
      }));
    }
  };

  const selectedAnswer = answer.find((option) => option.id === newQuestion.aid);
  const answerLabel = selectedAnswer ? selectedAnswer.answer_name : "";

  const handleExport = async () => {
    setIsLoading(true);
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/project-custom-question/sku/questions/${project.survey_id}/export`;

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const filename = `SKU Question ${project.project_name}.xlsx`;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error("Gagal mengunduh", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error("Failed to export process queue:", response.statusText);
      }
    } catch (error) {
      console.error("Error export process queue:", error);
    }
    setIsLoading(false);
  };

  const handleModalCreateAnswer = () => {
    setModalCreateAnswer(true);
  };

  const handleModalUpdateAnswer = () => {
    setModalUpdateAnswer(true);
  };

  const handleModalUpdateAnswerItem = () => {
    setModalviewAnswerItem(true);
  };

  const handleclosemodalanswer = () => {
    setModalUpdateAnswer(false);
  };

  //function of opening and closing the create box
  const handleCreateQuestionClick = () => {
    setIsCreatingQuestion(true);
    setEditAnswer([]);
    setSelectedItem("");
    setProjectCustomById({});
    setEditedtextquestion([]);
    setEditQuestionAnswer([]);
    setEditQuestionCondition([]);
  };

  const handleCancelCreateQuestion = () => {
    setIsCreatingQuestion(false);

    setNewQuestion((prevQuestion) => ({
      ...prevQuestion,
      title: "",
      question: "",
      type: "",
      is_mandatory: "",
      text_question_show_type: "",
      aid: "",
      cid: "",
      numeric_min_value: 0,
      numeric_max_value: 1,
      numeric_decimal_places: 0,
      numeric_prefix: "",
      numeric_sufix: "",
    }));

    setIsNumeric(false);
    setIsAnswer(false);
  };

  const handleCancleAnswerItems = () => {
    setModalviewAnswerItem(false);
    seteditedAnswerItem({
      answer: "",
      answer_item_order: "",
    });
    setnewAnswerItem({
      code: "",
      answer: "",
      answer_id: answerId,
    });
  };

  useEffect(() => {
    setQuestionType(newQuestion.type);
  }, [newQuestion.type]);

  useEffect(() => {
    if (surveyId) {
      fetchRetailDistribusi();
      fetchProjectcustom();
      fetchProjectconditions();
      fetchProjectcustomByCreateCondition();
      fetchProjectType();
      setNewQuestion((prevState) => ({
        ...prevState,
        survey_id: surveyId,
      }));
      setcreatenewanswer((prevState) => ({
        ...prevState,
        survey_id: surveyId,
      }));
      setcreatenewcondition((prevState) => ({
        ...prevState,
        survey_id: surveyId,
      }));
      setcreateconditionItem((prevState) => ({
        ...prevState,
        survey_id: surveyId,
      }));
    }
  }, [surveyId]);

  useEffect(() => {
    if (projectId) {
      fetchProject();
    }
  }, [projectId]);

  useEffect(() => {
    if (selectedItem) {
      fetchProjectcustombyId();
    }
  }, [selectedItem]);

  useEffect(() => {
    fetchAnswerItemsByview();
  }, [projectCustomById.aid]);

  useEffect(() => {
    if (answerId) {
      fetchAnswerItemsByCreate();
      setnewAnswerItem((prevState) => ({
        ...prevState,
        answer_id: answerId,
      }));
    }
  }, [answerId]);

  useEffect(() => {
    fetchProfile(accessToken, setDataProfile);
  }, [accessToken]);

  useEffect(() => {
    if (surveyId) {
      fetchProjectAnswer();
    }
  }, [surveyId, editAnswer]);
  useEffect(() => {
    if (createconditionItem.question_id) {
      fetchitemcondition();
    }
  }, [createconditionItem.question_id]);
  useEffect(() => {
    if (aidBycondition) {
      fetchAnswerItemByCondition();
    }
  }, [aidBycondition]);

  return (
    <div>
      <Navbar
        active="Project Custom Question SKU"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div>
        <div style={{ marginLeft: "20px", fontSize: "18px" }}>
          <p>
            Survey ID: <b>{project.survey_id}</b>
          </p>
          <p>
            Survey Type: <b>{project.project_type_id}</b>
          </p>
          <p>
            Survey Name: <b>{project.project_name}</b>
          </p>
          <p>
            Version: <b>{version}</b>
          </p>
        </div>
        {project.survey_id && (
          <BootstrapButton
            onClick={handleExport}
            variant="outlined"
            disableRipple
            disabled={isLoading}
            style={{ marginLeft: "20px" }}
            title="Perbarui Versi"
          >
            <FileDownloadIcon style={{ fontSize: "20px", color: "#000000" }} />
            <p
              style={{
                color: "#000000",
                fontSize: "12px",
                marginLeft: "2px",
              }}
            >
              {isLoading ? "Loading..." : "Download"}
            </p>
          </BootstrapButton>
        )}

        {project.project_type_id == "adhoc" && (
          <>
            <BootstrapButton
              onClick={() => setIsUpdateVersion(true)}
              variant="outlined"
              disableRipple
              disabled={isLoading}
              style={{ marginLeft: "5px" }}
              title="Perbarui Versi"
            >
              <UpgradeIcon style={{ fontSize: "20px", color: "#000000" }} />
              <p
                style={{
                  color: "#000000",
                  fontSize: "12px",
                  marginLeft: "2px",
                }}
              >
                Update Version
              </p>
            </BootstrapButton>
            <BootstrapButton
              onClick={() => setIsCreateCondition(true)}
              variant="outlined"
              disableRipple
              disabled={isLoading}
              style={{ marginLeft: "5px" }}
              title="Buat Condition"
            >
              <AddIcon style={{ fontSize: "20px", color: "#000000" }} />
              <p style={{ color: "#000000", fontSize: "12px" }}>
                Add Condition
              </p>
            </BootstrapButton>
            <BootstrapButton
              onClick={handleCreateQuestionClick}
              variant="outlined"
              disableRipple
              disabled={isLoading}
              style={{ marginLeft: "5px" }}
              title="Buat Project Question"
            >
              <AddIcon style={{ fontSize: "20px", color: "#000000" }} />
              <p style={{ color: "#000000", fontSize: "12px" }}>
                Add Question
              </p>
            </BootstrapButton>
          </>
        )}

        {/* <h2 style={{ marginLeft: "20px" }}>Retail Distribusi</h2>
        <div className="doubleBox-customquestion">
          <DataGridRetailDistribusi
            loading={isLoading}
            rows={retailDistribusi}
            projectTypeId={project.project_type_id}
          />
          <Box
            sx={{
              borderRadius: "3px",
              padding: 1,
              mr: 2,
            }}
            className="box-customquestion"
          ></Box>
        </div> */}
        {/* <h2 style={{ marginLeft: "20px" }}>Custom Retail Distribusi</h2> */}
        <div className="doubleBox-customquestion">
          <DataGridListQuestion
            loading={isLoading}
            rows={projectCustom}
            handleclickdata={handleclickdata}
            handleEditbyswitchTop={handleEditbyswitchTop}
            handleEditbyswitchDown={handleEditbyswitchDown}
            projectTypeId={project.project_type_id}
          />
          <Box
            sx={{
              borderRadius: "3px",
              boxShadow:
                "inset 3px 2px 10px 2px #0000000d, 2px 2px 4px 2px #0000000d",
              padding: 1,
              mr: 2,
            }}
            className="box-customquestion"
          >
            {isCreatingQuestion ? (
              // Content for creating a question
              <div style={{ marginTop: 10 }}>
                <TextField
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  name="title"
                  value={newQuestion.title}
                  onChange={handleinputQuestion}
                  multiline
                  minRows={1}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">A_</InputAdornment>
                    ),
                  }}
                  sx={{ width: "90%", mb: 2, mr: 2, mt: 2 }}
                />

                <TextField
                  id="outlined-basic"
                  label="Question"
                  variant="outlined"
                  name="question"
                  value={newQuestion.question}
                  onChange={handleinputQuestion}
                  multiline
                  minRows={1}
                  sx={{ width: "90%", mb: 2, mr: 2 }}
                />

                <FormControl sx={{ width: "90%", mb: 2, mr: 2 }}>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={newQuestion.type}
                    label="Type"
                    onChange={handleinputQuestion}
                    name="type"
                  >
                    {type.map((option) => (
                      <MenuItem
                        key={option.question_type_code}
                        value={option.question_type_code}
                      >
                        {option.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {questionType === "NUM_0" ? (
                  <>
                    <FormControl sx={{ width: "90%", mb: 2, mr: 2 }}>
                      <InputLabel>Is Mandatory</InputLabel>
                      <Select
                        value={newQuestion.is_mandatory}
                        label="Is Mandatory"
                        onChange={handleinputQuestion}
                        name="is_mandatory"
                      >
                        <MenuItem value={"N"}>Y</MenuItem>
                        <MenuItem value={"Y"}>N</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                ) : questionType === "MUL_0" || questionType === "SIN_0" ? (
                  <>
                    <FormControl sx={{ width: "90%", mb: 2, mr: 2 }}>
                      <InputLabel>Is Mandator</InputLabel>
                      <Select
                        value={newQuestion.is_mandatory}
                        label="Is Mandatory"
                        onChange={handleinputQuestion}
                        name="is_mandatory"
                      >
                        <MenuItem value={"Y"}>Y</MenuItem>
                        <MenuItem disabled value={"N"}>
                          N
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <>
                    <FormControl sx={{ width: "90%", mb: 2, mr: 2 }}>
                      <InputLabel>Is Mandatory</InputLabel>
                      <Select
                        value={newQuestion.is_mandatory}
                        label="Is Mandatory"
                        onChange={handleinputQuestion}
                        name="is_mandatory"
                      >
                        <MenuItem value={"Y"}>Y</MenuItem>
                        <MenuItem value={"N"}>N</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}

                <FormControl sx={{ width: "90%", mb: 2, mr: 2 }}>
                  <InputLabel>Condition</InputLabel>
                  <Select
                    value={newQuestion.cid || "all-respondent"}
                    label="Condition"
                    onChange={handleinputQuestion}
                    name="cid"
                  >
                    <MenuItem value="all-respondent">All Respondent</MenuItem>
                    {condition.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.condition_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {isAnswer && (
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <BootstrapButton
                      onClick={handleModalCreateAnswer}
                      variant="outlined"
                      disableRipple
                      disabled={isLoading}
                      style={{ margin: "5px" }}
                      title="Add Answer"
                    >
                      <AddIcon style={{ fontSize: "20px", color: "#000000" }} />
                    </BootstrapButton>
                    <FormControl sx={{ width: "80%", mb: 2 }}>
                      <InputLabel>Answer</InputLabel>
                      <Select
                        value={newQuestion.aid}
                        label="Answer"
                        onChange={handleinputQuestion}
                        name="aid"
                      >
                        {answer.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.answer_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {newQuestion.aid && (
                      <BootstrapButton
                        onClick={handleModalUpdateAnswerItem}
                        variant="outlined"
                        disableRipple
                        disabled={isLoading}
                        style={{ margin: "5px" }}
                        title="Edit Answer and Answer Item"
                      >
                        {isLoading ? (
                          "Loading..."
                        ) : (
                          <EditIcon
                            style={{ fontSize: "20px", color: "#000000" }}
                          />
                        )}
                      </BootstrapButton>
                    )}
                  </div>
                )}
                {isNumeric && (
                  <>
                    <TextField
                      value={newQuestion.numeric_min_value}
                      label="numeric_min_value"
                      name="numeric_min_value"
                      onChange={handleinputQuestion}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      sx={{ width: "90%", mb: 2, mr: 2, mt: 2 }}
                    />
                    <TextField
                      value={newQuestion.numeric_max_value}
                      label="numeric_max_value"
                      name="numeric_max_value"
                      onChange={handleinputQuestion}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      sx={{ width: "90%", mb: 2, mr: 2, mt: 2 }}
                    />
                    <TextField
                      value={newQuestion.numeric_decimal_places}
                      label="numeric_decimal_places"
                      name="numeric_decimal_places"
                      onChange={handleinputQuestion}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      sx={{ width: "90%", mb: 2, mr: 2, mt: 2 }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="numeric_prefix"
                      variant="outlined"
                      name="numeric_prefix"
                      value={newQuestion.numeric_prefix}
                      onChange={handleinputQuestion}
                      multiline
                      minRows={1}
                      sx={{ width: "90%", mb: 2, mr: 2, mt: 2 }}
                    >
                      <MenuItem value={""}> </MenuItem>
                    </TextField>
                    <TextField
                      id="outlined-basic"
                      label="numeric_sufix"
                      variant="outlined"
                      name="numeric_sufix"
                      value={newQuestion.numeric_sufix}
                      onChange={handleinputQuestion}
                      multiline
                      minRows={1}
                      sx={{ width: "90%", mb: 2, mr: 2, mt: 2 }}
                    >
                      <MenuItem value={""}> </MenuItem>
                    </TextField>
                  </>
                )}
                <div style={{ display: "flex" }}>
                  <Button
                    variant="outlined"
                    disableRipple
                    onClick={handleCancelCreateQuestion}
                    sx={{
                      width: 182,
                      mt: 2,
                      mr: 2,
                      height: 50,
                      color: "#b34469",
                      borderColor: "#b34469",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    disableRipple
                    sx={{
                      width: 182,
                      mt: 2,
                      height: 50,
                      color: "#5AB2FF",
                      borderColor: "#5AB2FF",
                    }}
                    disabled={isLoading}
                    onClick={handleCreateCustomquestion}
                  >
                    {isLoading ? "Loading..." : "Add"}
                  </Button>
                </div>
              </div>
            ) : (
              // Content for displaying existing questions

              <div
                style={{
                  margin: "2px",
                }}
              >
                {isLoading ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      textAlign: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <div>
                    {editedtextquestion.question &&
                      project.project_type_id === "adhoc" &&
                      projectCustomById.title !== "R0" && (
                        <BootstrapButton
                          variant="outlined"
                          disableRipple
                          disabled={isLoading}
                          style={{ float: "right" }}
                          title="Delete Question"
                          onClick={handleopenmodaldeletequestion}
                        >
                          <DeleteIcon
                            style={{ fontSize: "20px", color: "#000000" }}
                          />
                          <p style={{ color: "#000000", fontSize: "12px" }}>
                            Delete Question
                          </p>
                        </BootstrapButton>
                      )}

                    <div>
                      {projectCustomById?.title && (
                        <h1>Question Title: {projectCustomById.title}</h1>
                      )}
                      {projectCustomById?.type && (
                        <div>
                          <h1>Question: </h1>
                          <div style={{ display: "flex" }}>
                            <TextField
                              name="Question"
                              value={editedtextquestion.question}
                              sx={{ mb: 2, width: "95%" }}
                              multiline
                              minRows={1}
                              InputProps={{
                                style: {
                                  fontSize: "1.5rem",
                                  fontWeight: "bold",
                                },
                              }}
                              onChange={(e) =>
                                setEditedtextquestion({
                                  ...editedtextquestion,
                                  question: e.target.value,
                                })
                              }
                            />
                            {project.project_type_id == "adhoc" && (
                              <Button
                                variant="outlined"
                                sx={{
                                  mb: 2,
                                  height: 55,
                                  mr: 2,
                                  ml: 2,
                                  color: "#000000",
                                }}
                                onClick={handleUpdatetextquestion}
                                title="Update Question"
                              >
                                <h3>{isLoading ? "Loading..." : "Update"}</h3>
                              </Button>
                            )}
                          </div>
                        </div>
                      )}
                      {projectCustomById?.type_title && (
                        <ul>
                          <li>
                            <h1>Type: {projectCustomById?.type_title}</h1>
                          </li>
                        </ul>
                      )}
                      {projectCustomById?.type &&
                        project.project_type_id === "adhoc" && (
                          <ul>
                            <li>
                              <h1>Mandatory:</h1>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {projectCustomById.type === "NUM_0" ? (
                                  <FormControl sx={{ width: "95%" }}>
                                    <Select
                                      value={
                                        editQuestionMandatory.is_mandatory ||
                                        projectCustomById.is_mandatory
                                      }
                                      name="is_mandatory"
                                      onChange={(e) =>
                                        setEditQuestionMandatory({
                                          ...editQuestionMandatory,
                                          is_mandatory: e.target.value,
                                        })
                                      }
                                      sx={{ fontWeight: "bold" }}
                                      MenuProps={{
                                        MenuListProps: {
                                          sx: {
                                            fontWeight: "bold",
                                          },
                                        },
                                      }}
                                    >
                                      <MenuItem value={"N"}>Y</MenuItem>
                                      <MenuItem value={"Y"}>N</MenuItem>
                                    </Select>
                                  </FormControl>
                                ) : projectCustomById.type === "MUL_0" ||
                                  projectCustomById.type === "SIN_0" ? (
                                  <FormControl sx={{ width: "95%" }}>
                                    <Select
                                      value={
                                        editQuestionMandatory.is_mandatory ||
                                        projectCustomById.is_mandatory
                                      }
                                      name="is_mandatory"
                                      onChange={(e) =>
                                        setEditQuestionMandatory({
                                          ...editQuestionMandatory,
                                          is_mandatory: e.target.value,
                                        })
                                      }
                                      sx={{ fontWeight: "bold" }}
                                      MenuProps={{
                                        MenuListProps: {
                                          sx: {
                                            fontWeight: "bold",
                                          },
                                        },
                                      }}
                                    >
                                      <MenuItem value={"Y"}>Y</MenuItem>
                                      <MenuItem disabled value={"N"}>
                                        N
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                ) : (
                                  <FormControl sx={{ width: "95%" }}>
                                    <Select
                                      value={
                                        editQuestionMandatory.is_mandatory ||
                                        projectCustomById.is_mandatory
                                      }
                                      name="is_mandatory"
                                      onChange={(e) =>
                                        setEditQuestionMandatory({
                                          ...editQuestionMandatory,
                                          is_mandatory: e.target.value,
                                        })
                                      }
                                      sx={{ fontWeight: "bold" }}
                                      MenuProps={{
                                        MenuListProps: {
                                          sx: {
                                            fontWeight: "bold",
                                          },
                                        },
                                      }}
                                    >
                                      <MenuItem value={"Y"}>Y</MenuItem>
                                      <MenuItem value={"N"}>N</MenuItem>
                                    </Select>
                                  </FormControl>
                                )}

                                {project.project_type_id == "adhoc" && (
                                  <Button
                                    variant="outlined"
                                    sx={{
                                      height: 55,
                                      mr: 2,
                                      ml: 2,
                                      color: "#000000",
                                    }}
                                    onClick={handleUpdateMandatory}
                                    title="Update answer to this question"
                                  >
                                    <h3>
                                      {isLoading ? "Loading..." : "Update"}
                                    </h3>
                                  </Button>
                                )}
                              </div>
                            </li>
                          </ul>
                        )}
                    </div>
                    {/* Conditional rendering based on projectCustomById.type */}
                    {projectCustomById.type === "NUM_0" ||
                    projectCustomById.type === "TXT_0" ? (
                      <ul>
                        <li>
                          <h1>Condition:</h1>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <FormControl sx={{ width: "95%" }}>
                              <Select
                                value={
                                  editQuestionCondition.condition_id ||
                                  projectCustomById.cid ||
                                  "all-respondent"
                                }
                                name="condition_id"
                                onChange={(e) =>
                                  setEditQuestionCondition({
                                    ...editQuestionCondition,
                                    condition_id: e.target.value,
                                  })
                                }
                                sx={{ fontWeight: "bold" }}
                                MenuProps={{
                                  MenuListProps: {
                                    sx: {
                                      fontWeight: "bold",
                                    },
                                  },
                                }}
                              >
                                <MenuItem value="all-respondent">
                                  All Respondent
                                </MenuItem>
                                {condition.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.condition_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {project.project_type_id == "adhoc" && (
                              <Button
                                variant="outlined"
                                sx={{
                                  height: 55,
                                  mr: 2,
                                  ml: 2,
                                  color: "#000000",
                                }}
                                onClick={handleupdatequestionCondition}
                                title="Update answer to this question"
                              >
                                <h3>{isLoading ? "Loading..." : "Update"}</h3>
                              </Button>
                            )}
                          </div>
                        </li>
                      </ul>
                    ) : projectCustomById.type === "SIN_0" ||
                      projectCustomById.type === "MUL_0" ? (
                      <ul>
                        <li>
                          <h1>Answer:</h1>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {project.project_type_id == "adhoc" && (
                              <IconButton
                                sx={{
                                  width: 50,
                                  color: "#000000",
                                  cursor: "pointer",
                                }}
                                onClick={handleModalCreateAnswer}
                                variant="outlined"
                                disableRipple
                                disabled={isLoading}
                                title="Add Answer"
                              >
                                <AddIcon />
                              </IconButton>
                            )}
                            <FormControl sx={{ width: "95%" }}>
                              <Select
                                value={
                                  editQuestionAnswer.answer_id ||
                                  projectCustomById.aid
                                }
                                name="answer_id"
                                onChange={(e) =>
                                  setEditQuestionAnswer({
                                    ...editQuestionAnswer,
                                    answer_id: e.target.value,
                                  })
                                }
                                sx={{ fontWeight: "bold" }} // Menambahkan font tebal pada input Select
                                MenuProps={{
                                  MenuListProps: {
                                    sx: {
                                      fontWeight: "bold", // Menambahkan font tebal pada daftar menu
                                    },
                                  },
                                }}
                                disabled={project.project_type_id !== "adhoc"}
                              >
                                {answer.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.answer_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {project.project_type_id == "adhoc" && (
                              <Button
                                variant="outlined"
                                sx={{
                                  height: 55,
                                  mr: 2,
                                  ml: 2,
                                  color: "#000000",
                                }}
                                onClick={handleupdatequestionanswer}
                                title="Update answer to this question"
                              >
                                <h3>{isLoading ? "Loading..." : "Update"}</h3>
                              </Button>
                            )}
                            <IconButton
                              sx={{
                                width: 50,
                                color: "#000000",
                                cursor: "pointer",
                              }}
                              onClick={handleModalUpdateAnswer}
                              variant="outlined"
                              disableRipple
                              disabled={isLoading}
                              title="Edit Answer - view Answer Item"
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        </li>
                        <li>
                          <h1>Condition:</h1>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <FormControl sx={{ width: "95%" }}>
                              <Select
                                value={
                                  editQuestionCondition.condition_id ||
                                  projectCustomById.cid ||
                                  "all-respondent"
                                }
                                name="condition_id"
                                onChange={(e) =>
                                  setEditQuestionCondition({
                                    ...editQuestionCondition,
                                    condition_id: e.target.value,
                                  })
                                }
                                sx={{ fontWeight: "bold" }}
                                MenuProps={{
                                  MenuListProps: {
                                    sx: {
                                      fontWeight: "bold",
                                    },
                                  },
                                }}
                                disabled={project.project_type_id !== "adhoc"}
                              >
                                <MenuItem value="all-respondent">
                                  All Respondent
                                </MenuItem>
                                {condition.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.condition_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            {project.project_type_id == "adhoc" && (
                              <Button
                                variant="outlined"
                                sx={{
                                  height: 55,
                                  mr: 2,
                                  ml: 2,
                                  color: "#000000",
                                }}
                                onClick={handleupdatequestionCondition}
                                title="Update answer to this question"
                              >
                                <h3>{isLoading ? "Loading..." : "Update"}</h3>
                              </Button>
                            )}
                          </div>
                        </li>
                      </ul>
                    ) : null}
                  </div>
                )}
              </div>
            )}
          </Box>
        </div>
      </div>

      {/* delete answer*/}
      <Modal
        open={modaldeleteModalAnswer}
        onClose={() => setModaldeleteModalAnswer(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
        sx={{ border: 0 }}
      >
        <Box sx={modalStyle}>
          <h2>Konfirmasi Hapus</h2>
          <p>Apakah anda yakin untuk menghapus data ini?</p>
          <div>
            <Button
              variant="outlined"
              disableRipple
              sx={{
                width: 190,
                height: 50,
                mt: 2,
                color: "#b34469",
                borderColor: "#b34469",
              }}
              onClick={handleCloseDeleteAnswerId}
            >
              Cancel
            </Button>
            <BootstrapButton
              variant="contained"
              disableRipple
              sx={{ width: 182, mt: 2, height: 50, color: "#FFF" }}
              onClick={handleDeleteAnswer}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "DELETE"}
            </BootstrapButton>
          </div>
        </Box>
      </Modal>
      {/* modal create condition */}
      <Modal
        open={isCreateCondition}
        onClose={() => setIsCreateCondition(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
        sx={{ border: 0 }}
      >
        <Box
          sx={{
            ...style,
            width: "90vw",
            margin: "0 auto",
            overflow: "auto",
            overflowX: "hidden",
            height: "90vh",
          }}
        >
          <h1>Create Condition</h1>

          <Tooltip title="Close Modal">
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setIsCreateCondition(false)}
              sx={{ position: "absolute", top: 20, right: 20 }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Condition Name"
              variant="outlined"
              multiline
              name="condition_name"
              value={createnewcondition.condition_name}
              onChange={handleinputcreatecondition}
              minRows={1}
              sx={{ width: 405, mb: 2, mt: 2 }}
            />
            <FormControl sx={{ width: 405, mb: 2 }}>
              <InputLabel>Question</InputLabel>
              <Select
                value={createconditionItem.question_id}
                onChange={handleInputConditionItemQuestion}
                label="Question"
                name="question_id"
              >
                {customListByCondition.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.title}. {option.question}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: 405, mb: 2 }}>
              <InputLabel>Operator</InputLabel>
              <Select
                value={createconditionItem.operator}
                onChange={handleInputConditionItem}
                name="operator"
                label="Operator"
              >
                {dataOperator.map((option) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {isConditionNeedAnswerItem && (
              <FormControl sx={{ width: 405, mb: 2 }}>
                <p style={{ margin: "0px 0px 4px 0px", color: "#666670" }}>
                  Answer Item
                </p>
                {answerItemBYCondition.map((option) => (
                  <FormControlLabel
                    key={option.code}
                    control={
                      <Checkbox
                        checked={createconditionItem.value.includes(
                          option.code
                        )}
                        onChange={handleCheckboxToggle(option.code)}
                      />
                    }
                    label={option.answer}
                  />
                ))}
              </FormControl>
            )}
            {isConditionShowValue && (
              <TextField
                id="outlined-basic"
                label="Value "
                variant="outlined"
                multiline
                value={createconditionItem.value}
                // minRows={1}
                sx={{ width: 405, mb: 2 }}
                onChange={handleInputConditionItemMultiple}
                name="value"
              />
            )}

            <Button
              variant="outlined"
              disableRipple
              sx={{
                width: 405,
                mb: 2,
                height: 50,
                color: "#5AB2FF",
                borderColor: "#5AB2FF",
              }}
              disabled={isLoading}
              onClick={handleCreateCondition}
            >
              Create
            </Button>
          </div>
          <DataGridCondition
            isLoading={isLoading}
            rows={condition}
            handleEditCondition={handleEditCondition}
            handleOpenDeleteModalCondition={handleOpenDeleteModalCondition}
          />
        </Box>
      </Modal>
      <ModalEditCondition
        isLoading={isLoading}
        isUpdateModalCondition={isUpdateModalCondition}
        setIsUpdateModalCondition={setIsUpdateModalCondition}
        modalStyle={modalStyle}
        editedCondition={editedCondition}
        seteditedCondition={seteditedCondition}
        handleUpdateCondition={handleUpdateCondition}
        editcondition={editcondition}
      />
      {/* modal create answer  */}
      <ModalCreateAnswer
        isLoading={isLoading}
        modalCreateAnswer={modalCreateAnswer}
        setModalCreateAnswer={setModalCreateAnswer}
        modalStyle={modalStyle}
        createnewanswer={createnewanswer}
        handleinputnewAnswer={handleinputnewAnswer}
        handlecreatenewanswer={handlecreatenewanswer}
        answer={answer}
        handleDeleteAnswerId={handleDeleteAnswerId}
      />
      {/* update answer by view*/}
      <ModalViewAnswerItem
        isLoading={isLoading}
        modalviewansweritem={modalUpdateAnswer}
        setmodalviewansweritem={setModalUpdateAnswer}
        handleCancleAnswerItems={handleclosemodalanswer}
        setIsCreateAnswerItem={setIsCreateAnswerItem}
        answerItem={viewAnswerItem}
        handleEditClick={handleEditClick}
        handleOpenDeleteModal={handleOpenDeleteModal}
        editedAnswerId={editedAnswerId}
        setEditedAnswerId={setEditedAnswerId}
        handleUpadateAnswer={handleUpadateAnswer}
        title={"Edit Answer"}
        editTopAnswerItem={editTopAnswerItem}
        editdownAnswerItem={editdownAnswerItem}
        isAllowEditAnswer={project.project_type_id == "adhoc"}
      />
      {/* modal view answer item */}
      <ModalViewAnswerItem
        isLoading={isLoading}
        modalviewansweritem={modalviewAnswerItem}
        setmodalviewansweritem={setModalviewAnswerItem}
        handleCancleAnswerItems={handleCancleAnswerItems}
        setIsCreateAnswerItem={setIsCreateAnswerItem}
        answerItem={answerItem}
        handleEditClick={handleEditClick}
        handleOpenDeleteModal={handleOpenDeleteModal}
        editedAnswerId={editedAnswerId}
        setEditedAnswerId={setEditedAnswerId}
        handleUpadateAnswer={handleUpadateAnswer}
        title={`Edit Answer ${answerLabel || ""}`}
        editTopAnswerItem={editTopAnswerItem}
        editdownAnswerItem={editdownAnswerItem}
      />
      {/* modal Update Answer Items*/}
      <ModalUpdateAnswerItem
        isLoading={isLoading}
        isUpdateAnswerItem={isUpdateAnswerItem}
        setIsUpdateAnswerItem={setIsUpdateAnswerItem}
        modalStyle={modalStyle}
        editedAnswerItem={editedAnswerItem}
        seteditedAnswerItem={seteditedAnswerItem}
        handleUpdateAnswerItem={handleUpdateAnswerItem}
      />
      {/* modal Create Answer Items*/}
      <ModalCreateAnswerItem
        isLoading={isLoading}
        isCreateAnswerItem={isCreateAnswerItem}
        setIsCreateAnswerItem={setIsCreateAnswerItem}
        modalStyle={modalStyle}
        newAnswerItem={newAnswerItem}
        handleinputansweritem={handleinputansweritem}
        handleCreateAnswerItem={handleCreateAnswerItem}
      />
      {/* modal delete Answer Items*/}
      <ModalDeleteAnswerItem
        isLoading={isLoading}
        modalStyle={modalStyle}
        deleteModalOpen={deleteModalOpen}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleDelete={handleDelete}
      />
      {/* modal delete Condition*/}
      <ModalDeleteCondition
        isLoading={isLoading}
        modalStyle={modalStyle}
        openmmodalDeleteCondition={openmmodalDeleteCondition}
        handleCloseModalDeleteCondition={handleCloseModalDeleteCondition}
        handleDeleteCondition={handleDeleteCondition}
      />
      {/* modal delete Question*/}
      <ModalDeleteQuestion
        isLoading={isLoading}
        modalStyle={modalStyle}
        DeleteModalQuestion={deleteModalQuestion}
        handleCloseDeleteModalQuestion={handleCloseDeleteModalQuestion}
        handleDeleteQuestion={handleDeleteQuestion}
      />

      {/* modal update version */}
      <ModalUpdateVersion
        updateVersionLog={updateVersionLog}
        setUpdateVersionLog={setUpdateVersionLog}
        isLoading={isLoading}
        isUpdateVersion={isUpdateVersion}
        setIsUpdateVersion={setIsUpdateVersion}
        modalStyle={modalStyle}
        handleUpdateVersion={handleUpdateVersion}
      />
    </div>
  );
}

export default ProjectCustomQuestion;
