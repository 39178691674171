import Cookies from "js-cookie";
import { React, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { ButtonGroup, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Tooltip } from '@mui/material';
import { Slide, toast } from "react-toastify";
import * as XLSX from "xlsx";
import { ModalSyncRawdata } from "./Modal/ModalSyncRawdata";

// external
import Navbar from "../../../Components/Navbar";
import { fetchProfile } from "../../../utils/fetchProfile";
import { fetchSyncRawdata } from "../../../utils/fetchSyncRawdata";
import { dataReportGroup, dataReportDetail, dataLevel } from "./dataUtil";
import {
  fetchAreaData,
  fetchDistrictOptionsByArea,
  fetchSubDistrictOptionsByDistrict,
} from "../../../services/areaService";
import { fetchExport, fetchProcess } from "../../../services/reportingService";
import { ModalConfirmation } from "../../../Components/Fragments/Modals/ModalGeneral";

import "../../../style/reporting.css";
import { sanitizeFilename } from "../../../utils/fileName";
import ExcelRequestsImport from "./ExcelRequestImport";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "3px 10px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#069DAE",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#0696A6",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

function ProcessReportNonProjected() {
  const token = Cookies.get("access_token") || "";
  const [isLoading, setIsLoading] = useState(false);
  const [processCompleted, setProcessCompleted] = useState(false);

  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);
  const [dataSyncRawdata, setDataSyncRawdata] = useState([]);
  const [selectedReport, setSelectedReport] = useState("Detail Survey RA");
  const [dataReportSelected, setDataReportSelected] = useState({});

  const [selectedReportGroup, setSelectedReportGroup] = useState("RTA GT");
  const [selectedPeriodFirst, setSelectedPeriodFirst] = useState("");
  const [selectedPeriodSecond, setSelectedPeriodSecond] = useState("");
  const [selectedPeriodThird, setSelectedPeriodThird] = useState("");
  const [selectedSurveyType, setSelectedSurveyType] = useState([]);
  const [dataTipeSurvey, setDataTipeSurvey] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("Kota");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedSubDistrict, setSelectedSubDistrict] = useState("");
  const [selectedWilayah, setSelectedWilayah] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");

  const [isInputKotaDisabled, setIsInputKotaDisabled] = useState(false);
  const [isInputKecamatanDisabled, setIsInputKecamatanDisabled] =
    useState(false);

  const [periods, setPeriods] = useState("");
  const [periodOptions, setPeriodOptions] = useState([]);
  const [periodOptionsFirst, setPeriodOptionsFirst] = useState([]);
  const [periodOptionsSecond, setPeriodOptionsSecond] = useState([]);
  const [periodOptionsThird, setPeriodOptionsThird] = useState([]);
  const [provincesOptions, setProvincesOptions] = useState([]);
  const [areasOptions, setAreasOptions] = useState([]);
  const [regionsOptions, setRegionsOptions] = useState([]);
  const [wilayahOptions, setWilayahOptions] = useState([]);

  const [districtsOptions, setDistrictsOptions] = useState([]);
  const [subDistrictsOptions, setSubDistrictsOptions] = useState([]);

  const [openDialogConfirmationExport, setOpenDialogConfirmationExport] =
    useState(false);
  const [syncModalOpen, setSyncModalOpen] = useState(false);
  const [viewDataExport, setViewDataExport] = useState({
    title: null,
    process_at: null,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(token, setDataProfile, setNotAllowAccessPage, [
        "TL",
        "13",
        "14",
      ]);
      fetchPeriodOptions(selectedReport);
      fetchArea();
      fetchTypeOptions();
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  async function fetchArea() {
    const provincesData = await fetchAreaData(token, "provinces");
    setProvincesOptions(provincesData);
    const areasData = await fetchAreaData(token, "areas");
    setAreasOptions(areasData);
    const regionsData = await fetchAreaData(token, "regions");
    setRegionsOptions(regionsData);
    const wilayahData = await fetchAreaData(token, "wilayah");
    setWilayahOptions(wilayahData);
  }

  async function fetchPeriodOptions(selectedReport) {
    const dataReportActive = dataReportDetail.find(
      (report) => report.title === selectedReport
    );
    setDataReportSelected(dataReportActive);

    try {
      const projectTypeReport = dataReportActive.project_type;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/reporting/period?project_type_id=${projectTypeReport}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { data } = await response.json();
        setPeriodOptions(data);
        setPeriodOptionsFirst(data);
      } else {
        throw new Error("Failed to fetch period options");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchTypeOptions() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/tipe-survey`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const newArray = data.data.map((item) => ({
          label: item.desc,
          id: item.desc,
        }));
        setDataTipeSurvey(newArray);
      } else {
        throw new Error("Failed to fetch store types");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleRefresh = () => {
    fetchSyncRawdata(
      token,
      setDataSyncRawdata,
      selectedReportGroup.replace(/\s+/g, "_").toLowerCase(),
      selectedPeriodFirst
    );
  };

  const handleReportGroupChange = (event) => {
    // set group active
    const selectedValue = event.target.value;
    setSelectedReportGroup(selectedValue);
    // set default report based group
    const dataReportSelected = dataReportDetail.filter(
      (r) => r.report_group === selectedValue
    );
    const defaultReportSelected = dataReportSelected[0].title ?? "";
    setSelectedReport(defaultReportSelected);
    // set default
    fetchPeriodOptions(defaultReportSelected);
    setSelectedPeriodFirst("");
    setSelectedPeriodSecond("");
    setSelectedPeriodThird("");
    setSelectedSurveyType([]);
  };

  const handleReportChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedReport(selectedValue);
    fetchPeriodOptions(selectedValue);
    // default
    setSelectedPeriodFirst("");
    setSelectedPeriodSecond("");
    setSelectedPeriodThird("");
    setSelectedSurveyType([]);
  };

  const handlePeriodChange = (event, periodNumber) => {
    const selectedValue = event.target.value;

    // Create an array of selected values for all periods
    const selectedValues = [
      selectedValue,
      selectedPeriodFirst,
      selectedPeriodSecond,
      selectedPeriodThird,
    ].filter(Boolean);
    // Update the options for each period based on the selected values
    const updatedOptions = periodOptions.filter(
      (option) => !selectedValues.includes(option.id)
    );

    // Update the selected period state based on the period number
    switch (periodNumber) {
      case 1:
        setSelectedPeriodFirst(selectedValue);
        setPeriodOptionsSecond(updatedOptions);
        break;
      case 2:
        setSelectedPeriodSecond(selectedValue);
        setPeriodOptionsThird(updatedOptions);
        break;
      case 3:
        setSelectedPeriodThird(selectedValue);
        break;
      default:
        break;
    }
  };

  const handleLevelChange = (event) => {
    const selectedLevel = event.target.value;

    setSelectedLevel(selectedLevel);

    if (selectedLevel === "Kota") {
      setIsInputKotaDisabled(true);
      setIsInputKecamatanDisabled(true);
      setSelectedDistrict("");
      setSelectedSubDistrict("");
    } else if (selectedLevel === "Kecamatan") {
      setIsInputKotaDisabled(true);
      setSelectedSubDistrict("");
      setIsInputKecamatanDisabled(false);
    } else {
      setIsInputKotaDisabled(false);
      setIsInputKecamatanDisabled(false);
    }
  };

  const handleSurveyTypeChange = (event) => {
    setSelectedSurveyType(event.target.value);
    localStorage.setItem(
      "selectedSurveyType",
      JSON.stringify(event.target.value)
    );
  };

  const handleAreaChange = async (event) => {
    setSelectedArea(event.target.value);
    setDistrictsOptions(
      await fetchDistrictOptionsByArea(token, event.target.value)
    );
    // default
    setSelectedDistrict("");
    setSelectedSubDistrict("");
  };

  const handleDistrictChange = async (event) => {
    setSelectedDistrict(event.target.value);
    setSubDistrictsOptions(
      await fetchSubDistrictOptionsByDistrict(token, event.target.value)
    );
    // default
    setSelectedSubDistrict("");
  };

  const handleSyncModalOpen = () => {
    if (!selectedPeriodFirst || !selectedReportGroup) {
      toast.error("Harap mengisi period dan tipe project");
    } else {
      setSyncModalOpen(true);
    }
  };

  const handleSyncModalClose = () => {
    setSyncModalOpen(false);
  };

  const handleProcessSync = async () => {
    try {
      const formData = {
        project_type: selectedReportGroup.replace(/\s+/g, "_").toLowerCase(),
        period: selectedPeriodFirst,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/process_queue/sync_rawdata`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.status === 200) {
        toast.success("Data akan disinkronisasikan dari mobile ke web", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        setSyncModalOpen(false);
        fetchSyncRawdata(
          token,
          setDataSyncRawdata,
          selectedReportGroup.replace(/\s+/g, "_").toLowerCase(),
          selectedPeriodFirst
        );
      } else if (response.status === 400) {
        toast.error("Sedang ada proses yang berjalan, harap tunggu", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        setSyncModalOpen(false);
      } else {
        toast.error("Ada masalah saat sinkronisasi data", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      }
    } catch (error) {
      console.error("Error creating process queue:", error);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setProcessCompleted(false);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [
    selectedReportGroup,
    selectedReport,
    selectedPeriodFirst,
    selectedPeriodSecond,
    selectedPeriodThird,
    selectedSurveyType,
    selectedLevel,
    selectedProvince,
    selectedArea,
    selectedDistrict,
    selectedSubDistrict,
    selectedRegion,
    selectedWilayah,
  ]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (selectedReportGroup && selectedPeriodFirst) {
        fetchSyncRawdata(
          token,
          setDataSyncRawdata,
          selectedReportGroup.replace(/\s+/g, "_").toLowerCase(),
          selectedPeriodFirst || ""
        );
      } else if (!selectedReportGroup || !selectedPeriodFirst) {
        setDataSyncRawdata([]);
      }
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [selectedReportGroup, selectedPeriodFirst]);

  const validateSelectedOption = (selectedPeriods, numberOfPeriods) => {
    const nonEmptyPeriods = selectedPeriods.filter((period) => period !== "");

    if (nonEmptyPeriods.length !== numberOfPeriods) {
      alert(`Pilih ${numberOfPeriods} opsi terlebih dahulu!`);
      return false;
    }
    return true;
  };

  const handleProcess = async () => {
    setIsLoading(true);
    let validDownload = true;
    let apiUrlReport = "process_queue/reporting_non_projected";
    let formData = {
      report_list: selectedReportGroup,
      report_name: selectedReport,
      survey_types: selectedSurveyType.join(","),
      level: selectedLevel,
      province_code: selectedProvince,
      area_name: selectedArea,
      district_code: selectedDistrict,
      sub_district_code: selectedSubDistrict,
      region_name: selectedRegion,
      wilayah_name: selectedWilayah,
    };

    let periods = "";
    let title = "";

    switch (selectedReport) {
      case "Detail Survey RDC":
      case "Detail Survey RA":
      case "Display Report":
      case "RDC MT Non Projected Report":
      case "RDC Pharmacy Non Projected Report":
        if (
          validateSelectedOption([selectedPeriodFirst, selectedPeriodSecond], 2)
        ) {
          periods = `${selectedPeriodFirst},${selectedPeriodSecond}`;
        } else {
          validDownload = false;
        }
        break;

      case "Out Of Range Report":
      case "RDC MT Non Projected Report by Storetype":
      case "RDC Pharmacy Non Projected Report by Storetype":
      case "RDC Price Report by Area":
      case "RDC Selling Speed Non Projected Report":
      case "Report Ketepatan Penjualan":
      case "Report Undup Gabungan Produk":
      case "Report RDC MT Price By Area":
      case "Report RDC MT Price by Tipe Lokasi":
        if (validateSelectedOption([selectedPeriodFirst], 1)) {
          periods = `${selectedPeriodFirst}`;
        } else {
          validDownload = false;
        }
        break;

      case "Market Share Non Projected Report":
        if (
          validateSelectedOption(
            [
              selectedPeriodFirst,
              selectedPeriodSecond,
              selectedPeriodThird,
              selectedLevel,
            ],
            4
          )
        ) {
          periods = `${selectedPeriodFirst},${selectedPeriodSecond},${selectedPeriodThird}`;
        } else {
          validDownload = false;
        }
        break;

      case "RDC GT Non Projected Report":
      case "Distribusi Grosir Report":
        if (
          validateSelectedOption(
            [selectedPeriodFirst, selectedPeriodSecond, selectedLevel],
            3
          )
        ) {
          periods = `${selectedPeriodFirst},${selectedPeriodSecond}`;
        } else {
          validDownload = false;
        }
        break;
      default:
        validDownload = false;
        break;
    }

    if (validDownload) {
      title = `Reporting Non Projected ${selectedReportGroup} ${selectedReport} ${periods}`;
      formData = {
        ...formData,
        title: title,
        periods: periods,
      };

      fetchProcess(token, apiUrlReport, formData);
      setPeriods(periods);
    }
    setProcessCompleted(true);
    setIsLoading(false);
  };

  const handleConfirmationExport = async () => {
    if (!processCompleted) {
      // Jika proses belum dilakukan, tampilkan pesan kesalahan
      toast.error("Klik Process terlebih dahulu", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
      return;
    }

    setIsLoading(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/process_queue/reporting_non_projected/${selectedReportGroup}/${selectedReport}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      const { data } = await response.json();
      setOpenDialogConfirmationExport(true);
      setViewDataExport({
        title: data.data.title ?? "reporting non projected",
        process_at: data.process_at,
      });
    } else if (response.status === 400) {
      toast.error("Data sedang diproses", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    } else {
      toast.error("Tidak Ditemukan Excel", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setIsLoading(false);
  };

  const handleCloseDialog = () => {
    setOpenDialogConfirmationExport(false);
    // clear data
    setViewDataExport({
      title: "",
      process_at: "",
    });
  };

  const handleExport = async () => {
    setIsLoading(true);

    let apiUrlReport = `process_queue/reporting_non_projected/export/${selectedReportGroup}/${selectedReport}`;
    let title = `${selectedReportGroup} ${selectedReport} ${periods} ${selectedSurveyType} ${selectedLevel} ${selectedProvince} ${selectedArea} ${selectedDistrict} ${selectedSubDistrict} ${selectedRegion} ${selectedWilayah}`;
    let fileName = sanitizeFilename(title);

    fetchExport(token, apiUrlReport, fileName);
    setIsLoading(false);
  };
  const [excelData, setExcelData] = useState([]);

  const handlePreviewExport = () => {
    if (!processCompleted) {
      toast.error("Klik Process terlebih dahulu", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
      return;
    }

    setIsLoading(true);

    const titleParts = [
      selectedReportGroup && `${selectedReportGroup}`,
      selectedReport && `${selectedReport}`,
      periods && `${periods}`,
      selectedSurveyType && `${selectedSurveyType}`,
      selectedLevel && `Level ${selectedLevel}`,
      selectedProvince && `${selectedProvince}`,
      selectedArea && `${selectedArea}`,
      selectedDistrict && `${selectedDistrict}`,
      selectedSubDistrict && `${selectedSubDistrict}`,
      selectedRegion && `${selectedRegion}`,
      selectedWilayah && `${selectedWilayah}`,
    ].filter((part) => part);

    const title = titleParts.join(" - ");

    localStorage.setItem("selectedReportGroup", selectedReportGroup);
    localStorage.setItem("selectedReport", selectedReport);
    localStorage.setItem("previewDetail", title);

    window.open(
      `/non-projected-reporting/preview/${selectedReportGroup}/${selectedReport}`,
      "_blank"
    );

    setIsLoading(false);
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Non Projected Reporting"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allowed to access this page</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar
        active="Non Projected Reporting"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div style={{ padding: "2rem" }}>
        {/* <ButtonGroup style={{ color: "white" }}>
          <BootstrapButton
            style={{ color: "white", borderColor: "white", height: "40px" }}
          >
            Non Projected
          </BootstrapButton>
          <BootstrapButton
            href="/report-projected"
            style={{ color: "white", borderColor: "white", height: "40px" }}
          >
            Projected
          </BootstrapButton>
        </ButtonGroup> */}
        <div>
          <div style={{ marginLeft: "30px" }}>
            <div style={{ display: "block", marginLeft: "30px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                  marginLeft: "10%",
                }}
              >
                <h4
                  style={{
                    fontSize: 20,
                    fontFamily: ["Montserrat"].join(","),
                  }}
                >
                  Non Projected Reporting
                </h4>
                {selectedReportGroup && selectedPeriodFirst && (
                  <Tooltip title="Refresh">
                    <h4
                      style={{
                        fontSize: 20,
                        fontFamily: "Montserrat",
                        cursor: "pointer",
                        color: "#747474",
                      }}
                      onClick={handleRefresh}
                    >
                      <RefreshIcon />
                    </h4>
                  </Tooltip>
                )}
              </div>
              {/* group and project */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                  marginTop: "10px",
                  marginLeft: "10%",
                }}
              >
                <TextField
                  id="outlined-select-report-list"
                  label="Tipe Project"
                  select
                  sx={{
                    width: "32%",
                    height: 40,
                  }}
                  size="small"
                  value={selectedReportGroup}
                  onChange={handleReportGroupChange}
                >
                  {dataReportGroup?.map((data) => {
                    return (
                      <MenuItem value={data.title} key={data.title}>
                        {data.title}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <TextField
                  id="outlined-select-report"
                  label="Pilih Report"
                  select
                  sx={{
                    width: "66%",
                    height: 40,
                    ml: "2%",
                  }}
                  size="small"
                  value={selectedReport}
                  onChange={handleReportChange}
                >
                  {dataReportDetail
                    .filter((r) => r.report_group === selectedReportGroup)
                    ?.map((data) => (
                      <MenuItem key={data.title} value={data.title}>
                        {data.title}
                      </MenuItem>
                    ))}
                </TextField>
              </div>
              {/* period */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                  marginTop: "10px",
                  marginLeft: "10%",
                }}
              >
                <TextField
                  id="outlined-select-period-one"
                  label="Periode 1"
                  select
                  sx={{
                    width: "32%",
                  }}
                  InputProps={{
                    endAdornment: selectedPeriodFirst && (
                      <IconButton
                        sx={{ mr: 1.5 }}
                        edge="end"
                        onClick={() => setSelectedPeriodFirst("")}
                      >
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                  size="small"
                  value={selectedPeriodFirst}
                  onChange={(e) => handlePeriodChange(e, 1)}
                  disabled={dataReportSelected.input_period_first_disabled}
                >
                  {periodOptionsFirst?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-select-period-two"
                  label="Periode 2"
                  select
                  sx={{
                    width: "32%",
                  }}
                  InputProps={{
                    endAdornment: selectedPeriodSecond && (
                      <IconButton
                        sx={{ mr: 1.5 }}
                        edge="end"
                        onClick={() => setSelectedPeriodSecond("")}
                      >
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                  size="small"
                  value={selectedPeriodSecond}
                  onChange={(e) => handlePeriodChange(e, 2)}
                  disabled={dataReportSelected.input_period_second_disabled}
                >
                  {periodOptionsSecond?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-select-period-two"
                  label="Periode 3"
                  select
                  sx={{
                    width: "32%",
                  }}
                  InputProps={{
                    endAdornment: selectedPeriodThird && (
                      <IconButton
                        sx={{ mr: 1.5 }}
                        edge="end"
                        onClick={() => setSelectedPeriodThird("")}
                      >
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                  size="small"
                  value={selectedPeriodThird}
                  onChange={(e) => handlePeriodChange(e, 3)}
                  disabled={dataReportSelected.input_period_third_disabled}
                >
                  {periodOptionsThird?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {/* survey type level */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                  marginTop: "10px",
                  marginLeft: "10%",
                }}
              >
                <FormControl
                  sx={{
                    width: "49%",
                  }}
                >
                  <InputLabel
                    sx={{ mt: "-5px" }}
                    id="demo-multiple-survey-type-label"
                  >
                    Survey Type
                  </InputLabel>
                  <Select
                    multiple
                    sx={{ height: 40 }}
                    labelId="demo-multiple-survey-type-label"
                    id="demo-multiple-survey-type"
                    value={selectedSurveyType}
                    onChange={handleSurveyTypeChange}
                    input={<OutlinedInput label="Status" />}
                    disabled={dataReportSelected.input_survey_type_disabled}
                  >
                    {dataTipeSurvey?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-select-currency"
                  label="Level"
                  select
                  sx={{
                    width: "49%",
                  }}
                  size="small"
                  value={selectedLevel}
                  onChange={handleLevelChange}
                  disabled={dataReportSelected.input_level_disabled}
                >
                  {dataLevel?.map((data) => {
                    return (
                      <MenuItem key={data.title} value={data.title}>
                        {data.title}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
              {/* province kota */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                  marginTop: "10px",
                  marginLeft: "10%",
                }}
              >
                <TextField
                  id="outlined-select-province"
                  label="Pilih Provinsi"
                  select
                  sx={{
                    width: "49%",
                  }}
                  size="small"
                  value={selectedProvince}
                  onChange={(event) => setSelectedProvince(event.target.value)}
                  InputProps={{
                    endAdornment: selectedProvince && (
                      <IconButton
                        sx={{ mr: 1.5 }}
                        edge="end"
                        onClick={() => setSelectedProvince("")}
                      >
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                  disabled={dataReportSelected.input_province_disabled}
                >
                  {provincesOptions.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-select-area"
                  label="Pilih Area"
                  select
                  sx={{
                    width: "49%",
                  }}
                  InputProps={{
                    endAdornment: selectedArea && (
                      <IconButton
                        sx={{ mr: 1.5 }}
                        edge="end"
                        onClick={() => setSelectedArea("")}
                      >
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                  size="small"
                  value={selectedArea}
                  onChange={handleAreaChange}
                  disabled={dataReportSelected.input_regency_disabled}
                >
                  {areasOptions.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {/* kecamatan kelurahan */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                  marginTop: "10px",
                  marginLeft: "10%",
                }}
              >
                <TextField
                  id="outlined-select-district"
                  label="Pilih Kecamatan"
                  select
                  sx={{
                    width: "49%",
                  }}
                  size="small"
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                  InputProps={{
                    endAdornment: selectedDistrict && (
                      <IconButton
                        sx={{ mr: 1.5 }}
                        edge="end"
                        onClick={() => setSelectedDistrict("")}
                      >
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                  disabled={
                    isInputKecamatanDisabled ||
                    dataReportSelected.input_district_disabled
                  }
                >
                  {districtsOptions?.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-select-kelurahan"
                  label="Pilih Kelurahan"
                  select
                  sx={{
                    width: "49%",
                  }}
                  InputProps={{
                    endAdornment: selectedSubDistrict && (
                      <IconButton
                        sx={{ mr: 1.5 }}
                        edge="end"
                        onClick={() => setSelectedSubDistrict("")}
                      >
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                  size="small"
                  value={selectedSubDistrict}
                  onChange={(event) =>
                    setSelectedSubDistrict(event.target.value)
                  }
                  disabled={
                    isInputKotaDisabled ||
                    isInputKecamatanDisabled ||
                    dataReportSelected.input_sub_district_disabled
                  }
                >
                  {subDistrictsOptions?.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {/* region wilayah */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                  marginTop: "10px",
                  marginLeft: "10%",
                }}
              >
                <TextField
                  id="outlined-select-region"
                  label="Pilih Region"
                  select
                  sx={{
                    width: "49%",
                  }}
                  size="small"
                  value={selectedRegion}
                  onChange={(event) => setSelectedRegion(event.target.value)}
                  InputProps={{
                    endAdornment: selectedRegion && (
                      <IconButton
                        sx={{ mr: 1.5 }}
                        edge="end"
                        onClick={() => setSelectedRegion("")}
                      >
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                  disabled={dataReportSelected.input_region_disabled}
                >
                  {regionsOptions.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-select-wilayah"
                  label="Pilih Wilayah"
                  select
                  sx={{
                    width: "49%",
                  }}
                  size="small"
                  value={selectedWilayah}
                  onChange={(event) => setSelectedWilayah(event.target.value)}
                  InputProps={{
                    endAdornment: selectedWilayah && (
                      <IconButton
                        sx={{ mr: 1.5 }}
                        edge="end"
                        onClick={() => setSelectedWilayah("")}
                      >
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                  disabled={dataReportSelected.input_wilayah_disabled}
                >
                  {wilayahOptions.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {/* Confirmation Export */}
              <ModalConfirmation
                open={openDialogConfirmationExport}
                onClose={handleCloseDialog}
                title={"Konfirmasi Download"}
                confirmationText={"Yes"}
                description={`Ditemukan ${viewDataExport.title} terakhir diproses pada ${viewDataExport.process_at}. Apakah anda yakin ingin download reporting non projected ini?`}
                isLoading={isLoading}
                handleClick={handleExport}
              />
              <ModalSyncRawdata
                open={syncModalOpen}
                onClose={handleSyncModalClose}
                isLoading={isLoading}
                handleClick={handleProcessSync}
                textKonfirmasi={`Apakah anda yakin ingin melakukan sinkronisasi rawdata ${selectedReportGroup} period ${selectedPeriodFirst}?`}
              />
                            <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <BootstrapButton
                  variant="contained"
                  style={{
                    color: "#FFF",
                    width: "15%",
                    marginTop: "20px",
                    height: "40px",
                  }}
                  button onClick={() => window.location.href = '/setup-area'}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Setup Area"}
                </BootstrapButton>
                <div
                  style={{
                    // width: "19%",
                    width: "15%",
                    marginLeft: "20px",
                    marginTop: "20px",
                  }}
                >
                  <BootstrapButton
                    onClick={handleSyncModalOpen}
                    style={{
                      color: "#FFF",
                      height: "40px",
                      width: "100%",
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Sync Rawdata"}
                  </BootstrapButton>
                  {dataSyncRawdata.status === "done" && (
                    <p style={{ margin: 0, color: "#747474", fontSize: "14px", marginTop: "10px"}}>
                    {selectedReportGroup} {selectedPeriodFirst} Rawdata synchronization was completed on {dataSyncRawdata.process_at}
                    </p>
                  )}
                  {dataSyncRawdata.status === "progress" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <RefreshIcon onClick={handleRefresh} />
                      <p style={{ margin: 0, marginLeft: 2, color: "#747474", fontSize: "14px", marginTop: "10px"}}>
                        {dataSyncRawdata.title} in {dataSyncRawdata.status}...
                      </p>
                    </div>
                  )}
                </div>
                <BootstrapButton
                  variant="contained"
                  style={{
                    color: "#FFF",
                    // width: "19%",
                    width: "15%",
                    marginTop: "20px",
                    marginLeft: "20px",
                    height: "40px",
                  }}
                  onClick={handleProcess}
                  // disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Process"}
                </BootstrapButton>
                <BootstrapButton
                  variant="contained"
                  style={{
                    color: "#FFF",
                    // width: "19%",
                    width: "15%",
                    height: "40px",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  onClick={handlePreviewExport}
                  // disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Preview Report"}
                </BootstrapButton>
                <BootstrapButton
                  variant="contained"
                  style={{
                    color: "#FFF",
                    // width: "19%",
                    width: "15%",
                    height: "40px",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  onClick={handleConfirmationExport}
                  // disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Download Report"}
                </BootstrapButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessReportNonProjected;