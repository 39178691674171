import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import { fetchProfile } from "../../utils/fetchProfile";
import Cookies from "js-cookie";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import { modalStyle } from "../../Components/Modal/Index";
import { ImportModalSetup } from "../../Components/Fragments/Modals/ModalSetupStoreImport";
import { exportStoreMaster } from "../../services/storeMasterService";
import { Slide, toast } from "react-toastify";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  IconButton,
  Modal,
  Box,
  MenuItem,
  Grid,
} from "@mui/material";
import "../../style/setup.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

const tableCellStyle = {
  whiteSpace: "nowrap",
  backgroundColor: "rgb(6,157,174)",
  color: "#FFF",
  fontWeight: "bold",
  position: "sticky",
  top: 0,
  zIndex: 1000,
  minWidth: 120,
  textAlign: "center",
};

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "5px 10px",
  border: "1px solid",
  width: "fit-content",
  height: "100%",
  lineHeight: 1.5,
  backgroundColor: "#069DAE",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#0696A6",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

function SetupStoreType() {
  const accessToken = Cookies.get("access_token") || "";

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const itemsPerPageList = [5, 10, 50, 100];
  const [totalItems, setTotalItems] = useState("");

  const [dataProfile, setDataProfile] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchBarValue, setSearchBarValue] = useState("setup-store-type");
  const [modalAddSetup, setModalAddSetup] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedStore, setEditedStore] = useState({});
  const [editStoreId, setEditStoreId] = useState(null);
  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [initialText, setInitialText] = useState("");

  const [openDialogImport, setOpenDialogImport] = useState(false);
  const [excelFile, setExcelFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  const dataStatModules = [
    { label: "Setup Category", value: "setup-category" },
    { label: "Setup Store Type", value: "setup-store-type" },
  ];

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, [
        "13",
        "14",
        "TL",
        "COA",
        "A"
      ]);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchMainData();
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken, currentPage, itemsPerPage]);

  async function fetchMainData() {
    const token = Cookies.get("access_token");

    setFilteredRows([]);
    setIsLoading(true);

    const queryParams = new URLSearchParams();

    queryParams.append("page", currentPage);
    queryParams.append("size", itemsPerPage);

    // if (selectedStoreType && selectedStoreType !== 'all') {
    //     queryParams.append('store_type', selectedStoreType);
    // }

    const queryString = queryParams.toString();
    const apiUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/store-type` +
      (queryString ? `?${queryString}` : "");

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setTotalItems(data.pagination_info.total);
        setFilteredRows(data.data);
      } else {
        setFilteredRows([]);
        // Handle error
      }
    } catch (error) {
      // Handle error
    }

    setIsLoading(false);
  }

  async function fetchExportData() {
    const token = Cookies.get("access_token");
    setIsLoading(true);

    const queryParams = new URLSearchParams();

    // if (selectedStoreType && selectedStoreType !== 'all') {
    //     queryParams.append('store_type', selectedStoreType);
    // }

    const queryString = queryParams.toString();
    const apiUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/store-type/export` +
      (queryString ? `?${queryString}` : "");
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Result Store Type.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else if (response.status === 404) {
        alert("data not found");
      } else {
        alert("something went wrong went export data");
      }
    } catch (error) {
      // Handle error
    }

    setIsLoading(false);
  }

  const handleTableSelection = (e) => {
    const targetValue = e.target.value;

    window.location.href = targetValue;
  };

  const [newStoreType, setNewStoreType] = useState({
    id: "",
    code: "",
    name: "",
  });

  const handleSetupInputChange = (event) => {
    const { name, value } = event.target;
    setNewStoreType({
      ...newStoreType,
      [name]: value,
    });
  };

  const handleCreateStoreType = async () => {
    const token = Cookies.get("access_token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/store-type`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newStoreType),
        }
      );

      if (response.status === 201) {
        alert("Store Type berhasil dibuat!");
        setModalAddSetup(false);
        setCurrentPage(1);
        await fetchMainData();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Store Type gagal dibuat!");
      }
    } catch (error) {
      console.error("Error creating Store Type:", error);
    }
  };

  const handleAddNewSetup = () => {
    setModalAddSetup(true);
  };

  const handleEditClick = async (row) => {
    setEditedStore(row);
    setEditStoreId(row.id);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };

  const handleUpdateStoreType = async () => {
    setIsLoading(true);
    if (editStoreId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/store-type/${editStoreId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedStore),
          }
        );

        if (response.status === 200) {
          alert("Data berhasil diubah!");
          setEditModalOpen(false);
          setCurrentPage(1);
          await fetchMainData();
        } else {
          alert("Data gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleExportData = async () => {
    await fetchMainData();

    fetchExportData(accessToken, setIsLoading);
  };

  const handleImportData = () => {
    setOpenDialogImport(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogImport(false);
    setExcelFile("");
    setFileUrl("");
  };

  async function importStoreMasterTemplate() {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", excelFile);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/store-type/import/template`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }
      );

      if (response.status === 201) {
        toast.success("Import Setup Store berhasil dibuat!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        // close
        setOpenDialogImport(false);
        //refresh
        setCurrentPage(1);
        refreshData();
      } else if (response.status === 200) {
        // throw data as blob then download it when validation on excel got fail
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Setup Store Type Error.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        toast.error("Ada masalah di excel. Periksa kembali isi excel!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 400) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error("Gagal mengimport setup store!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error(
          "Failed to import setup store template:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error importing setup store template:", error);
    }
    setIsLoading(false);
  }

  async function exportStoreMasterTemplate() {
    setIsLoading(true);
    const blob = await exportStoreMaster(
      accessToken,
      "template",
      "api/v1/store-type/export/template/base",
      {}
    );
    if (blob) {
      // throw data as blob then download it
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Template Setup Store Type.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      toast.error("Gagal mendownload template setup store!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setIsLoading(false);
  }

  const refreshData = () => {
    fetchMainData();
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await importStoreMasterTemplate();
    setIsLoading(false);
  };

  // pagination
  const handlePageChange = (event, page) => {
    setCurrentPage(page + 1);
  };

  const handleChangeItemPerPageSelect = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Stat Module"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allowed to access this page</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar
        active="Stat Module"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div style={{ padding: "1rem" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: "10px",
            marginBottom: "15px",
          }}
        >
          <Grid container spacing={0.5}>
            <Grid item xs="auto" sm="auto">
              <TextField
                select
                label="Pilih"
                value={searchBarValue}
                onChange={(e) => handleTableSelection(e)}
                style={{ width: "200px" }}
                size="small"
              >
                {dataStatModules.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs="auto" sm="auto">
              <BootstrapButton
                variant="contained"
                disableRipple
                onClick={handleAddNewSetup}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Add"}
              </BootstrapButton>
            </Grid>
            <Grid item xs="auto" sm="auto">
              <BootstrapButton
                variant="contained"
                disableRipple
                onClick={handleImportData}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Upload"}
              </BootstrapButton>
            </Grid>
            <Grid item xs="auto" sm="auto">
              <BootstrapButton
                variant="contained"
                disableRipple
                onClick={handleExportData}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Download"}
              </BootstrapButton>
            </Grid>
            <Grid item xs="auto" sm>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <BootstrapButton
                    variant="contained"
                    disableRipple
                    onClick={() => (window.location.href = "/store-master")}
                    disabled={isLoading}
                    >
                    {isLoading ? "Loading..." : "Go to Store Master"}
                    </BootstrapButton>
                </div>
            </Grid>
          </Grid>
        </div>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 650 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={tableCellStyle}>ID</TableCell>
                  <TableCell style={tableCellStyle}>Kode</TableCell>
                  <TableCell style={tableCellStyle}>Tipe Outlet</TableCell>
                  <TableCell style={tableCellStyle}>Aksi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.length > 0 ? (
                  filteredRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">{row.id}</TableCell>
                      <TableCell align="center">{row.code}</TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title="Edit Data"
                          onClick={() => handleEditClick(row)}
                        >
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      {isLoading ? "Loading..." : "No Data Available"}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* pagination */}
          {filteredRows.length > 0 && (
            <TablePagination
              rowsPerPageOptions={itemsPerPageList}
              component="div"
              count={totalItems}
              rowsPerPage={itemsPerPage}
              page={currentPage - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeItemPerPageSelect}
            />
          )}
        </Paper>

        <Modal
          open={modalAddSetup}
          onClose={() => setModalAddSetup(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ border: 0 }}
        >
          <Box
            sx={{
              ...style,
              width: 900,
              margin: "0 auto",
              maxHeight: "700px",
              overflow: "auto",
              overflowX: "hidden",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Tooltip title="Close Modal">
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setModalAddSetup(false)}
                sx={{ position: "absolute", top: 20, right: 30 }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <div className="store-type">
              <h2 style={{ marginBottom: "15px" }}>Create Store Type</h2>
              <div>
                <TextField
                  label="ID"
                  name="id"
                  value={newStoreType.id}
                  onChange={handleSetupInputChange}
                  sx={{ width: "100%", mb: 2 }}
                />
              </div>
              <div>
                <TextField
                  label="KODE"
                  name="code"
                  value={newStoreType.code}
                  onChange={handleSetupInputChange}
                  sx={{ width: "100%", mb: 2 }}
                />
              </div>
              <div>
                <TextField
                  label="NAME"
                  name="name"
                  value={newStoreType.name}
                  onChange={handleSetupInputChange}
                  sx={{ width: "100%", mb: 2 }}
                />
              </div>
              <div>
                <BootstrapButton
                  variant="contained"
                  disableRipple
                  sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                  onClick={handleCreateStoreType}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Submit"}
                </BootstrapButton>
              </div>
            </div>
          </Box>
        </Modal>

        <Modal
          open={editModalOpen}
          onClose={handleCloseEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ border: 0 }}
        >
          <Box
            sx={modalStyle}
            style={{
              maxHeight: "400px",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            <div className="store-type">
              <h2>Edit Store Type</h2>
              <div>
                <TextField
                  name="code"
                  label="Code"
                  sx={{ width: "100%", mb: 2 }}
                  value={editedStore.code ?? ""}
                  onChange={(e) =>
                    setEditedStore({
                      ...editedStore,
                      code: e.target.value,
                    })
                  }
                ></TextField>

                <TextField
                  name="name"
                  label="Name"
                  sx={{ width: "100%", mb: 2 }}
                  value={editedStore.name ?? ""}
                  onChange={(e) =>
                    setEditedStore({
                      ...editedStore,
                      name: e.target.value,
                    })
                  }
                ></TextField>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant="outlined"
                  disableRipple
                  sx={{
                    width: "50%",
                    height: 50,
                    mt: 2,
                    color: "#b34469",
                    borderColor: "#b34469",
                  }}
                  onClick={handleCloseEditModal}
                >
                  Cancel
                </Button>
                <BootstrapButton
                  variant="contained"
                  disableRipple
                  sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                  onClick={handleUpdateStoreType}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "UPDATE"}
                </BootstrapButton>
              </div>
            </div>
          </Box>
        </Modal>

        <ImportModalSetup
          isLoading={isLoading}
          open={openDialogImport}
          onClose={handleCloseDialog}
          title={"Import Setup Store Type"}
          buttonTitle={"Upload"}
          fileUrl={fileUrl}
          handleSubmit={handleUpload}
          handleChange={{
            excel: setExcelFile,
            fileUrl: setFileUrl,
          }}
          handleExport={{
            template: exportStoreMasterTemplate,
          }}
        />
      </div>
    </div>
  );
}

export default SetupStoreType;
