import Cookies from "js-cookie";
import { React, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  fetchProductMasterColumn,
} from "../../services/productMasterService";


// external
import Navbar from "../../Components/Navbar";
import { fetchProfile } from "../../utils/fetchProfile";
import { fetchPackSize, fetchPackType, fetchFlavour } from "./FilterService";
import { useParams } from "react-router-dom";

import "../../style/productmaster.css";

// button style
const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  padding: "3px 10px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#069DAE",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#0696A6",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

function EditProductPage(data) {
  const { productId } = useParams();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [noStoreData, setNoStoreData] = useState(false);
  const [dataBrandMaster, setDataBrandMaster] = useState([]);
  const [dataStockUnit, setDataStockUnit] = useState([]);
  const [dataCategoryMaster, setDataCategoryMaster] = useState([]);
  const [dataPrincipalMaster, setDataPrincipalMaster] = useState();
  const [unitVolume, setUnitVolume] = useState("");

  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);

  const [storeNameOptions2, setStoreNameOptions2] = useState([]);

  const [selectedFilteredCategory, setSelectedFilteredCategory] = useState("");
  const [filteredPackSize, setFilteredPackSize] = useState([]);
  const [filteredPackType, setFilteredPackType] = useState([]);
  const [filteredFlavour, setFilteredFlavour] = useState([]);

  async function fetchBrandMaster() {
    const token = Cookies.get("access_token") || "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/brand-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataBrandMaster(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/stock-unit`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataStockUnit(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  async function fetchCategoryMaster() {
    const token = Cookies.get("access_token") || "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/category-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataCategoryMaster(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

async function fetchPrincipalMaster() {
    const token = Cookies.get("access_token") || "";
    
    const queryParams = new URLSearchParams();

    // if (editedProduct.brand_master_id) {
    //   queryParams.append("brand_master_id", editedProduct.brand_master_id);
    // }

    // if (editedProduct.category_master_id) {
    //   queryParams.append("category_master_id", editedProduct.category_master_id);
    // }

    const queryString = queryParams.toString();
    const apiUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/principal-master` +
      (queryString ? `?${queryString}` : "");

    try {
      const response = await fetch(apiUrl,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataPrincipalMaster(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  const categorySelector = (event) => {
    if (event && event.target) {
      const selectedCategory = event.target.value;
      setEditedProduct({
        ...editedProduct,
        category_master_id: selectedCategory,
      });
      setSelectedFilteredCategory(selectedCategory);
    } else {
      console.error("Something went wrong");
    }
  };

  // token
  const token = Cookies.get("access_token") || "";

  // edit or update
  const [editedProduct, setEditedProduct] = useState({});
  const [editProductId, setEditProductId] = useState(null);

  const handleEditClick = async (id) => {
    setEditedProduct(await fetchProductMasterSpecific(id));
    setEditProductId(id);
  };

  const stockUnitDescriptionToIdMap = {};
  dataStockUnit.forEach((item) => {
    stockUnitDescriptionToIdMap[item.description] = item.id;
  });

  const ProductGroupLabelToValueMap = {};
  storeNameOptions2.forEach((item) => {
    ProductGroupLabelToValueMap[item.label] = item.value;
  });

  function findNewAndOldItems(newArray, oldArray) {
    const sortedNewArray = newArray.slice().sort();
    const sortedOldArray = oldArray.slice().sort();
    return { sortedNewArray, sortedOldArray };
  }

  function findAddedAndRemovedItemGroups(newArray, oldArray) {
    const addedItemGroups = newArray.filter((item) => !oldArray.includes(item));
    const removedItemGroups = oldArray.filter(
      (item) => !newArray.includes(item)
    );
    return { addedItemGroups, removedItemGroups };
  }

  const handleRedirect = () => {
    const selectedProductGroupId = localStorage.getItem('selectedProductGroupId');
    const selectedCategory = localStorage.getItem('selectedCategory');
    const selectedPrincipal = localStorage.getItem('selectedPrincipal');
    const selectedBrand = localStorage.getItem('selectedBrand');
    const filterColumnName = localStorage.getItem('filterColumnName');
    const filterColumnValue = localStorage.getItem('filterColumnValue');

    const queryParams = new URLSearchParams();

    if (selectedProductGroupId) {
      queryParams.append('product_group_master_id', selectedProductGroupId);
    }
    if (selectedCategory) {
      queryParams.append('category_master_id', selectedCategory);
    }
    if (selectedPrincipal) {
      queryParams.append('principal_master_id', selectedPrincipal);
    }
    if (selectedBrand) {
      queryParams.append('brand_master_id', selectedBrand);
    }
    if (filterColumnName) {
      queryParams.append('column_name', filterColumnName);
    }
    if (filterColumnValue) {
      queryParams.append('column_value', filterColumnValue);
    }

    const queryString = queryParams.toString();
    const redirectUrl = `/product-master` + (queryString ? `?${queryString}` : '');

    window.location.replace(redirectUrl);
  };

  const handleUpdateProduct = async () => {
    setIsLoading(true)
    if (editProductId) {
      let isSuccessUpdate = true;
      const token = Cookies.get("access_token");
      const { sortedNewArray, sortedOldArray } = findNewAndOldItems(
        editedStock,
        selectedOldStock
      );
      if (sortedOldArray.length > 0) {
        try {
          // unassign
          // Create a new FormData object
          const formData = new FormData();

          const stockUnitIds = sortedOldArray.map(
            (description) => stockUnitDescriptionToIdMap[description]
          );
          formData.append("stock_unit_ids", stockUnitIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/stock-unit/unassign`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );

          if (!response.ok) {
            alert("Stock gagal diubah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }

      if (sortedNewArray.length > 0) {
        try {
          // assign
          // Create a new FormData object
          const formData = new FormData();

          // Add the stock_unit_ids to the form data
          const stockUnitIds = sortedNewArray.map(
            (description) => stockUnitDescriptionToIdMap[description]
          );
          formData.append("stock_unit_ids", stockUnitIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/stock-unit/assign`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );

          if (response.ok) {
            alert("Stock berhasil diubah!");
          } else {
            isSuccessUpdate = false;
            alert("Stock gagal diubah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }

      const { addedItemGroups, removedItemGroups } =
        findAddedAndRemovedItemGroups(editedGroup, selectedOldGroup);
      if (addedItemGroups.length > 0) {
        try {
          // assign
          // Create a new FormData object
          const formData = new FormData();

          // Add the groups_ids to the form data
          const groupIds = addedItemGroups.map(
            (label) => ProductGroupLabelToValueMap[label]
          );
          formData.append("product_group_ids", groupIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/group/assign`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );

          if (response.ok) {
            alert("Group berhasil ditambah!");
          } else {
            isSuccessUpdate = false;
            alert("Group gagal ditambah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
      if (removedItemGroups.length > 0) {
        try {
          // unassign
          // Create a new FormData object
          const formData = new FormData();

          const groupIds = removedItemGroups.map(
            (label) => ProductGroupLabelToValueMap[label]
          );
          formData.append("product_group_ids", groupIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/group/unassign`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );

          if (response.ok) {
            alert("Group berhasil diubah!");
          } else {
            isSuccessUpdate = false;
            alert("Group gagal diubah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedProduct),
          }
        );

        const message = await response.json();

        if (response.ok) {
          alert("Produk berhasil diubah!");
        } else if (response.status === 404 || response.status === 400) {
          isSuccessUpdate = false;
          alert(message.detail);
        } else {
          isSuccessUpdate = false;
          alert("Produk gagal diubah!");
        }
      } catch (error) {
        console.error("Error:", error);
      }
      if (isSuccessUpdate) {
        setTimeout(function () {
          handleRedirect();
        }, 500);
      }
    }
    setIsLoading(false)
  };

  async function fetchProductMasterSpecific(id) {
    setIsLoadingData(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-master/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const { data } = await response.json();
        setEditedStock(data.product_stock_unit);
        setSelectedOldStock(data.product_stock_unit);
        setEditedGroup(data.product_group);
        setSelectedOldGroup(data.product_group);
        setIsLoadingData(false);
        return data;
      } else {
        setNoStoreData(true);
        throw new Error("Failed to fetch product master");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoadingData(false);
  }

  const [editedStock, setEditedStock] = useState([]);
  const [selectedOldStock, setSelectedOldStock] = useState([]);

  const handleStockUnitChange = (event) => {
    const {
      target: { value },
    } = event;
    setEditedStock(typeof value === "string" ? value.split(",") : value);
  };

  const [editedGroup, setEditedGroup] = useState([]);
  const [selectedOldGroup, setSelectedOldGroup] = useState([]);

  const handleGroupChange = (event) => {
    const {
      target: { value },
    } = event;
    setEditedGroup(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  async function fetchProductGroup() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-group-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const { data } = await response.json();
        const transformedData = data.map((group) => ({
          value: group.id,
          label: group.name,
        }));
        setStoreNameOptions2(transformedData);
      } else {
        throw new Error("Failed to fetch product groups");
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (productId) {
      handleEditClick(productId);
    }
  }, [productId]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchCategoryMaster();
      fetchBrandMaster();
      fetchProductGroup();
      fetchProductMasterColumn(dispatch, token);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchPrincipalMaster();
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [
    // editedProduct.brand_master_id, editedProduct.category_master_id
  ]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(token, setDataProfile, setNotAllowAccessPage, [
        "TL",
        "13",
        "14",
      ]);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  useEffect(() => {
    if (editedProduct && dataCategoryMaster) {
      const selectedCategory = dataCategoryMaster.find(
        (option) => option.id === editedProduct?.category_master_id
      );
      if (selectedCategory) {
        setUnitVolume(selectedCategory.unitvolume || "");
      } else {
        setUnitVolume("");
      }
    }
  }, [editedProduct, dataCategoryMaster]);

  useEffect(() => {
    setSelectedFilteredCategory(editedProduct?.category_master_id);
  }, [editedProduct?.category_master_id]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchPackSize(token, setFilteredPackSize, selectedFilteredCategory);
      fetchPackType(token, setFilteredPackType, selectedFilteredCategory);
      fetchFlavour(token, setFilteredFlavour, selectedFilteredCategory);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [selectedFilteredCategory]);

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Product Master"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allow to access this page</div>
      </div>
    );
  }
  return (
    <div>
      <Navbar
        active="Product Master"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />

      <div style={{ width: "70%", margin: "0 auto" }}>
        {isLoadingData ? (
          <h2 style={{ textAlign: "center", mt: 30 }}>Loading...</h2>
        ) : !noStoreData ? (
          <div>
            <h2 style={{ textAlign: "center", mt: 2, mb: 2 }}>
              Edit Product Master
            </h2>
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={12} sm={6}>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={12} sm={12} mb={2}>
                    <TextField
                      label="Product Code"
                      sx={{ width: "100%" }}
                      value={editedProduct?.product_id}
                      onChange={(e) =>
                        setEditedProduct({
                          ...editedProduct,
                          product_id: e.target.value,
                        })
                      }
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} mb={2}>
                    <TextField
                      label="Product Name"
                      sx={{ width: "100%" }}
                      value={editedProduct?.product_name}
                      onChange={(e) =>
                        setEditedProduct({
                          ...editedProduct,
                          product_name: e.target.value,
                        })
                      }
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} mb={2}>
                    <TextField
                      label="Barcode"
                      sx={{ width: "100%" }}
                      value={editedProduct?.barcode}
                      onChange={(e) =>
                        setEditedProduct({
                          ...editedProduct,
                          barcode: e.target.value,
                        })
                      }
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} mb={2}>
                    <Autocomplete
                      options={dataCategoryMaster || []}
                      getOptionLabel={(option) => option.name || ""}
                      value={
                        dataCategoryMaster?.find(
                          (option) =>
                            option.id === editedProduct?.category_master_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        categorySelector({
                          target: {
                            value: newValue ? newValue.id : "",
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Category Master"
                          sx={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} mb={2}>
                    <Autocomplete
                      options={dataBrandMaster || []}
                      getOptionLabel={(option) => option.name || ""}
                      value={
                        dataBrandMaster?.find(
                          (option) =>
                            option.id === editedProduct?.brand_master_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setEditedProduct({
                          ...editedProduct,
                          brand_master_id: newValue ? newValue.id : "",
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Brand Master"
                          sx={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} mb={2}>
                    <Autocomplete
                      options={dataPrincipalMaster || []}
                      getOptionLabel={(option) => option.name || ""}
                      value={
                        dataPrincipalMaster?.find(
                          (option) =>
                            option.id === editedProduct?.principal_master_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setEditedProduct({
                          ...editedProduct,
                          principal_master_id: newValue ? newValue.id : "",
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Principal Master"
                          sx={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} mb={2}>
                    <Autocomplete
                      options={filteredPackSize || []}
                      getOptionLabel={(option) => option.atribut_value || ""}
                      value={
                        filteredPackSize?.find(
                          (option) =>
                            option.atribut_value === editedProduct?.packsize
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setEditedProduct({
                          ...editedProduct,
                          packsize: newValue ? newValue.atribut_value : "",
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Pack Size"
                          sx={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container justifyContent="flex-start">
                  <Grid item xs={12} sm={12} mb={2}>
                    <Autocomplete
                      options={filteredPackType || []}
                      getOptionLabel={(option) => option.atribut_value || ""}
                      value={
                        filteredPackType?.find(
                          (option) =>
                            option.atribut_value === editedProduct?.packtype
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setEditedProduct({
                          ...editedProduct,
                          packtype: newValue ? newValue.atribut_value : "",
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Pack Type"
                          sx={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} mb={2}>
                    <Autocomplete
                      options={filteredFlavour || []}
                      getOptionLabel={(option) => option.atribut_value || ""}
                      value={
                        filteredFlavour?.find(
                          (option) =>
                            option.atribut_value === editedProduct?.flavour
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setEditedProduct({
                          ...editedProduct,
                          flavour: newValue ? newValue.atribut_value : "",
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Flavour"
                          sx={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} mb={2}>
                    <TextField
                      label={`Converted Volume${
                        unitVolume ? ` (${unitVolume})` : ""
                      }`}
                      type="number"
                      sx={{ width: "100%" }}
                      value={editedProduct?.convertedvol}
                      onChange={(e) =>
                        setEditedProduct({
                          ...editedProduct,
                          convertedvol: e.target.value,
                        })
                      }
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} mb={2}>
                    <TextField
                      label="Control Price"
                      type="number"
                      sx={{ width: "100%" }}
                      value={editedProduct?.ctrl_price}
                      onChange={(e) =>
                        setEditedProduct({
                          ...editedProduct,
                          ctrl_price: e.target.value,
                        })
                      }
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} mb={2}>
                    <FormControl sx={{ width: "100%" }} shrink>
                      <InputLabel id="demo-multiple-name-label">
                        Stok Unit *
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={editedStock}
                        onChange={handleStockUnitChange}
                        input={<OutlinedInput label="Stok Unit *" />}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      >
                        {dataStockUnit.map((item, index) => (
                          <MenuItem key={index} value={item.description}>
                            {item.code} - {item.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} mb={2}>
                    <FormControl sx={{ width: "100%" }} shrink>
                      <InputLabel id="demo-multiple-name-label-group">
                        Group *
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label-group"
                        id="demo-multiple-name-group"
                        multiple
                        value={editedGroup}
                        onChange={handleGroupChange}
                        input={<OutlinedInput label="Group *" />}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {storeNameOptions2.map((item) => (
                          <MenuItem key={item.label} value={item.label}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    mb={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 2
                    }}
                  >
                    <Button
                      xs={12}
                      sm={6}
                      variant="outlined"
                      disableRipple
                      sx={{
                        height: 55, width: "50%",
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleRedirect}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      xs={12}
                      sm={6}
                      variant="contained"
                      disableRipple
                      sx={{ height: 55, width: "50%", ml: 2 }}
                      type="submit"
                      disabled={isLoading}
                      onClick={handleUpdateProduct}
                    >
                      {isLoading ? "Loading..." : "Update"}
                    </BootstrapButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        ) : (
          <h2 style={{ textAlign: "center", mt: 30 }}>Product can not be found.</h2>
        )}
      </div>
    </div>
  );
}

export default EditProductPage;
