export const dataOperatorAll = [
  {
    'label': "Equal",
    'value': "==",
    'question_type': ['NUM_0', 'SIN_0', 'MUL_0']
  },
  {
    'label': "Not Equal",
    'value': "!=",
    'question_type': ['NUM_0', 'SIN_0', 'MUL_0']
  },
  {
    'label': "More Than",
    'value': ">",
    'question_type': ['NUM_0']
  },
  {
    'label': "More Than Equal",
    'value': ">=",
    'question_type': ['NUM_0']
  },
  {
    'label': "Less Than",
    'value': "<",
    'question_type': ['NUM_0']
  },
  {
    'label': "Less Than Equal",
    'value': "<=",
    'question_type': ['NUM_0']
  },
  // etc
]


export const roleSuperAdminId = "SA";
export const roleAdminId = "A";
export const roleCoAdminId = "COA";
export const roleTeamLeaderId = "TL";
export const roleSurveyorId = "13";
export const roleQcId = "14";
