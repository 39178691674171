import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Cookies from "js-cookie";
import {
  IconButton,
  Modal,
  Box,
  Divider,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Slide, toast } from "react-toastify";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "5px 10px",
  border: "1px solid",
  width: "100px",
  lineHeight: 1.5,
  backgroundColor: "#069DAE",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#0696A6",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const TableCategory = ({ selectedCategoryId, selectedInformation }) => {
  const token = Cookies.get("access_token") || "";
  const [isLoading, setIsLoading] = useState(true);

  const [selectedAttribute, setSelectedAttribute] = useState("packtype");
  const [dataCategories, setDataCategories] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editRowData, setEditRowData] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [newAttribute, setNewAttribute] = useState({
    atribut: "packsize",
    atribut_value: "",
  });

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState(null);

  async function fetchCategories() {
    const queryParams = new URLSearchParams();

    if (selectedAttribute && selectedAttribute !== "") {
      queryParams.append("atribut", selectedAttribute);
    }

    if (selectedCategoryId && selectedCategoryId !== "") {
      queryParams.append("category_master_id", selectedCategoryId);
    }

    const queryString = queryParams.toString();
    const apiUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/product-master-attribute` +
      (queryString ? `?${queryString}` : "");

    setIsLoading(true);
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          authorization: "Bearer " + token,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setDataCategories(data.data);
      }
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  }

  const handleEditAttribute = (params) => {
    setEditRowData(params.row);
    setOpenEditModal(true);
  };

  const handleEditSubmit = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/product-master-attribute/${editRowData.id}`;

    const formBody = {
      category_master_id: selectedCategoryId,
      atribut: editRowData.atribut,
      atribut_value: editRowData.atribut_value,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
        body: JSON.stringify(formBody),
      });

      if (response.ok) {
        toast.success("Data berhasil diubah!");
        setOpenEditModal(false);
        fetchCategories();
      } else {
        const message = await response.json();
        toast.error(message.detail);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteAttribute = (params) => {
    setDeleteRowData(params.row);
    setOpenDeleteModal(true);
  };

  const handleDeleteSubmit = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/product-master-attribute/${deleteRowData.id}`;

    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          authorization: "Bearer " + token,
        },
      });

      if (response.ok) {
        toast.success("Data berhasil dihapus!");
        setOpenDeleteModal(false);
        fetchCategories();
      } else if (response.status === 404) {
        toast.error("Cannot delete data less than one");
      } else {
        const message = await response.json();
        toast.error(message.detail);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleCreateSubmit = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/product-master-attribute`;

    const formBody = {
      category_master_id: selectedCategoryId,
      atribut: selectedAttribute,
      atribut_value: newAttribute.atribut_value,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
        body: JSON.stringify(formBody),
      });

      if (response.ok) {
        toast.success("Data berhasil diubah!");
        setOpenCreateModal(false);
        setNewAttribute({ atribut: "packsize", atribut_value: "" });
        fetchCategories();
      } else {
        const message = await response.json();
        toast.error(message.detail);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const Categories = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      flex: 1,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    // {
    //   field: "category_master",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Category Master",
    //   flex: 2,
    //   align: "left",
    //   headerAlign: "center",
    //   editable: false,
    //   renderCell: (params) => params.row.category_master.name,
    // },
    {
      field: "atribut_value",
      headerClassName: "super-app-theme--header",
      headerName: "Atribut",
      flex: 2,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      flex: 2,
      renderCell: (params) => (
        <div>
          {/* <Tooltip
            title="Edit Category"
            onClick={() => handleEditAttribute(params)}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip> */}
          <Tooltip
            title="Delete Category"
            onClick={() => handleDeleteAttribute(params)}
          >
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchCategories(selectedAttribute);
  }, [selectedAttribute]);

  return (
    <div style={{ padding: 7 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <p>ID : {selectedInformation?.id}</p>
          <p>Name : {selectedInformation?.name}</p>
          <p>Ord Category : {selectedInformation?.ord_category}</p>
        </div>
        <div
          style={{
            width: "fit-content",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <TextField
            label="Atribut"
            name="packsize"
            select
            value={selectedAttribute}
            onChange={(e) => {
              setSelectedAttribute(e.target.value);
            }}
            sx={{ width: "200px", mt: 2 }}
          >
            <MenuItem key="packtype" value="packtype">
              Pack Type
            </MenuItem>
            <MenuItem key="packsize" value="packsize">
              Pack Size
            </MenuItem>
            <MenuItem key="flavour" value="flavour">
              Flavour
            </MenuItem>
          </TextField>
          <BootstrapButton
            variant="contained"
            disableRipple
            onClick={() => setOpenCreateModal(true)}
            sx={{ mt: 2 }}
          >
            {isLoading ? "Loading..." : "Add"}
          </BootstrapButton>
        </div>
      </div>
      <div>
        {isLoading ? (
          <div>
            <p style={{ textAlign: "center", margin: "5% 0px" }}>Loading...</p>
          </div>
        ) : dataCategories.length === 0 ? (
          <p style={{ textAlign: "center", margin: "5% 0px" }}>Data Kosong</p>
        ) : (
          <DataGrid
            autoWidth
            sx={{
              maxWidth: "100%",
              fontSize: 15,
              mt: 2,
              "& .super-app-theme--header": {
                backgroundColor: "rgb(6,157,174)",
                color: "#FFF",
                border: 2,
                fontWeight: "bold",
                borderColor: "#FFF",
              },
            }}
            getRowHeight={() => "auto"}
            getRowId={(row) => row.id}
            rows={dataCategories}
            columns={Categories}
          />
        )}
      </div>
      {editRowData && (
        <Modal
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="edit-modal-title"
          aria-describedby="edit-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              bgcolor: "background.paper",
              border: "0px solid #000",
              borderRadius: "4px",
              boxShadow: 24,
              overflowY: "auto",
              maxHeight: 600,
              p: 3,
            }}
          >
            <h2 id="edit-modal-title">Edit Atribut</h2>
            <Divider />
            <TextField
              label="Atribut"
              value={editRowData.atribut}
              onChange={(e) =>
                setEditRowData({ ...editRowData, atribut: e.target.value })
              }
              fullWidth
              margin="normal"
              select
            >
              <MenuItem key="packtype" value="packtype">
                Pack Type
              </MenuItem>
              <MenuItem key="packsize" value="packsize">
                Pack Size
              </MenuItem>
              <MenuItem key="flavour" value="flavour">
                Flavour
              </MenuItem>
            </TextField>
            <TextField
              label="Atribut Value"
              value={editRowData.atribut_value}
              onChange={(e) =>
                setEditRowData({
                  ...editRowData,
                  atribut_value: e.target.value,
                })
              }
              fullWidth
              margin="normal"
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <BootstrapButton
                variant="contained"
                disableRipple
                onClick={() => setOpenEditModal(false)}
                sx={{ mt: 2 }}
              >
                Cancel
              </BootstrapButton>
              <BootstrapButton
                variant="contained"
                disableRipple
                onClick={handleEditSubmit}
                sx={{ mt: 2 }}
              >
                Submit
              </BootstrapButton>
            </div>
          </Box>
        </Modal>
      )}
      {deleteRowData && (
        <Modal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="delete-modal-title"
          aria-describedby="delete-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "0px solid #000",
              borderRadius: "4px",
              boxShadow: 24,
              overflowY: "auto",
              maxHeight: 600,
              p: 3,
            }}
          >
            <h2 id="delete-modal-title">Konfirmasi Hapus</h2>
            <Divider />
            <p>Apakah anda yakin ingin menghapus data ini?</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <BootstrapButton
                variant="contained"
                disableRipple
                onClick={() => setOpenDeleteModal(false)}
                sx={{ mt: 2 }}
              >
                {isLoading ? "Loading..." : "Cancel"}
              </BootstrapButton>
              <BootstrapButton
                variant="contained"
                disableRipple
                onClick={handleDeleteSubmit}
                sx={{ mt: 2 }}
              >
                {isLoading ? "Loading..." : "Confirm"}
              </BootstrapButton>
            </div>
          </Box>
        </Modal>
      )}

      <Modal
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        aria-labelledby="create-modal-title"
        aria-describedby="create-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            border: "0px solid #000",
            borderRadius: "4px",
            boxShadow: 24,
            overflowY: "auto",
            maxHeight: 600,
            p: 3,
          }}
        >
          <h2 id="create-modal-title">Create New Data</h2>
          <Divider />
          <TextField
            label="Atribut"
            value={selectedAttribute}
            onChange={(e) => setSelectedAttribute(e.target.value)}
            fullWidth
            margin="normal"
            select
          >
            <MenuItem key="packtype" value="packtype">
              Pack Type
            </MenuItem>
            <MenuItem key="packsize" value="packsize">
              Pack Size
            </MenuItem>
            <MenuItem key="flavour" value="flavour">
              Flavour
            </MenuItem>
          </TextField>
          <TextField
            label="Atribut Value"
            value={newAttribute.atribut_value}
            onChange={(e) =>
              setNewAttribute({
                ...newAttribute,
                atribut_value: e.target.value,
              })
            }
            fullWidth
            margin="normal"
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <BootstrapButton
              variant="contained"
              disableRipple
              onClick={() => setOpenCreateModal(false)}
              sx={{ mt: 2 }}
            >
              {isLoading ? "Loading..." : "Cancel"}
            </BootstrapButton>
            <BootstrapButton
              variant="contained"
              disableRipple
              onClick={handleCreateSubmit}
              sx={{ mt: 2 }}
            >
              {isLoading ? "Loading..." : "Submit"}
            </BootstrapButton>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default TableCategory;
