import { setStoreMasterColumn, setStoreMasters, setTotal } from '../redux/slices/storeMasterSlice';

export const fetchStoreMaster = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/store-master`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(setStoreMasters(data.data));
      dispatch(setTotal(data.pagination_info.total));
    } else {
      dispatch(setStoreMasters([]));
      console.error(`Failed to fetch store masters:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching store masters:`, error);
  }
};


export const fetchStoreMasterById = async (accessToken, id) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/store-master/${id}`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data.data;
    } else {
      console.error(`Failed to fetch store master:`, response.statusText);
      return;
    }
  } catch (error) {
    console.error(`Error fetching store master:`, error);
  }
};

export const fetchStorePhoto = async (accessToken, storeCode) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_MOBILE_URL}/survey/get_images?client_code=${process.env.REACT_APP_CLIENT_CODE}&store_code=${storeCode}&title=SP2`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.ok) {
      const { data } = await response.json();

      if (Array.isArray(data) && data.length > 0) {
        const imageUrls = data.map((item, index) => {
          const { survey_id, file_name, question_id, participant_id } = item;
          const imageUrl = `${process.env.REACT_APP_API_MOBILE_URL}/survey/view_image?survey_id=${survey_id}&question_id=${question_id}&participant_id=${participant_id}&file_name=${file_name}`
          return { id: index, file: imageUrl };
        });

        return imageUrls;
      }
    }
    return [];
  } catch (error) {
    // Handle error
    console.error(error)
    return [];
  }
}

export const fetchStorePhotoBySurveyIdStoreCode = async (accessToken, surveyId, storeCode) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_MOBILE_URL}/survey/get_images?client_code=${process.env.REACT_APP_CLIENT_CODE}&survey_id=${surveyId}&store_code=${storeCode}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.ok) {
      const { data } = await response.json();
      
      if (Array.isArray(data) && data.length > 0) {
        const imageUrls = data.map((item, index) => {
          const { survey_id, file_name, question_id, participant_id } = item;
          const imageUrl = `${process.env.REACT_APP_API_MOBILE_URL}/survey/view_image?survey_id=${survey_id}&question_id=${question_id}&participant_id=${participant_id}&file_name=${file_name}`
          return { id: index, file: imageUrl };
        });

        return imageUrls;
      }
    }
    return [];
  } catch (error) {
    // Handle error
    console.error(error)
    return [];
  }
}

export const exportStoreMaster = async (accessToken, title, url, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/${url}`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;
    
    const response = await fetch(fullUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      return blob;
    } else {
      console.error(`Failed to export ${title} store:`, response.statusText);
      return;
    }
  } catch (error) {
    console.error(`Error exporting ${title} store:`, error);
  }
};

export const fetchStoreMasterColumn = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/store-master-columns`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(setStoreMasterColumn(data.data));
    } else {
      dispatch(setStoreMasterColumn([]));
      console.error(`Failed to fetch store master column:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching store master column:`, error);
  }
};
