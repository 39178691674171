import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompletenessProduct,
  fetchCompletenessStoreGT,
} from "../../../../services/completenessService";
import {
  setCurrentPage,
  setItemsPerPage,
} from "../../../../redux/slices/completenessProductSlice";
import {
  setCurrentPageStore,
  setItemsPerPageStore,
} from "../../../../redux/slices/completenessStoreSlice";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const tableCellStyle = {
  whiteSpace: "normal",
  wordWrap: "break-word",
  wordBreak: "break-word",
  backgroundColor: "black",
  color: "#FFF",
  fontWeight: "bold",
  position: "sticky",
  top: 0,
  zIndex: 1000,
  minWidth: 120,
  textAlign: "center",
};

const itemsPerPageList = [5, 10, 50, 100];

export const TableCompleteness = ({ onEdit, itemsPerPageList }) => {
  const dispatch = useDispatch();

  const items = useSelector((state) => state.completenessProduct.data);
  const accessToken = useSelector((state) => state.general.accessToken);
  const currentPage = useSelector(
    (state) => state.completenessProduct.currentPage
  );
  const itemsPerPage = useSelector(
    (state) => state.completenessProduct.itemsPerPage
  );
  const total = useSelector((state) => state.completenessProduct.total);

  const selectedProductId = useSelector(
    (state) => state.completenessProduct.selectedProductId
  );
  const selectedProductName = useSelector(
    (state) => state.completenessProduct.selectedProductName
  );
  const selectedNotes = useSelector(
    (state) => state.completenessProduct.selectedNotes
  );

  const [sortBy, setSortBy] = useState("product_name");
  const [sortOrder, setSortOrder] = useState("asc");

  function fetchData(currentPage, itemsPerPage, sortBy, sortOrder) {
    dispatch(setCurrentPage(currentPage));
    const customParams = {
      size: itemsPerPage,
      page: currentPage,
      product_id: selectedProductId,
      product_name: selectedProductName,
      notes: selectedNotes,
      sort_by: sortBy,
      sort_order: sortOrder,
    };
    fetchCompletenessProduct(dispatch, accessToken, customParams);
  }

  useEffect(() => {
    fetchData(currentPage || 1, itemsPerPage, sortBy, sortOrder);
  }, [
    dispatch,
    currentPage,
    itemsPerPage,
    selectedProductId,
    selectedProductName,
    selectedNotes,
    sortBy,
    sortOrder,
  ]);

  const handlePageChange = (event, page) => {
    fetchData(page + 1, itemsPerPage, sortBy, sortOrder);
  };

  const handleChangeItemPerPageSelect = (e) => {
    // set how many items are selected
    dispatch(setItemsPerPage(e.target.value));
    // this will set again to page 1
    fetchData(1, e.target.value);
  };

  const handleSort = (column) => {
    const isSorting = sortBy === column && sortOrder === "asc";
    setSortOrder(isSorting ? "desc" : "asc");
    setSortBy(column);
  };

  const sortTableCell = (label, sortByKey) => (
    <TableCell style={tableCellStyle}>
      <TableSortLabel
        active={sortBy === sortByKey}
        direction={sortOrder}
        onClick={() => handleSort(sortByKey)}
        sx={{
          color: "#FFF !important",
          "&:hover": {
            color: "#FFF",
          },
          "& .MuiTableSortLabel-icon": {
            color: "#FFF !important",
          },
        }}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table aria-label="table product master">
          {/* header */}
          <TableHead>
            <TableRow>
              {sortTableCell("KODE PRODUK", "product_id")}
              {sortTableCell("NAMA PRODUK", "product_name")}
              {sortTableCell("CATATAN", "notes")}
              <TableCell style={tableCellStyle} align="center">
                Aksi
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length > 0 ? (
              items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{item.product_id}</TableCell>
                  <TableCell align="center">{item.product_name}</TableCell>
                  <TableCell align="center">{item.notes}</TableCell>
                  <TableCell align="center">
                    <Tooltip
                      title="Edit Product Master"
                      onClick={() => onEdit(item.product_master_id)}
                    >
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {items.length > 0 && (
        <TablePagination
          rowsPerPageOptions={itemsPerPageList}
          component="div"
          count={total}
          rowsPerPage={itemsPerPage}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeItemPerPageSelect}
        />
      )}
    </Paper>
  );
};

export const TableCompletenessStoreGT = ({ onEdit }) => {
  const { id } = useParams();
  const [projectType, setProjectType] = useState(null);
  const dispatch = useDispatch();
  const items = useSelector((state) => state.completenessStore.data);
  const accessToken = useSelector((state) => state.general.accessToken);
  const currentPage = useSelector(
    (state) => state.completenessStore.currentPage
  );
  const itemsPerPage = useSelector(
    (state) => state.completenessStore.itemsPerPage
  );
  const selectedStoreId = useSelector(
    (state) => state.completenessStore.selectedStoreId
  );
  const selectedStoreName = useSelector(
    (state) => state.completenessStore.selectedStoreName
  );
  const selectedNotes = useSelector(
    (state) => state.completenessStore.selectedNotes
  );
  const total = useSelector((state) => state.completenessStore.total);

  // role code
  const roleCode = useSelector((state) => state.profile.role_code);

  //sorting
  const [sortBy, setSortBy] = useState("store_id");
  const [sortOrder, setSortOrder] = useState("desc");

  // fetch data
  function fetchData(currentPage, itemsPerPage, sortBy, sortOrder) {
    dispatch(setCurrentPageStore(currentPage));
    const customParams = {
      size: itemsPerPage,
      page: currentPage,
      store_id: selectedStoreId,
      store_name: selectedStoreName,
      notes: selectedNotes,
      type: projectType,
      sort_by: sortBy,
      sort_order: sortOrder,
    };
    fetchCompletenessStoreGT(dispatch, accessToken, customParams);
  }

  useEffect(() => {
    if (projectType) {
      fetchData(currentPage || 1, itemsPerPage, sortBy, sortOrder);
    }
  }, [
    dispatch,
    currentPage,
    itemsPerPage,
    selectedStoreId,
    selectedStoreName,
    selectedNotes,
    sortBy,
    sortOrder,
    projectType
  ]);

  useEffect(() => {
    if (id) {
      setProjectType(id);
    }
  }, [id]);

  const handlePageChange = (event, page) => {
    fetchData(page + 1, itemsPerPage, sortBy, sortOrder);
  };

  const handleChangeItemPerPageSelect = (e) => {
    // set how many items are selected
    dispatch(setItemsPerPageStore(e.target.value));
    // this will set again to page 1
    fetchData(1, e.target.value);
  };

  const handleSort = (column) => {
    const isSorting = sortBy === column && sortOrder === "asc";
    setSortOrder(isSorting ? "desc" : "asc");
    setSortBy(column);
  };

  const sortTableCell = (label, sortByKey) => (
    <TableCell style={tableCellStyle}>
      <TableSortLabel
        active={sortBy === sortByKey}
        direction={sortOrder}
        onClick={() => handleSort(sortByKey)}
        sx={{
          color: "#FFF !important",
          "&:hover": {
            color: "#FFF",
          },
          "& .MuiTableSortLabel-icon": {
            color: "#FFF !important",
          },
        }}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table aria-label="table store master">
          {/* header */}
          <TableHead>
            <TableRow>
              {sortTableCell("Kode Outlet", "store_id")}
              {sortTableCell("Nama Outlet", "store_name")}
              {sortTableCell("Catatan", "notes")}
              <TableCell style={tableCellStyle} align="center">
                Aksi
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length > 0 ? (
              items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{item.store_id}</TableCell>
                  <TableCell align="center">{item.store_name}</TableCell>
                  <TableCell align="center">{item.notes}</TableCell>
                  <TableCell align="center">
                    <Tooltip
                      title="Edit Store Master"
                      onClick={() => onEdit(item.store_master_id)}
                    >
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* pagination */}
      {items.length > 0 && (
        <TablePagination
          rowsPerPageOptions={itemsPerPageList}
          component="div"
          count={total}
          rowsPerPage={itemsPerPage}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeItemPerPageSelect}
        />
      )}
    </Paper>
  );
};

export const TableCompletenessStoreMT = ({ onEdit, itemsPerPageList }) => {
  const dispatch = useDispatch();

  const items = useSelector((state) => state.completenessStore.data);
  const accessToken = useSelector((state) => state.general.accessToken);
  const currentPage = useSelector(
    (state) => state.completenessStore.currentPage
  );
  const itemsPerPage = useSelector(
    (state) => state.completenessStore.itemsPerPage
  );
  const selectedStoreId = useSelector(
    (state) => state.completenessStore.selectedStoreId
  );
  const selectedStoreName = useSelector(
    (state) => state.completenessStore.selectedStoreName
  );
  const selectedNotes = useSelector(
    (state) => state.completenessStore.selectedNotes
  );
  const total = useSelector((state) => state.completenessStore.total);

  const [sortBy, setSortBy] = useState("store_name");
  const [sortOrder, setSortOrder] = useState("asc");

  function fetchData(currentPage, itemsPerPage, sortBy, sortOrder) {
    dispatch(setCurrentPageStore(currentPage));
    const customParams = {
      size: itemsPerPage,
      page: currentPage,
      store_id: selectedStoreId,
      store_name: selectedStoreName,
      notes: selectedNotes,
      type: "rdc_mt",
      sort_by: sortBy,
      sort_order: sortOrder,
    };
    fetchCompletenessStoreGT(dispatch, accessToken, customParams);
  }

  useEffect(() => {
    fetchData(currentPage || 1, itemsPerPage, sortBy, sortOrder);
  }, [
    dispatch,
    currentPage,
    itemsPerPage,
    selectedStoreId,
    selectedStoreName,
    selectedNotes,
    sortBy,
    sortOrder,
  ]);

  const handlePageChange = (event, page) => {
    fetchData(page + 1, itemsPerPage, sortBy, sortOrder);
  };

  const handleChangeItemPerPageSelect = (e) => {
    // set how many items are selected
    dispatch(setItemsPerPageStore(e.target.value));
    // this will set again to page 1
    fetchData(1, e.target.value);
  };

  const handleSort = (column) => {
    const isSorting = sortBy === column && sortOrder === "asc";
    setSortOrder(isSorting ? "desc" : "asc");
    setSortBy(column);
  };

  const sortTableCell = (label, sortByKey) => (
    <TableCell style={tableCellStyle}>
      <TableSortLabel
        active={sortBy === sortByKey}
        direction={sortOrder}
        onClick={() => handleSort(sortByKey)}
        sx={{
          color: "#FFF !important",
          "&:hover": {
            color: "#FFF",
          },
          "& .MuiTableSortLabel-icon": {
            color: "#FFF !important",
          },
        }}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table aria-label="table store master">
          {/* header */}
          <TableHead>
            <TableRow>
              {sortTableCell("Kode Outlet", "store_id")}
              {sortTableCell("Nama Outlet", "store_name")}
              {sortTableCell("Catatan", "notes")}
              <TableCell style={tableCellStyle} align="center">
                Aksi
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length > 0 ? (
              items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{item.store_id}</TableCell>
                  <TableCell align="center">{item.store_name}</TableCell>
                  <TableCell align="center">{item.notes}</TableCell>
                  <TableCell align="center">
                    <Tooltip
                      title="Edit Store Master"
                      onClick={() => onEdit(item.store_master_id)}
                    >
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {items.length > 0 && (
        <TablePagination
          rowsPerPageOptions={itemsPerPageList}
          component="div"
          count={total}
          rowsPerPage={itemsPerPage}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeItemPerPageSelect}
        />
      )}
    </Paper>
  );
};
