import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setItemsPerPage } from '../../../redux/slices/productMasterSlice';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import ImageIcon from "@mui/icons-material/Image";
import EditIcon from "@mui/icons-material/Edit";
import { fetchProductMaster } from '../../../services/productMasterService';

export const TableProductMaster = ({ onView, onViewModalOpen, itemsPerPageList }) => {
  const dispatch = useDispatch();

  const items = useSelector((state) => state.productMaster.data);
  const accessToken = useSelector((state) => state.general.accessToken);
  const currentPage = useSelector((state) => state.productMaster.currentPage);
  const itemsPerPage = useSelector((state) => state.productMaster.itemsPerPage);
  const selectedProductGroupId = useSelector((state) => state.productMaster.selectedProductGroupId);
  const selectedBrand = useSelector((state) => state.productMaster.selectedBrand);
  const selectedCategory = useSelector((state) => state.productMaster.selectedCategory);
  const selectedPrincipal = useSelector((state) => state.productMaster.selectedPrincipal);
  const total = useSelector((state) => state.productMaster.total);

  const filterColumnName = useSelector((state) => state.productMaster.filterColumnName);
  const filterColumnOperator = useSelector((state) => state.productMaster.filterColumnOperator);
  const filterColumnValue = useSelector((state) => state.productMaster.filterColumnValue);

  const [sortBy, setSortBy] = useState('product_name');
  const [sortOrder, setSortOrder] = useState('asc');

  function fetchData(currentPage, itemsPerPage, sortBy, sortOrder) {
    dispatch(setCurrentPage(currentPage));
    const customParams = {
      size: itemsPerPage,
      page: currentPage,
      product_group_master_id: selectedProductGroupId,
      category_master_id: selectedCategory,
      principal_master_id: selectedPrincipal,
      brand_master_id: selectedBrand,
      column_name: filterColumnName,
      column_operator: filterColumnOperator,
      column_value: filterColumnValue,
      sort_by: sortBy,
      sort_order: sortOrder
    };
    fetchProductMaster(dispatch, accessToken, customParams);
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchData(currentPage || 1, itemsPerPage, sortBy, sortOrder);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [
    dispatch,
    currentPage,
    itemsPerPage,
    selectedProductGroupId,
    selectedCategory,
    selectedPrincipal,
    selectedBrand,
    filterColumnName,
    sortBy,
    sortOrder
  ]);

  const handlePageChange = (event, page) => {
    fetchData(page + 1, itemsPerPage, sortBy, sortOrder);
  };

  const handleChangeItemPerPageSelect = (e) => {
    // set how many items are selected
    dispatch(setItemsPerPage(e.target.value));
    // this will set again to page 1
    fetchData(1, e.target.value)
  };

  const handleSort = (column) => {
    const isSorting = sortBy === column && sortOrder === 'asc';
    setSortOrder(isSorting ? 'desc' : 'asc');
    setSortBy(column);
  }

  // const columns = [
  //   { id: 1, label: 'KODE PRODUK' },
  //   { id: 2, label: 'NAMA PRODUK' },
  //   { id: 3, label: 'KATEGORI PRODUK' },
  //   { id: 4, label: 'NAMA PRINSIPAL' },
  //   { id: 5, label: 'NAMA BRAND' },
  //   { id: 6, label: 'STOK UNIT PRODUK' },
  //   { id: 7, label: 'TANGGAL DIBUAT' },
  //   { id: 8, label: 'TANGGAL DIPERBARUI' },
  //   { id: 9, label: 'ACTIONS' },
  // ];

  const sortTableCell = (label, sortByKey) => (
    <TableCell style={tableCellStyle}>
      <TableSortLabel
        active={sortBy === sortByKey}
        direction={sortOrder}
        onClick={() => handleSort(sortByKey)}
        sx={{
          color: '#FFF !important',
          '&:hover': {
            color: '#FFF',
          },
          '& .MuiTableSortLabel-icon': {
            color: '#FFF !important',
          },
        }}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  const tableCellStyle = {
    whiteSpace: 'nowrap',
    backgroundColor: "rgb(6,157,174)",
    color: "#FFF",
    fontWeight: "bold",
    position: "sticky",
    top: 0,
    zIndex: 1000,
    minWidth: 120,
  };


  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table aria-label="table product master">
          {/* header */}
          <TableHead>
            <TableRow>
              {sortTableCell('KODE PRODUK', 'product_id')}
              {sortTableCell('NAMA PRODUK', 'product_name')}
              {sortTableCell('KATEGORI PRODUK', 'category_master_id')}
              {sortTableCell('NAMA PRINSIPAL', 'principal_master_id')}
              {sortTableCell('NAMA BRAND', 'brand_master_id')}
              <TableCell style={tableCellStyle}>STOK UNIT PRODUK</TableCell>
              {sortTableCell('TANGGAL DIBUAT', 'created_at')}
              {sortTableCell('TANGGAL DIPERBARUI', 'updated_at')}
              <TableCell style={tableCellStyle}>Aksi</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length > 0 ? (
              items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.product_id}</TableCell>
                  <TableCell>{item.product_name}</TableCell>
                  <TableCell>{item.category_master}</TableCell>
                  <TableCell>{item.principal_master}</TableCell>
                  <TableCell>{item.brand_master}</TableCell>
                  <TableCell>{item.product_stock_unit.toString()}</TableCell>
                  <TableCell>{item.created_at}</TableCell>
                  <TableCell>{item.updated_at}</TableCell>
                  <TableCell>
                    <Tooltip
                      title="View Product Master"
                      onClick={() => {
                        onView(item.id, item.product_id);
                        onViewModalOpen(true);
                      }}
                    >
                      <IconButton>
                        <ImageIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Edit Product Master"
                    >
                      <a 
                        href={`/product-master/edit/${item.id}`}
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </a>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {items.length > 0 && (
        <TablePagination
          rowsPerPageOptions={itemsPerPageList}
          component="div"
          count={total}
          rowsPerPage={itemsPerPage}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeItemPerPageSelect}
        />
      )}
    </Paper>
  );
};
