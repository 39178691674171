import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#069dae",
    color: theme.palette.common.white,
    border: "solid 1px #fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '6px 16px', 
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '40px',
}));

function createData(brand, product, group) {
  return { brand, product, group };
}

const rows = [
  createData('Air Sejuk Adem Sari', 'Air Sejuk Adem Sari', 'Pereda Panas Dalam'),
];

export default function TableMaster() {
  return (
    <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ height: "10px" }}>
            <StyledTableCell>NAMABRAND</StyledTableCell>
            <StyledTableCell align="left">NAMAPRODUCT</StyledTableCell>
            <StyledTableCell align="left">NAMAGROUP</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.brand}>
              <StyledTableCell component="th" scope="row">
                {row.brand}
              </StyledTableCell>
              <StyledTableCell align="left">{row.product}</StyledTableCell>
              <StyledTableCell align="left">{row.group}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}