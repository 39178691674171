import React, { useState, useEffect } from "react";
import Navbar from "../../../Components/Navbar";
import { fetchProfile } from "../../../utils/fetchProfile";
import Cookies from "js-cookie";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { IconButton, Modal, Box, Divider, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchBarSetupMaster from "./SearchBarSetupMaster";
import TextField from "@mui/material/TextField";
import { DataGrid, GridRowEditStopReasons } from "@mui/x-data-grid";
import DataGridSetup from "./DataGridSetup"
import EditIcon from '@mui/icons-material/Edit';
import { modalStyle } from "../../../Components/Modal/Index";
import { fetchDataStoreMaster, fetchExportDataStoreMaster } from "./DataUtil";
import { ImportModalSetup } from "../../../Components/Fragments/Modals/ModalSetupStoreImport";
import { exportStoreMaster } from "../../../services/storeMasterService";
import { Slide, toast } from 'react-toastify';
import "../../../style/setup.css"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "5px 10px",
  border: "1px solid",
  marginLeft: "10px",
  width: "100px",
  lineHeight: 1.5,
  backgroundColor: "#069DAE",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#0696A6",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

function SetUpStoreMaster() {
  const accessToken = Cookies.get("access_token") || "";
  const [dataProfile, setDataProfile] = useState([]);
  const [dataStoreType, setDataStoreType] = useState([]);
  const [dataJenisOutlet, setDataJenisOutlet] = useState([]);
  const [dataTipeSurvey, setDataTipeSurvey] = useState([]);
  const [dataTipeJalan, setDataTipeJalan] = useState([]);
  const [dataTipeLokasi, setDataTipeLokasi] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchBarValue, setSearchBarValue] = useState("store_type");
  const [selectedOption, setSelectedOption] = useState("store_type");
  const [selectedUpdate, setSelectedUpdate] = useState("store_type");
  const [modalAddSetup, setModalAddSetup] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedStore, setEditedStore] = useState({});
  // const [editedLokasi, setEditedLokasi] = useState({

  //   desc: ""
  // });
  const [editStoreId, setEditStoreId] = useState(null);
  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedTable, setSelectedTable] = useState("store_type");
  const [initialText, setInitialText] = useState("");

  const [openDialogImport, setOpenDialogImport] = useState(false);
  const [excelFile, setExcelFile] = useState('');
  const [fileUrl, setFileUrl] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, ["13", "14"]);
      fetchStoreType();
      fetchJenisOutlet();
      fetchTipeSurvey();
      fetchTipeJalan();
      fetchTipeLokasi();
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchDataStoreMaster(
        accessToken,
        setIsLoading,
        setFilteredRows,
      );
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  async function fetchStoreType() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/store-type`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken
        }
      });

      if (response.ok) {
        const data = await response.json();
        setDataStoreType(data.data);
      }
    } catch (e) {
      console.error(e);
    }
  }
  async function fetchJenisOutlet() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/jenis-outlet`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken
        }
      });

      if (response.ok) {
        const data = await response.json();
        setDataJenisOutlet(data.data);
      } else {
        console.error("Error fetching Jenis Outlet:", response.statusText);
      }
    } catch (e) {
      console.error("Error fetching Jenis Outlet:", e);
    }
  }

  async function fetchTipeSurvey() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/tipe-survey`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken
        }
      });

      if (response.ok) {
        const data = await response.json();
        setDataTipeSurvey(data.data);
      } else {
        console.error("Error fetching Tipe Survey:", response.statusText);
      }
    } catch (e) {
      console.error("Error fetching Tipe Survey:", e);
    }
  }


  async function fetchTipeJalan() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/tipe-jalan`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken
        }
      });

      if (response.ok) {
        const data = await response.json();
        setDataTipeJalan(data.data);
      }
    } catch (e) {
      console.error(e);
    }
  }
  async function fetchTipeLokasi() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/tipe-lokasi`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken
        }
      });

      if (response.ok) {
        const data = await response.json();
        setDataTipeLokasi(data.data);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const storeType = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "code",
      headerClassName: "super-app-theme--header",
      headerName: "KODE",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "NAME",
      width: 202,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "",
      headerClassName: "super-app-theme--header",
      headerName: "ACTION",
      width: 202,
      align: "center",
      headerAlign: "center",
      editable: false,
      renderCell: (params) => (
        <div>
          {dataProfile && (dataProfile.role_code === 'A' || dataProfile.role_code === 'COA') ?
            <>
              <Tooltip title="Edit Store Type" onClick={() => handleEditClick(params)}>
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </> : null}
        </div>
      ),
    },
  ];

  const jenisOutlet = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "desc",
      headerClassName: "super-app-theme--header",
      headerName: "DESC",
      width: 400,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "",
      headerClassName: "super-app-theme--header",
      headerName: "ACTION",
      width: 202,
      align: "center",
      headerAlign: "center",
      editable: false,
      renderCell: (params) => (
        <div style={{ justifyContent: "center" }}>
          {dataProfile && (dataProfile.role_code === 'A' || dataProfile.role_code === 'COA') ?
            <>
              <Tooltip title="Edit Jenis Outlet" onClick={() => handleEditClick(params)}>
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </> : null}
        </div>
      ),
    },
  ];

  const tipeSurvey = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "desc",
      headerClassName: "super-app-theme--header",
      headerName: "DESC",
      width: 400,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "",
      headerClassName: "super-app-theme--header",
      headerName: "ACTION",
      width: 202,
      align: "center",
      headerAlign: "center",
      editable: false,
      renderCell: (params) => (
        <div>
          {dataProfile && (dataProfile.role_code === 'A' || dataProfile.role_code === 'COA') ?
            <>
              <Tooltip title="Edit Tipe Jalan" onClick={() => handleEditClick(params)}>
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </> : null}
        </div>
      ),
    },
  ];
  const tipeJalan = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "desc",
      headerClassName: "super-app-theme--header",
      headerName: "DESC",
      width: 400,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "",
      headerClassName: "super-app-theme--header",
      headerName: "ACTION",
      width: 202,
      align: "center",
      headerAlign: "center",
      editable: false,
      renderCell: (params) => (
        <div>
          {dataProfile && (dataProfile.role_code === 'A' || dataProfile.role_code === 'COA') ?
            <>
              <Tooltip title="Edit Tipe Jalan" onClick={() => handleEditClick(params)}>
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </> : null}
        </div>
      ),
    },
  ];

  const tipeLokasi = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "desc",
      headerClassName: "super-app-theme--header",
      headerName: "DESC",
      width: 400,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "",
      headerClassName: "super-app-theme--header",
      headerName: "ACTION",
      width: 202,
      align: "center",
      headerAlign: "center",
      editable: false,
      renderCell: (params) => (
        <div>
          {dataProfile && (dataProfile.role_code === 'A' || dataProfile.role_code === 'COA') ?
            <>
              <Tooltip title="Edit Tipe Lokasi" onClick={() => handleEditClick(params)}>
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </> : null}
        </div>
      ),
    },
  ];

  const handleTableSelection = (table) => {
    setSelectedTable(table);
    setSearchBarValue(table);
    setSelectedOption(table);
    setSelectedUpdate(table);
    refreshData();
  };

  const [newStoreType, setNewStoreType] = useState({
    id: "",
    code: "",
    name: ""
  });
  const [newJenisOutlet, setNewJenisOutlet] = useState({
    id: "",
    desc: ""
  });
  const [newTipeSurvey, setNewTipeSurvey] = useState({
    id: "",
    desc: ""
  });
  const [newTipeJalan, setNewTipeJalan] = useState({
    id: "",
    desc: ""
  });
  const [newTipeLokasi, setNewTipeLokasi] = useState({
    id: "",
    desc: ""
  });



  const handleSetupInputChange = (event) => {
    const { name, value } = event.target;
    setNewStoreType({
      ...newStoreType,
      [name]: value,
    });
    setNewJenisOutlet({
      ...newJenisOutlet,
      [name]: value,
    });
    setNewTipeSurvey({
      ...newTipeSurvey,
      [name]: value,
    });
    setNewTipeJalan({
      ...newTipeJalan,
      [name]: value,
    });
    setNewTipeLokasi({
      ...newTipeLokasi,
      [name]: value,
    });
  };

  const handleCreateStoreType = async () => {
    const token = Cookies.get("access_token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/store-type`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newStoreType),
        }
      );

      if (response.status === 201) {
        alert("Store Type berhasil dibuat!");
        setModalAddSetup(false);
        await fetchStoreType();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Store Type gagal dibuat!");
      }
    } catch (error) {
      console.error("Error creating Store Type:", error);
    }
  };

  const handleCreateJenisOutlet = async () => {
    const token = Cookies.get("access_token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/jenis-outlet`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newJenisOutlet),
        }
      );

      if (response.status === 201) {
        alert("Jenis Outlet berhasil dibuat!");
        setModalAddSetup(false);
        await fetchJenisOutlet();
      } else if (response.status === 400) {
        const data = await response.json();
        console.log("Data gagal dibuat. Pesan kesalahan:", data.detail);
        alert(data.detail);
      } else {
        alert("Jenis Outlet gagal dibuat!");
      }
    } catch (error) {
      console.error("Error creating Jenis Outlet:", error);
    }
  };

  const handleCreateTipeSurvey = async () => {
    const token = Cookies.get("access_token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/tipe-survey`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newTipeSurvey),
        }
      );

      if (response.status === 201) {
        alert("Tipe Survey berhasil dibuat!");
        setModalAddSetup(false);
        await fetchTipeSurvey();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Tipe Survey gagal dibuat!");
      }
    } catch (error) {
      console.error("Error creating Tipe Survey:", error);
    }
  };

  const handleCreateTipeJalan = async () => {
    const token = Cookies.get("access_token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/tipe-jalan`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newTipeJalan),
        }
      );

      if (response.status === 201) {
        alert("Tipe Jalan berhasil dibuat!");
        setModalAddSetup(false);
        await fetchTipeJalan();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Tipe Jalan gagal dibuat!");
      }
    } catch (error) {
      console.error("Error creating Tipe Jalan:", error);
    }
  };

  const handleCreateTipeLokasi = async () => {
    const token = Cookies.get("access_token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/tipe-lokasi`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newTipeLokasi),
        }
      );

      if (response.status === 201) {
        alert("Tipe Lokasi berhasil dibuat!");
        setModalAddSetup(false);
        await fetchTipeLokasi();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Tipe Lokasi gagal dibuat!");
      }
    } catch (error) {
      console.error("Error creating Tipe Lokasi:", error);
    }
  };

  const handleAddNewSetup = () => {
    setModalAddSetup(true);
  };

  const handleEditClick = async (params) => {
    setEditedStore(params.row);
    setEditStoreId(params.row.id);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };

  const handleUpdateStoreType = async () => {
    setIsLoading(true);
    if (editStoreId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/store-type/${editStoreId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedStore),
          }
        );

        if (response.status === 200) {
          alert("Store berhasil diubah!");
          setEditModalOpen(false);
          await fetchStoreType();
        } else {
          alert("Store gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleUpdateJenisOutlet = async () => {
    setIsLoading(true);
    if (editStoreId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/jenis-outlet/${editStoreId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedStore),
          }
        );

        if (response.status === 200) {
          alert("Jenis Outlet berhasil diubah!");
          setEditModalOpen(false);
          await fetchJenisOutlet();
        } else {
          alert("Jenis Outlet gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleUpdateTipeSurvey = async () => {
    setIsLoading(true);
    if (editStoreId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/tipe-survey/${editStoreId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedStore),
          }
        );

        if (response.status === 200) {
          alert("Tipe Survey berhasil diubah!");
          setEditModalOpen(false);
          await fetchTipeSurvey();
        } else {
          alert("Tipe Survey gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleUpdateTipeJalan = async () => {
    setIsLoading(true);
    if (editStoreId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/tipe-jalan/${editStoreId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedStore),
          }
        );

        if (response.status === 200) {
          alert("Tipe Jalan berhasil diubah!");
          setEditModalOpen(false);
          await fetchTipeJalan();
        } else {
          alert("Tipe Jalan gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleUpdateTipeLokasi = async () => {
    setIsLoading(true);
    if (editStoreId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/tipe-lokasi/${editStoreId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedStore),
          }
        );

        if (response.status === 200) {
          alert("Tipe Lokasi berhasil diubah!");
          setEditModalOpen(false);
          await fetchTipeLokasi();
        } else {
          alert("Tipe Lokasi gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const [selectedStoreType, setSelectedStoreType] = useState(
    localStorage.getItem("selectedStoreType") || "all"
  );
  const [selectedJenisOutlet, setSelectedJenisOutlet] = useState(
    localStorage.getItem("selectedJenisOutlet") || "all"
  );
  const [selectedTipeSurvey, setSelectedTipeSurvey] = useState(
    localStorage.getItem("selectedTipeSurvey") || "all"
  );
  const [selectedTipeJalan, setSelectedTipeJalan] = useState(
    localStorage.getItem("selectedTipeJalan") || "all"
  );
  const [selectedTipeLokasi, setSelectedTipeLokasi] = useState(
    localStorage.getItem("selectedTipeLokasi") || "all"
  );

  const handleExportData = async () => {

    await fetchDataStoreMaster(
      accessToken,
      setIsLoading,
      setFilteredRows,
      'store-type', // Endpoint
      selectedStoreType,
      selectedJenisOutlet,
      selectedTipeSurvey,
      selectedTipeJalan,
      selectedTipeLokasi,
    );

    await fetchDataStoreMaster(
      accessToken,
      setIsLoading,
      setFilteredRows,
      'jenis-outlet', // Endpoint
      selectedStoreType,
      selectedJenisOutlet,
      selectedTipeSurvey,
      selectedTipeJalan,
      selectedTipeLokasi,
    );

    await fetchDataStoreMaster(
      accessToken,
      setIsLoading,
      setFilteredRows,
      'tipe-survey', // Endpoint
      selectedStoreType,
      selectedJenisOutlet,
      selectedTipeSurvey,
      selectedTipeJalan,
      selectedTipeLokasi,
    );

    await fetchDataStoreMaster(
      accessToken,
      setIsLoading,
      setFilteredRows,
      'tipe-jalan', // Endpoint
      selectedStoreType,
      selectedJenisOutlet,
      selectedTipeSurvey,
      selectedTipeJalan,
      selectedTipeLokasi,
    );

    await fetchDataStoreMaster(
      accessToken,
      setIsLoading,
      setFilteredRows,
      'tipe-lokasi', // Endpoint
      selectedStoreType,
      selectedJenisOutlet,
      selectedTipeSurvey,
      selectedTipeJalan,
      selectedTipeLokasi,
    );

    // Panggil fetchExportDataStoreMaster dengan parameter yang sesuai
    fetchExportDataStoreMaster(
      accessToken,
      setIsLoading,
      selectedStoreType,
      selectedJenisOutlet,
      selectedTipeSurvey,
      selectedTipeJalan,
      selectedTipeLokasi,
    );
  };

  const handleImportData = () => {
    setOpenDialogImport(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogImport(false);
    setExcelFile('');
    setFileUrl('');
  };

  async function importStoreMasterTemplate() {
    setIsLoading(true)
    try {
      const formData = new FormData();
      formData.append("file", excelFile);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/store-master/import/setup/template`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (response.status === 201) {
        toast.success('Import Setup Store berhasil dibuat!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        // close
        setOpenDialogImport(false);
        //refresh
        refreshData();

      } else if (response.status === 200) {
        // throw data as blob then download it when validation on excel got fail
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Setup Store Master Error.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        toast.error('Ada masalah di excel. Periksa kembali isi excel!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 400) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error('Gagal mengimport setup store!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error('Failed to import setup store template:', response.statusText);
      }
    } catch (error) {
      console.error('Error importing setup store template:', error);
    }
    setIsLoading(false)
  }

  async function exportStoreMasterTemplate() {
    setIsLoading(true);
    const blob = await exportStoreMaster(accessToken, 'template', 'api/v1/store-master/export/setup/template/base', {});
    if (blob) {
      // throw data as blob then download it
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Template Setup Store Master.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      toast.error('Gagal mendownload template setup store!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setIsLoading(false);
  }

  const refreshData = () => {
    switch (searchBarValue) {
      case 'store_type':
        fetchStoreType();
        break;
      case 'jenis_outlet':
        fetchJenisOutlet();
        break;
      case 'tipe_survey':
        fetchTipeSurvey();
        break;
      case 'tipe_jalan':
        fetchTipeJalan();
        break;
      case 'tipe_lokasi':
        fetchTipeLokasi();
        break;
      default:
        break;
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await importStoreMasterTemplate();
    setIsLoading(false);
  };

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar active="Setup Store Master" initial={dataProfile.initial} role_code={dataProfile.role_code} />
        <div>Not allowed to access this page</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar active="Setup Store Master" initial={dataProfile.initial} role_code={dataProfile.role_code} />
      <div style={{ padding: "1rem" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "30px",
            marginLeft: "20px",
          }}
        >

          <div>
            <SearchBarSetupMaster
              label={"Pilih"}
              data={[
                { label: "Store Type", value: "store_type" },
                { label: "Jenis Outlet", value: "jenis_outlet" },
                { label: "Tipe Survey", value: "tipe_survey" },
                { label: "Tipe Jalan", value: "tipe_jalan" },
                { label: "Tipe Lokasi", value: "tipe_lokasi" },
              ]}
              onSelectTable={(table) => handleTableSelection(table)}
              value={searchBarValue}
            />

          </div>
          {/* button */}
          <div className="button-setup" style={{ display: "flex", gap: "5px" }}>
            <BootstrapButton
              variant="contained"
              disableRipple
              onClick={handleAddNewSetup}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Add"}
            </BootstrapButton>

            <BootstrapButton
              variant="contained"
              disableRipple
              onClick={handleImportData}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Upload"}
            </BootstrapButton>
            <BootstrapButton
              variant="contained"
              disableRipple
              onClick={handleExportData}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Download"}
            </BootstrapButton>
          </div>



          {/* create store */}
          <Modal
            open={modalAddSetup}
            onClose={() => setModalAddSetup(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ border: 0 }}
          >
            <Box
              sx={{
                ...style,
                width: 900,
                margin: "0 auto",
                maxHeight: "700px",
                overflow: "auto",
                overflowX: "hidden",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Tooltip title="Close Modal">
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setModalAddSetup(false)}
                  sx={{ position: "absolute", top: 20, right: 30 }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              {/* Add your modal content here */}

              {selectedOption === "store_type" && (
                <div className="store-type">
                  <h2 style={{ marginBottom: "15px" }}>Create Store Type</h2>
                  <div>
                    <TextField
                      label="ID"
                      name="id"
                      value={newStoreType.id}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <TextField
                      label="KODE"
                      name="code"
                      value={newStoreType.code}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <TextField
                      label="NAME"
                      name="name"
                      value={newStoreType.name}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                      onClick={handleCreateStoreType}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Submit"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedOption === "jenis_outlet" && (
                <div className="jenis-outlet">
                  <h2 style={{ marginBottom: "15px" }}>Create Jenis Outlet</h2>
                  <div>
                    <TextField
                      label="ID"
                      name="id"
                      value={newJenisOutlet.id}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <TextField
                      label="DESCRIPTION"
                      name="desc"
                      value={newJenisOutlet.desc}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                      onClick={handleCreateJenisOutlet}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Submit"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedOption === "tipe_survey" && (
                <div className="tipe-survey">
                  <h2 style={{ marginBottom: "15px" }}>Create Tipe Survey</h2>
                  <div>
                    <TextField
                      label="ID"
                      name="id"
                      value={newTipeSurvey.id}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <TextField
                      label="DESCRIPTION"
                      name="desc"
                      value={newTipeSurvey.desc}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                      onClick={handleCreateTipeSurvey}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Submit"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedOption === "tipe_jalan" && (
                <div className="tipe-jalan">
                  <h2 style={{ marginBottom: "15px" }}>Create Tipe Jalan</h2>
                  <div>
                    <TextField
                      label="ID"
                      name="id"
                      value={newTipeJalan.id}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <TextField
                      label="DESCRIPTION"
                      name="desc"
                      value={newTipeJalan.desc}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                      onClick={handleCreateTipeJalan}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Submit"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedOption === "tipe_lokasi" && (
                <div className="tipe-lokasi">
                  <h2 style={{ marginBottom: "15px" }}>Create Tipe Lokasi</h2>
                  <div>
                    <TextField
                      label="ID"
                      name="id"
                      value={newTipeLokasi.id}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <TextField
                      label="DESCRIPTION"
                      name="desc"
                      value={newTipeLokasi.desc}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                      onClick={handleCreateTipeLokasi}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Submit"}
                    </BootstrapButton>
                  </div>
                </div>
              )}
            </Box>
          </Modal>

          <Modal
            open={editModalOpen}
            onClose={handleCloseEditModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ border: 0 }}
          >
            <Box
              sx={modalStyle}
              style={{
                maxHeight: "400px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              {selectedUpdate === "store_type" && (
                <div className="store-type">
                  <h2>Edit Store Type</h2>
                  <div>
                    <TextField
                      name="code"
                      label="Code"
                      sx={{ width: "100%", mb: 2 }}
                      value={editedStore.code ?? ''}
                      onChange={(e) =>
                        setEditedStore({
                          ...editedStore,
                          code: e.target.value,
                        })
                      }
                    >
                    </TextField>

                    <TextField
                      name="name"
                      label="Name"
                      sx={{ width: "100%", mb: 2 }}
                      value={editedStore.name ?? ''}
                      onChange={(e) =>
                        setEditedStore({
                          ...editedStore,
                          name: e.target.value,
                        })
                      }
                    >
                    </TextField>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateStoreType}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedUpdate === "jenis_outlet" && (
                <div className="jenis-outlet">
                  <h2>Edit  Jenis Outlet</h2>
                  <div>
                    <TextField
                      name="desc"
                      label="Desc"
                      sx={{ width: "100%", mb: 2 }}
                      value={editedStore.desc ?? ''}
                      onChange={(e) =>
                        setEditedStore({
                          ...editedStore,
                          desc: e.target.value,
                        })
                      }
                    >
                    </TextField>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateJenisOutlet}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedUpdate === "tipe_survey" && (
                <div className="tipe-survey">
                  <h2>Edit Tipe Survey</h2>
                  <div>
                    <TextField
                      name="desc"
                      label="Desc"
                      sx={{ width: "100%", mb: 2 }}
                      value={editedStore.desc ?? ''}
                      onChange={(e) =>
                        setEditedStore({
                          ...editedStore,
                          desc: e.target.value,
                        })
                      }
                    >
                    </TextField>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateTipeSurvey}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedUpdate === "tipe_jalan" && (
                <div className="tipe-jalan">
                  <h2>Edit Tipe Jalan</h2>
                  <div>
                    <TextField
                      name="desc"
                      label="Desc"
                      sx={{ width: "100%", mb: 2 }}
                      value={editedStore.desc ?? ''}
                      onChange={(e) =>
                        setEditedStore({
                          ...editedStore,
                          desc: e.target.value,
                        })
                      }
                    >
                    </TextField>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateTipeJalan}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedUpdate === "tipe_lokasi" && (
                <div className="tipe-lokasi">
                  <h2>Edit Tipe Lokasi</h2>
                  <div>
                    <TextField
                      name="desc"
                      label="Desc"
                      sx={{ width: "100%", mb: 2 }}
                      value={editedStore.desc ?? ''}
                      onChange={(e) =>
                        setEditedStore({
                          ...editedStore,
                          desc: e.target.value,
                        })
                      }
                    >
                    </TextField>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateTipeLokasi}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              )}
            </Box>
          </Modal>
        </div>

        <ImportModalSetup
          isLoading={isLoading}
          open={openDialogImport}
          onClose={handleCloseDialog}
          title={'Import Setup Store Master'}
          buttonTitle={'Upload'}
          fileUrl={fileUrl}
          handleSubmit={handleUpload}
          handleChange={{
            excel: setExcelFile,
            fileUrl: setFileUrl,
          }}
          handleExport={{
            template: exportStoreMasterTemplate,
            // worksheet: exportStoreMasterWorksheet
          }}
        />

        {selectedTable === "store_type" && dataStoreType && dataStoreType.length > 0 ? (
          <DataGridSetup

            getRowId={(row) => row.id}
            rows={dataStoreType}
            columns={storeType}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            slotProps={{
              toolbar: { setDataStoreType, setRowModesModel },
            }}
          />
        ) : selectedTable === "jenis_outlet" && dataJenisOutlet && dataJenisOutlet.length > 0 ? (
          <DataGridSetup

            getRowId={(row) => row.id}
            rows={dataJenisOutlet}
            columns={jenisOutlet}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            slotProps={{
              toolbar: { setDataJenisOutlet, setRowModesModel },
            }}
          />
        ) : selectedTable === "tipe_survey" && dataTipeSurvey && dataTipeSurvey.length > 0 ? (
          <DataGridSetup

            getRowId={(row) => row.id}
            rows={dataTipeSurvey}
            columns={tipeSurvey}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            slotProps={{
              toolbar: { setDataTipeSurvey, setRowModesModel },
            }}
          />
        ) : selectedTable === "tipe_jalan" && dataTipeJalan && dataTipeJalan.length > 0 ? (
          <DataGridSetup

            getRowId={(row) => row.id}
            rows={dataTipeJalan}
            columns={tipeJalan}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            slotProps={{
              toolbar: { setDataTipeJalan, setRowModesModel },
            }}
          />
        ) : selectedTable === "tipe_lokasi" && dataTipeLokasi && dataTipeLokasi.length > 0 ? (
          <DataGridSetup

            getRowId={(row) => row.id}
            rows={dataTipeLokasi}
            columns={tipeLokasi}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            slotProps={{
              toolbar: { setDataTipeLokasi, setRowModesModel },
            }}
          />
        ) : (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <p
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginTop: "5px",
                marginLeft: "20px",
              }}
            >
              {initialText}
            </p>
          </div>
        )}

      </div>
    </div>
  );
}

export default SetUpStoreMaster;
