export async function fetchAuditPlanMaster(token, setDataAuditPlanMaster, setInitialText) {
  try {
    setInitialText('Loading...')
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/audit-plan-master`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      setDataAuditPlanMaster(data.data);
    } else {
      setDataAuditPlanMaster([])
      setInitialText('No audit plan master found')
    }
    setInitialText('')
  } catch (error) {
    // Handle error
    console.error(error);
  }
}

export async function fetchAuditPlanMasterFormatted(token, setDataAuditPlanMaster) {
  try {
    // Create an empty URLSearchParams object
    const queryParams = new URLSearchParams();

    // add only active project
    const activeProjectStatus = '1';
    queryParams.append('project_status', activeProjectStatus);

    // add only project that already done
    const projectAlreadyDone = 'true';
    queryParams.append('is_project_already_end', projectAlreadyDone);

    const queryString = queryParams.toString();
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/audit-plan-master` + (queryString ? `?${queryString}` : '');
    
    setDataAuditPlanMaster([])
    const response = await fetch(
      apiUrl,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const {data} = await response.json();

      const newArray = data.map(item => ({
        label: item.name,
        id: item.id,
      }));

      setDataAuditPlanMaster(newArray);
    } else {
      setDataAuditPlanMaster([])
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }
}
