import { React } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
} from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";

function EditDataModal({
  isLoading, editedProject,
  editModalOpen, setEditedProject, modalStyle,
  handleUpdateProject, handleCloseEditModal
}) {
  return (
    <Modal
      open={editModalOpen}
      onClose={handleCloseEditModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box
        sx={modalStyle}
        style={{
          maxHeight: "400px",
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        <h2>Edit Project</h2>
        <div>
          <TextField
            variant="filled"
            name="project_name"
            label="Project Name"
            value={editedProject.project_name}
            onChange={(e) =>
              setEditedProject({
                ...editedProject,
                project_name: e.target.value,
              })
            }
            sx={{ width: "100%", mb: 2 }}
          />
          <TextField
            variant="filled"
            name="project_start_at"
            label="Project Start Date"
            type="date"
            value={editedProject.project_start_at}
            onChange={(e) => {
              const newStartDate = e.target.value;
          
              if (newStartDate > editedProject.project_end_at) {
                setEditedProject({
                  ...editedProject,
                  project_start_at: newStartDate,
                  project_end_at: newStartDate,
                });
              } else {
                setEditedProject({
                  ...editedProject,
                  project_start_at: newStartDate,
                });
              }
            }}
            sx={{ width: "100%", mb: 2 }}
          />
          <TextField
            variant="filled"
            name="project_end_at"
            label="Project End Date"
            type="date"
            value={editedProject.project_end_at}
            InputProps={{ inputProps: { min: editedProject.project_start_at, max: 10 } }}
            onChange={(e) =>
              setEditedProject({
                ...editedProject,
                project_end_at: e.target.value,
              })
            }
            sx={{ width: "100%", mb: 2 }}
          />
          <TextField
            variant="filled"
            name="period"
            label="Period"
            type="number"
            value={editedProject.period}
            onChange={(e) =>
              setEditedProject({
                ...editedProject,
                period: e.target.value,
              })
            }
            sx={{ width: "100%", mb: 2 }}
          />
          {
            editedProject.project_type_id == 'rak2' && <TextField
              variant="filled"
              name="prev_period"
              label="Prev Period"
              type="number"
              value={editedProject.prev_period}
              onChange={(e) =>
                setEditedProject({
                  ...editedProject,
                  prev_period: e.target.value,
                })
              }
              sx={{ width: "100%", mb: 2 }}
            />
          }
        </div>
        <Button
          variant="outlined"
          disableRipple
          sx={{
            width: 190,
            height: 50,
            mt: 2,
            color: "#b34469",
            borderColor: "#b34469",
          }}
          onClick={handleCloseEditModal}
        >
          Cancel
        </Button>
        <BootstrapButton
          variant="contained"
          disableRipple
          sx={{ width: 190, height: 50, mt: 2, ml: 0.3 }}
          onClick={handleUpdateProject}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "UPDATE"}
        </BootstrapButton>
      </Box>
    </Modal>
  )
}

export default EditDataModal
