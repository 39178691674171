import React from "react";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Slide, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { fetchProductMasterColumn } from "../../../services/productMasterService";
import {
  ConsistencyProductModal,
  ConsistencyProductModalMT,
  ConsistencyStoreGTModal,
  ConsistencyStoreMTModal,
} from "../../../Components/Fragments/Modals/ModalConsistensy";
import { RawDataModal } from "../../../Components/Fragments/Modals/ModalRawData";
import ModalConfirmOk from "../../../Components/Fragments/Modals/ModalConfirmOk";
import ModalUpdateConsistency from "../../../Components/Fragments/Modals/ModalUpdateConsistency";
import ModalUploadRetailer from "../../../Components/Fragments/Modals/ModalUploadRetailer";
import ModalProcessPreparationStep from "../../../Components/Fragments/Modals/ModalProcessPreparationStep";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#069dae",
    color: theme.palette.common.white,
    border: "solid 1px #fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const PreparationTable = ({
  preparationData,
  projectType,
  currentPeriod,
  previousPeriod,
  handleRefresh,
}) => {
  const dispatch = useDispatch();
  const itemsPerPageList = [5, 10, 50, 100];
  const token = Cookies.get("access_token") || "";
  const [isLoading, setIsLoading] = useState(false);

  const [openTableConsistency, setOpenTableConsistency] = useState(false);
  const [openTableConsistencyProductMT, setOpenTableConsistencyProductMT] =
    useState(false);
  const [openTableConsistencyStoreGT, setOpenTableConsistencyStoreGT] =
    useState(false);
  const [openTableConsistencyStoreMT, setOpenTableConsistencyStoreMT] =
    useState(false);
  const [openRawDataModal, setOpenRawDataModal] = useState(false);
  const [openRetailerModal, setOpenRetailerModal] = useState(false);
  const [remarkDataValidationRtaGT, setRemarkDataValidationRtaGT] =
    useState("");
  const accessToken = useSelector((state) => state.general.accessToken);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProductMasterColumn(dispatch, token);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  const [uploadRetailerId, setUploadRetailerId] = useState("");

  // confirm ok
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  // const [selectedRowDataDataValidation, setSelectedRowDataDataValidation] = useState(null);
  // const [confirmModalOpenDataValidation, setConfirmModalOpenDataValidation] = useState(false);

  const handleConfirmOk = async () => {
    try {
      const { period, prev_period } = selectedRowData;
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-processing-raw/rta_gt_sales_check`;
      const queryParams = new URLSearchParams({
        period,
        prev_period,
      }).toString();

      const response = await fetch(`${apiUrl}?${queryParams}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        toast.success("Data berhasil dikonfirmasi!");
        handleRefresh();
        setConfirmModalOpen(false);
      } else {
        const message = await response.json();
        toast.error(message.detail);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Gagal mengkonfirmasi data!");
    }
  };

  // confirm ok
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  // const [selectedRowData, setSelectedRowData] = useState(null);

  const handleUpdateOk = async () => {
    try {
      const queryParamsProduct = new URLSearchParams();

      queryParamsProduct.append("option", "sivina");
      
      const queryStringProduct = queryParamsProduct.toString();
      
      const productApiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-processing/product-master/compare-all` 
      + (queryStringProduct ? `?${queryStringProduct}` : "");

      const productResponse = await fetch(productApiUrl, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const queryParamsStore = new URLSearchParams();

      if (projectType) {
        queryParamsStore.append("project_type", projectType);
      }

      queryParamsStore.append("option", "sivina");
      
      const queryStringStore = queryParamsStore.toString();
      
      const storeApiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-processing/store-master/compare-all` +
      (queryStringStore ? `?${queryStringStore}` : "");

      const storeResponse = await fetch(storeApiUrl, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      
      if (productResponse.status === 200) {
        toast.success("Product Master : Data consistency berhasil diupdate!");
        handleRefresh();
        setUpdateModalOpen(false);
      } else if (productResponse.status !== 200) {
        const productMessage = await productResponse.json();
        toast.error(`Product Master: ${productMessage.detail}`);
        handleRefresh();
        setUpdateModalOpen(false);
      }
      
      if (storeResponse.status === 200) {
        toast.success("Store Master : Data consistency berhasil diupdate!");
        handleRefresh();
        setUpdateModalOpen(false);
      } else if (storeResponse.status !== 200) {
        const storeMessage = await storeResponse.json();
        toast.error(`Store Master: ${storeMessage.detail}`);
        handleRefresh();
        setUpdateModalOpen(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Gagal mengkonfirmasi data!");
    }
  };

  // const handleConfirmDataValidation = async () => {
  //   try {
  //     const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-processing/data_preparation-data-validation`;

  //     const response = await fetch(`${apiUrl}`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(selectedRowDataDataValidation)
  //     });

  //     if (response.status === 201) {
  //       toast.success("Data berhasil dikonfirmasi!");
  //       handleRefresh();
  //       setConfirmModalOpen(false);
  //     } else {
  //       const message = await response.json();
  //       toast.error(message.detail);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     toast.error("Gagal mengkonfirmasi data!");
  //   }
  // };

  const handleOpenConfirmModal = (rowData) => {
    setSelectedRowData(rowData);
    setConfirmModalOpen(true);
  };

  const handleOpenUpdateModal = (rowData) => {
    // setSelectedRowData(rowData);
    setUpdateModalOpen(true);
  };

  // const handleOpenConfirmModalDataValidation = (rowData) => {
  //   setSelectedRowDataDataValidation(rowData);
  //   setConfirmModalOpenDataValidation(true);
  // };

  const [processModalOpen, setProcessModalOpen] = useState(false);
  const [selectedProcessData, setSelectedProcessData] = useState(null);

  const handleProcessOk = async () => {
    setIsLoading(true);
    try {
      const { id, description } = selectedProcessData;
      let apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-processing-single/data_preparation/${id}/process`;

      if (description === "Data Validation") {
        apiUrl += `?prev_period=${previousPeriod}`;
      }

      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        setProcessModalOpen(false);
        toast.success(`${description} mulai diproses!`);
        handleRefresh();
        setSelectedProcessData(null);
      } else {
        const message = await response.json();
        toast.error(message.detail);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Gagal mengkonfirmasi data!");
    }
    setIsLoading(false);
  };

  const handleOpenProcessModal = (rowData) => {
    setSelectedProcessData(rowData);
    setProcessModalOpen(true);
  };

  const handleRemarkClick = async (remark, stepDescription, id) => {
    if (remark !== "done" && remark !== "progress") {
      if (stepDescription === "Completeness Check") {
        let newTabUrl;
        if (
          ["rta_gt", "rdc_gt", "rdc_pharmacy", "rdc_mt"].includes(projectType)
        ) {
          newTabUrl = `/data-processing/completeness-check-store/${projectType}`;
        }
        if (newTabUrl) {
          window.open(newTabUrl, "_blank");
        } else if (remark === "Not Yet") {
          return null;
        }
      } else if (stepDescription === "Consistency Check") {
        if (["rta_gt", "rdc_gt", "rdc_pharmacy"].includes(projectType)) {
          setOpenTableConsistencyStoreGT(true);
        } else if (projectType === "rdc_mt") {
          setOpenTableConsistencyStoreMT(true);
        } else if (remark === "Not Yet") {
          return null;
        }
      } else if (stepDescription === "Data Validation") {
        if (projectType === "rta_gt" && remark) {
          setOpenRawDataModal(true);
          setRemarkDataValidationRtaGT(remark);
        } else if (remark === "Not Yet") {
          return null;
        }
      } else if (stepDescription === "Cell Store Mapping") {
        if (remark === "terdapat store yang tidak memiliki cellcode") {
          const filename = `store_cell_mapping_incomplete_${projectType}_${currentPeriod}`;
          await handleExportValidation(id, filename);
        } else if (remark === "Not Yet") {
          return null;
        }
      } else if (stepDescription === "Calculate Projection Factor") {
        if (
          remark === "terdapat projection factor atau adj factor yang kosong"
        ) {
          const filename = `df_raw_incomplete_p_adj_factor_${projectType}_${currentPeriod}`;
          await handleExportValidation(id, filename);
        } else if (remark === "Not Yet") {
          return null;
        }
      }
    }
  };

  const handleExportProductCompare = async () => {
    try {
      const queryParams = new URLSearchParams();
      const queryString = queryParams.toString();
      const apiUrl =
        `${process.env.REACT_APP_API_URL}/api/v1/data-processing/product-master/compare/export` +
        (queryString ? `&${queryString}` : "");

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const filename = `Product Master Compare.xlsx`;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success(`Data Product Master Compare berhasil di export!`);
      } else {
        const message = await response.json();
        toast.error(
          message.detail || "An error occurred while exporting data."
        );
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while exporting data.");
    }
  };

  const handleExportStoreCompare = async () => {
    try {
      const queryParams = new URLSearchParams();
      const queryString = queryParams.toString();
      const apiUrl =
        `${process.env.REACT_APP_API_URL}/api/v1/data-processing/store-master/compare/export` +
        (queryString ? `&${queryString}` : "");

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const filename = `Store Master Compare.xlsx`;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success(`Data Store Master Compare berhasil di export!`);
      } else {
        const message = await response.json();
        toast.error(
          message.detail || "An error occurred while exporting data."
        );
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while exporting data.");
    }
  };

  const handleExportValidation = async (id, filename) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-processing/data_preparation/${id}/export`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${filename}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else if (response.status === 422) {
        alert("Gagal mengexport file!");
      }
    } catch (e) {
      console.error("Error exporting data:", e);
      toast.error("Error exporting data. Please try again.");
    }
  };

  const handleClose = () => {
    setOpenTableConsistency(false);
    setOpenTableConsistencyProductMT(false);
    setOpenTableConsistencyStoreGT(false);
    setOpenTableConsistencyStoreMT(false);
    setOpenRawDataModal(false);
  };

  const handleConsistencyProduct = () => {
    setOpenTableConsistency(true);
    setOpenTableConsistencyStoreGT(false);
    setOpenTableConsistencyStoreMT(false);
  };

  const handleConsistencyProductMT = () => {
    setOpenTableConsistencyProductMT(true);
    setOpenTableConsistencyStoreGT(false);
    setOpenTableConsistencyStoreMT(false);
  };

  const handleConsistencyStoreGT = () => {
    setOpenTableConsistencyStoreGT(true);
    setOpenTableConsistency(false);
    setOpenTableConsistencyStoreMT(false);
  };

  const handleConsistencyStoreMT = () => {
    setOpenTableConsistencyStoreMT(true);
    setOpenTableConsistencyProductMT(false);
    setOpenTableConsistencyStoreGT(false);
  };

  const handleOpenRetailerModal = (row) => {
    setOpenRetailerModal(true);
    setUploadRetailerId(row.id)
  };

  return (
    <div>
      <TableContainer component={Paper} style={{ maxHeight: "fit-content" }}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Panel
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Current Period
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Previous Period
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Order
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Step Description
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Remarks
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Completed Time
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {preparationData.length > 0 ? (
              preparationData.map((row, index) => {
                const previousRow = preparationData.find(
                  (r) => r.position === row.position - 1
                );
                const canShowAction = !previousRow || previousRow.remarks;

                const handleClick = () => {
                  if (
                    projectType === "rta_mt" &&
                    row.description === "Upload Retailer Data"
                  ) {
                    handleOpenRetailerModal(row);
                  } else {
                    handleProcessClick(row);
                  }
                };

                const handleProcessClick = () => {
                  if (
                    projectType === "rta_gt" &&
                    row.description === "Data Validation" &&
                    !previousPeriod
                  ) {
                    toast.error(
                      "Mohon mengisi previous period untuk menjalankan process data validation."
                    );
                    return;
                  }
                  handleOpenProcessModal(row);
                };

                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    style={{
                      whiteSpace: "nowrap",
                      minWidth: row.minWidth,
                      color: "#FFF",
                      fontWeight: "bold",
                    }}
                  >
                    <TableCell align="center">{row.project_type}</TableCell>
                    <TableCell align="center">{row.period}</TableCell>
                    <TableCell align="center">{row.prev_period}</TableCell>
                    <TableCell align="center">{row.position}</TableCell>
                    <TableCell align="center">{row.description}</TableCell>
                    <TableCell align="center">
                      {row.remarks && (
                        <Tooltip
                          title="Edit Data Preparation"
                          onClick={() =>
                            handleRemarkClick(
                              row.remarks,
                              row.description,
                              row.id
                            )
                          }
                        >
                          <span
                            style={{
                              cursor:
                                row.remarks !== "done" ? "pointer" : "default",
                              backgroundColor:
                                row.remarks !== "done"
                                  ? "lightgray"
                                  : "transparent",
                              padding: "5px 10px",
                              borderRadius: "4px",
                              display: "inline-block",
                            }}
                          >
                            {row.remarks}
                          </span>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="center">{row.process_at}</TableCell>
                    <TableCell align="center">
                      {canShowAction && (
                        <>
                          <Tooltip title="Process Data Preparation">
                            <IconButton onClick={handleClick}>
                              <DirectionsRunIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          {row.remarks !== "done" &&
                            projectType === "rta_gt" &&
                            row.description === "Data Validation" &&
                            /Ada \d+ transaksi out of ranges/.test(
                              row.remarks
                            ) &&
                            !row.remarks.includes(
                              "dan telah dikonfirmasi OK"
                            ) && (
                              <Tooltip title="Confirm Ok">
                                <IconButton
                                  onClick={() => handleOpenConfirmModal(row)}
                                >
                                  <CheckBoxIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                        </>
                      )}
                      {canShowAction && 
                        row.description === "Consistency Check" 
                        && row.remarks 
                        && row.remarks !== "done" 
                        && (
                        <Tooltip title="Update Consistency">
                          <IconButton
                            onClick={() => handleOpenUpdateModal(row)}
                          >
                            <CheckBoxIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  {isLoading ? "Loading..." : "No Data Available"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal Consistency Check Product*/}
      <ConsistencyProductModal
        handleExportProductCompare={handleExportProductCompare}
        open={openTableConsistency}
        onClose={handleClose}
        onConsistencyStoreGT={handleConsistencyStoreGT}
        isLoading={isLoading}
        itemsPerPageList={itemsPerPageList}
      />

      {/* Modal Consistency MT Check Product*/}
      <ConsistencyProductModalMT
        open={openTableConsistencyProductMT}
        onClose={handleClose}
        onConsistencyStoreMT={handleConsistencyStoreMT}
        isLoading={isLoading}
        itemsPerPageList={itemsPerPageList}
      />

      {/* Modal Consistency Check Store GT*/}
      <ConsistencyStoreGTModal
        handleExportStoreCompare={handleExportStoreCompare}
        open={openTableConsistencyStoreGT}
        onClose={handleClose}
        onConsistencyProduct={handleConsistencyProduct}
        isLoading={isLoading}
        itemsPerPageList={itemsPerPageList}
        projectType={projectType}
      />

      {/* Modal Consistency Check Store MT*/}
      <ConsistencyStoreMTModal
        open={openTableConsistencyStoreMT}
        onClose={handleClose}
        onConsistencyProduct={handleConsistencyProductMT}
        isLoading={isLoading}
        itemsPerPageList={itemsPerPageList}
      />

      {/* Modal Validation*/}
      <RawDataModal
        open={openRawDataModal}
        onClose={handleClose}
        currentPeriod={currentPeriod}
        accessToken={accessToken}
        remarkDataValidationRtaGT={remarkDataValidationRtaGT}
      />

      {/* modal confirm ok */}
      <ModalConfirmOk
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={handleConfirmOk}
      />

      {/* modal update consistency */}
      <ModalUpdateConsistency
        open={updateModalOpen}
        onClose={() => setUpdateModalOpen(false)}
        onUpdate={handleUpdateOk}
      />

      {/* modal process preparation step */}
      <ModalProcessPreparationStep
        open={processModalOpen}
        onClose={() => setProcessModalOpen(false)}
        onProcess={handleProcessOk}
        isLoading={isLoading}
        selectedProcessData={selectedProcessData}
      />

      <ModalUploadRetailer
        open={openRetailerModal}
        onClose={() => setOpenRetailerModal(false)}
        uploadRetailerId={uploadRetailerId}
      />
    </div>
  );
};

export default PreparationTable;
