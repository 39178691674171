import Cookies from "js-cookie";
import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

// external
import Navbar from "../../Components/Navbar";
import { fetchProfile } from "../../utils/fetchProfile";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import { modalStyle } from "../../Components/Modal/Index";
import { Select, MenuItem, Grid, TextField, Typography,  FormControl, InputLabel, Checkbox, ListItemText  } from '@mui/material';
import DataGridAuditPlan from "../AuditPlan/DataGridAuditPlan";
import EditDataModal from "../AuditPlan/EditDataModal";
import AddDataModal from "../AuditPlan/AddDataModal";
import ImportDataModal from "../AuditPlan/ImportDataModal";
import { fetchUserFormatted } from "../../utils/fetchUser";
// import { fetchProjectFormatted } from "../../utils/fetchProject";
import { fetchProjectFormatted } from "../AuditPlan/DataUtil";
import { fetchAuditPlanMasterFormatted } from "../../utils/fetchAuditPlanMaster";
import {
  fetchDistrictFormatted,
  fetchExportDataAuditPlan,
  fetchProvincesFormatted,
  fetchRegenciesFormatted,
  fetchSubDistrictFormatted,
  fetchPeriodeFormatted
} from "../AuditPlan/DataUtil";
import { fetchSurveyType } from "../AuditPlan/FilterService";
import SearchBarAudit from "../../Components/SearchBarAudit";
import DeleteDataModal from "../AuditPlan/DeleteDataModal";
import StatusDataModal from "../AuditPlan/StatusDataModal";
import TransferDataRak2Modal from "../AuditPlan/TransferDataRak2Modal";
import "../../style/auditplan.css"
import UpdateDataMobileToWeb from "../AuditPlan/UpdateDataMobileToWeb";
import { fetchAuditPlans, fetchAuditPlanColumn } from "../../services/auditPlanService";
import { TableAuditPlan } from "../../Components/Fragments/Tables/TableAuditPlan";
import { setSelectedAuditStatus, setSelectedDistrictId, setSelectedProvinceId, setSelectedRegencyId, setSelectedStoreTypeCode, setSelectedSubDistrictId, setSelectedSurveyId, setSelectedSurveyTypeCode, setSelectedUserNameSurveyor, setCurrentPage, setFilterColumnName, setFilterColumnValue } from "../../redux/slices/auditPlanSlice";

// Functional component for AuditPlanTwo
function AuditPlanTwo() {
  // initial
  const accessToken = Cookies.get("access_token") || "";
  const [isLoading, setIsLoading] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);

  const itemsPerPageList = [5, 10, 50, 100];
  const dispatch = useDispatch();

  const currentPage = useSelector((state) => state.auditPlan.currentPage);
  const itemsPerPage = useSelector((state) => state.auditPlan.itemsPerPage);

  const auditPlanfilterColumnName = useSelector((state) => state.auditPlan.auditPlanfilterColumnName);
  const filterColumnName = useSelector((state) => state.auditPlan.filterColumnName);
  const filterColumnOperator = useSelector((state) => state.auditPlan.filterColumnOperator);
  const filterColumnValue = useSelector((state) => state.auditPlan.filterColumnValue);

  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);

  // create
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [dataUserSurveyorOptions, setDataUserSurveyorOptions] = useState([]);
  const [dataUserQcOptions, setDataUserQcOptions] = useState([]);

  const [newAuditPlanData, setNewAuditPlanData] = useState({
    store_id: "",
    audit_plan: "",
    user_name_surveyor: "",
    user_name_qc: "",
    audit_start_at: "",
    audit_end_at: "",
  });

  // handling modal
  const [modalAdd, setModalAdd] = useState(false);

  // edit or update
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedAuditPlan, setEditedAuditPlan] = useState({});
  const [editAuditPlanId, setEditAuditPlanId] = useState(null);

  // upload
  const [file, setFile] = useState(null);

  // token
  const token = Cookies.get("access_token") || "";

  // for filter
  const dataAuditStatus = [
    {
      label: "Closed",
      id: "Closed",
    },
    {
      label: "On Progress",
      id: "On Progress",
    },
    {
      label: "Valid",
      id: "Valid",
    },
    {
      label: "Not Started",
      id: "Not Started",
    },
  ];
  const [dataProject, setDataProject] = useState([]);
  const [dataUserSurveyor, setDataUserSurveyor] = useState([]);
  const [dataAuditPlanMaster, setDataAuditPlanMaster] = useState([]);
  const [dataSurveyType, setDataSurveyType] = useState([]);
  const [dataProvince, setDataProvince] = useState([]);
  const [dataRegency, setDataRegency] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubDistrict, setDataSubDistrict] = useState([]);
  const [dataPeriode, setDataPeriode] = useState([]);

  const transformedData = dataSurveyType.map((item) => ({
    id: item.desc,
    label: item.desc,
  }));

  // selected
  const [selectedProjectType, setSelectedProjectType] = useState(localStorage.getItem("selectedProjectType") || "");
  const [selectedPeriodeId, setSelectedPeriodeId] = useState(localStorage.getItem("selectedPeriodeId") || "all");
  const items = useSelector((state) => state.auditPlan.data);
  const selectedSurveyId = useSelector((state) => state.auditPlan.selectedSurveyId);
  const selectedStoreTypeCode = useSelector((state) => state.auditPlan.selectedStoreTypeId);
  const selectedSurveyTypeCode = useSelector((state) => state.auditPlan.selectedSurveyTypeId);
  const selectedProvinceId = useSelector((state) => state.auditPlan.selectedProvinceId);
  const selectedRegencyId = useSelector((state) => state.auditPlan.selectedRegencyId);
  const selectedDistrictId = useSelector((state) => state.auditPlan.selectedDistrictId);
  const selectedSubDistrictId = useSelector((state) => state.auditPlan.selectedSubDistrictId);
  const selectedAuditStatus = useSelector((state) => state.auditPlan.selectedAuditStatus);
  const selectedUserNameSurveyor = useSelector((state) => state.auditPlan.selectedUserNameSurveyor);

  // read
  useEffect(() => {
    const handler = setTimeout(() => {
      fetchAuditPlanMasterFormatted(accessToken, setDataAuditPlanMaster);
      fetchUserFormatted(accessToken, setDataUserSurveyorOptions, "13", "1");
      fetchUserFormatted(accessToken, setDataUserSurveyor, "13");
      fetchUserFormatted(accessToken, setDataUserQcOptions, "14", "1");
      fetchProvincesFormatted(accessToken, setDataProvince);
      fetchPeriodeFormatted(accessToken, setDataPeriode);
      fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, ["13", "14"]);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);
  
  useEffect(() => {
    const handler = setTimeout(() => {
      fetchSurveyType(accessToken, setDataSurveyType);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProjectFormatted(accessToken, selectedPeriodeId, setDataProject);
      fetchRegenciesFormatted(accessToken, selectedProvinceId, setDataRegency);
      fetchDistrictFormatted(accessToken, selectedRegencyId, setDataDistrict);
      fetchSubDistrictFormatted(accessToken, selectedDistrictId, setDataSubDistrict);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [
    accessToken,
    selectedSurveyId,
    selectedAuditStatus,
    selectedStoreTypeCode,
    selectedSurveyTypeCode,
    selectedProvinceId,
    selectedRegencyId,
    selectedDistrictId,
    selectedSubDistrictId,
    selectedUserNameSurveyor,
    selectedPeriodeId,
  ]);

  const setDefaultSelectedPeriode = () => {
    if (dataPeriode.length > 0) {
      const selectedOption = dataPeriode.find((option) => option.id === selectedPeriodeId);
      if (selectedOption) {
        setSelectedPeriodeId(selectedOption.id);
      } else {
        setSelectedPeriodeId(dataPeriode[0].id);
      }
    }
  };

  const setDefaultSelectedProject = () => {
    if (dataProject.length > 0) {
      const selectedOption = dataProject.find((option) => option.id === selectedSurveyId);
      if (selectedOption) {
        dispatch(setSelectedSurveyId(selectedOption.id));
        setSelectedProjectType(selectedOption.project_type_id);
      } else {
        dispatch(setSelectedSurveyId(dataProject[0].id));
        setSelectedProjectType(dataProject[0].project_type_id);
      }
    }
  };

  useEffect(() => {
    setDefaultSelectedPeriode();
    setDefaultSelectedProject();
  }, [dataPeriode, dataProject]);

  const handlePeriodeIdChange = (event, newValue) => {
    const id = newValue ? newValue.id : "";
    localStorage.setItem("selectedPeriodeId", id);
    dispatch(setSelectedSurveyId(newValue ? newValue.id : ""));
  };

  const handleProjectChange = (event, newValue) => {
    dispatch(setSelectedSurveyId(newValue ? newValue.id : ""));
  };

  const handleAuditStatusChange = (event) => {
    dispatch(setSelectedAuditStatus(event.target.value)); 
  };

  const handleUserNameSurveyorChange = (event, newValue) => {
    dispatch(setSelectedUserNameSurveyor(newValue ? newValue.id : ""));
  };

  const handleStoreTypeCodeChange = (event, newValue) => {
    dispatch(setSelectedStoreTypeCode(newValue ? newValue.id : ""));
  };

  const handleSurveyTypeCodeChange = (event, newValue) => {
    dispatch(setSelectedSurveyTypeCode(newValue ? newValue.id : ""));
  };

  const handleProvinceIdChange = (event, newValue) => {
    dispatch(setSelectedProvinceId(newValue ? newValue.id : ""));
  };

  const handleRegencyIdChange = (event, newValue) => {
    dispatch(setSelectedRegencyId(newValue ? newValue.id : ""));
  };

  const handleDistrictIdChange = (event, newValue) => {
    dispatch(setSelectedDistrictId(newValue ? newValue.id : ""));
  };

  const handleSubDistrictIdChange = (event, newValue) => {
    dispatch(setSelectedSubDistrictId(newValue ? newValue.id : ""));
  };



  // Add data
  const handleAdd = () => {
    setModalAdd(true);
  };

  // Event handler for file selection
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // Event handler for file upload
  const handleUpload = async () => {
    setIsLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const token = Cookies.get("access_token"); // Replace with the actual cookie name

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/audit-plan-master/store/assign/import/template`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Audit Plan Error.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          alert("Error, ada masalah di excel");
        } else if (response.status === 201) {
          alert("Data audit plan berhasil ditambahkan");
          window.location.reload();
        } else if (response.status === 400) {
          const data = await response.json();
          alert(data.detail);
        } else {
          alert("Ada masalah");
        }
      } catch (error) {
        alert("Error");
      }
    }
    setIsLoading(false);
  };

  // Template Download
  const handleDownloadTemplateFile = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/audit-plan-master/export/template/base`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Template Audit Plan.xlsx"; // Change the filename as needed
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "Failed to download template file. Response status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error downloading template file:", error);
    }
    setIsLoading(false);
  };

  // Template Download
  const handleExportData = async () => {
    fetchExportDataAuditPlan(
      accessToken,
      setIsLoading,
      selectedSurveyId,
      selectedStoreTypeCode,
      selectedProvinceId,
      selectedRegencyId,
      selectedDistrictId,
      selectedSubDistrictId,
      selectedAuditStatus,
      selectedUserNameSurveyor
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewAuditPlanData({
      ...newAuditPlanData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const requestBody = {
        ...newAuditPlanData,
      };
      // Check if any value in newUserData is an empty string
      const { user_name_qc, ...requestCheckBody } = newAuditPlanData;
      if (Object.values(requestCheckBody).some((value) => value === "")) {
        alert("Isi semua data.");
        setIsLoading(false);
        return; // Exit the function if any value is an empty string
      }

      const token = Cookies.get("access_token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/audit-plan`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status === 201) {
        alert("Audit plan berhasil ditambahkan!");
        window.location.reload();
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Audit plan gagal ditambahkan!");
      }
    } catch (error) {
      console.error("Error creating audit plan:", error);
    }
    setIsLoading(false);
  };

  // status
  const [statusModalOpen, seStatusModalOpen] = useState(false);
  const [statusModalId, seStatusModalId] = useState(null);
  const [titleStatusModal, seTitleStatusModal] = useState('');

  const handleOpenStatusModal = async (id, condition) => {
    seTitleStatusModal(condition)
    seStatusModalId(id);
    seStatusModalOpen(true);
  };

  const handleCloseStatusModal = () => {
    seStatusModalId(null);
    seStatusModalOpen(false);
    seTitleStatusModal('');
  };

  const handleStatus = async () => {
    setIsLoading(true);
    if (statusModalId) {
      try {
        let apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/audit-plan/${statusModalId}/status?set_to=Valid`
        if (titleStatusModal === 'Closed') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/audit-plan/${statusModalId}/status?set_to=Closed`
        }
        const token = Cookies.get("access_token");
        const response = await fetch(
          apiUrl,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          alert("Audit plan status berhasil diubah!");
          window.location.reload();
        } else {
          alert("Gagal mengubah status audit plan!");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState(null);

  const handleOpenDeleteModal = async (id) => {
    setDeleteModalId(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalId(null);
    setDeleteModalOpen(false);
  };

  // transferData
  const [transferDataRak2ModalOpen, setTransferDataRak2ModalOpen] = useState(false);
  const [transferDataRak2ModalRow, setTransferDataRak2ModalRow] = useState(null);

  const handleOpenTransferDataRak2Modal = async (row) => {
    setTransferDataRak2ModalRow(row);
    setTransferDataRak2ModalOpen(true);
  };

  const handleCloseTransferDataRak2Modal = () => {
    setTransferDataRak2ModalRow(null);
    setTransferDataRak2ModalOpen(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    if (deleteModalId) {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/audit-plan/${deleteModalId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          alert("Audit plan berhasil dihapus!");
          handleCloseDeleteModal();
          window.location.reload();
        } else if (response.status === 400 || response.status === 404) {
          const data = await response.json();
          alert(data.detail);
        } else {
          alert("Gagal menghapus audit plan!");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  const handleTransferRak2 = async () => {
    setIsLoading(true);
    if (transferDataRak2ModalRow) {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/audit-plan/${transferDataRak2ModalRow.id}/participant/${transferDataRak2ModalRow.store_code}/${selectedSurveyId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const { data } = await response.json();
          const responseTransfer = await fetch(
            `${process.env.REACT_APP_API_MOBILE_URL}/survey/manual_upload_rak_1?client_code=${data.client_code}&survey_id=${data.survey_id}&participant_id=${data.participant_id}&role_code=${data.role_code}`,
            {
              method: "POST",
            }
          );

          try {
            const result = await responseTransfer.json();
            if (result.status === 'success') {
              alert('data berhasil ditransfer ke rak2');
              handleCloseTransferDataRak2Modal();
            } else {
              alert('ada masalah saat transfer ke rak2');
            }
          } catch (error) {
            console.error("Error:", error);
            alert('ada masalah saat transfer ke rak2');
          }

        } else if (response.status === 400 || response.status === 404) {
          const data = await response.json();
          alert(data.detail);
        } else {
          alert('ada masalah saat pengecekan transfer ke rak2');
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  // update data
  const [updateDataMobileToWebModalOpen, setUpdateDataMobileToWebModalOpen] = useState(false);
  const [updateDataMobileToWebModalRow, setUpdateDataMobileToWebModalRow] = useState(null);

  const handleUpdateDataMobileToWebModal = async (row) => {
    setUpdateDataMobileToWebModalRow(row);
    setUpdateDataMobileToWebModalOpen(true);
  };

  const handleCloseUpdateDataMobileToWebModal = () => {
    setUpdateDataMobileToWebModalRow(null);
    setUpdateDataMobileToWebModalOpen(false);
  };

  const handleDataUpdateMobileToWeb = async () => {
    setIsLoading(true);
    if (updateDataMobileToWebModalRow) {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/audit-plan/${updateDataMobileToWebModalRow.id}/participant/${updateDataMobileToWebModalRow.store_code}/${selectedSurveyId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const { data } = await response.json();
          const responseTransfer = await fetch(
            `${process.env.REACT_APP_API_MOBILE_URL}/survey/update_postgress_data?participant_id=${data.participant_id}&survey_id=${data.survey_id}&client_code=${data.client_code}&role_code=${data.role_code}`,
            {
              method: "POST",
            }
          );

          try {
            const result = await responseTransfer.json();
            if (result.status === 'success') {
              alert('data berhasil di update');
              handleCloseTransferDataRak2Modal();
            } else {
              alert('ada masalah saat update data');
            }
          } catch (error) {
            console.error("Error:", error);
            alert('ada masalah saat update data');
          }

        } else if (response.status === 400 || response.status === 404) {
          const data = await response.json();
          alert(data.detail);
        } else {
          alert('ada masalah saat pengecekan update data');
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  const handleEditClick = async (id) => {
    // Find the row data for the clicked row by id
    const rowData = items.find((row) => row.id === id);  // use 'items' instead of 'filteredRows'
    if (rowData) {
      // Populate the formDataUpdate state with the current values
      setEditedAuditPlan(rowData);
      setEditAuditPlanId(id);
      setEditModalOpen(true);
    }
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };

  const handleUpdateAuditPlan = async () => {
    setIsLoading(true);
    if (editAuditPlanId) {
      const { audit_status, ...requestBody } = editedAuditPlan;
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/audit-plan/${editAuditPlanId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (response.status === 200) {
          alert("Audit plan berhasil diubah!");
        } else if (
          response.status === 422 ||
          response.status === 400 ||
          response.status === 404
        ) {
          const data = await response.json();
          alert(data.detail);
        } else {
          alert("Audit plan gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
      window.location.reload();
    }
    setIsLoading(false);
  };

  // handle search
  const handleSearch = (e) => {
    e.preventDefault();

    dispatch(setCurrentPage(1))
    const customParams = {
      size: itemsPerPage,
      page: currentPage,
      survey_id: selectedSurveyId,
      province_code: selectedProvinceId,
      regency_code: selectedRegencyId,
      district_code: selectedDistrictId,
      sub_district_code: selectedSubDistrictId,
      store_type_code: selectedStoreTypeCode,
      tipe_survey: selectedSurveyTypeCode,
      audit_status: selectedAuditStatus,
      user_name_surveyor: selectedUserNameSurveyor,
      column_name: filterColumnName,
      column_operator: filterColumnOperator,
      column_value: filterColumnValue,
    };
    fetchAuditPlans(dispatch, accessToken, customParams);
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchAuditPlanColumn(dispatch, token);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Audit Plan"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allow to access this page</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar
        active="Audit Plan"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div className="content" style={{ padding: "1rem" }}>
        <div className="legend">
          {/* search bar */}
          <div className="dahsboard-searchbar">
            <SearchBarAudit
              id="periode-autocomplete"
              data={dataPeriode}
              value={dataPeriode.find((option) => option.id === selectedPeriodeId) || null}
              handleChange={handlePeriodeIdChange}
              label="Periode"
            />
            <SearchBarAudit
              id="project-autocomplete"
              data={dataProject}
              value={dataProject.find((option) => option.id === selectedSurveyId) ?? null}
              handleChange={handleProjectChange}
              label="Project"
            />
            <FormControl fullWidth variant="outlined" sx={{ height: 40, mt: 1, mr: 1 }}>
              <InputLabel sx={{ mt: "-5px" }}>Status</InputLabel>
              <Select
                label="Status"
                sx={{ height: 40,  mr: 1 }}
                multiple
                value={selectedAuditStatus}
                onChange={handleAuditStatusChange}
                renderValue={(selected) => 
                  selected
                    .map((id) => {
                      const status = dataAuditStatus.find((option) => option.id === id);
                      return status ? status.label : id;
                    })
                    .join(", ")
                }
              >
                {dataAuditStatus.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <SearchBarAudit
              id="full-name-surveyor"
              data={dataUserSurveyor}
              value={dataUserSurveyor.find((option) => option.id === selectedUserNameSurveyor) ?? null}
              handleChange={handleUserNameSurveyorChange}
              label="Full Name Surveyor"
            />
            <SearchBarAudit
              id="store-type-autocomplete"
              data={transformedData}
              value={
                transformedData.find(
                  (option) => option.id === selectedSurveyTypeCode
                ) ?? null
              }
              handleChange={handleSurveyTypeCodeChange}
              label="Survey Type"
            />
            <SearchBarAudit
              id="province-autocomplete"
              data={dataProvince}
              value={dataProvince.find((option) => option.id === selectedProvinceId) ?? null}
              handleChange={handleProvinceIdChange}
              label="Provinsi"
            />
            <SearchBarAudit
              id="regency-autocomplete"
              data={dataRegency}
              value={dataRegency.find((option) => option.id === selectedRegencyId) ?? null}
              handleChange={handleRegencyIdChange}
              label="Kota/Kab"
            />
            <SearchBarAudit
              id="district-autocomplete"
              data={dataDistrict}
              value={dataDistrict.find((option) => option.id === selectedDistrictId) ?? null}
              handleChange={handleDistrictIdChange}
              label="Kec"
            />
            <SearchBarAudit
              id="sub-district-autocomplete"
              data={dataSubDistrict}
              value={dataSubDistrict.find((option) => option.id === selectedSubDistrictId) ?? null}
              handleChange={handleSubDistrictIdChange}
              label="Kel"
            />
          </div>
        </div>
      </div>

      {/* button */}
      <div
        className="button-audit"
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "5px",
          marginTop: "10px",
          marginRight: "20px"
        }}
      >
        <form onSubmit={handleSearch} style={{ display: 'flex', alignItems: 'center' }}>
          <Select
            sx={{
              mr: 1,
              mt: 1,
              height: 40,
            }}
            required
            labelId="select-label-column_name"
            name="column_name"
            id="select_column_name"
            value={filterColumnName}
            onChange={(e) => {
              dispatch(setFilterColumnName(e.target.value));
            }}
          >
            {auditPlanfilterColumnName.map((data) => (
              <MenuItem value={data.id} key={data.id}>
                {data.name}
              </MenuItem>
            ))}
          </Select>
          <TextField
            sx={{
              mr: 1,
              mt: 1,
              height: 40,
            }}
            InputProps={{
              style: {
                height: 40,
              },
            }}
            name="column_value"
            label="Cari"
            size="small"
            value={filterColumnValue}
            onChange={(e) => {
              dispatch(setFilterColumnValue(e.target.value));
            }}
          />
          <BootstrapButton
            variant="contained"
            disableRipple
            sx={{ width: 120, height: 40, mt: 1 }}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : 'Cari'}
          </BootstrapButton>
        </form>
        <div className="spacing-one"></div>

        <BootstrapButton
          onClick={() => setIsModalOpen(true)}
          variant="contained"
          disableRipple
          disabled={isLoading}
          className="button-audit-plan"
        >
          <p style={{ fontSize: "15px", height: "10px", textAlign: "center", marginTop: "1px" }}>{isLoading ? "Loading..." : "Add Audit Plan"}</p>
        </BootstrapButton>
        <BootstrapButton
          onClick={handleAdd}
          variant="contained"
          disableRipple
          disabled={isLoading}
          className="button-audit-plan"
        >
          <p style={{ fontSize: "15px", height: "10px", textAlign: "center", marginTop: "1px" }}>{isLoading ? "Loading..." : "Upload Audit Plans"}</p>
        </BootstrapButton>
        <BootstrapButton
          onClick={handleExportData}
          variant="contained"
          disableRipple
          disabled={isLoading}
          className="button-audit-plan"
        >
          <p style={{ fontSize: "15px", height: "10px", textAlign: "center", marginTop: "1px" }}>{isLoading ? "Loading..." : "Download"}</p>
        </BootstrapButton>

        {/* modal import */}
        <ImportDataModal
          isLoading={isLoading}
          modalAdd={modalAdd}
          setModalAdd={setModalAdd}
          modalStyle={modalStyle}
          handleFileChange={handleFileChange}
          handleDownloadTemplateFile={handleDownloadTemplateFile}
          handleUpload={handleUpload}
        />
        {/* modal add*/}
        <AddDataModal
          accessToken={accessToken}
          isLoading={isLoading}
          newAuditPlanData={newAuditPlanData}
          dataAuditPlanMaster={dataAuditPlanMaster}
          dataUserSurveyorOptions={dataUserSurveyorOptions}
          dataUserQcOptions={dataUserQcOptions}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          modalStyle={modalStyle}
        />
        {/* modal edit */}
        <EditDataModal
          isLoading={isLoading}
          editedAuditPlan={editedAuditPlan}
          dataUserSurveyorOptions={dataUserSurveyorOptions}
          dataUserQcOptions={dataUserQcOptions}
          setEditedAuditPlan={setEditedAuditPlan}
          handleUpdateAuditPlan={handleUpdateAuditPlan}
          handleCloseEditModal={handleCloseEditModal}
          editModalOpen={editModalOpen}
          modalStyle={modalStyle}
        />
        {/* modal delete */}
        <DeleteDataModal
          isLoading={isLoading}
          modalStyle={modalStyle}
          deleteModalOpen={deleteModalOpen}
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleDelete={handleDelete}
        />
        {/* modal transfer data rak2 */}
        <TransferDataRak2Modal
          isLoading={isLoading}
          modalStyle={modalStyle}
          transferDataRak2ModalOpen={transferDataRak2ModalOpen}
          closeIconTarget={handleCloseTransferDataRak2Modal}
          handleCloseTransferDataRak2Modal={handleCloseTransferDataRak2Modal}
          handleTransferRak2={handleTransferRak2}
        />
        <UpdateDataMobileToWeb
          isLoading={isLoading}
          modalStyle={modalStyle}
          modalOpen={updateDataMobileToWebModalOpen}
          closeIconTarget={handleCloseUpdateDataMobileToWebModal}
          handleClose={handleCloseUpdateDataMobileToWebModal}
          handleSubmit={handleDataUpdateMobileToWeb}
        />
        {/* modal status */}
        <StatusDataModal
          isLoading={isLoading}
          modalStyle={modalStyle}
          statusModalOpen={statusModalOpen}
          handleCloseStatusModal={handleCloseStatusModal}
          handleStatus={handleStatus}
          title={titleStatusModal}
        />

      </div>
      <div style={{ margin: "20px" }}>
        <TableAuditPlan
          itemsPerPageList={itemsPerPageList}
          handleOpenStatusModal={handleOpenStatusModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleOpenTransferDataRak2Modal={handleOpenTransferDataRak2Modal}
          handleUpdateDataMobileToWebModal={handleUpdateDataMobileToWebModal}
          handleEditClick={handleEditClick}
          selectedProjectType={selectedProjectType}
          selected
        />
      </div>
    </div>
  );
}

export default AuditPlanTwo;
