import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setItemsPerPage,
} from "../../../redux/slices/projectManagementSlice";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  Edit as EditIcon,
  Store as StoreIcon,
  Autorenew as AutorenewIcon,
  SettingsSuggestOutlined as SettingsSuggestOutlinedIcon,
} from "@mui/icons-material";
import { fetchProjectManagements } from "../../../services/projectManagementService";
import { Link as RouterLink } from "react-router-dom";

export const TableProjectManagementDev = ({
  isLoading,
  itemsPerPageList,
  handleEditClick,
  handleOpenStatusModal,
  handleOpenProjectCustom,
  handleOpenProjectStore,
}) => {
  const dispatch = useDispatch();

  const items = useSelector((state) => state.projectManagement.data);
  const accessToken = useSelector((state) => state.general.accessToken);
  const currentPage = useSelector(
    (state) => state.projectManagement.currentPage
  );
  const itemsPerPage = useSelector(
    (state) => state.projectManagement.itemsPerPage
  );
  const total = useSelector((state) => state.projectManagement.total);

  const selectedProjectStatus = useSelector(
    (state) => state.projectManagement.selectedProjectStatus
  );
  const selectedPeriod = useSelector(
    (state) => state.projectManagement.selectedPeriod
  );
  const selectedProjectType = useSelector(
    (state) => state.projectManagement.selectedProjectType
  );

  const [sortBy, setSortBy] = useState("project_name");
  const [sortOrder, setSortOrder] = useState("desc");

  const fetchData = (currentPage, itemsPerPage) => {
    try {
      dispatch(setCurrentPage(currentPage));
      const customParams = {
        size: itemsPerPage,
        page: currentPage,
        project_status: selectedProjectStatus,
        period: selectedPeriod,
        project_type_id: selectedProjectType,
        sort_by: sortBy,
        sort_order: sortOrder,
      };
      fetchProjectManagements(dispatch, accessToken, customParams);
    } catch (error) {
      console.error(`Error fetching data:`, error);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchData(currentPage || 1, itemsPerPage, sortBy, sortOrder);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [
    dispatch,
    currentPage,
    itemsPerPage,
    selectedProjectStatus,
    selectedPeriod,
    selectedProjectType,
    sortBy,
    sortOrder,
  ]);

  const handlePageChange = (event, page) => {
    dispatch(setCurrentPage(page + 1));
  };

  const handleChangeItemPerPageSelect = (e) => {
    dispatch(setItemsPerPage(e.target.value));
    dispatch(setCurrentPage(1));
  };

  const handleSort = (column) => {
    const isSorting = sortBy === column && sortOrder === "asc";
    setSortOrder(isSorting ? "desc" : "asc");
    setSortBy(column);
  };

  const sortTableCell = (label, sortByKey) => (
    <TableCell style={tableCellStyle}>
      <TableSortLabel
        active={sortBy === sortByKey}
        direction={sortOrder}
        onClick={() => handleSort(sortByKey)}
        sx={{
          color: "#FFF !important",
          "&:hover": {
            color: "#FFF",
          },
          "& .MuiTableSortLabel-icon": {
            color: "#FFF !important",
          },
        }}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  const tableCellStyle = {
    whiteSpace: "nowrap",
    backgroundColor: "rgb(6,157,174)",
    color: "#FFF",
    fontWeight: "bold",
    position: "sticky",
    top: 0,
    zIndex: 1000,
    minWidth: 120,
    textAlign: "center",
  };

  const renderStatusCell = (value) => {
    let statusText = "";

    switch (value) {
      case "1":
        statusText = (
          <div style={{ background: "green", color: "white", padding: "10px" }}>
            Active
          </div>
        );
        break;
      case "3":
        statusText = (
          <div style={{ background: "red", color: "white", padding: "10px" }}>
            Inactive
          </div>
        );
        break;
      default:
        statusText = value;
        break;
    }

    return statusText;
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 650 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {sortTableCell("Nama Project", "project_name")}
              {sortTableCell("Tipe Project", "project_type_id")}
              {sortTableCell("Audit Plan", "audit_plan_master_id")}
              {sortTableCell("Produk Grup", "product_group_master_id")}
              {sortTableCell("Period", "period")}
              {sortTableCell("Prev Period", "prev_period")}
              {sortTableCell("Project Start", "project_start_at")}
              {sortTableCell("Project End", "project_end_at")}
              <TableCell style={tableCellStyle}>Status</TableCell>
              <TableCell style={tableCellStyle}>Aksi</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length > 0 ? (
              items.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="center">{row.project_name}</TableCell>
                  <TableCell align="center">{row.project_type_name}</TableCell>
                  <TableCell align="center">
                    {row.audit_plan_master_name}
                  </TableCell>
                  <TableCell align="center">
                    {row.product_group_master_name}
                  </TableCell>
                  <TableCell align="center">{row.period}</TableCell>
                  <TableCell align="center">{row.prev_period}</TableCell>
                  <TableCell align="center">{row.project_start_at}</TableCell>
                  <TableCell align="center">{row.project_end_at}</TableCell>
                  <TableCell align="center">
                    {renderStatusCell(row.project_status)}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip
                      title="Edit Project"
                      onClick={() => handleEditClick(row)}
                    >
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Edit Project Status"
                      onClick={() => handleOpenStatusModal(row)}
                    >
                      <IconButton>
                        <AutorenewIcon />
                      </IconButton>
                    </Tooltip>
                    {["ADHOC", "RDC", "RDC_MT", "RDC_PHARMACY"].includes(
                      row.project_type_name
                    ) && (
                      <Tooltip
                        title="Set Custom Question Sku"
                        component={RouterLink}
                        to={`/project-management/custom-question-sku/${row.id}`}
                        onClick={() => handleOpenProjectCustom(row)}
                      >
                        <IconButton>
                          <SettingsSuggestOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {/* {["ADHOC", "RDC", "RDC_MT", "RDC_PHARMACY"].includes(
                      row.project_type_name
                    ) && (
                      <Tooltip
                        title="Set Custom Question Store"
                        component={RouterLink}
                        to={`/project-management/custom-question-store/${row.id}`}
                        onClick={() => handleOpenProjectStore(row)}
                      >
                        <IconButton>
                          <StoreIcon />
                        </IconButton>
                      </Tooltip>
                    )} */}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  {isLoading ? "Loading..." : "No Data Available"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* pagination */}
      {items.length > 0 && (
        <TablePagination
          rowsPerPageOptions={itemsPerPageList}
          component="div"
          count={total}
          rowsPerPage={itemsPerPage}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeItemPerPageSelect}
        />
      )}
    </Paper>
  );
};
