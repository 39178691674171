import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  IconButton,
  CircularProgress,
  Paper,
} from "@mui/material";
import DownloadingIcon from "@mui/icons-material/Downloading";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function DataGridAuditPlan({
  rows,
  initialText,
  page,
  size,
  totalRows,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOpenStatusModal,
  handleOpenDeleteModal,
  handleOpenTransferDataRak2Modal,
  handleUpdateDataMobileToWebModal,
  handleEditClick,
  selectedProjectType,
  sortBy,
  sortOrder,
  onSortChange,
}) {
  const handleSort = (column) => {
    const newSortOrder = sortBy === column && sortOrder === 'asc' ? 'desc' : 'asc';
    onSortChange(column, newSortOrder);
  };

  const renderSortIcon = (column) => {
    if (sortBy !== column) return null;
    return sortOrder === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
  };

  return (
    <Paper
      sx={{
        mt: 2,
        ml: 2,
        mr: 2,
        mb: 2,
        border: 2,
        borderColor: "rgb(234,243,248)",
        overflow: "auto"
      }}
    >
      <TableContainer style={{width: "2300px"}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                style={{
                  fontWeight: "bold",
                  backgroundColor: "rgb(6,157,174)",
                  color: "#FFF",
                }}
              >
                ACTIONS
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontWeight: "bold",
                  backgroundColor: "rgb(6,157,174)",
                  color: "#FFF",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                }}
                onClick={() => handleSort('store_code')}
              >
                KODE OUTLET {renderSortIcon('store_code')}
              </TableCell>
              <TableCell
                align="center"
                style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
              >
                AUDIT PLAN
              </TableCell>
              <TableCell
                align="center"
                style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
              >
                NAMA OUTLET
              </TableCell>
              <TableCell
                align="center"
                style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
              >
                TIPE OUTLET
              </TableCell>
              <TableCell
                align="center"
                style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer"
                }}
                onClick={() => handleSort('audit_status')}
                >
                  STATUS {renderSortIcon('audit_status')}
              </TableCell>
              <TableCell
                align="center"
                style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer"
                }}
                onClick={() => handleSort('username_surveyor')}
              >
                USERNAME SURVEYOR {renderSortIcon('username_surveyor')}
              </TableCell>
              <TableCell
                align="center"
                style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer"
                }}
                onClick={() => handleSort('user_full_name_surveyor')}
              >
                FULLNAME SURVEYOR {renderSortIcon('user_full_name_surveyor')}
              </TableCell>
              <TableCell
                align="center"
                style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer"
                }}
                onClick={() => handleSort('username_qc')}
              >
                USERNAME QC {renderSortIcon('username_qc')}
              </TableCell>
              <TableCell
                align="center"
                style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer"
                }}
                onClick={() => handleSort('user_full_name_qc')}
              >
                FULLNAME QC {renderSortIcon('user_full_name_qc')}
              </TableCell>
              <TableCell
                align="center"
                style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer"
                }}
                onClick={() => handleSort('audit_start_at')}
                >
                  MULAI KUNJUNGAN {renderSortIcon('audit_start_at')}
              </TableCell>
              <TableCell
                align="center"
                style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer"
                }}
                onClick={() => handleSort('audit_end_at')}
                >
                  AKHIR KUNJUNGAN {renderSortIcon('audit_end_at')}
              </TableCell>
              <TableCell
                align="center"
                style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer"
                }}
                onClick={() => handleSort('created_at')}
                >
                  TANGGAL DIBUAT {renderSortIcon('created_at')}
              </TableCell>
              <TableCell
                align="center"
                style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer"
                }}
                onClick={() => handleSort('updated_at')}
                >
                  TANGGAL DIPERBARUI {renderSortIcon('updated_at')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="center">
                    {selectedProjectType !== "adhoc" && (
                      <Tooltip title="Update Data Mobile to Website">
                        <IconButton
                          onClick={() => handleUpdateDataMobileToWebModal(row)}
                        >
                          <DownloadingIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {selectedProjectType === "rak1" && (
                      <Tooltip title="Transfer data to rak2">
                        <IconButton
                          onClick={() => handleOpenTransferDataRak2Modal(row)}
                        >
                          <MoveDownIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {(row.audit_status === "Not Started" ||
                      row.audit_status === "Valid" ||
                      row.audit_status === "On Progress") && (
                      <>
                        <Tooltip title="Edit Audit Plan">
                          <IconButton onClick={() => handleEditClick(row.id)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Audit Plan">
                          <IconButton
                            onClick={() => handleOpenDeleteModal(row.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    {(row.audit_status === "Closed" ||
                      row.audit_status === "closed") && (
                      <Tooltip title="Update Audit Plan to Valid">
                        <IconButton
                          onClick={() => handleOpenStatusModal(row.id, "Valid")}
                        >
                          <AutorenewIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {row.audit_status === "Valid" && (
                      <Tooltip title="Update Audit Plan to Closed">
                        <IconButton
                          onClick={() =>
                            handleOpenStatusModal(row.id, "Closed")
                          }
                        >
                          <AutorenewIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell align="center">{row.store_code}</TableCell>
                  <TableCell align="center">{row.audit_plan}</TableCell>
                  <TableCell align="center">{row.store_name}</TableCell>
                  <TableCell align="center">{row.store_type}</TableCell>
                  <TableCell align="center">
                    <div
                      style={{
                        background:
                          row.audit_status === "Valid"
                            ? "lightblue"
                            : row.audit_status === "Not Started"
                            ? "red"
                            : row.audit_status === "Closed"
                            ? "green"
                            : row.audit_status === "On Progress"
                            ? "orange"
                            : "grey",
                        color: "white",
                        padding: "5px",
                        borderRadius: "4px",
                      }}
                    >
                      {row.audit_status}
                    </div>
                  </TableCell>
                  <TableCell align="center">{row.user_name_surveyor}</TableCell>
                  <TableCell align="center">
                    {row.user_full_name_surveyor}
                  </TableCell>
                  <TableCell align="center">{row.user_name_qc}</TableCell>
                  <TableCell align="center">{row.user_full_name_qc}</TableCell>
                  <TableCell align="center">{row.audit_start_at}</TableCell>
                  <TableCell align="center">{row.audit_end_at}</TableCell>
                  <TableCell align="center">{row.created_at}</TableCell>
                  <TableCell align="center">{row.updated_at}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  {initialText}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalRows}
          page={page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={size}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
      </TableContainer>
    </Paper>
  );
}

export default DataGridAuditPlan;
