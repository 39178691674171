import {
  Button,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

export const ModalSyncRawdata = ({ open, onClose, isLoading, handleClick, textKonfirmasi}) => {
  const CancelButton = styled(Button)({
    backgroundColor: "#DE3436",
    color: "white",
    "&:hover": {
      backgroundColor: "#DC1F21",
    },
  });

  const ConfirmButton = styled(Button)({
    backgroundColor: "#469F3F",
    color: "white",
    "&:hover": {
      backgroundColor: "#32932B",
    },
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          backgroundColor: "white",
          border: "0px solid #000",
          boxShadow: 24,
          padding: "16px 32px 24px",
          borderRadius: "5px",
        }}
      >
        <Typography sx={{ mt: 2, fontSize: "18px", color: "#383838" }}>
          {textKonfirmasi}
        </Typography>
        <Box mt={4} display="flex" justifyContent="flex-end">
          <CancelButton
            onClick={onClose}
            style={{ marginRight: "8px" }}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Tidak"}
          </CancelButton>
          <ConfirmButton onClick={handleClick} disabled={isLoading}>
            {isLoading ? "Loading..." : "Ya"}
          </ConfirmButton>
        </Box>
      </Box>
    </Modal>
  );
};
