import React from 'react'
import Box from "@mui/material/Box";

function CardDashboard({audit_status, count_audit_status, percentage = false}) {
  return (
    <Box
      sx={{
        height: 100,
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        backgroundColor: "#ffffff",
      }}
      className="box-Card"
    >
      <div className="label">
        <p
          className="text-wrapper"
          style={{
            marginTop: "7px",
            marginLeft: "7px",
            bgcolor: "#000000",
            fontFamily: "Roboto",
            fontWeight: "bold",
            fontSize: 12,
          }}
        >
          {audit_status}
        </p>
      </div>
      <div className="label">
        <div
          className="text-wrapper"
          style={{
            color: "#49BC93",
            fontFamily: "Roboto",
            fontWeight: 620,
            top:100,
            bottom:0,
            fontSize: 38,
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              top:100,
              bottom:0,
            }}
          >
            {" "}
            {count_audit_status}
            {
              percentage ? 
              <p
                style={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  marginTop: 18,
                  fontSize: 16,
                  textAlign: "center",
                  marginLeft: 5,
                }}
              >
                ({percentage})
              </p> : null
            }
          </div>
        </div>
      </div>
    </Box>
  )
}

export default CardDashboard
