import Cookies from "js-cookie";
import { React, useState, useEffect } from "react";

// external
import Navbar from "../../Components/Navbar";
import { fetchProfile } from "../../utils/fetchProfile";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import { modalStyle } from "../../Components/Modal/Index";
import DataGridUserManagement from "./DataGridUserManagement";
import EditDataModal from "./EditDataModal";
import AddDataModal from "./AddDataModal";
import ImportModal from "./ImportModal";
import { fetchDataUserManagement, fetchExportDataUserManagement, fetchExportTemplateUserManagement } from "./DataUtil";
import SearchBar from "../../Components/SearchBar";
import StatusDataModal from "../StoreMaster/StatusDataModal";
import { Slide, toast } from 'react-toastify';
import "../../style/usermanagement.css"

// Functional component for UserManagement
function UserManagement() {
  // initial
  const accessToken = Cookies.get("access_token") || "";
  const [isLoading, setIsLoading] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);

  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);

  // create
  const [isModalOpen, setIsModalOpen] = useState(false);

  //import
  const [openDialogImport, setOpenDialogImport] = useState(false);

  const dataStatusOption = [
    {
      label: "Active",
      id: "1",
    },
    {
      label: "Inactive",
      id: "0",
    },
  ];
  const dataRoleCodeOption = [
    {
      'label': 'ADMIN',
      'id': 'A'
    },
    {
      'label': 'CO ADMIN',
      'id': 'COA'
    },
    {
      'label': 'TEAM LEADER',
      'id': 'TL'
    },
    {
      'label': 'DATA COLLECTOR',
      'id': '13'
    },
    {
      'label': 'QC OFFICER',
      'id': '14'
    },
  ];

  const [newUserData, setNewUserData] = useState({
    full_name: "",
    user_name: "",
    password: "",
    role_code: "",
  });

  // edit or update
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedUser, setEditedUser] = useState({});
  const [editUserId, setEditUserId] = useState(null);


  // selected
  const [selectedRoleCode, setSelectedRoleCode] = useState(
    localStorage.getItem("selectedRoleCode") || "all"
  );
  const [selectedStatus, setSelectedStatus] = useState(
    localStorage.getItem("selectedStatus") || "1"
  );

  // read
  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, ["TL"]);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchDataUserManagement(accessToken, setIsLoading, setFilteredRows, selectedRoleCode, selectedStatus)
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken, selectedRoleCode, selectedStatus]);

  // Handle change in the dropdown selection
  const handleRoleCodeChange = (event, newValue) => {
    let id = ''
    if (newValue) {
      id = newValue.id;
    }
    setSelectedRoleCode(id);
    localStorage.setItem("selectedRoleCode", id);
  };
  // Handle change in the dropdown selection
  const handleStatusChange = (event, newValue) => {
    let id = ''
    if (newValue) {
      id = newValue.id;
    }
    setSelectedStatus(id);
    localStorage.setItem("selectedStatus", id);
  };

  // Template Download
  const handleExportData = async () => {
    fetchExportDataUserManagement(accessToken, setIsLoading, selectedRoleCode, selectedStatus);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewUserData({
      ...newUserData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const requestBody = {
        ...newUserData,
      };
      // Check if any value in newUserData is an empty string
      if (Object.values(newUserData).some(value => value === "")) {
        alert("Isi semua data.");
        setIsLoading(false);
        return; // Exit the function if any value is an empty string
      }

      const token = Cookies.get("access_token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/create_user`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status === 201) {
        setIsModalOpen(false);
        alert("User berhasil ditambahkan!");
        window.location.reload();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("User gagal dibuat!");
      }
    } catch (error) {
      console.error("Error creating User:", error);
    }
    setIsLoading(false);
  };


  const handleEditClick = async (rows) => {
    setEditedUser(rows.row);
    setEditUserId(rows.row.id);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  }

  const handleUpdateUser = async () => {
    setIsLoading(true);
    if (editUserId) {
      const token = Cookies.get("access_token");
      try {
        const { status, role_code, role_name, ...requestBody } = {
          ...editedUser,
        };
        // Check if any value in newUserData is an empty string
        if (Object.values(requestBody).some(value => value === "")) {
          alert("Isi semua data.");
          setIsLoading(false);
          return; // Exit the function if any value is an empty string
        }
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/user/${editUserId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (response.status === 200) {
          alert("User berhasil diubah!");
        } else {
          alert("User gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
      window.location.reload();
    }
    setIsLoading(false);
  };

  // status
  const [statusModalOpen, seStatusModalOpen] = useState(false);
  const [statusModalData, seStatusModalData] = useState(null);

  const handleOpenStatusModal = async (row) => {
    seStatusModalData(row);
    seStatusModalOpen(true);
  };

  const handleCloseStatusModal = () => {
    seStatusModalData(null);
    seStatusModalOpen(false);
  };

  const handleStatus = async () => {
    setIsLoading(true);
    let statusChange = "1";
    if (statusModalData.status === '1') {
      statusChange = "0";
    }
    if (statusModalData) {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/user/${statusModalData.id}/status?user_status=${statusChange}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          alert("user status berhasil diubah!");
          window.location.reload();
        } else {
          alert("Gagal mengubah status user!");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  //function export template
  const [fileUrl, setFileUrl] = useState('');
  const [excelFile, setExcelFile] = useState('');

  const handleImport = () => {
    setOpenDialogImport(true);
  };
  const handleCloseDialog = () => {
    setOpenDialogImport(false);
    setFileUrl('');
    setExcelFile('');
  };

  // export product master setup template
  async function ExportUserManagementTemplate() {
    setIsLoading(true);
    const blob = await fetchExportTemplateUserManagement(accessToken, 'template', 'api/v1/user/export/template/base', {});
    if (blob) {
      // throw data as blob then download it
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Template User Management.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      toast.error('Gagal mendownload template User Management!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setIsLoading(false);
  }

  // function import 
  async function ImportUserManagement() {
    try {
      const formData = new FormData();
      formData.append("file", excelFile);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/user/import/template`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (response.status === 201) {
        toast.success('Import User Management berhasil dibuat!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        // refresh
        fetchDataUserManagement(accessToken, setIsLoading, setFilteredRows, selectedRoleCode, selectedStatus)
        // setFileUrl('')

        // close 
        setOpenDialogImport(false);
      } else if (response.status === 200) {
        // throw data as blob then download it when validation on excel got fail
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "User Management Error.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        toast.error('Ada masalah di excel. Periksa kembali isi excel!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 400) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error('Gagal mengimport User Management!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error('Failed to import User Management template:', response.statusText);
      }
    } catch (error) {
      console.error('Error importing User Management template:', error);
    }
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    ImportUserManagement()
    setIsLoading(false);
  };

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar active="User Management" initial={dataProfile.initial} role_code={dataProfile.role_code} />
        <div>Not allow to access this page</div>
      </div>
    );
  }
  return (
    <div>
      <Navbar active="User Management" initial={dataProfile.initial} role_code={dataProfile.role_code} />
      <div className="content" style={{ padding: "1rem" }}>
        <div
          className="legend"
          style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}
        >
          {/* search bar */}
          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: "10px" }}>
            <SearchBar
              id={"role-code-autocomplete"}
              data={dataRoleCodeOption}
              value={dataRoleCodeOption.find(option => option.id === selectedRoleCode) ?? null}
              handleChange={handleRoleCodeChange}
              label={"Role Name"}
            />
            <SearchBar
              id={"status-autocomplete"}
              data={dataStatusOption}
              value={dataStatusOption.find(option => option.id === selectedStatus) ?? null}
              handleChange={handleStatusChange}
              label={"Status"}
            />
          </div>
          {/* button */}
          <div className="button-user" style={{ display: "flex", justifyContent: "space-between", gap: "5px", marginTop: "10px", marginLeft: "-15px" }}>
            <div className="spacing-one"></div>
            <BootstrapButton
              onClick={() => setIsModalOpen(true)}
              variant="contained"
              disableRipple
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Add User"}
            </BootstrapButton>
            <BootstrapButton
              onClick={() => handleImport()}
              variant="contained"
              disableRipple
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Upload Users"}
            </BootstrapButton>
            <BootstrapButton
              onClick={handleExportData}
              variant="contained"
              disableRipple
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Download"}
            </BootstrapButton>
          </div>
          {/* modal add*/}
          <AddDataModal
            isLoading={isLoading}
            newUserData={newUserData}

            dataRoleCodeOption={dataRoleCodeOption}

            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            modalStyle={modalStyle}
          />
          {/* modal Import*/}
          <ImportModal
            open={openDialogImport}
            onClose={handleCloseDialog}
            title={'Import User Management'}
            buttonTitle={'Upload'}
            fileUrl={fileUrl}
            handleSubmit={handleUpload}
            handleChange={{
              excel: setExcelFile,
              fileUrl: setFileUrl,
            }}
            handleExport={ExportUserManagementTemplate}
            isLoading={isLoading}
          />
          {/* modal edit */}
          <EditDataModal
            isLoading={isLoading}
            editedUser={editedUser}

            setEditedUser={setEditedUser}
            handleUpdateUser={handleUpdateUser}
            handleCloseEditModal={handleCloseEditModal}
            editModalOpen={editModalOpen}
            modalStyle={modalStyle}
          />
          {/* modal status */}
          <StatusDataModal
            isLoading={isLoading}
            modalStyle={modalStyle}
            statusModalOpen={statusModalOpen}
            handleCloseStatusModal={handleCloseStatusModal}
            handleStatus={handleStatus}
          />
        </div>
      </div>
      <div>
        <DataGridUserManagement
          loading={isLoading}
          rows={filteredRows}
          handleEditClick={handleEditClick}
          handleOpenStatusModal={handleOpenStatusModal}
        />
      </div>
    </div>
  );
}

export default UserManagement;
