import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setItemsPerPage } from '../../../redux/slices/storeMasterSlice';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ImageIcon from "@mui/icons-material/Image";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { fetchStoreMaster } from '../../../services/storeMasterService';
import { Slide, toast } from 'react-toastify';

export const TableStoreMaster = ({ onView, onEditStatus, itemsPerPageList }) => {
  const dispatch = useDispatch();
  // initial
  const items = useSelector((state) => state.storeMaster.data);
  const accessToken = useSelector((state) => state.general.accessToken);
  // initial page 
  const currentPage = useSelector((state) => state.storeMaster.currentPage);
  const itemsPerPage = useSelector((state) => state.storeMaster.itemsPerPage);
  // selected filter
  const selectedStatusId = useSelector((state) => state.storeMaster.selectedStatusId);
  const selectedTipeSurvey = useSelector((state) => state.storeMaster.selectedTipeSurvey);
  const selectedProvinceId = useSelector((state) => state.storeMaster.selectedProvinceId);
  const selectedRegencyId = useSelector((state) => state.storeMaster.selectedRegencyId);
  const selectedDistrictId = useSelector((state) => state.storeMaster.selectedDistrictId);
  const selectedSubDistrictId = useSelector((state) => state.storeMaster.selectedSubDistrictId);
  const filterColumnName = useSelector((state) => state.storeMaster.filterColumnName);
  const filterColumnOperator = useSelector((state) => state.storeMaster.filterColumnOperator);
  const filterColumnValue = useSelector((state) => state.storeMaster.filterColumnValue);

  // total
  const total = useSelector((state) => state.storeMaster.total);
  // role code
  const roleCode = useSelector((state) => state.profile.role_code)

  //sorting
  const [sortBy, setSortBy] = useState('store_id');
  const [sortOrder, setSortOrder] = useState('desc');

  // fetch data
  function fetchData(currentPage, itemsPerPage, sortBy, sortOrder) {
    dispatch(setCurrentPage(currentPage));
    const customParams = {
      size: itemsPerPage,
      page: currentPage,
      store_status: selectedStatusId,
      tipe_survey: selectedTipeSurvey,
      province_id: selectedProvinceId,
      regency_id: selectedRegencyId,
      district_id: selectedDistrictId,
      sub_district_id: selectedSubDistrictId,
      column_name: filterColumnName,
      column_operator: filterColumnOperator,
      column_value: filterColumnValue,
      sort_by: sortBy,
      sort_order: sortOrder
    };
    fetchStoreMaster(dispatch, accessToken, customParams)
  }

  // trigger when selected filter change then set again to page 1
  useEffect(() => {
    // Check if it's the first time loading the page
    if (currentPage === 0) {
      fetchData(1, itemsPerPage, sortBy, sortOrder);
    } else {
      fetchData(currentPage, itemsPerPage, sortBy, sortOrder);
    }
  }, [
    dispatch,
    currentPage,
    selectedStatusId,
    selectedTipeSurvey,
    selectedProvinceId,
    selectedRegencyId,
    selectedDistrictId,
    selectedSubDistrictId,
    sortBy,
    sortOrder
  ]);

  // for page change
  const handlePageChange = (event, page) => {
    // this will go to next or prev page
    fetchData(page + 1, itemsPerPage)
  };

  const handleChangeItemPerPageSelect = (e) => {
    // set how many items are selected
    dispatch(setItemsPerPage(e.target.value));
    // this will set again to page 1
    fetchData(1, e.target.value)
  };

  const handleLocateMap = (store_latitude, store_longitude) => () => {
    // handling map invalid
    if (!store_latitude || !store_longitude) {
      toast.error('latitude atau longitude tidak ditemukan', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    } else {
      // Open Google Maps with the specified latitude and longitude
      const mapsLink = `https://www.google.com/maps/search/?api=1&query=${store_longitude},${store_latitude}`;
      window.open(mapsLink, "_blank");
    }
  };

  const handleSort = (column) => {
    const isSorting = sortBy === column && sortOrder === 'asc';
    setSortOrder(isSorting ? 'desc' : 'asc');
    setSortBy(column);
  }

  const tableCellStyle = {
    whiteSpace: 'nowrap',
    backgroundColor: "rgb(6,157,174)",
    color: "#FFF",
    fontWeight: "bold",
    position: "sticky",
    top: 0,
    zIndex: 1000,
    minWidth: 120,
  };

  const sortTableCell = (label, sortByKey) => (
    <TableCell style={tableCellStyle}>
      <TableSortLabel
        active={sortBy === sortByKey}
        direction={sortOrder}
        onClick={() => handleSort(sortByKey)}
        sx={{
          color: '#FFF !important', // Menetapkan warna teks menjadi putih
          '&:hover': {
            color: '#FFF', // Tetapkan warna teks menjadi putih saat mengarahkan kursor
          },
          '& .MuiTableSortLabel-icon': {
            color: '#FFF !important',
          },
        }}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 550 }} >
        <Table aria-label="table store master">
          {/* header */}
          <TableHead>
            <TableRow>
              {sortTableCell('Kode Outlet', 'store_id')}
              {sortTableCell('Nama Outlet', 'store_name')}
              {sortTableCell('Tipe Toko', 'store_type_id')}
              {sortTableCell('Jenis Outlet', 'jenis_outlet')}
              {sortTableCell('Tipe Survey', 'tipe_survey')}
              {sortTableCell('Tipe Jalan', 'tipe_jalan')}
              {sortTableCell('Tipe Lokasi', 'tipe_lokasi')}
              {sortTableCell('Provinsi', 'province_id')}
              {sortTableCell('Kota/Kabupaten', 'regency_id')}
              {sortTableCell('Kecamatan', 'district_id')}
              {sortTableCell('Kelurahan', 'sub_district_id')}
              {sortTableCell('Alamat', 'store_address')}
              {sortTableCell('Status', 'store_status')}
              {sortTableCell('Tanggal Dibuat', 'created_at')}
              {sortTableCell('Tanggal Diperbarui', 'updated_at')}
              <TableCell style={tableCellStyle}>Aksi</TableCell>
            </TableRow>
          </TableHead>
          {/* body */}
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.store_id}</TableCell>
                <TableCell>{item.store_name}</TableCell>
                <TableCell>{item.store_type}</TableCell>
                <TableCell>{item.jenis_outlet}</TableCell>
                <TableCell>{item.tipe_survey}</TableCell>
                <TableCell>{item.tipe_jalan}</TableCell>
                <TableCell>{item.tipe_lokasi}</TableCell>
                <TableCell>{item.province_name}</TableCell>
                <TableCell>{item.regency_name}</TableCell>
                <TableCell>{item.district_name}</TableCell>
                <TableCell>{item.sub_district_name}</TableCell>
                <TableCell>{item.store_address}</TableCell>
                <TableCell>
                  {
                    (() => {
                      if (item.store_status === '0') {
                        return <Button size="small" variant="contained" color="error">Inactive </Button>;
                      } else {
                        return <Button size="small" variant="contained" color="success">Active </Button>;
                      }
                    })()
                  }
                </TableCell>
                <TableCell>{item.created_at}</TableCell>
                <TableCell>{item.updated_at}</TableCell>
                <TableCell>
                  <Tooltip
                    title="Store Image"
                    onClick={() => onView(item.id, item.store_id)}
                  >
                    <IconButton>
                      <ImageIcon />
                    </IconButton>
                  </Tooltip>
                  {
                    item.store_latitude && item.store_longitude ? <Tooltip title="View Location" onClick={handleLocateMap(item.store_latitude, item.store_longitude)}>
                      <IconButton>
                        <LocationOnIcon />
                      </IconButton>
                    </Tooltip> : null
                  }
                  {roleCode === 'A' || roleCode === 'COA' ?
                    <>
                      <Tooltip
                        title="Edit Store"
                      >
                        <a 
                          href={`/store-master/edit/${item.id}`}
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'none' }}
                        >
                          <IconButton>
                            <StorefrontIcon />
                          </IconButton>
                        </a>
                      </Tooltip>
                      <Tooltip
                        title="Edit Status"
                        onClick={() => onEditStatus(item.id, item.store_status)}
                      >
                        <IconButton>
                          <AutorenewIcon />
                        </IconButton>
                      </Tooltip>
                    </> : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* pagination */}
      {items.length > 0 &&
        <TablePagination
          rowsPerPageOptions={itemsPerPageList}
          component="div"
          count={total}
          rowsPerPage={itemsPerPage}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeItemPerPageSelect}
        />
      }
    </Paper>
  );
};
