export async function fetchUser(token, setDataUser, setInitialText, setDataUserSurveyor = false, setDataUserQc = false, roleCode = 'all', selectedStatus = 'all') {
  try {
    setInitialText('Loading...')

    // Create an empty URLSearchParams object
    const queryParams = new URLSearchParams();

     // Add roleCode to the query if it's provided
    if (roleCode && roleCode !== 'all') {
      queryParams.append('role_code', roleCode);
    }
    
    if (selectedStatus && selectedStatus !== 'all') {
      queryParams.append('user_status', selectedStatus);
    }

    // Construct the full URL with query parameters
    const queryString = queryParams.toString();
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/user/users_with_roles` + (queryString ? `?${queryString}` : '');

    const response = await fetch(
      apiUrl,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      setDataUser(data.data);

      // 
      if (setDataUserSurveyor) {
        setDataUserSurveyor(data.data.filter((user) => user.role_code === '13'));
      }
      
      if (setDataUserQc) {
        setDataUserQc(data.data.filter((user) => user.role_code === '14'));
      }

      setInitialText('')
    } else {
      setDataUser([])
      setInitialText('No user found')
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }
}

export async function fetchUserRole(token, setDataRole, setInitialText) {
  try {
    setInitialText('Loading...')
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/role`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      setDataRole(data.data);

    } else {
      setDataRole([])
      setInitialText('No user role found')
    }
    setInitialText('')
  } catch (error) {
    // Handle error
    console.error(error);
  }
}

export async function fetchUserFormatted(token, setDataUser, roleCode = 'all', selectedStatus = 'all') {
  try {
    setDataUser([])

    // Create an empty URLSearchParams object
    const queryParams = new URLSearchParams();

    // Add roleCode to the query if it's provided
    if (roleCode && roleCode !== 'all') {
      queryParams.append('role_code', roleCode);
    }

    // Add selectedStatus to the query if it's provided
    if (selectedStatus && selectedStatus !== 'all') {
      queryParams.append('user_status', selectedStatus);
    }

    // Construct the full URL with query parameters
    const queryString = queryParams.toString();
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/user/users_with_roles` + (queryString ? `?${queryString}` : '');

    const response = await fetch(
      apiUrl,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const {data} = await response.json();
      
      const newArray = data.map(item => ({
        label: `${item.user_name} - ${item.full_name}`,
        id: item.user_name,
      }));

      setDataUser(newArray);
    } else {
      setDataUser([])
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }
}
