import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";

import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


const ViewModal = ({ open, onClose, selectedHistoryId }) => {
  const accessToken = Cookies.get("access_token") || "";
  const [isLoading, setIsLoading] = useState(false);
  const [dataHistory, setDataHistory] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/process_queue/${selectedHistoryId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const result = await response.json();

      if (result.status === 200) {
        const { data } = result;
        setDataHistory(data);
      } else setDataHistory([]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  console.log(dataHistory);

  useEffect(() => {
    if (open && selectedHistoryId) {
      fetchData();
    } else {
      setDataHistory(null);
    }
  }, [open, selectedHistoryId]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="view-modal-title"
      aria-describedby="view-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "0px solid #000",
          borderRadius: 5,
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton style={{ float: "right" }} onClick={onClose}>
          <CloseIcon/>
        </IconButton>
        <Typography id="view-modal-title" variant="h6" component="h2">
          Detail
        </Typography>
        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : dataHistory ? (
          <>
            <p>ID : {dataHistory.id}</p>
            <p>User : {dataHistory.user_name}</p>
            <p>Audit Plan Title : {dataHistory.data?.title}</p>
            <p>Status :                   <div
                    style={{
                      backgroundColor:
                        dataHistory.status === "done"
                          ? "green"
                          : dataHistory.status === "failed"
                          ? "red"
                          : dataHistory.status === "progress"
                          ? "orange"
                          : dataHistory.status === "open"
                          ? "blue"
                          : dataHistory.status === "ready_import"
                          ? "purple"
                          : "inherit",
                      color: "#FFF",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      display: "inline-block",
                    }}
                  >
                    {dataHistory.status}
                  </div></p>
            <p>Description : {dataHistory.description}</p>
            <p>Created Time : {dataHistory.created_at}</p>
            <p>Completed Time : {dataHistory.updated_at}</p>
          </>
        ) : (
          <Typography>No data available</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default ViewModal;
