import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const ActiveSessionRedirect = ({ children }) => {
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();
  
  if (isLoading) {
    return <div>Loading...</div>; // loading
  }

  if(user){
    navigate("/dashboard");
  }
  
  return children;
};

export default ActiveSessionRedirect;
