export async function fetchDataStoreMaster(token, setIsLoading, setFilteredRows, selectedStoreType = 'all', selectedJenisOutlet = 'all', selectedTipeSurvey = 'all', selectedTipeJalan = 'all', selectedTipeLokasi = 'all') {
    setFilteredRows([])
    setIsLoading(true);

    // Create an empty URLSearchParams object
    const queryParams = new URLSearchParams();

    if (selectedStoreType && selectedStoreType !== 'all') {
        queryParams.append('store_type', selectedStoreType);
    }

    if (selectedJenisOutlet && selectedJenisOutlet !== 'all') {
        queryParams.append('jenis_outlet', selectedJenisOutlet);
    }

    if (selectedTipeSurvey && selectedTipeSurvey !== 'all') {
        queryParams.append('tipe_survey', selectedTipeSurvey);
    }

    if (selectedTipeJalan && selectedTipeJalan !== 'all') {
        queryParams.append('tipe_jalan', selectedTipeJalan);
    }

    if (selectedTipeLokasi && selectedTipeLokasi !== 'all') {
        queryParams.append('tipe_lokasi', selectedTipeLokasi);
    }


    // Construct the full URL with query parameters
    const queryString = queryParams.toString();
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/tipe-survey` + (queryString ? `?${queryString}` : '');

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            setFilteredRows(data.data); // Initialize filteredRows with the retrieved data
        } else {
            setFilteredRows([])
            // Handle error
        }
    } catch (error) {
        // Handle error
    }

    setIsLoading(false);
}

export async function fetchExportDataStoreMaster(token, setIsLoading, selectedStoreType = 'all', selectedJenisOutlet = 'all', selectedTipeSurvey = 'all', selectedTipeJalan = 'all', selectedTipeLokasi = 'all') {
    setIsLoading(true);

    // Create an empty URLSearchParams object
    const queryParams = new URLSearchParams();

    if (selectedStoreType && selectedStoreType !== 'all') {
        queryParams.append('store_type', selectedStoreType);
    }

    if (selectedJenisOutlet && selectedJenisOutlet !== 'all') {
        queryParams.append('jenis_outlet', selectedJenisOutlet);
    }

    if (selectedTipeSurvey && selectedTipeSurvey !== 'all') {
        queryParams.append('tipe_survey', selectedTipeSurvey);
    }

    if (selectedTipeJalan && selectedTipeJalan !== 'all') {
        queryParams.append('tipe_jalan', selectedTipeJalan);
    }

    if (selectedTipeLokasi && selectedTipeLokasi !== 'all') {
        queryParams.append('tipe_lokasi', selectedTipeLokasi);
    }


    const queryString = queryParams.toString();
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/store-master/setup/export` + (queryString ? `?${queryString}` : '');
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.status === 200) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "Result Store Master.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
        } else if (response.status === 404) {
            alert('data not found')
        } else {
            alert('something went wrong went export data')
        }
    } catch (error) {
        // Handle error
    }

    setIsLoading(false);
}

export async function fetchProvinces(token, setIsLoading, setDataProvinces, setSelectedProvince) {
    setDataProvinces([])
    setIsLoading(true);

    const queryParams = new URLSearchParams();

    // if (selectedStoreType && selectedStoreType !== 'all') {
    //     queryParams.append('store_type', selectedStoreType);
    // }


    const queryString = queryParams.toString();
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/area/provinces` + (queryString ? `?${queryString}` : '');

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            setDataProvinces(data.data); 
            if (data) {
                setSelectedProvince({
                    label: data.data[0].name,
                    value: data.data[0].code,
                });
              }
        } else {
            setDataProvinces([])
        }
    } catch (error) {
        setDataProvinces([]);
    }

    setIsLoading(false);
}

export async function fetchArea(token, setIsLoading, setDataArea) {
    setDataArea([])
    setIsLoading(true);

    const queryParams = new URLSearchParams();

    queryParams.append('sort_by', 'area');
    queryParams.append('sort_order', 'asc');

    const queryString = queryParams.toString();
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/area-breakdown` + (queryString ? `?${queryString}` : '');

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            setDataArea(data.data); 
        } else {
            setDataArea([])
        }
    } catch (error) {
        setDataArea([]);
    }

    setIsLoading(false);
}