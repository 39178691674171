import Cookies from "js-cookie";
import { React, useState, useEffect } from "react";

// external
import Navbar from "../../Components/Navbar";
import { fetchProfile } from "../../utils/fetchProfile";
import DataGridDashboard from "./DataGridDashboard";
import CardDashboard from "./CardDashboard";
import { fetchUserFormatted } from "../../utils/fetchUser";
import {
  fetchDataStatData,
  fetchDataSummaryData,
  fetchDataDailyTrendData,
  fetchDistrictFormatted,
  fetchAreasFormatted,
  fetchRegionFormatted,
  fetchWilayahFormatted,
  fetchRegenciesFormatted,
  fetchSubDistrictFormatted,
  fetchPeriodeFormatted,
  fetchProjectFormatted,
} from "./DataUtil";
import SearchBarDashboard from "../../Components/SearchBarDashboard";
import Highcharts from "highcharts";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import Box from "@mui/material/Box";
import MapLayout from "../Map/MapLayout";
import L from "leaflet";
import ProvinsiData from "../Map/IDN.json";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import { TextField } from "@mui/material";
//styles for open source
import "../../style/dashboard.css";

HighchartsAccessibility(Highcharts);

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RectangleIcon = ({ color }) => (
  <div
    style={{
      width: "20px",
      height: "20px",
      backgroundColor: color || "gray",
      marginRight: "3px",
    }}
  ></div>
);

const LegendItem = ({ color, label }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      marginBottom: "4px",
      marginRight: "5px",
    }}
  >
    <RectangleIcon color={color} />
    {label}
  </div>
);

// Functional component for Dashboard
function Dashboard() {
  // initial
  const accessToken = Cookies.get("access_token") || "";
  const [isLoading, setIsLoading] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [filteredStatRows, setFilteredStatRows] = useState([]);
  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);

  // map
  const [surveyDataId, setSurveyDataId] = useState([]);
  const [dataCount, setDataCount] = useState(0);

  // for filter
  const [dataProject, setDataProject] = useState([]);
  const [selectedDatestart, setselectedDatestart] = useState([]);
  const [selectedDateend, setselectedDateend] = useState([]);
  // const dataTipeSurvey = [
  //   {
  //     label: "RA",
  //     id: "RA",
  //   },
  //   {
  //     label: "RDC",
  //     id: "RDC",
  //   },
  // ];
  const [dataTipeSurvey, setdataTipeSurvey] = useState([]);
  const [dataArea, setDataArea] = useState([]);
  const [dataRegion, setDataRegion] = useState([]);
  const [dataWilayah, setDataWilayah] = useState([]);
  const [dataRegency, setDataRegency] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubDistrict, setDataSubDistrict] = useState([]);
  const [dataUserSurveyor, setDataUserSurveyor] = useState([]);
  const [dataPeriode, setDataPeriode] = useState([]);

  //selected date
  const [Datagrafik, setDatagrafik] = useState([]);
  const [selectedDateFirst, setSelectedDateFirst] = useState("");
  const [selectedDateLast, setSelectedDateLast] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // selected
  const [selectedSurveyId, setSelectedSurveyId] = useState(
    localStorage.getItem("selectedSurveyId") || "all"
  );
  const [selectedTipeSurvey, setSelectedTipeSurvey] = useState([]);
  //   localStorage.getItem("selectedTipeSurvey") || "all"
  // );
  const [selectedArea, setSelectedArea] = useState(
    localStorage.getItem("selectedArea") || "all"
  );
  const [selectedRegion, setSelectedRegion] = useState(
    localStorage.getItem("selectedRegion") || "all"
  );
  const [selectedWilayah, setSelectedWilayah] = useState(
    localStorage.getItem("selectedWilayah") || "all"
  );
  const [selectedRegencyId, setSelectedRegencyId] = useState(
    localStorage.getItem("selectedRegencyId") || "all"
  );
  const [selectedDistrictId, setSelectedDistrictId] = useState(
    localStorage.getItem("selectedDistrictId") || "all"
  );
  const [selectedSubDistrictId, setSelectedSubDistrictId] = useState(
    localStorage.getItem("selectedSubDistrictId") || "all"
  );
  const [selectedUserNameSurveyor, setSelectedUserNameSurveyor] = useState(
    localStorage.getItem("selectedUserNameSurveyor") || "all"
  );
  const [selectedPeriodeId, setSelectedPeriodeId] = useState(
    localStorage.getItem("selectedPeriodeId") || "all"
  );

  const MapIcon = (mapData) => {
    let iconUrl = "";

    switch (mapData.audit_status) {
      case "Closed":
        iconUrl =
          "https://img.icons8.com/?size=100&id=7880&format=png&color=FA5252";
        break;
      case "Valid":
        iconUrl =
          "https://img.icons8.com/?size=100&id=7880&format=png&color=5D9C59";
        break;
      case "On Progress":
        iconUrl =
          "https://img.icons8.com/?size=100&id=7880&format=png&color=2CAFFE";
        break;
      case "Not Started":
        iconUrl =
          "https://img.icons8.com/?size=100&id=7880&format=png&color=FFFFFF";
        break;
      default:
        iconUrl = "default-icon-url.png";
        break;
    }

    const icon = L.icon({
      iconUrl: iconUrl,
      iconSize: [10, 10],
      className: "custom-icon",
    });

    return icon;
  };

  const [mapPosition, setMapPosition] = useState([-2.548, 112.544]);
  const [mapZoom, setMapZoom] = useState(5);

  const detailMasterData = (mapData) => {
    return (
      <article>
        <h4>Kode Outlet : {mapData.id}</h4>
        <h4>Nama Outlet : {mapData.name}</h4>
        <h4>Tipe Outlet : {mapData.tipe_toko}</h4>
        <h4>Tipe Survey : {mapData.tipe_survey}</h4>
        <h4>Alamat : {mapData.address}</h4>
      </article>
    );
  };

  const [filteredGeoJSONData, setFilteredGeoJSONData] = useState(ProvinsiData);

  // read
  async function fetchTypeOptions() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/tipe-survey`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const newArray = data.data.map((item) => ({
          label: item.desc,
          id: item.desc,
        }));
        setdataTipeSurvey(newArray);
      } else {
        throw new Error("Failed to fetch store types");
      }
    } catch (error) {
      console.error(error);
    }
  }

  // // map
  // async function fetchData(
  //   token,
  //   selectedSurveyId,
  //   selectedRegencyId = "all",
  //   selectedDistrictId = "all",
  //   selectedSubDistrictId = "all",
  //   selectedTipeSurvey = [],
  //   selectedUserNameSurveyor = "all",
  //   selectedIsNegative = "",
  //   selectedRegion = "",
  //   selectedWilayah = ""
  // ) {
  //   setIsLoading(true);
  //   if (selectedSurveyId) {
  //     // Create an empty URLSearchParams object
  //     const queryParams = new URLSearchParams();

  //     // Add selectedIsNegative to the query if it's provided
  //     if (typeof selectedIsNegative === "boolean") {
  //       queryParams.append("is_negative", selectedIsNegative.toString());
  //     }

  //     // Add selectedSurveyType to the query if it's provided
  //     if (Array.isArray(selectedTipeSurvey) && selectedTipeSurvey.length > 0) {
  //       queryParams.append("tipe_survey", selectedTipeSurvey.join(","));
  //     }

  //     // Add selectedSurveyType to the query if it's provided
  //     if (selectedUserNameSurveyor && selectedUserNameSurveyor !== "all") {
  //       queryParams.append("user_name_surveyor", selectedUserNameSurveyor);
  //     }

  //     // Add selectedRegencyId to the query if it's provided
  //     if (selectedRegencyId && selectedRegencyId !== "all") {
  //       queryParams.append("regency_code", selectedRegencyId);
  //     }

  //     // Add selectedDistrictId to the query if it's provided
  //     if (selectedDistrictId && selectedDistrictId !== "all") {
  //       queryParams.append("district_code", selectedDistrictId);
  //     }

  //     // Add selectedSubDistrictId to the query if it's provided
  //     if (selectedSubDistrictId && selectedSubDistrictId !== "all") {
  //       queryParams.append("sub_district_code", selectedSubDistrictId);
  //     }

  //     // Add selectedRegion to the query if it's provided
  //     if (selectedRegion && selectedRegion !== "all") {
  //       queryParams.append("region_name", selectedRegion);
  //     }

  //     // Add selectedWilayah to the query if it's provided
  //     if (selectedWilayah && selectedWilayah !== "all") {
  //       queryParams.append("wilayah_name", selectedWilayah);
  //     }

  //     // Construct the full URL with query parameters
  //     const queryString = queryParams.toString();
  //     const apiUrl =
  //       `${process.env.REACT_APP_API_URL}/api/v1/survey/store/${selectedSurveyId}` +
  //       (queryString ? `?${queryString}` : "");

  //     try {
  //       setSurveyDataId([]);
  //       const response = await fetch(apiUrl, {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });

  //       if (response.status === 200) {
  //         const { data, pagination_info } = await response.json();

  //         const mapData = data.map((item) => ({
  //           id: item.store_id,
  //           name: item.store_name,
  //           address: item.store_address,
  //           tipe_toko: item.store_type,
  //           tipe_survey: item.tipe_survey,
  //           latitude: item.store_latitude,
  //           longitude: item.store_longitude,
  //           audit_status: item.audit_status,
  //           icon: MapIcon(item),
  //         }));

  //         // const validLocationsCount = mapData.filter(
  //         //   (item) => item.latitude && item.longitude
  //         // ).length;

  //         setSurveyDataId(mapData);
  //         setDataCount(pagination_info.total);
  //         // setValidLocationsCount(validLocationsCount);
  //       } else if (response.status === 404) {
  //         setSurveyDataId([]);
  //         setDataCount(0); // Reset the data count
  //       }
  //     } catch (error) {
  //       // Handle error
  //     }
  //   }
  //   setIsLoading(false);
  // }

  // useEffect(() => {
  //   if (selectedSurveyId) {
  //     fetchData(
  //       accessToken,
  //       selectedSurveyId,
  //       selectedRegencyId,
  //       selectedDistrictId,
  //       selectedSubDistrictId,
  //       selectedTipeSurvey,
  //       selectedUserNameSurveyor,
  //       // selectedPeriodeId,
  //       selectedRegion,
  //       selectedWilayah
  //     );
  //   }
  // }, [
  //   accessToken,
  //   selectedSurveyId,
  //   selectedRegencyId,
  //   selectedDistrictId,
  //   selectedSubDistrictId,
  //   selectedTipeSurvey,
  //   selectedUserNameSurveyor,
  //   // selectedPeriodeId,
  //   selectedRegion,
  //   selectedWilayah,
  // ]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchUserFormatted(accessToken, setDataUserSurveyor, "13");
      fetchAreasFormatted(accessToken, setDataArea);
      fetchRegionFormatted(accessToken, setDataRegion);
      fetchWilayahFormatted(accessToken, setDataWilayah);
      fetchPeriodeFormatted(accessToken, setDataPeriode);
      fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, []);
      fetchTypeOptions();
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProjectFormatted(
        accessToken,
        selectedPeriodeId,
        setDataProject,
        setselectedDateend,
        setselectedDatestart
      );
      if (selectedSurveyId !== "all") {
        fetchDataSummaryData(
          accessToken,
          setIsLoading,
          setFilteredRows,
          selectedSurveyId,
          selectedTipeSurvey,
          selectedArea,
          selectedRegion,
          selectedWilayah,
          selectedRegencyId,
          selectedDistrictId,
          selectedSubDistrictId,
          selectedUserNameSurveyor,
          selectedPeriodeId
        );
      }
      if (selectedSurveyId !== "all") {
        fetchDataStatData(
          accessToken,
          setIsLoading,
          setFilteredStatRows,
          selectedSurveyId,
          selectedTipeSurvey,
          selectedArea,
          selectedRegion,
          selectedWilayah,
          selectedRegencyId,
          selectedDistrictId,
          selectedSubDistrictId,
          selectedUserNameSurveyor
        );
      }
      fetchRegenciesFormatted(accessToken, selectedArea, setDataRegency);
      fetchDistrictFormatted(accessToken, selectedRegencyId, setDataDistrict);
      fetchSubDistrictFormatted(
        accessToken,
        selectedDistrictId,
        setDataSubDistrict
      );
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [
    accessToken,
    selectedSurveyId,
    selectedTipeSurvey,
    selectedArea,
    selectedRegion,
    selectedWilayah,
    selectedRegencyId,
    selectedDistrictId,
    selectedSubDistrictId,
    selectedUserNameSurveyor,
    selectedPeriodeId,
  ]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (selectedSurveyId !== "all") {
        fetchDataDailyTrendData(
          accessToken,
          setIsLoading,
          setDatagrafik,
          Highcharts,
          selectedSurveyId,
          selectedTipeSurvey,
          selectedArea,
          selectedRegion,
          selectedWilayah,
          selectedRegencyId,
          selectedDistrictId,
          selectedSubDistrictId,
          selectedUserNameSurveyor,
          selectedDatestart,
          selectedDateend,
          setSelectedDateFirst,
          setSelectedDateLast,
          setFilteredData
        );
      }
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [
    accessToken,
    selectedSurveyId,
    selectedTipeSurvey,
    selectedArea,
    selectedRegion,
    selectedWilayah,
    selectedRegencyId,
    selectedDistrictId,
    selectedSubDistrictId,
    selectedUserNameSurveyor,
    selectedDatestart,
    selectedDateend,
  ]);

  const setDefaultSelectedPeriode = () => {
    if (dataPeriode.length > 0) {
      const selectedOption = dataPeriode.find(
        (option) => option.id === selectedPeriodeId
      );
      if (selectedOption) {
        setSelectedPeriodeId(selectedOption.id);
      } else {
        setSelectedPeriodeId(dataPeriode[0].id);
      }
    }
  };

  const setDefaultSelectedProject = () => {
    if (dataProject.length > 0) {
      const selectedOption = dataProject.find(
        (option) => option.id === selectedSurveyId
      );
      if (selectedOption) {
        setSelectedSurveyId(selectedOption.id);
        setselectedDatestart(selectedOption.project_start_at);
        setselectedDateend(selectedOption.project_end_at);
      } else {
        setSelectedSurveyId(dataProject[0].id);
        setselectedDatestart(dataProject[0].project_start_at);
        setselectedDateend(dataProject[0].project_end_at);
      }
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDefaultSelectedPeriode();
      setDefaultSelectedProject();
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [dataPeriode, dataProject]);

  const handlePeriodeIdChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedPeriodeId(id);
    localStorage.setItem("selectedPeriodeId", id);

    setSelectedSurveyId("all");
    localStorage.setItem("selectedSurveyId", "all");
  };

  const handleProjectChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedSurveyId(id);
    localStorage.setItem("selectedSurveyId", id);
  };

  // const handleTipeSurveyChange = (event, newValue) => {
  //   let id = "";
  //   if (newValue) {
  //     id = newValue.id;
  //   }
  //   setSelectedTipeSurvey(id);
  //   localStorage.setItem("selectedTipeSurvey", id);
  // };

  const handleTipeSurveyChange = (event) => {
    setSelectedTipeSurvey(event.target.value);
    localStorage.setItem(
      "selectedTipeSurvey",
      JSON.stringify(event.target.value)
    );
  };

  const handleRegionChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedRegion(id);
    localStorage.setItem("selectedRegion", id);
  };

  const handleWilayahChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedWilayah(id);
    localStorage.setItem("selectedWilayah", id);
  };

  const handleAreaChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedArea(id);
    localStorage.setItem("selectedArea", id);
    setSelectedRegencyId("all");
    localStorage.setItem("selectedRegencyId", "all");
    setSelectedDistrictId("all");
    localStorage.setItem("selectedDistrictId", "all");
    setSelectedSubDistrictId("all");
    localStorage.setItem("selectedSubDistrictId", "all");
  };

  const handleRegencyIdChange = (event, newValue) => {
    let id = "";
    let provinceId = "";
    if (newValue) {
      id = newValue.id;
      provinceId = newValue.province_code;
    }
    setSelectedRegencyId(id);
    localStorage.setItem("selectedRegencyId", id);
    setSelectedDistrictId("all");
    localStorage.setItem("selectedDistrictId", "all");
    setSelectedSubDistrictId("all");
    localStorage.setItem("selectedSubDistrictId", "all");
  };

  const handleDistrictIdChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedDistrictId(id);
    localStorage.setItem("selectedDistrictId", id);

    setSelectedSubDistrictId("all");
    localStorage.setItem("selectedSubDistrictId", "all");
  };

  const handleSubDistrictIdChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedSubDistrictId(id);
    localStorage.setItem("selectedSubDistrictId", id);
  };

  const handleUserNameSurveyorChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedUserNameSurveyor(id);
    localStorage.setItem("selectedUserNameSurveyor", id);
  };

  const handleDateChangeFirst = (event) => {
    const dateValuefirst = event.target.value;
    setSelectedDateFirst(dateValuefirst);
    filterDataByDateRange(dateValuefirst, selectedDateLast);
  };

  const handleDateChangeLast = (event) => {
    const dateValuelast = event.target.value;
    setSelectedDateLast(dateValuelast);
    filterDataByDateRange(selectedDateFirst, dateValuelast);
  };

  const filterDataByDateRange = (startDate, endDate) => {
    const filtered = Datagrafik.filter(
      (data) =>
        data.date_of_data_collected >= startDate &&
        data.date_of_data_collected <= endDate
    );
    setFilteredData(filtered);
  };

  const categories = filteredData.map((item) => {
    // mengambil tanggal dalam bentuk data/angka
    const date = new Date(item.date_of_data_collected);
    //mengubah tanggal dalam bentuk angka menjadi text
    const month = date.toLocaleString("en-US", { month: "long" });
    //mengambil tahun dan tanggal
    const day = date.getDate();
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  });
  const validData = filteredData.map((item) => item.store_valid_count);
  const closedData = filteredData.map((item) => item.store_closed_count);

  const filteredReachChartOption = {
    chart: {
      type: "line",
      renderTo: "daily-trend-chart",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    series: [
      {
        name: "Closed",
        data: closedData,
        color: "#DF2E38",
      },
      {
        name: "Valid",
        data: validData,
        color: "#5D9C59",
      },
    ],
  };
  if (document.getElementById("daily-trend-chart")) {
    Highcharts.chart(filteredReachChartOption);
  }

  const totalClosed = filteredStatRows?.find(item => item.audit_status === "Closed")?.count_audit_status || 0;
  const totalValid = filteredStatRows?.find(item => item.audit_status === "Valid")?.count_audit_status || 0;
  const totalOnProgress = filteredStatRows?.find(item => item.audit_status === "On Progress")?.count_audit_status || 0;

  const filteredDailyUpdateChartOption = {
    chart: {
      type: "column",
      renderTo: "daily-progress-diagram",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: ["Closed Store", "Valid Stores", "On Progress"],
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    series: [
      {
        name: "Closed Store",
        data: [totalClosed],
        color: "#DF2E38",
      },
      {
        name: "Valid Stores",
        data: [totalValid],
        color: "#5D9C59",
      },
      {
        name: "On Progress",
        data: [totalOnProgress],
      },
    ],
  };

  if (document.getElementById("daily-progress-diagram")) {
    Highcharts.chart(filteredDailyUpdateChartOption);
  }

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Dashboard"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allow to access this page</div>
      </div>
    );
  }
  return (
    <div>
      <Navbar
        active="Dashboard"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div className="content" style={{ padding: "1rem" }}>
        <div className="legend">
          {/* search bar */}
          <div className="dahsboard-searchbar">
            <SearchBarDashboard
              id={"periode-autocomplete"}
              data={dataPeriode}
              value={
                dataPeriode.find((option) => option.id === selectedPeriodeId) ||
                null
              }
              handleChange={handlePeriodeIdChange}
              label={"Periode"}
            />
            <SearchBarDashboard
              id={"project-autocomplete"}
              data={dataProject}
              value={
                dataProject.find((option) => option.id === selectedSurveyId) ??
                null
              }
              handleChange={handleProjectChange}
              label={"Project"}
            />
            {/* <SearchBarDashboard
              id={"tipe-survey-autocomplete"}
              data={dataTipeSurvey}
              value={
                dataTipeSurvey.find(
                  (option) => option.id === selectedTipeSurvey
                ) ?? null
              }
              handleChange={handleTipeSurveyChange}
              label={"Tipe Survey"}
              multiple
            /> */}
            <FormControl sx={{ mr: 1, height: 40 }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Tipe Survey
              </InputLabel>
              <Select
                sx={{ height: 40, marginTop: 1 }}
                labelId="demo-multiple-checkbox-label"
                label="Tipe-Survey"
                id="demo-multiple-checkbox"
                multiple
                value={selectedTipeSurvey}
                onChange={handleTipeSurveyChange}
                input={<OutlinedInput label="Tipe Survey" />}
                renderValue={(selected) => selected.join(",")}
                MenuProps={MenuProps}
              >
                {dataTipeSurvey.map((tipesurvey) => (
                  <MenuItem key={tipesurvey.id} value={tipesurvey.id}>
                    <ListItemText primary={tipesurvey.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <SearchBarDashboard
              id={"region-autocomplete"}
              data={dataRegion}
              value={
                dataRegion.find((option) => option.id === selectedRegion) ??
                null
              }
              handleChange={handleRegionChange}
              label={"Region"}
              disabled={isLoading}
            />
            <SearchBarDashboard
              id={"wilayah-autocomplete"}
              data={dataWilayah}
              value={
                dataWilayah.find((option) => option.id === selectedWilayah) ??
                null
              }
              handleChange={handleWilayahChange}
              label={"Wilayah"}
              disabled={isLoading}
            />
            <SearchBarDashboard
              id={"area-autocomplete"}
              data={dataArea}
              value={
                dataArea.find((option) => option.id === selectedArea) ?? null
              }
              handleChange={handleAreaChange}
              label={"Area"}
              disabled={isLoading}
            />
            <SearchBarDashboard
              id={"regency-autocomplete"}
              data={dataRegency}
              value={
                dataRegency.find((option) => option.id === selectedRegencyId) ??
                null
              }
              handleChange={handleRegencyIdChange}
              label={"Kota/Kab"}
              disabled={isLoading}
            />
            <SearchBarDashboard
              id={"district-autocomplete"}
              data={dataDistrict}
              value={
                dataDistrict.find(
                  (option) => option.id === selectedDistrictId
                ) ?? null
              }
              handleChange={handleDistrictIdChange}
              label={"Kec"}
              disabled={isLoading}
            />
            <SearchBarDashboard
              id={"sub-district-autocomplete"}
              data={dataSubDistrict}
              value={
                dataSubDistrict.find(
                  (option) => option.id === selectedSubDistrictId
                ) ?? null
              }
              handleChange={handleSubDistrictIdChange}
              label={"Kel"}
              disabled={isLoading}
            />
            <SearchBarDashboard
              id={"full-name-surveyor"}
              data={dataUserSurveyor}
              value={
                dataUserSurveyor.find(
                  (option) => option.id === selectedUserNameSurveyor
                ) ?? null
              }
              handleChange={handleUserNameSurveyorChange}
              label={"Full Name Surveyor"}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
      <div style={{ padding: "10px" }}>
        <DataGridDashboard loading={isLoading} rows={filteredRows} />
      </div>
      <div className="dashboard-card">
        <div className="Content-Card-Dashboard" style={{ width: "98%" }}>
          {filteredStatRows?.map((item) => (
            <CardDashboard
              key={item.audit_status}
              audit_status={item.audit_status}
              count_audit_status={item.count_audit_status}
              percentage={item.percentage}
            />
          ))}
        </div>
      </div>
      <div className="box-update">
        <Box
          sx={{
            height: 530,
            borderRadius: "10px",
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            backgroundColor: "#FCFCFC",
            width: "48%",
          }}
          className="box-dashboard"
        >
          <div className="label">
            <p
              className="text-wrapper"
              style={{
                bgcolor: "#000000",
                fontFamily: "Roboto",
                fontWeight: 590,
                fontSize: 24,
                textAlign: "center",
                margin: "2px",
                paddingTop: "30px",
              }}
            >
              Pencapaian Toko Closed, Valid, dan On Progress
            </p>
          </div>
          <div id="daily-progress-diagram"></div>
        </Box>
        <Box
          sx={{
            height: 530,
            borderRadius: "10px",
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            backgroundColor: "#FCFCFC",
            marginRight: "1%",
            width: "48%",
          }}
          className="box-dashboard"
        >
          {Datagrafik.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: 5,
              }}
            >
              <div>
                <label
                  htmlFor="firstDate"
                  style={{
                    bgcolor: "#000000",
                    fontFamily: "Roboto",
                    fontWeight: 600,
                    fontSize: 15,
                    textAlign: "center",
                    margin: "5px",
                  }}
                >
                  Tanggal Awal:
                </label>
                <br></br>
                <input
                  style={{
                    backgroundColor: "#f5f2f2",
                    padding: "10px",
                    fontFamily: "Roboto Mono, monospace",
                    color: "#000000",
                    fontSize: "18px",
                    border: "none",
                    outline: "none",
                    borderRadius: "5px",
                  }}
                  id="firstDate"
                  name="date"
                  type="date"
                  value={selectedDateFirst}
                  onChange={handleDateChangeFirst}
                  min={Datagrafik[0].date_of_data_collected}
                  max={selectedDateLast}
                />
              </div>
              <div>
                <label
                  htmlFor="lastDate"
                  style={{
                    bgcolor: "#000000",
                    fontFamily: "Roboto",
                    fontWeight: 600,
                    fontSize: 15,
                    textAlign: "center",
                    margin: "5px",
                  }}
                >
                  Tanggal Akhir:
                </label>
                <br></br>
                <input
                  style={{
                    backgroundColor: "#f5f2f2",
                    padding: "10px",
                    fontFamily: "Roboto Mono, monospace",
                    color: "#000000",
                    fontSize: "18px",
                    border: "none",
                    outline: "none",
                    borderRadius: "5px",
                  }}
                  id="lastDate"
                  name="date"
                  type="date"
                  value={selectedDateLast}
                  onChange={handleDateChangeLast}
                  min={selectedDateFirst}
                  max={Datagrafik[Datagrafik.length - 1].date_of_data_collected}
                />
              </div>
            </div>
          )}
          <div className="label">
            <p
              className="text-wrapper"
              style={{
                bgcolor: "#000000",
                fontFamily: "Roboto",
                fontWeight: 600,
                fontSize: 25,
                textAlign: "center",
                margin: "5px",
              }}
            >
              Pencapaian Toko Valid & Closed per Hari
            </p>
          </div>
          <div id="daily-trend-chart"></div>
        </Box>
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "4rem",
          marginTop: "30px",
        }}
        className="Status"
      >
        <LegendItem color="#DF2E38" label="closed" />
        <LegendItem color="#5D9C59" label="valid" />
        <LegendItem color="#2CAFFE" label="on progress" />
        <LegendItem color="#2F383E" label="not started" />
      </div>
      <div
        className="Peta"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px",
          marginBottom: "25px",
        }}
      >
        {isLoading ? (
          <div style={{ margin: "50px 0px" }}>Loading Map...</div>
        ) : (
          <MapLayout
            markersData={{
              data: surveyDataId,
              icon: MapIcon,
            }}
            position={mapPosition}
            zoom={mapZoom}
            scrollWheelZoom={true}
            styleContainer={{ width: "98%", height: "500px" }}
            renderDataDetail={detailMasterData}
            geoJSONData={filteredGeoJSONData}
            pathOptions={{ color: "black" }}
          />
        )}
      </div> */}
    </div>
  );
}

export default Dashboard;
