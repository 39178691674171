import React from 'react';
import { DataGrid } from "@mui/x-data-grid";

const DataTable = ({ rows, columns: initialColumns, setRows, setRowModesModel }) => {
  
  const columns = initialColumns.map(column => ({
    ...column,
    flex: 1
  }));

  return (
    <div>
      <DataGrid
        autoWidth
        sx={{
          maxWidth: "100%", 
          fontSize: 15,
          mt: 2,
          ml: 2,
          mr: 2,
          "& .super-app-theme--header": {
            backgroundColor: "rgb(6,157,174)", 
            color: "#FFF",
            border: 2,
            fontWeight: "bold",
            borderColor: "#FFF",
          },
        }}
        getRowHeight={() => 'auto'}
        getRowId={(row) => row.id}
        rows={rows}
        columns={columns}
        editMode="row"
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </div>
  );
};

export default DataTable;
